import {
  cleanOptions,
  dropOptions,
  inspectionOptions,
  maintenanceOptions,
  pickupOptions,
  ShuttleLocationOption,
  shuttleServiceOptions,
  TaskShuttleNumPersonsOptions,
  taskTypeOptions,
} from "@classes/Task/taskStrings";
import * as Yup from "yup";

const taskValidationErrorTextBase = "Please provide a ";
const buildTaskValidationErrorText = (suffix: string) =>
  taskValidationErrorTextBase + suffix + ".";
export const taskTitleValidationErrorText =
  buildTaskValidationErrorText("title");
export const taskRelatedValidationErrorText =
  buildTaskValidationErrorText("related");
export const taskAssigneeValidationErrorText =
  buildTaskValidationErrorText("assignee");
export const taskReporterValidationErrorText =
  buildTaskValidationErrorText("reporter");
export const taskSubTypeValidationErrorText =
  buildTaskValidationErrorText("sub type");
export const taskShuttleServiceValidationErrorText =
  buildTaskValidationErrorText("time");
export const taskTypeValidationErrorText = buildTaskValidationErrorText("type");
// export const taskDescriptionValidationErrorText =
//   buildTaskValidationErrorText("description");
export const taskStatusValidationErrorText =
  buildTaskValidationErrorText("status");
export const taskDuedateValidationErrorText =
  buildTaskValidationErrorText("due date");
export const taskPriorityValidationErrorText =
  buildTaskValidationErrorText("priority");

export const TaskFormValidator = Yup.object().shape({
  // Task Form field key
  // The following keys are present in mode: (M)aintenance, (D)rop, (I)nspection, (S)huttle Service
  //
  // description        M D
  // reporter           M D
  // sub_type             D I
  // title              M D
  //
  // assignee           M D I
  // due_datetime       M D I
  // priority           M D I
  // related            M D I
  // status             M D I
  // type               M D I
  //
  // pick_up_location         S S
  // drop_off_location        S S

  title: Yup.lazy((value) => {
    // not needed on inspection tasks
    if (value !== undefined) {
      return Yup.string().min(1).required(taskTitleValidationErrorText);
    }
    return Yup.string().notRequired();
  }),
  reporter: Yup.lazy((value) => {
    // not needed on inspection tasks
    if (value) {
      return Yup.object().required(taskReporterValidationErrorText);
    }
    return Yup.object().notRequired();
  }),

  assignee: Yup.object().required(taskAssigneeValidationErrorText),
  related: Yup.object().required(taskRelatedValidationErrorText),
  sub_type: Yup.string()
    .when(["type"], {
      is: "Maintenance",
      then: (scheme) =>
        scheme
          .oneOf(maintenanceOptions, taskSubTypeValidationErrorText)
          .required(taskSubTypeValidationErrorText),
    })
    .when(["type"], {
      is: "Pickup",
      then: (scheme) =>
        scheme
          .oneOf(pickupOptions, taskSubTypeValidationErrorText)
          .required(taskSubTypeValidationErrorText),
    })
    .when(["type"], {
      is: "Drop",
      then: (scheme) =>
        scheme
          .oneOf(dropOptions, taskSubTypeValidationErrorText)
          .required(taskSubTypeValidationErrorText),
    })
    .when(["type"], {
      is: "Clean",
      then: (scheme) =>
        scheme
          .oneOf(cleanOptions, taskSubTypeValidationErrorText)
          .required(taskSubTypeValidationErrorText),
    })
    .when(["type"], {
      is: "Inspection",
      then: (scheme) =>
        scheme
          .oneOf(inspectionOptions, taskSubTypeValidationErrorText)
          .required(taskSubTypeValidationErrorText),
    })
    .when(["type"], {
      is: "Shuttle Service",
      then: (scheme) =>
        scheme
          .oneOf(shuttleServiceOptions, taskSubTypeValidationErrorText)
          .required(taskSubTypeValidationErrorText),
    }),
  type: Yup.string()
    .oneOf(taskTypeOptions, taskTypeValidationErrorText)
    .required(taskTypeValidationErrorText),
  inventory_id: Yup.string().notRequired().nullable(),
  description: Yup.string().notRequired().nullable(true),
  due_datetime: Yup.string().required(taskDuedateValidationErrorText),
  status: Yup.string().min(1).required(taskStatusValidationErrorText),
  priority: Yup.string().min(1).required(taskPriorityValidationErrorText),
});
