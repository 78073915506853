export function EmailLink({ email }: { email: string; }) {
  return (
    <a
      href={`mailTo:${email}`}
      style={{
        wordSpacing: "-1px",
      }}
    >
      {email}
    </a>
  );
}
export function PhoneLink({ phoneNumber }: { phoneNumber: string; }) {
  return (
    <a
      href={`tel:${phoneNumber}`}
      style={{
        wordSpacing: "-1px",
      }}
    >
      {phoneNumber}
    </a>
  );
}
