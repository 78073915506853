import { LoadingButton } from "@mui/lab";
import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import AppFormInputText from "@components/Inputs/AppFormInputText";
import useYupValidationResolver from "@components/Inputs/Validators";
import useProfile from "@hooks/Selectors/useProfile";
import { auth } from "../../Config/Firebase/app";
import { sendPasswordResetEmail } from "firebase/auth";
import { SnackBarData } from "../Snackbar";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import { ToggleProfileSidebarContext } from "../Sidebars/ProfileSidebar";
import { FontSizeToggle, ThemeToggle } from "../Theme";
import { AppBarToggle } from "../Sidebars/SideBar";
import { logOut } from "../../Store/Reducers/Session";
import ModalHeader from "@components/Auth/Shared/ModalHeader";
import Copyright from "@config/Copyright";
import "./forgotPassword.css"

type OpenResetPasswordDialogType = [
  openResetPasswordDialog: boolean,
  setOpenResetPasswordDialog: React.Dispatch<React.SetStateAction<boolean>>
]

export const OpenResetPasswordDialog = React.createContext<OpenResetPasswordDialogType>(undefined!);

export default function ResetPasswordProvider({ children }: any) {
  const [openResetPasswordDialog, setOpenResetPasswordDialog] =
    React.useState(false);
  return (
    <OpenResetPasswordDialog.Provider
      value={[openResetPasswordDialog, setOpenResetPasswordDialog]}
    >
      <ResetPasswordDialog
        open={openResetPasswordDialog}
        setOpen={setOpenResetPasswordDialog}
      />
      {children}
    </OpenResetPasswordDialog.Provider>
  );
}

interface ResetPasswordForm {
  Reset_Password_Email: string;
}

const validation = Yup.object().shape({
  Reset_Password_Email: Yup.string()
    .required("Please provide your email!")
    .email("Please provide a valid email!"),
});

function ResetPasswordDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: any;
}) {
  const user = useProfile();
  const [, setSnackData] = React.useContext(SnackBarData);
  const resolver = useYupValidationResolver(validation);
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    reset,
  } = useForm<ResetPasswordForm>({
    defaultValues: {
      Reset_Password_Email: user?.email || "",
    },
    resolver,
    mode: "all",
    reValidateMode: "onChange"
  });
  React.useEffect(() => {
    if (user !== null) {
      if (user.email) {
        setValue("Reset_Password_Email", user.email);
      }
    }
  }, [setValue, user]);
  // const disabled = Object.keys(errors).length > 0;
  const dispatch = useAppDispatch();
  const openProfileSidebar = React.useContext(ToggleProfileSidebarContext);
  const toggleFontSize = React.useContext(FontSizeToggle);
  const toggleTheme = React.useContext(ThemeToggle);
  const [, toggleAppSidebar] = React.useContext(AppBarToggle);
  const [emailLinkWasSentToo, setEmailLinkWasSentToo] = React.useState<string | null>(null)

  const signOutOfAppliction = async () => {
    Promise.all([
      openProfileSidebar(false),
      toggleFontSize("regular"),
      toggleTheme("light", { sync: false }),
      toggleAppSidebar(false),
    ])
      .then(() => { })
      .catch(() => { });
    await dispatch(logOut());
  };

  const onSubmit = handleSubmit((data) => {
    if (auth) {
      const { Reset_Password_Email: email } = data;
      sendPasswordResetEmail(auth, email);
      setSnackData({
        severity: "success",
        color: "success",
        message: `Reset link has been sent to ${email}`,
        autoHideDuration: 10000
      });
      if (auth.currentUser) {
        console.log('AUTH: Logout::ResetPassword');
        // we do not sign out until the user leaves the info page
      }
      setEmailLinkWasSentToo(email)
    }
  });
  return (
    <Modal
      open={open}
      aria-labelledby="auth_modal"
      aria-describedby="modal used to authenticate user"
      className="forgot_password-Modal"
      sx={{ background: theme => theme.palette.primary.dark, opacity: 1, '& .MuiBackdrop-root': { backgroundColor: theme => theme.palette.primary.dark } }}
    >
      <Paper className="forgot_password-Paper">
        <ModalHeader title="Reset Password" />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {emailLinkWasSentToo === null && <form onSubmit={onSubmit} name="forgot_password-Form" className="forgot_password-Form">
            {/* <Typography className="helper-Text" fontSize="small" paragraph>A message will be sent to the provided email.</Typography> */}
            <AppFormInputText
              variant="outlined"
              name="Reset_Password_Email"
              control={control}
              label="Email"
            />
            <Typography mt={'20px'} className="helper-Text" fontSize="large" paragraph>Please provide an email address to continue.</Typography>
            <div className="forgot_password-button-group">
              <div className="forgot_password-Submit">
                <Button
                  onClick={() => {
                    reset()
                    setEmailLinkWasSentToo(null)
                    setOpen(false)
                  }}
                  variant="text"
                  color="primary"
                  type="button"
                >
                  Cancel
                </Button>
              </div>
              <div className="forgot_password-Submit">
                <LoadingButton
                  variant="contained"
                  loading={isSubmitting}
                  color="secondary"
                  type="submit"
                  disabled={!!errors.Reset_Password_Email}
                >
                  Confirm Reset
                </LoadingButton>
              </div>
            </div>
          </form>}
          {emailLinkWasSentToo !== null &&
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              min-width={'200px'}
              min-height={'200px'}
            >
              <Typography
                p={'20px'}
                textAlign="center"
                className="helper-Text"
                fontSize="large"
                paragraph>
                {`An email has been sent to ${emailLinkWasSentToo} with instructions to reset your password.`}
              </Typography>
              <div className="forgot_password-button-group">
                <div className="forgot_password-Submit">
                  <Button
                    onClick={() => {
                      // We hold until now to sign out, so that users changing from the profile menu
                      // will remain on the info page, rather than jumping to login.
                      signOutOfAppliction();

                      reset()
                      setEmailLinkWasSentToo(null)
                      setOpen(false)
                    }}
                    variant="contained"
                    color="secondary"
                    type="button"
                  >
                    Back to Login
                  </Button>
                </div>
              </div>
            </Box>
          }
        </Box>
        <Copyright />
      </Paper>
    </Modal >
  );
}