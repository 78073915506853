import { isValid, format, parseISO, formatISO } from "date-fns";
type dateParam = string | Date;

function _convertDateStringToLocalTime(dateString: string) {
  if (dateString.indexOf("T") === -1) {
    return new Date(formatISO(parseISO(dateString.concat("T00:00:00.000Z"))));
  }
  if (dateString.indexOf("T") !== -1) {
    const dateOnlyString = dateString.split("T")[0];
    return new Date(
      formatISO(parseISO(dateOnlyString.concat("T00:00:00.000Z")))
    );
  }
  return new Date(formatISO(new Date(dateString)));
}

export function validateDateParam(date: dateParam) {
  if (!date) {
    throw new Error("Please provide a date. <_validateDateParam>");
  }
  if (typeof date === "string") {
    if (isValid(new Date(date))) {
      return _convertDateStringToLocalTime(date);
    }
    throw new Error("Please provide a valid date string. <_validateDateParam>");
  }

  if (!isValid(date)) {
    throw new Error("Please provide a valid date object. <_validateDateParam>");
  }
  return date;
}

export default function formatDateParam(date: dateParam) {
  let validatedDateParam = validateDateParam(date);
  const formatedDate = format(validatedDateParam, "yyyy-MM-dd");
  return formatedDate;
}
