import { Add, Archive, ArrowDropDown, CheckCircle, ContentCopy, ModeEditOutline, PauseCircle, PlayCircle, SkipNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Menu, MenuItem, ClickAwayListener, IconButton, Typography, Divider } from '@mui/material';
import * as React from 'react'
import { createMessageFromAction } from '../../Sub_Components/TaskCard/Footer/Helpers';
import { SnackBarData } from '@context/Snackbar';
import useProfile from '@hooks/Selectors/useProfile';
import { TaskChangeShouldRefreshView, TaskDrawerControl } from '@context/Task/TaskDrawer';
import CompleteTaskModal from '../../Sub_Components/TaskCard/Footer/ConfirmModal/CompletedConfirmModal';
import MoveTaskModal from '../../Sub_Components/TaskCard/Footer/ConfirmModal/MoveTaskConfrimModal';
import ConfirmModal from '../../Sub_Components/TaskCard/Footer/ConfirmModal';
import { createFollowUpTaskFromOldTask, FollowUpTask } from './TaskFollowUpButton';
import { environment } from '@config/index';
import useSalesforceRequest from '@request/Salesforce';
import { ApiTask } from '../../../../Types/task';
/*
======================================================================
* Action     ||	Style                   || Type     || Conditional                                              ||	Location   || compact card?
* Edit Task  ||	Dark Blue w/ White Font || Fixed    ||	Display when is_closed = false                          ||	2nd column ||	no
* Reset      ||	Dark Blue Text          || Fixed    ||	Display when is_closed = true                           ||	2nd column ||	no
* Start      ||	Light Blue w/White Font || Primary  ||	Display when is_closed = false,                         ||  1st column ||	yes
*                                                        is_timed = true, status = "Not Started",
*                                                         Assigned To type = "User"
* Resume     ||	Light Blue w/White Font || Primary  ||	Display when is_closed = false,                         || 1st column  ||	yes
*                                                        is_timed = true,
*                                                         status = "On Hold",
*                                                         Assigned To type = "User"
* Pause      ||	Light Blue w/White Font || Primary  ||	Display when is_closed = false,                         || 1st column  ||	yes
*                                                        is_timed = true,
*                                                        status = "In Progress",
*                                                        Assigned To type = "User"
* Complete   ||	Dark Blue w/ White Font || Primary  ||	Display when status = "In Progress" OR is_timed = false,|| 4th column  ||	yes
*                                                        Assigned To type = "User",
*                                                        is_closed = false
* Claim      ||	Green w/ White Font     || Secondary||	Display when assigned to type = "Group",                || 3rd column
*                                                        is_closed = false
* Defer      ||	Green w/ White Font     || Secondary||	Display when is_closed = false                          ||	3rd column
* Move       ||	Dark Blue Text w/Icon	  || Secondary||	Display when is_closed = false                          ||	3rd column
* */

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center"
}
/**
 * @param TaskActionBtnProps
 * @param Options[]
 * should return a loading button with label matching correct action
 * should return a confirm modal to:
 * confirm, <in some cases> : have a select option, have a checkbox option
 */
interface TaskActionBtnVersionTwoPros {
  options: Record<string, string>[];
  defaultAction: string;
  label: string;
  taskId: string;
  // These props are specific to the buttons action
  setStatus?: React.Dispatch<React.SetStateAction<string | null>>;
  reset?: boolean;
  toggleLabel?: string;
  onToggleChange?: any;
  toggleValue?: boolean;
  selectOptions?: {
    label: string;
    value: string;
  }[];
  selectDefaultValue?: string;
  selectLabel?: string;
  curTask: ApiTask | null;
  overrideEnabled: null | boolean;
  bypassOverrideWithNoOptions?: null | boolean;
  setCurTask: (
    taskId: string | null,
    newTask?: ApiTask | undefined
  ) => ApiTask | null;
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function TaskMultiActionButton(props: TaskActionBtnVersionTwoPros) {
  const {
    defaultAction,
    label,
    options,
    taskId,
    curTask,
    overrideEnabled,
    bypassOverrideWithNoOptions = null,
    setCurTask,
    // openForm,
    setOpenForm
  } = props;
  const [, setSnackData] = React.useContext(SnackBarData);
  const user = useProfile()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, toggleDrawer] = React.useContext(TaskDrawerControl)
  const [loading, setLoading] = React.useState<boolean>(false)

  //---------- Handles menu toggling start -----------------
  const [anchorElementForMenuDropDown, setAnchorElementForMenuDropdown] = React.useState<null | HTMLElement>()
  const [openActionSelectMenu, setOpenActionSelectMenu] = React.useState(false)
  const menuId = React.useId()
  //---------- Handles menu toggling end -----------------
  const [, changeShouldRefreshViewInList] = React.useContext(TaskChangeShouldRefreshView)
  //--------- Handles menu dropdown label and action start -------------
  const [actionLabel, setActionLabel] = React.useState(label)
  const [curAction, setCurAction] = React.useState(defaultAction)

  const taskActionRequest = useSalesforceRequest()

  //These use effects are there to reset the action label and curAction should the props change
  React.useEffect(() => {
    setCurAction(defaultAction)
  }, [defaultAction])
  React.useEffect(() => {
    setActionLabel(label)
  }, [label])

  //--------- Handles menu dropdown label and action end -------------

  //--------- Handles confirm modal start --------------------
  const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false)
  //--------- Handles confirm modal end --------------------

  //--------- Handle escalating on complete start ---------------
  const [escalateTask, setEscalateTask] = React.useState<boolean>(false)
  //--------- Handle escalating on complete end ---------------

  //---------- On click of the drop down icon ---------------
  const handleDropDownArrowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElementForMenuDropdown(event.currentTarget)
    setOpenActionSelectMenu(true)
  }

  //----------- On Menu selection------------
  const handleMenuClose = (action: string, label: string) => {
    setActionLabel(label)
    setCurAction(action)
    setOpenActionSelectMenu(false)
  }

  //------------ Will open confirm modal-------------
  const handleActionButtonClick = async () => {
    if (curAction === 'editTask') {
      setOpenForm(true)
    } else {
      setOpenConfirmModal(true)
    }
  }

  //------ This function will preform our action if confimed to execute in modal ---------
  const handleConfirmClick = React.useCallback((config?: { groupToReassignToo: string }) => {
    setLoading(true)
    if (curAction === 'followUpTask') {
      setLoading(false) // no loading state needed for this selection
      toggleDrawer(false)
      console.log("Current action === 'followUp', see handleConfirmClick in TaskMultiAction!")
      if (curTask) {
        let { id, ...otherCurTaskProperties } = curTask
        const follow_up_task_props = otherCurTaskProperties as FollowUpTask
        follow_up_task_props.follow_up_task = true
        const newTaskPayload = createFollowUpTaskFromOldTask(follow_up_task_props)
        setCurTask(null, newTaskPayload)
      }
      setOpenForm(true)
      return
    }

    let action = curAction

    if (escalateTask === true && action === "endTask") {
      action = 'endTask&escalate=true'
    }

    if (user === null) {
      // This component cannot exist without the user model. In this case, the system
      // should already be routing the user to login.
      return;
    }

    if (props.reset) {
      const resetRequest = taskActionRequest({ endpoint: `/api/task/${taskId}?action=resetTask` })
      const request = taskActionRequest({ endpoint: config ? `/api/task/${taskId}?action=${action}&queue_id=${config.groupToReassignToo}` : `/api/task/${taskId}?action=${action}` })
      resetRequest.attemptSend().then((resetResult) => {
        if (!resetResult.callSent) return
        request.attemptSend().then((result) => {
          if (!result.callSent) return
          const json = result.GetParsedJson()
          if (json.hasOwnProperty('task')) {
            console.log("Resetting current task. Will request for new task payload!")
            setCurTask(json.task.id)
          }
        })
        return
      }).catch((e) => {
        console.error(e);
        setCurTask(null);
      })
    } else {
      let endpoint = `/api/task/${taskId}?action=${action}`
      if (action === "moveTask") {
        if (config && config.groupToReassignToo !== undefined && config.groupToReassignToo !== 'doNotReassign') {
          endpoint = endpoint.concat(`&queue_id=${config.groupToReassignToo}`)
        }
      }
      const performTaskAction = taskActionRequest({ endpoint, method: "PATCH" })
      performTaskAction.attemptSend().then((result) => {
        if (!result.callSent) return
        const json = result.GetParsedJson()
        if (json.hasOwnProperty("task")) {
          const { task } = json
          setSnackData({
            message: createMessageFromAction(action),
            color: "success",
            severity: "success",
          });
          if (typeof props.setStatus === 'function') {
            props.setStatus(task.status.toUpperCase());
          }
          console.log("calling setCurTask in performTaskAction from TaskMultiAction.", task)
          setCurTask(null, task);
          changeShouldRefreshViewInList(true)
          return
        } else {
          setSnackData({ message: "error", color: "error", severity: "error" });
          setCurTask(null);
          return
        }
      }).catch((e) => {
        if (environment.env === "development") {
          console.error(e);
        }
        setCurTask(null);
      }).finally(() => {
        setLoading(false);
      });

    }
    return;
  }, [changeShouldRefreshViewInList, curAction, curTask, escalateTask, props, setCurTask, setOpenForm, setSnackData, taskActionRequest, taskId, toggleDrawer, user]);

  const color = determinColorFromAction(defaultAction)

  let actionEnabled = overrideEnabled === null ? true : overrideEnabled;
  let optionsEnabled = actionEnabled && options.length > 1;

  if (bypassOverrideWithNoOptions !== null) {
    actionEnabled = true;
    optionsEnabled = false;
  }

  return (
    <div>
      {curAction === 'moveTask' && <MoveTaskModal open={openConfirmModal} setOpen={setOpenConfirmModal} handleConfirm={handleConfirmClick} />}
      {curAction === 'endTask' && <CompleteTaskModal open={openConfirmModal} setOpen={setOpenConfirmModal} handleConfirm={handleConfirmClick} escalateTask={escalateTask} setEscalateTask={setEscalateTask} />}
      {(curAction !== 'moveTask' && curAction !== 'endTask') && <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} curAction={curAction} handleConfirm={handleConfirmClick} />}
      <Box
        className='Button_Max_Width-Container'
        sx={{
          ...center,
          backgroundColor: (theme) => theme.palette?.[color].main,
          borderRadius: '4px',
          boxShadow: (theme) => theme.shadows[1],
        }}
      >
        {/* This is the MAIN BUTTON */}
        <LoadingButton
          className={curAction}
          sx={{
            justifyContent: "flex-start",
            height: '36px',
            borderTopRightRadius: optionsEnabled ? "0px" : "4px",
            borderBottomRightRadius: optionsEnabled ? "0px" : "4px",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
            pl: '8px',
            pr: '8px',
          }}
          size={"medium"}
          disabled={loading || !actionEnabled}
          loading={loading}
          onClick={handleActionButtonClick}
          variant="contained"
          disableElevation
          color={color}
          loadingPosition="center"
          loadingIndicator={<Typography color={(theme) => theme.palette.common.white}>Loading</Typography>}
          startIcon={<DeterminedIconFromAction action={curAction} />}>
          <Typography variant='button' whiteSpace={'nowrap'} fontSize={"medium"}>{actionLabel}</Typography>
        </LoadingButton>
        <Divider orientation='vertical' flexItem sx={{ width: '.5px', borderColor: (theme) => theme.palette.common.white }} />

        {/* This is the OPENER (which lets you change the main button) */}
        {optionsEnabled &&
          <IconButton
            sx={{ pr: '4px', pl: '4px', height: '36px', }}
            size={'large'}
            className={curAction}
            disabled={loading || !optionsEnabled}
            color={color}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleDropDownArrowClick(e)
            }}
          >
            {openActionSelectMenu === true ?
              <ArrowDropDown
                sx={{
                  transform: 'rotate(180deg)',
                  color: (theme) => openConfirmModal ? theme.palette.text.disabled : theme.palette.common.white
                }}
              /> : <ArrowDropDown
                sx={{
                  color: (theme) => openConfirmModal ? theme.palette.text.disabled : theme.palette.common.white
                }} />}
          </IconButton>
        }
      </Box>
      <ClickAwayListener onClickAway={() => {
        setOpenActionSelectMenu(false)
      }}>
        <Menu
          id={menuId}
          anchorEl={anchorElementForMenuDropDown}
          open={openActionSelectMenu === true}
          onClose={() => {
            setOpenActionSelectMenu(false)
          }}
          MenuListProps={{
            'aria-labelledby': `action-button-${curAction}`,
          }}>
          {/* <MenuItem onClick={() => handleMenuClose(defaultAction, actionLabel)} key={actionLabel}>{actionLabel}</MenuItem> */}
          {options.map((option) => {
            return <MenuItem onClick={() => handleMenuClose(option.action, option.label)} key={option.action}>{option.label}</MenuItem>
          })}
        </Menu>
      </ClickAwayListener>
    </div>
  )


}


/**
 *
 * @param props action to preform
 * @returns Appropriate Icon for button
 */
function DeterminedIconFromAction(props: { action: string }) {
  switch (props.action) {
    case 'moveTask':
      return <SkipNext />;
    case 'resetTask':
      return null
    case 'startTask':
      return <PlayCircle />
    case 'pauseTask':
      return <PauseCircle />
    case 'endTask':
      return <CheckCircle />
    case 'editTask':
      return <ModeEditOutline />
    case 'deferTask':
      return <Archive />
    case 'claimTask':
      return <Add />
    case 'followUpTask':
      return <ContentCopy />
    default:
      return null
  }
}

function determinColorFromAction(action: string) {
  switch (action) {
    case 'moveTask':
      return 'primary';
    case 'resetTask':
      return 'primary'
    case 'startTask':
      return 'secondary'
    case 'pauseTask':
      return 'secondary'
    case 'endTask':
      return 'primary'
    case 'editTask':
      return 'primary'
    case 'deferTask':
      return 'success'
    case 'claimTask':
      return 'success'
    case 'followUpTask':
      return 'success'
    default:
      return "primary"
  }
}
