import { Button, Typography } from "@mui/material";
import PagenationProps from "../Interfaces";

export function OpenFilterModal(props: Pick<PagenationProps, "openFilterModal">) {
  return (
    <Button
      className="pagenation-button"
      disableElevation
      disableFocusRipple
      disableRipple
      disableTouchRipple
      onClick={() => props.openFilterModal()}
      variant="contained"
      color="primary"
    >
      <Typography variant="button" className="pagenation-button-text">Filter</Typography>
    </Button>
  )
}