import FilterOptions from "@classes/Filter";
import { sortParamKey } from "@components/Task/Sub_Components/SearchPagenation/useSelectedSort";
import { cleansSubtypeOptions, PATH_TASKS } from "@context/Sidebars/SideBar";
import { Button, Typography } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AppFormLabel } from "../Task/Sub_Components/StyledComponents";
import { RadioFilterField, SelectFilterField } from "./FilterField";


export interface FilterModalTemplateProps {
  options: FilterOptions
  closeModal: () => void
}

export function FilterModalTemplate(props: FilterModalTemplateProps) {
  const [curSearchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { control, handleSubmit, reset, getValues } = useForm<FilterOptions>();
  React.useEffect(() => {
    const defaultValues = { checkBoxOptions: {}, selectOptions: {} }
    const optionTypes = Object.keys(props.options) as [keyof typeof props.options]
    optionTypes.forEach((type) => {
      const searchKeys = Object.keys(props.options[type])
      for (let searchKey of searchKeys) {
        let alreadyFilteredByLabels = curSearchParams.getAll(searchKey)
        switch (type) {
          case "selectOptions":
            const selectOptions = { [searchKey]: props.options[type][searchKey].filter((possibleOption) => alreadyFilteredByLabels.includes(possibleOption.value)) }
            Object.assign(defaultValues.selectOptions, selectOptions)
            break
          case "checkBoxOptions":
            const checkBoxKeysPerSearchItem = Object.keys(getValues("checkBoxOptions")[searchKey])
            checkBoxKeysPerSearchItem.forEach((filterOption, i) => {
              if (!alreadyFilteredByLabels.includes(filterOption)) {
                const payload = getValues("checkBoxOptions")
                payload[searchKey][filterOption] = false
                Object.assign(defaultValues.checkBoxOptions, payload)
              } else {
                const payload = getValues("checkBoxOptions")
                payload[searchKey][filterOption] = true
                Object.assign(defaultValues.checkBoxOptions, payload)
              }
            })
            break
          default:
            throw new Error("Invalid filter type")
        }
      }
    })
    reset({ selectOptions: defaultValues.selectOptions, checkBoxOptions: defaultValues.checkBoxOptions })
  }, [curSearchParams, getValues, props, reset])

  const onSubmit = handleSubmit((filterOptions) => {
    // Note needs to make a freash search params to set on successful submit
    const searchParams = getDefaultSearchParams(curSearchParams)
    // {string, {label, value}[]}
    for (const checkBoxType in filterOptions.checkBoxOptions) {
      // {label, value}
      const valueObject = filterOptions.checkBoxOptions[checkBoxType]
      for (const checkBoxKey in valueObject) {
        if (valueObject[checkBoxKey]) {
          // ?Type=Key
          searchParams.append(checkBoxType, checkBoxKey)
        }
      }
    }

    let filterKey: keyof typeof filterOptions.selectOptions
    for (filterKey in filterOptions.selectOptions) {
      if (!filterOptions.selectOptions[filterKey]) {
        continue
      }
      if (filterOptions.selectOptions[filterKey].length === 0) {
        searchParams.delete(filterKey)
        continue
      }
      for (let { value } of filterOptions.selectOptions[filterKey]) {
        if (!value) continue
        searchParams.append(filterKey, value)
      }
    }
    setSearchParams(searchParams)
    props.closeModal()

  });
  return (
    <form id="Filter-Modal-Form" onSubmit={onSubmit} className="Filter-Container">
      <div className="Filter-Select-Container">
        {Object.keys(props.options.selectOptions).map((optionTitle) => {
          return <SelectFilterField key={`selectField_${optionTitle}`} name={optionTitle} options={props.options.selectOptions[optionTitle]} control={control} />
        })}
      </div>
      <div className="Filter_Radio-Container">
        {Object.keys(props.options.checkBoxOptions).map((filterTitle, index) => {
          let sortedRadioKeys: string[] = []
          const radios = props.options.checkBoxOptions[filterTitle];
          //? NOTE: If you would like to add any custom sorting to the checkbox filter options that can be done here <Switch on filterTitle>
          // it is safe to do our sorting here as this filters are drawn just once
          if (filterTitle === "Beds") {
            // Custom sort for Beds
            let keysToSort = Object.keys(radios)
            let noStudioKeyToSort = keysToSort.slice(0, keysToSort.indexOf("Studio")).concat(keysToSort.slice(keysToSort.indexOf("Studio") + 1))
            noStudioKeyToSort = noStudioKeyToSort.sort((a, b) => {
              if (a > b) return 1
              if (a < b) return -1
              return 0
            })
            noStudioKeyToSort.unshift(keysToSort[keysToSort.indexOf("Studio")])
            sortedRadioKeys = noStudioKeyToSort
          } else {
            // Default sort for filter checkbox options
            sortedRadioKeys = Object.keys(radios).sort((a, b) => {
              if (a > b) return 1
              if (a < b) return -1
              return 0
            })
          }
          return (
            <div key={`${filterTitle}_Radio_${index}`}>
              <AppFormLabel id={`task-${filterTitle}-label`}>
                {filterTitle}
              </AppFormLabel>
              <RadioGroup row>
                {sortedRadioKeys.map((label, radioIndex) => (
                  <RadioFilterField key={`${label}-${radioIndex}`} label={label} control={control} name={`${filterTitle}.${label}`} />
                ))}
              </RadioGroup>
            </div>
          );
        })}
      </div>
      <div className="Filter_Modal-Buttons">
        <Button
          variant="text"
          type="button"
          sx={(theme) => ({
            color: theme.palette.primary.light,
            textTransform: "none",
            mr: 2,
          })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if ([
              '/listings',
              '/tasks/Engineering',
              '/tasks/OwnerExperience',
              '/tasks/ShuttleDrivers',
              '/tasks/unassigned',
              '/tasks/all',
              '/tasks/my-tasks'
            ].includes(pathname)) {
              navigate(pathname)
            } else {
              if (pathname === '/tasks/DropStrips') {
                navigate(PATH_TASKS.DROP_STRIPS)
              }
              if (pathname === '/tasks/Maintenance%20Inspections') {
                navigate(PATH_TASKS.MAINTENANCE_INSPECTION)
              }
              if (pathname === '/tasks/Cleanings') {
                navigate(PATH_TASKS.CLEANINGS)
              }
              if (pathname === '/tasks/GlassLanai') {
                navigate(PATH_TASKS.GLASS_LANAI)
              }
              if (pathname === '/tasks/Final%20Inspections') {
                navigate(PATH_TASKS.FINAL_INSPECTION)
              }
              props.closeModal();
            }
            // setSearchParams(getDefaultSearchParams(curSearchParams))
          }}
        >
          <Typography variant="button" fontWeight="bold">
            Clear Filters
          </Typography>
        </Button>
        <Button
          form="Filter-Modal-Form"
          type="submit"
          color="primary"
          variant="contained"
        >
          <Typography variant="button" fontWeight="bold">
            Apply
          </Typography>
        </Button>
      </div>
    </form>
  );
}


function getDefaultSearchParams(curSearchParams: URLSearchParams) {
  const searchParams = new URLSearchParams()
  const startDate = curSearchParams.get("taskStartDate")
  if (startDate) {
    searchParams.set("taskStartDate", startDate)
  }
  const endDate = curSearchParams.get("taskEndDate")
  if (endDate) {
    searchParams.set("taskEndDate", endDate)
  }
  const filterBy = curSearchParams.get("filterBy")
  if (filterBy) {
    searchParams.set("filterBy", filterBy)
  }
  const sortBy = curSearchParams.get(sortParamKey)
  if (sortBy) {
    searchParams.set(sortParamKey, sortBy)
  }
  return searchParams
}