import * as React from "react";
import TaskCompactCard from "./TaskCompactCard";
import TaskGroup, { Region } from "./TaskGroup";
import Stack from "@mui/material/Stack";
import createDateLabel from "@helpers/dateLabel";
import { useLocation, useSearchParams } from "react-router-dom";
import NoTaskMask from "./NoTaskMask";
import useDocumentTitle from "@hooks/Window/useDocTitle";
import TaskDrawer from "./TaskDrawer";
import { filterTasks } from "@helpers/filterTasks";
import { NoMatchingFilters } from "./NoMatchingFilters";
import { parseISO } from "date-fns";
import formatDateParam from "@helpers/formatDateParam";
import { sortParamKey } from "./SearchPagenation/useSelectedSort";
import { TaskSortKey, TaskSortKeySet, createTasksSortableByDate, extractSortedTasks, sortTasksByDueDate, sortTasksByParam, sortTasksByType } from "./sortTasks";
import { WeekViewNoTaskMask } from "./WeekViewNoTaskMask";
import { TaskListGroupLabel } from "./TaskListGroupLabel";
import { useSession } from "@hooks/Selectors/useSession";
import { defaultTaskOrder } from "@store/Reducers/Session/UserSettings";
import { ApiTask, TaskGroupKey } from "../../../Types/task";

interface TasksReducerProps {
  tasks: ApiTask[];
  startDate: string | null;
  endDate: string | null;
  filterBy: string | null;
  taskGroupKey: TaskGroupKey
}

export default function TaskSearchFilterList(props: TasksReducerProps) {
  const { startDate, endDate, filterBy, taskGroupKey } = props
  const session = useSession()
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const docTitle = createDocTitle(pathname)
  useDocumentTitle(docTitle)
  /* We are creating a deep clone of props.tasks.
   This is because js sort will mutate the original tasks! */
  const unfilteredTasks: ApiTask[] = props.tasks ? JSON.parse(JSON.stringify(props.tasks)) : []
  const totalAmountOfTasks = unfilteredTasks.length
  // Filter task using search params
  let filteredTasks = filterTasks(searchParams, unfilteredTasks)

  if (taskGroupKey === 'assignedTasks') {
    // MY Task should seporate all 'is_closed' task and place them at the bottom of the list in its own group.
    filteredTasks = filteredTasks.sort((a, b) => {
      if (a.is_closed === b.is_closed) {
        return 0
      }
      if (a.is_closed) {
        return 1
      }
      return -1
    })

  }
  /* prepare tasks for sort
     This will add an extra js_date key to the task that can be used to sort
   */
  const sortableByDateFilteredTaskArray = createTasksSortableByDate(filteredTasks)
  // set filteredTasks to the sortable array

  //! NOTE: This is a complicated list. A few things to keep in mind:
  //      - It's grouped in at multiple levels. First by date, then by Task type.
  //      - Those type groups can be rearranged by the user, so their order is tricky.
  //      - We first sort by due date, as the baseline.
  //      - Next, we assign the user's preferred sort order (default is Priority). This
  //        order applies within the type group, not the outer date group.
  //      - Finally, we sort by type, to establish those sub groups.
  //      - In Week mode, you'll see Date -> Type -> tasklist. However in Day mode you'll
  //        only see one level of grouping (Type -> tasklist).

  sortTasksByDueDate(sortableByDateFilteredTaskArray)

  //! NOTE: The list component's behavior is driven entirely by the url Param (or lack thereof).
  const urlSortParam = searchParams.get(sortParamKey);
  if (urlSortParam !== null && !TaskSortKeySet.has(urlSortParam)) {
    // unknown value; use default order
    sortTasksByParam(sortableByDateFilteredTaskArray, null);
  }
  else {
    // apply the user's chosen sort order
    sortTasksByParam(sortableByDateFilteredTaskArray, urlSortParam as (TaskSortKey | null));
  }

  let groupOrder = defaultTaskOrder
  if (session.userSettings) {
    const userTaskOrderSet = new Set(session.userSettings.task_order)
    const fullOrder = session.userSettings.task_order
    defaultTaskOrder.forEach((orderKey) => {
      if (!userTaskOrderSet.has(orderKey)) {
        fullOrder.unshift(orderKey) // bring any new entries to the top by default
      }
    })
    sortTasksByType(sortableByDateFilteredTaskArray, session.userSettings.task_order)
    groupOrder = session.userSettings.task_order
  } else {
    sortTasksByType(sortableByDateFilteredTaskArray, defaultTaskOrder)
  }
  /*
    Strip off date key we added before sorting
   */
  filteredTasks = extractSortedTasks(sortableByDateFilteredTaskArray)
  if (totalAmountOfTasks === 0 && filterBy === "day") return <NoTaskMask />
  if (totalAmountOfTasks !== 0 && filteredTasks.length === 0) {
    return <NoMatchingFilters componentName="Tasks" />
  }

  const dateLabelMap: Set<string> = new Set();
  const useDate = startDate ? parseISO(startDate) : new Date();
  const useEndDate = endDate ? parseISO(endDate) : new Date();
  while (!dateLabelMap.has(formatDateParam(useEndDate))) {
    dateLabelMap.add(formatDateParam(useDate));
    useDate.setDate(useDate.getDate() + 1);
  }
  // end result should look like {Today: { Maintenance: <TASK ARRAY>, ...  }, Tomorrow: ....}
  const datedTaskGroups = Array.from(dateLabelMap).reduce((acc, date) => {
    const label = createDateLabel(date) // TODAY, TOMORROW, YESTERDAY ...
    acc[label] = sortableByDateFilteredTaskArray.reduce((acc, task) => {
      if (task.sortable_js_date && task.sortable_type) {
        if (label === createDateLabel(task.sortable_js_date)) {
          if (acc.hasOwnProperty(task.sortable_type)) {
            acc[task.sortable_type].push(task.task)
          } else {
            acc[task.sortable_type] = [task.task]
          }
        }
      }
      return acc
    }, {} as Record<string, ApiTask[]>)
    return acc
  }, {} as Record<string, Record<string, ApiTask[]>>)

  const noMatchingDayFilters = filteredTasks.length === 0 && filterBy === 'day'
  const uniqueDateLabels = Array.from(dateLabelMap)
  return (
    <>
      {noMatchingDayFilters && (
        <NoTaskMask />
      )}
      {uniqueDateLabels.map((date, index) => <LabeledAndFilteredTaskItem key={`labeledTaskItem_${index}`} date={date} groupOrder={groupOrder} datedTaskGroups={datedTaskGroups} filterBy={filterBy} />)}
      <TaskDrawer />
    </>
  );
}

// This component groups our regions in date labels. Primarly looking at filterBy 'day' or 'week'.
function LabeledAndFilteredTaskItem({
  date,
  groupOrder,
  datedTaskGroups,
  additionRegions,
  filterBy
}: {
  date: string,
  groupOrder: string[],
  datedTaskGroups: Record<string, Record<string, ApiTask[]>>
  additionRegions?: Region[],
  filterBy: string | null
}) {
  const dateLabel = createDateLabel(date)
  let numberOfRegionsWithTasks = 0
  let firstVisableSet = false
  let regions: Region[] = groupOrder.map((group, i) => {
    const hasTasks = Object.keys(datedTaskGroups[dateLabel]).includes(group)
    if (hasTasks) {
      numberOfRegionsWithTasks++
    }
    const isFirst = hasTasks && !firstVisableSet
    if (isFirst) {
      firstVisableSet = true
    }
    return {
      group,
      hasTasks,
      tasks: hasTasks ? datedTaskGroups[dateLabel][group] : [],
      order: i,
      isFirst: isFirst,
      isLast: false
    }
  })

  if (additionRegions && additionRegions.length > 0) {
    regions = [...regions, ...additionRegions]
  }

  for (let i = regions.length - 1; i >= 0; i--) {
    if (regions[i].hasTasks) {
      regions[i].isLast = true
      break
    }
  }

  return (
    <Stack key={`${dateLabel}_container-stack`} mb={1} width="100%">
      {filterBy === "week" && (
        <TaskListGroupLabel label={dateLabel} date={parseISO(date)} />
      )}
      {numberOfRegionsWithTasks === 0 && filterBy === 'week' && <WeekViewNoTaskMask />}
      {regions.map((region, index, arr) => (<TaskRegion key={`regions_${region.group}_${index}`} region={region} regions={arr} />))}
    </Stack>
  )
}

// This Region is the group i.e Maintenance and all of its task cards
function TaskRegion({
  regions,
  region,
}: {
  regions: Region[],
  region: Region,
}) {
  const [showRegion, setShowRegion] = React.useState(true)
  const toggleGroup = () => { setShowRegion(prev => !prev) }
  const hasTask = region.hasTasks
  const size = region.tasks.length
  if (!hasTask) {
    return null
  }
  return (
    <Stack key={`${region.group}-outter-container`} width="100%">
      <TaskGroup
        regions={regions}
        region={region}
        title={region.group}
        toggleGroup={toggleGroup}
        size={size}
        hidden={!showRegion}
      />
      {
        showRegion && region.tasks.map((task: ApiTask) => {
          return (
            <TaskCompactCard
              task={task}
              key={`${task.id}_comapact_card-container`}
            />
          );
        })
      }
    </Stack>
  )
}
// --------------------------------------------

function createDocTitle(pathname: string) {
  return pathname === "/tasks/my-tasks"
    ? "My Tasks"
    : pathname === "/tasks/all"
      ? "All Tasks"
      : pathname === "/tasks/DropStrips"
        ? "Drop & Strips"
        : pathname === "/tasks/GlassLanai"
          ? "Glass & Lanai"
          : decodeURIComponent(pathname.split("/tasks/")[1])
}