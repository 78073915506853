import * as React from "react";
import { TaskDrawerControl } from "../../../../../Context/Task/TaskDrawer";
import { Stars } from "@mui/icons-material";
import { CardItem } from "@components/Typography/CardItemText";
import { ApiTask } from "../../../../../Types/task";

export default function AssigneeReporterRow({ curTask }: { curTask: ApiTask | null }) {
  const [, toggleDrawer] = React.useContext(TaskDrawerControl);
  if (curTask === null) return null;
  const isCase = curTask.related?.hasOwnProperty("case_number") || false;
  const hasHouseman = curTask.related?.hasOwnProperty("houseman") || false; // we always show houseman on task drawer
  const hasInspector = curTask.sub_type === 'Glass & Lanai' ? curTask.related?.hasOwnProperty("inspector") : false;
  const hasEngineer = curTask.sub_type === 'Departure Clean' ? curTask.related?.hasOwnProperty("inspector") : false;

  return (
    <>
      <div className="Task-Card-Item-Container">
        <div className="Task-Card-Item-Row">
          {curTask.type && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem header="Type" text={curTask.type || "-"} />
            </div>
          )}
          {curTask.sub_type && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem header="Sub Type" text={curTask.sub_type || "-"} />
            </div>
          )}
        </div>
        <div className="Task-Card-Item-Row">
          {!isCase && curTask.assignee && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Assignee"
                text={curTask.assignee.name}
                linkProps={{
                  to: `/users/${curTask.assignee_id}`,
                  onClick: () => {
                    toggleDrawer(false)
                  }
                }} />
            </div>
          )}
          {isCase && curTask.related?.owner_name && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Owner"
                text={curTask.related.owner_name}
                textIcon={
                  curTask.related.owner_name
                    ? Stars
                    : undefined
                }
              />
            </div>
          )}
          {curTask.reporter && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Reporter"
                text={curTask.reporter.name}
                linkProps={{
                  to: `/users/${curTask.reporter_id}`,
                  onClick: () => {
                    toggleDrawer(false)
                  }
                }} />
            </div>
          )}
        </div>
        <div className="Task-Card-Item-Row">
          {curTask.additional_housekeepers && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Additional Housekeepers"
                splitAsArray
                arrayOfLines={curTask.additional_housekeepers}
                text=''
              />
            </div>
          )}
          {hasHouseman && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Houseman"
                text={(curTask.related as any).houseman}
              />
            </div>
          )}
          {hasInspector && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Inspector"
                text={(curTask.related as any).inspector}
              />
            </div>
          )}
          {hasEngineer && (
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Engineer"
                text={(curTask.related as any).engineer}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
