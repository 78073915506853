import * as React from 'react'
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import { AttachFile } from "@mui/icons-material";
import { Typography } from "@mui/material";
import InputForFileManager from './InputForFileManager';
import { TaskAttachmentContentPostRequest } from '@context/Attachments';

interface ActionButtonProps {
  taskId?: string;
  label?: string;
}

export default function AttachButton(props: ActionButtonProps) {
  const id = React.useId()
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const postAttachmentContent = React.useContext(TaskAttachmentContentPostRequest)
  const inputId = props.taskId ? `image_upload_input_${props.taskId}_${id}` : `image_upload_input_${id}`
  return (
    <>
      <InputForFileManager id={inputId} ref={fileInputRef} taskId={props.taskId} taskAttachmentContentPostRequest={postAttachmentContent} />
      <label htmlFor={inputId}>
        <Tooltip title="Upload File">
          <LoadingButton size="small" startIcon={<AttachFile />} component="span" variant="text" disableFocusRipple disableRipple>
            <Typography variant="button">{props.label}</Typography>
          </LoadingButton>
        </Tooltip>
      </label >
    </>
  );
}
