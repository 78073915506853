export enum TaskFilterKeys {
  assignee = "Assignee",
  listing = "Listing",
  status = "Status",
  type = "Types",
  Subtype = "Subtype",
  priority = "Priority",
  pickup = "Pickup",
}

export type CheckBoxOptions = Record<string, Record<string, boolean>>;
export type SelectOptions = Record<
  string,
  { label: string; value: string; group: string }[]
>;

export default class FilterOptions {
  constructor(
    public selectOptions: SelectOptions,
    public checkBoxOptions: CheckBoxOptions
  ) {}
}
