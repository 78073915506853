import {
  bedroomParamKey,
  ListingFilterOptions,
} from "./createSelectableObjects";

export function filterListing(
  searchParams: URLSearchParams,
  unfilteredListings: ApiListing[]
) {
  const isFilteredResort = searchParams.has(ListingFilterOptions.Resort);
  const isFilteredBedroom = searchParams.has(ListingFilterOptions.Beds);
  const isFilteredStatus = searchParams.has(ListingFilterOptions.Status);
  const isFilteredUnit = searchParams.has(ListingFilterOptions.Listings);

  const resortTerms = isFilteredResort
    ? new Set(searchParams.getAll(ListingFilterOptions.Resort))
    : null;
  const bedroomTerms = isFilteredBedroom
    ? new Set(searchParams.getAll(ListingFilterOptions.Beds))
    : null;
  const statusTerms = isFilteredStatus
    ? new Set(searchParams.getAll(ListingFilterOptions.Status))
    : null;
  const listingTerms = isFilteredUnit
    ? new Set(searchParams.getAll(ListingFilterOptions.Listings))
    : null;

  return unfilteredListings.filter((listing) => {
    if (isFilteredResort) {
      if (!listing.resort_name) {
        return false;
      }
      if (!resortTerms?.has(listing.resort_name)) {
        return false;
      }
    }
    if (isFilteredBedroom) {
      if (listing.bedrooms === undefined) {
        return false;
      }
      if (!bedroomTerms?.has(bedroomParamKey(listing))) {
        return false;
      }
    }
    if (isFilteredStatus) {
      if (!listing.listing_status) {
        return false;
      }
      if (!statusTerms?.has(listing.listing_status)) {
        return false;
      }
    }
    if (isFilteredUnit) {
      if (!listing.unit_id) {
        return false;
      }
      if (!listingTerms?.has(listing.unit_id)) {
        return false;
      }
    }
    return true;
  });
}
