import * as React from "react";
import { Divider, Typography } from "@mui/material";
import useDocumentTitle from "@hooks/Window/useDocTitle";
import './unknownPage.css'
import { useNavigate } from "react-router-dom";
import { StyledBoxContainer } from "@containers/StyledBoxContainer";

export default function UnknownPage() {
  useDocumentTitle("Unknown Page");
  const navigate = useNavigate()
  const timeout = setTimeout(() => {
    navigate("/home")
  }, 5_000)

  React.useEffect(() => {
    return () => { clearTimeout(timeout) }
  }, [navigate, timeout])

  const onClick = () => {
    clearTimeout(timeout)
    navigate("/home")
  }

  return (
    <div className="Center-Container">
      <div className="Unknown-Page_Container">
        <StyledBoxContainer onClick={onClick} title="" subTitle="404 page not found.">
          <Divider />
          <br></br>
          <br></br>
          <Typography variant="h5" color={theme => theme.palette.mode === "light" ? "GrayText" : theme.palette.common.white}>
            You will be navigated back to the homepage automatically.
          </Typography>
          <br></br>
          <br></br>
          <Divider />
          <br></br>
          <Typography variant="body1" color={theme => theme.palette.mode === "light" ? "GrayText" : theme.palette.common.white}>Sorry, we will not be able to navigate you to: </Typography>
          <Typography variant="subtitle2" textOverflow="ellipsis" overflow='hidden' color={theme => theme.palette.mode === "light" ? "GrayText" : theme.palette.common.white}>{window.location.href}</Typography>
        </StyledBoxContainer >
      </div>
    </div>
  );
}
