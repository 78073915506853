import Typography from "@mui/material/Typography";
import RelatedNameDateTime from "./RelatedNameDateTime";
import { ApiTask } from "../../../../../Types/task";
import { Chip, Stack } from "@mui/material";
import listingStatusColor from "../../../../../utils/listingStatusColor";

export default function TopRow(props: {
  curTask: ApiTask | null,
}) {

  if (!props.curTask) return null

  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <Stack direction='row' spacing={1} mr={1}>
          <RelatedNameDateTime curTask={props.curTask} />
          {props.curTask.related?.listing_status && <Chip size="small" color={listingStatusColor(props.curTask.related.listing_status)} label={props.curTask.related.listing_status} />}
        </Stack>
        <Typography color={(theme) => theme.palette.mode === 'light' ? "GrayText" : theme.palette.common.white} letterSpacing={0} whiteSpace="nowrap" textAlign="right" fontWeight="bold" variant="subtitle2">
          {props.curTask.status}
        </Typography>
      </Stack>
    </>
  );
}
