import * as React from 'react';

import CardContent from '@mui/material/CardContent';

const _PDF = 'data:application/pdf'
/**
 * 
 * @param props PDFCardProps
 * @returns JSX.Element for image "data:application/pdf"
 */
export default function PDFCard(props: FileData) {
  const { src, meta, handleClick } = props
  const id = React.useId()


  if (!src.startsWith(_PDF)) {
    console.error(`Not PDF: ${src.slice(0, 100)}...`)
    return null
  }

  return (
    <CardContent>
      <object
        name={meta?.name}
        id={id}
        aria-haspopup="true"
        type={meta?.type || "application/pdf"}
        data={src}
        title={`pdf_content_${id}`}
        height="75px"
        width="100px"
        style={{ padding: 0, margin: 0 }}
      />
      {/* This is a work around to allow us to click and display the object element in multiple devices! */}
      <div
        onClick={handleClick}
        style={{
          backgroundColor: '#efefef',
          opacity: '0.0',
          position: "absolute",
          left: 0,
          top: 0,
          height: '100%',
          width: '100%',
        }} />
    </CardContent>
  )
}