import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../Config/Firebase/app";
import validateAndRetriveUserDoc from "./validateAndRetriveUserDoc";

const verboseLogging = true;

export async function removeMessageIdFromFirestore(
  emailIdentifier: string,
  message_id: string
) {
  verboseLogging && console.log("Entering removeMessageIdFromFirestore");
  // exit if there is no authorized user to interact with the firestore
  // throw an error if message_id is invalid
  if (typeof message_id !== "string" || message_id === "")
    throw new Error("Please provide a valid message id.");

  try {
    // parse firestore data
    const existingUserData = await validateAndRetriveUserDoc(emailIdentifier);
    if (existingUserData === undefined) {
      throw new Error("Problem with firestore document.");
    }
    // for users who have not logged in after Jan 31st will not have the message_ids array on their user document yet.
    if (existingUserData === null) {
      verboseLogging && console.warn("User does not exist in firestore.");
      return;
    }
    if (
      !Array.isArray(existingUserData.message_ids) ||
      existingUserData.message_ids.length === 0
    ) {
      verboseLogging && console.log("No Firestore message_ids to remove");
      return;
    }
    const ids = new Set(existingUserData.message_ids);
    verboseLogging && console.log("Ids set", ids);
    if (ids.delete(message_id)) {
      verboseLogging &&
        console.log("Id removed from firestore message_ids.", message_id);
      const messageIdPayload = {
        ...existingUserData,
        message_ids: Array.from(ids),
      };
      const userRef = doc(firestore, "Users", emailIdentifier);
      await setDoc(userRef, messageIdPayload);
      return messageIdPayload;
    }
    verboseLogging &&
      console.log("message_ids not found in firestore", message_id);
  } catch (e) {
    console.error(e);
  }
}
