import { FCMSubscription } from '@classes/Notifications/notifications'
import * as React from 'react'

export const FCMSubscriptionContext = React.createContext<React.MutableRefObject<FCMSubscription>>({ current: new FCMSubscription() })

export default function FCMSubscriptionContextProvider(props: React.PropsWithChildren) {
  //Note: destructoring will create a referance not a clone
  const subscriptionRef = React.useRef(new FCMSubscription())

  return (
    <FCMSubscriptionContext.Provider value={subscriptionRef}>
      {props.children}
    </FCMSubscriptionContext.Provider>
  )
}