import * as React from 'react';
import { Modal } from '@mui/material';
import { StyledBoxContainer } from '@containers/StyledBoxContainer';
import FilterHandler from '@classes/Filter';
import { FilterModalTemplate } from '@components/Filter/FilterModalTemplate';
import { listingCheckboxFilters, listingSelectableFilters } from '@helpers/createSelectableObjects';
import "./listingFilterModal.css"

// const testReservations: any[] = []
// const testStudio: ApiListing = {
//   bathrooms: 2,
//   bedrooms: 0,
//   id: "a084V00000xboWbQAI",
//   lessor_name: "Mark Potkin and Laura Hash",
//   listing_status: "Dirty",
//   name: "Alii 1105",
//   name_detailed: "Kaanapali Alii 1105",
//   next_check_in: "2023-01-21T02:00:00.000Z",
//   owner_name: "Lorin Ifkovic",
//   reservations: testReservations,
//   resort_name: "The Kaanapali Alii",
//   turnaround: true,
//   unit_id: "A1105",
//   unit_view: "Oceanfront"
// }

export function ListingFilterModal(props: { onClose: () => void; open: boolean; listings: ApiListing[]; }) {
  const { listings } = props
  // const test = React.useMemo(() => [...listings, testStudio], [listings])
  const options = React.useMemo(() => new FilterHandler(listingSelectableFilters(listings), listingCheckboxFilters(listings)), [listings])

  return (
    <Modal disableAutoFocus disableEnforceFocus onClose={props.onClose} open={props.open} classes={{ root: "Filter-Modal" }}>
      <div id="Mui-Ref-Holder">
        <StyledBoxContainer title="Filters" borderBottom disableScrollToo>
          <div className="Listing-Filter-Modal_Container">
            <FilterModalTemplate closeModal={props.onClose} options={options} />
          </div>
        </StyledBoxContainer>
      </div>
    </Modal>
  );
}
