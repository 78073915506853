import { ApiTask } from "../../../Types/task"

export type SortDirectionKey = "asc" | "desc";

export class SortableTask {
  public readonly sortable_js_date: Date | null
  public readonly sortable_assignee_name: string | null
  public readonly sortable_unit_id: string | null
  public readonly sortable_type: string | null
  public readonly sortable_status: string | null
  public readonly sortable_priority: number | null
  public readonly sortable_sub_type: string | null
  constructor(
    public readonly task: ApiTask
  ) {
    this.sortable_js_date = task.due_datetime ? new Date(task.due_datetime) : null
    this.sortable_assignee_name = task.assignee && task.assignee.name ? task.assignee.name : null
    this.sortable_unit_id = task.related && task.related.unit_id ? task.related.unit_id : null
    this.sortable_type = task.type
    this.sortable_status = task.status
    this.sortable_priority = typeof task.priority_value === 'number' ? task.priority_value : 3
    this.sortable_sub_type = task.sub_type ? task.sub_type : null
  }
}

export const enum TaskSortKey {
  Assignee = "Assignee",
  Listing = "Listing",
  Status = "Status",
  Priority = "Priority",
  Subtype = "Subtype"
};

// this is a simple string because this is used to validate a url param, which could contain anything
export const TaskSortKeySet = new Set<string>();

//TODO: TS isn't letting me iterate over this enum, or convert it to an array
TaskSortKeySet.add(TaskSortKey.Assignee);
TaskSortKeySet.add(TaskSortKey.Listing);
TaskSortKeySet.add(TaskSortKey.Status);
TaskSortKeySet.add(TaskSortKey.Priority);
TaskSortKeySet.add(TaskSortKey.Subtype);

// Is being called in every compare function to order tasks by due_datetime
function taskDueDateSortCompareFn(a: SortableTask, b: SortableTask) {
  // sort "asc" by task due date
  if (a.sortable_js_date === null) {
    return -1;
  }
  if (b.sortable_js_date === null) {
    return -1;
  }
  const dateA = a.sortable_js_date
  const dateB = b.sortable_js_date

  if (dateA > dateB) {
    return 1;
  }
  if (dateA < dateB) {
    return -1;
  }
  return 0;
}

/**
 * @mutates
 * @param tasks ApiTask array
 * @returns a sorted array of tasks by due_datetime
 */
export function sortTasksByDueDate(tasks: SortableTask[]) {
  return tasks.sort(taskDueDateSortCompareFn)
}
export function createTasksSortableByDate(tasks: ApiTask[]) {
  return tasks.map((task) => {
    return new SortableTask(task)
  })
}
export function extractSortedTasks(tasks: SortableTask[]) {
  return tasks.map((sortableTask) => {
    return sortableTask.task
  })
}
export function sortTasksByType(tasks: SortableTask[], order: string[]) {
  return tasks.sort(taskTypeSortCompareFn(order))
}
/**
 * @Mutates mutates the original task array.
 * @param tasks ApiTask array
 * @param sortParam TaskSortKey | null
 * @returns a sorted array of tasks by url search param ?sort=<param>
*/
export function sortTasksByParam(tasks: SortableTask[], sortParam: TaskSortKey | null) {
  switch (sortParam) {
    case TaskSortKey.Assignee:
      return tasks.sort(taskAssigneeSortCompareFn);
    case TaskSortKey.Listing:
      return tasks.sort(taskListingUnitIdSortCompareFn);
    case TaskSortKey.Status:
      return tasks.sort(taskStatusSortCompareFn);
    case TaskSortKey.Priority:
      return tasks.sort(taskPriorityValueSortCompareFn);
    case TaskSortKey.Subtype:
      return tasks.sort(taskSubTypeSortCompareFn);
    default:
      return tasks.sort(taskPriorityValueSortCompareFn);
  }
}

function taskAssigneeSortCompareFn(a: SortableTask, b: SortableTask) {
  // sort "asc" by task assingees name
  if (a.sortable_assignee_name === null) {
    return -1;
  }
  if (b.sortable_assignee_name === null) {
    return -1;
  }
  const nameA = a.sortable_assignee_name;
  const nameB = b.sortable_assignee_name;

  if (nameA > nameB) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  return 0
}
function taskListingUnitIdSortCompareFn(a: SortableTask, b: SortableTask) {
  // sort "asc" by task related unit id
  if (a.sortable_unit_id === null) {
    return -1;
  }
  if (b.sortable_unit_id === null) {
    return -1;
  }
  const unitA = a.sortable_unit_id;
  const unitB = b.sortable_unit_id;

  if (unitA > unitB) {
    return 1;
  }
  if (unitA < unitB) {
    return -1;
  }
  return 0
}
function taskTypeSortCompareFn(order: string[]) {
  return function (a: SortableTask, b: SortableTask) {
    // sort "asc" by task type
    if (a.sortable_type === null) {
      return -1;
    }
    if (b.sortable_type === null) {
      return -1;
    }
    const typeA = order.indexOf(a.sortable_type);
    const typeB = order.indexOf(b.sortable_type);

    if (typeA > typeB) {
      return 1;
    }
    if (typeA < typeB) {
      return -1;
    }
    return 0
  }
}
function taskStatusSortCompareFn(a: SortableTask, b: SortableTask) {
  // sort "asc" by task status
  if (a.sortable_status === null) {
    return -1;
  }
  if (b.sortable_status === null) {
    return -1;
  }
  const statusA = a.sortable_status;
  const statusB = b.sortable_status;

  if (statusA > statusB) {
    return 1;
  }
  if (statusA < statusB) {
    return -1;
  }
  return 0
}
function taskPriorityValueSortCompareFn(a: SortableTask, b: SortableTask) {
  //sort "asc" by task priority_value
  if (a.sortable_priority === null) {
    return -1;
  }
  if (b.sortable_priority === null) {
    return -1;
  }
  const priorityA = a.sortable_priority;
  const priorityB = b.sortable_priority;
  if (priorityA > priorityB) {
    return 1;
  }
  if (priorityA < priorityB) {
    return -1;
  }
  return 0
}
function taskSubTypeSortCompareFn(a: SortableTask, b: SortableTask) {
  // if either key is missing, send it to the end
  const nonNullA = a.sortable_sub_type !== null ? a.sortable_sub_type : '';
  const nonNullB = b.sortable_sub_type !== null ? b.sortable_sub_type : '';

  // basic alphabetical
  return nonNullA.localeCompare(nonNullB);
}
