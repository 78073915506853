import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Autocomplete, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import FilterOptions from "@classes/Filter";



export interface SelectFilterFieldProps {
  control: Control<any>;
  options: {
    label: string;
    value: string;
    group: string;
  }[];
  name: string
}
export interface RadioFilterFieldProps {
  control: Control<FilterOptions, any>;
  name: string;
  label: string
}

export function SelectFilterField(props: SelectFilterFieldProps) {
  return (
    <Controller
      name={`selectOptions.${props.name}`}
      control={props.control}
      render={({ field }) => {
        return (
          <Autocomplete
            size="small"
            multiple={true}
            groupBy={(option) => typeof option.group === 'string' ? option.group : ""}
            value={field.value || []}
            onChange={(__, val) => {
              field.onChange(val);
            }}
            getOptionLabel={(option) => option.label || ""}
            ref={field.ref}
            onBlur={field.onBlur}
            options={props.options}
            isOptionEqualToValue={(option, value) => {
              return option.label === value.label;
            }}
            renderInput={(params) => <TextField label={props.name} {...params} />} />);
      }} />
  );
}
export function RadioFilterField(props: RadioFilterFieldProps) {
  return (
    <Controller
      name={`checkBoxOptions.${props.name}`}
      control={props.control}
      render={({ field }) => {
        return (
          <FormControlLabel
            classes={{ root: "Filter_Checkbox" }}
            key={`${props.name}-FormControlLabel`}
            label={props.label}
            control={<Checkbox onChange={field.onChange} checked={!!field.value} />}
          />
        )
      }}
    />)
}
