import * as React from "react";
import { Box, IconButton } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { SnackBarData } from "@context/Snackbar";

interface RefreshButtonProps {
  asyncRefresh: (a?: any) => any;
}

const snackbarMessageStart = "Refreshing Tasks!"

export default function RefreshButton(props: RefreshButtonProps) {
  const [disabled, setDisabled] = React.useState(false);
  const [, setSnackData] = React.useContext(SnackBarData);

  const refresh = async () => {
    setDisabled(true);
    setSnackData({
      message: snackbarMessageStart,
      color: "info",
      id: 'refreshing_task_list'
    });
    await props.asyncRefresh();

    setDisabled(false);
  };

  return (
    <Box width='100%' height='100%'>
      <IconButton
        className={disabled ? "spin-me" : ""}
        disabled={disabled}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={refresh}
        aria-label="Refresh Tasks"
      >
        <Refresh />
      </IconButton>
    </Box>
  );
}
