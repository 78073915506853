import FileManager from "@components/FileManager";
import MessagesTextEditor from "@components/Messages/MessageComponent";
import { editorConfig } from "@components/Messages/plugins/config";
import { FollowUpTask } from "@components/Task/TaskControls/ActionButtons/TaskFollowUpButton";
import ErrorFallback from "@components/UserPage/Error";
import Fallback from "@components/UserPage/Fallback";
import { StyledBoxContainer } from "@containers/StyledBoxContainer";
import { FileManagerWorking, useGetTaskAttachments } from "@context/Attachments";
import { addTaskPermissionFields } from "@context/Task/taskUtils";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";
import useProfile from "@hooks/Selectors/useProfile";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { Box, Grid, Stack } from "@mui/material";
import useSalesforceRequest from "@request/Salesforce";
import * as React from "react";
import { useParams } from "react-router-dom";
import { ApiTask } from "../../../../Types/task";
import TaskFormModal from "../../Modals/TaskFormModal";
import ContactDetail from "../TaskCard/ContactRow";
import DateFooter from "../TaskCard/DateFooter";
import DescriptionRow from "../TaskCard/DescriptionRow";
import TaskDrawerFooter from "../TaskCard/Footer";
import RelatedRow from "../TaskCard/RelatedRow";
import TaskDetail from "../TaskCard/TaskDetail";
import TitleRow from "../TaskCard/TitleRow";
import TopRow from "../TaskCard/TopRow";
import "./taskPage.css";

const verboseLogging = false;
const Logger = "color:#db5e2c"

export default function TaskPage() {
  const user = useProfile()
  const persistedObject = usePersistentObject()
  const { taskId } = useParams() // will parse the taskId from the url
  const [taskFormOpen, setTaskFormOpen] = React.useState(false)
  const taskRequest = useSalesforceRequest()
  const { fileManagerWorking } = React.useContext(FileManagerWorking)
  const [curTask, setCurTask] = React.useState<ApiTask | FollowUpTask | null>(null)
  const [attachmentsMeta, setAttachmentsMeta] = React.useState<any[]>([])
  const { getTaskAttachments, getTaskAttachmentsContent } = useGetTaskAttachments()
  const [loadingState, setLoadingState] = React.useState<APP_STATE>("unset")
  const [attachmentState, setAttachmentsState] = React.useState<APP_STATE>("unset")
  const [formTask, setFormTask] = React.useState(curTask)
  //Boolean to display if we should display the task discription.
  const [showDescription, setShowDescription] = React.useState<boolean>(true)
  React.useEffect(() => {
    // We will not want to trigger a rerender if curTask is a follow up task
    if (curTask !== null) {
      let typedTask = curTask as FollowUpTask
      if (typedTask.follow_up_task) return
    }
    if (curTask === null || curTask.id !== taskId) {
      setLoadingState('unset')
    }
  }, [curTask, taskId])

  React.useEffect(() => {
    if (fileManagerWorking === false) {
      setAttachmentsState("unset")
    }
  }, [fileManagerWorking])

  //Responsible for getting the current task based off the url params for taskId
  React.useEffect(() => {
    if (loadingState === "unset") {
      setLoadingState("loading")
      verboseLogging && console.log("%cATTEMPTING GET TASKS ON TASKPAGE!", Logger)

      const getTaskRequest = taskRequest({ endpoint: `/api/task/${taskId}` })

      getTaskRequest.attemptSend().then(async (resultingTask) => {
        if (!resultingTask.callSent) {
          setLoadingState("error")
          return null // session expired
        }
        const taskJson = resultingTask.GetParsedJson();

        if (taskJson.status === 200) {
          addTaskPermissionFields(taskJson.task, user, persistedObject)
          setCurTask(taskJson.task as ApiTask)
          setFormTask(taskJson.task as ApiTask)
          setLoadingState("success")
          return
        }
        throw Error("Failed to get task")
      }).catch((error) => {
        console.error(error)
        setLoadingState('error')
      })
    }
  }, [loadingState, taskId, taskRequest, persistedObject])

  //Responsible for getting the attachments meta and content
  React.useEffect(() => {
    if (curTask === null) return
    if (attachmentState !== 'unset') return
    setAttachmentsState("loading")
    verboseLogging && console.log("%cTASKPAGE GETTING ATTACHMENTS", Logger)
    getTaskAttachments(curTask.id).then(async (meta) => {
      setAttachmentsMeta(meta)
      if (Array.isArray(meta)) {
        meta.forEach(async value => await getTaskAttachmentsContent(value))
        setAttachmentsState("success")
        return
      }
      throw new Error("Failed to get attachments for task.")
    }).catch((e) => {
      console.error(e)
      setAttachmentsState("error")
    })
  }, [attachmentState, curTask, getTaskAttachments, getTaskAttachmentsContent])

  function setTask(_taskId: string | null, _followUpTask?: ApiTask | undefined) {
    if (_followUpTask !== undefined) {
      setCurTask(_followUpTask)
      setFormTask(_followUpTask)
      return null
    }
    setCurTask(null)
    setLoadingState('unset')
    setAttachmentsState('unset')
    setTaskFormOpen(false)
    return null
  }
  if (loadingState === 'loading') return <Fallback message="Loading task..." />
  if (loadingState === 'error') return <ErrorFallback message="Problem loading task!" />
  if (curTask === null || taskId === undefined) return null
  return (
    <>
      <TaskFormModal refreshAttachments={() => { setAttachmentsState('unset') }} setAttachmentsMeta={setAttachmentsMeta} attachmentsMeta={attachmentsMeta} curTask={formTask} setCurTask={setTask} close={
        () => {
          setTaskFormOpen(false)
          // setTask(null)
        }} taskId={curTask?.id} open={taskFormOpen} />
      <Stack rowGap={2} pb='60px'>
        <div className="Task-Page-Header">
          <StyledBoxContainer title="" disableScrollToo>
            <TopRow curTask={curTask} />
            <TitleRow permission_to_enter={curTask.permission_to_enter} curTask={curTask} />
          </StyledBoxContainer>
        </div>
        <Grid container columns={2} spacing={2}>
          <Grid item sm={2} md={1} zeroMinWidth>
            <StyledBoxContainer disableScrollToo borderBottom title="Task Details" className='Task-Page-Title-Border'>
              <TaskDetail curTask={curTask} hideTitle />
              <DescriptionRow
                handleClick={() => {
                  setShowDescription(prev => !prev)
                  return !showDescription
                }}
                description={curTask.description}
              />
              <RelatedRow related={curTask.related} type={curTask.related_type} alwaysShowDescription={true} />
              <ContactDetail contact={curTask.contact} />
              <DateFooter curTask={curTask} />
            </StyledBoxContainer>
          </Grid>
          {
            attachmentState === "success" &&
            <Grid item sm={2} md={1} width='100%'>
              <StyledBoxContainer disableScrollToo borderBottom title="Messages and Attachments" className='Task-Page-Title-Border'>
                <div className="Task-Messages-Container">
                  <FileManager disableBorder setAttachmentsMeta={setAttachmentsMeta} attachmentsMeta={attachmentsMeta} delayedAttachments={[]} />
                  <LexicalComposer initialConfig={editorConfig}>
                    <MessagesTextEditor
                      curTaskOrListing={curTask}
                    />
                  </LexicalComposer>
                </div>
              </StyledBoxContainer>
            </Grid>
          }
        </Grid>
        {
          attachmentState === 'loading' &&
          <StyledBoxContainer title="">
            <Fallback minWidth="350px" message="Loading messages and attachments..." />
          </StyledBoxContainer>
        }
        {
          attachmentState === 'error' && <ErrorFallback disableRedirect message="Problem loading messages and attachments!" />
        }
        <Box
          bgcolor={(theme) => theme.palette.background.paper}
          boxShadow={(theme) => theme.shadows[4]}
          className="Task-Page-Footer-Container">
          <TaskDrawerFooter
            openForm={taskFormOpen}
            setOpenForm={setTaskFormOpen}
            curTask={curTask}
            setCurTask={setTask}
            is_timed={curTask.is_timed}
            is_closed={curTask.is_closed}
            taskId={curTask.id}
            taskStatus={curTask.status}
            can_claim={curTask.is_claimable}
          />
        </Box>
      </Stack>
    </>
  );
}

