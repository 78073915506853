import * as React from "react";

import { useNavigate } from "react-router-dom";

import {
  ThemeToggle,
  // FontSizeToggle,
  Theme,
  // ThemeSize,
} from "@context/Theme";
import {
  ProfileSidebarNavigationContext,
  ToggleProfileSidebarContext,
} from "@context/Sidebars/ProfileSidebar";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import { logOut } from "@store/Reducers/Session";
// import { Persistor } from "../../Store"

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Switch from "@mui/material/Switch";
import {
  VerifiedUser,
  Copyright,
  Logout,
  // TextDecrease,
  // TextIncrease,
  Notifications,
} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { TaskDrawerControl } from "@context/Task/TaskDrawer";
import { ListItemButton, Stack } from "@mui/material";
import useProfile from "@hooks/Selectors/useProfile";
import { FCMSubscriptionContext } from "@context/Notifications";
import { environment } from "@config/index";
// import { AppBarToggle } from "@context/Sidebars/SideBar";

export default function ProfileSidebar() {
  const dispatch = useAppDispatch();
  const user = useProfile()
  const fcmNotifications = React.useContext(FCMSubscriptionContext)
  // const [, setSnackData] = React.useContext(SnackBarData)
  // const [, toggleAppSidebar] = React.useContext(AppBarToggle);
  const navigate = useNavigate();
  const toggleTheme = React.useContext(ThemeToggle);
  // const fontSize = React.useContext(ThemeSize);
  // const toggleFontSize = React.useContext(FontSizeToggle);
  // const [TextIcon, setTextIcon] = React.useState<"increase" | "decrease">(
  //   fontSize === "regular" ? "increase" : "decrease"
  // );
  const theme = React.useContext(Theme);
  const profileSidebarIsOpen = React.useContext(
    ProfileSidebarNavigationContext
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [taskDrawerOpen, toggleTaskDrawer] = React.useContext(TaskDrawerControl);

  const openProfileSidebar = React.useContext(ToggleProfileSidebarContext);

  const signOut = async () => {
    console.log('AUTH: Logout::ProfileSideBar');
    openProfileSidebar(false)
    toggleTaskDrawer(false)
    toggleTheme("light", { sync: false })
    if (fcmNotifications.current.isSubscribed && user !== null) {
      await fcmNotifications.current.unsubscribe(user.email)
    }
    await dispatch(logOut())
    navigate("/login")
  };
  if (user === null) return null
  return (
    <Drawer
      anchor="right"
      open={profileSidebarIsOpen}
      onClose={() => {
        openProfileSidebar(false);
      }}
      onKeyDown={(event) => {
        if (event.key === "ESC") {
          openProfileSidebar(false);
        }
      }}
    >
      <Box
        sx={{
          width: 200,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
        role="presentation"
        onKeyDown={(event) => {
          if (event.key === "ESC") {
            openProfileSidebar(false);
          }
        }}
      >
        <Toolbar />
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ListItemButton
            key="profileLink"
            onClick={() => {
              openProfileSidebar(false);
              return navigate(`/users/${user.id}`);
            }}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ListItemIcon>
              <VerifiedUser sx={{ color: (theme) => theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
          {/* <ListItemButton
            key="notificationsLink"
            onClick={() => {
              openProfileSidebar(false);
              return navigate("/notifications");
            }}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ListItemIcon>
              <Notifications sx={{ color: (theme) => theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton> */}
          <Divider light sx={{ width: "100%" }} />
          <ListItem key="setTheme" onClick={() => toggleTheme()}>
            <Switch
              inputProps={{ "aria-label": "Mode" }}
              sx={{ left: -1 }}
              checked={theme.palette.mode === "dark"}
            />
            <ListItemText
              primary={theme.palette.mode === "light" ? "Light" : "Dark"}
            />
          </ListItem>
          {/* <ListItem
          button
          key="setFontSize"
          onClick={() => {
            toggleFontSize();
            setTextIcon((prev) =>
              prev === "increase" ? "decrease" : "increase"
            );
          }}
        >
          <ListItemIcon>
            {TextIcon === "increase" ? <TextIncrease /> : <TextDecrease />}
          </ListItemIcon>
          <ListItemText primary="Font" />
        </ListItem> */}
          <Divider light sx={{ width: "100%" }} />
          <ListItemButton
            key="logOutLink"
            onClick={async () => {
              return await signOut();
            }}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ListItemIcon>
              <Logout sx={{ color: (theme) => theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItemButton>
          <Divider light sx={{ width: "100%" }} />
          <ListItem
            key="copywrite_profile_sidebar"
            sx={{ margin: 0, mt: 2, justifySelf: "flex-end", }}
          >
            <Stack alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={2}>
                <Copyright sx={{ color: (theme) => theme.palette.primary.main, width: 16, height: 16 }} />
                <ListItemText secondary="Maui Resort Rentals" />
              </Stack>
              <div key="version_label" style={{ visibility: environment.mrrEnv === "production" ? "hidden" : "visible", fontSize: 14 }}>Version 000026</div>
            </Stack>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
