import { CompactCard } from '@components/Task/Sub_Components/StyledComponents/CompactCard';
import ListingDetail from '@components/Task/Sub_Components/TaskCard/RelatedRow/ListingDetail';
import { useNavigate } from 'react-router-dom';

interface ListingCompactCardProps {
  listing: ApiListing;
}
export function ListingCompactCard(props: ListingCompactCardProps) {
  const { listing } = props;
  const navigate = useNavigate()
  const onClick = () => { navigate(`/listing/${listing.id}`) }
  return (
    <CompactCard paperProps={{ onClick }} variant={createListingVariant(listing)}>
      <ListingDetail listing={listing} fullTitle />
    </CompactCard>

  );
}

/**
 * @param Listing: ApiListing
 * @returns will return a matching varient or undefined driven by listing.listing_status
 */
function createListingVariant(listing: ApiListing) {
  if (!listing) throw new Error("Please provide a valid listing.")
  switch (listing.listing_status) {
    case "Cleaning":
      return 'secondary'
    case "Dirty":
      return 'warning'
    default:
      return undefined
  }
}