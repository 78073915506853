import { isBefore, addDays } from 'date-fns'
import { TaskStatus } from '../../../../Strings/TaskStatus'
import { ApiTask } from '../../../../Types/task'


/**
 * @param oldTask : ApiTask
 * @returns a new task which is the oldTask with its id removed
 *
 * The due date in the follow up task will also be set to today at 5 pm
 * if 5 pm has past it will set it to tommorrow at 5 pm.
 *
 * @important we are setting an optional parameter in ApiTask "follow_up_task".
 *
 * ** This follow_up_task boolean will be removed in the task form and is used to
 * ** differentiate from 'create' and 'follow up' ( Concernces task form title )
 */
export interface FollowUpTask extends ApiTask {
  follow_up_task?: boolean,
  reset?: boolean,
  is_claimable_by_user?: boolean,
  is_manageable_by_user?: boolean,
  is_my_task?: boolean
}
export function createFollowUpTaskFromOldTask(oldTask: ApiTask) {
  let due_datetime = new Date()
  due_datetime.setHours(17, 0, 0, 0) // Today @ 5:00 PM

  while (isBefore(due_datetime, new Date())) {
    due_datetime = addDays(due_datetime, 1) // Shift days by 1 @ 5:00 PM
  }
  // if related type is 'Reservation__c' use the related.listing_id as the related_id for the follow up task
  const related_id =
    oldTask.related_type === 'Reservation__c'
      && oldTask?.related
      ? oldTask.related?.listing_id !== undefined
        ? oldTask.related.listing_id
        : oldTask.related_id
      : oldTask.related_id

  const newTask: FollowUpTask = {
    ...oldTask,
    // id: "", // here we remove the taskId
    follow_up_task: true,
    due_datetime: due_datetime.toJSON(), // here we reassign the due date to the next end of work day.
    status: TaskStatus.NotStarted,
    related_id: related_id,
    ...(oldTask.description && { description: "" }), // here we clear out any description if that task has a description.
    ...(oldTask.title && { title: "" }) // here we clear out the title if the task has a title
  }

  return newTask
}