import * as React from "react";
import "./message.css";

import { format } from "date-fns";

import useProfile from "@hooks/Selectors/useProfile";
import useUserPhotos from "../UsersPhoto";

import { Grid, Avatar, Typography, Tooltip, Paper } from "@mui/material";
import { useLocation } from "react-router-dom";

// Recives a dynamic list of messages related to the task in the drawer
export default function MessagesList({
  messages,
}: {
  messages: ApiFeedItem[];
}) {
  const user = useProfile();
  const { pathname } = useLocation()
  const photosGraber = useUserPhotos();
  const lastMessageScrollRef = React.useRef<HTMLDivElement>(null);
  // ! scroll to most recent message
  // React.useEffect(() => {
  //   if (messages?.length > 0) {
  //     lastMessageScrollRef.current!.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [messages]);

  if (user === null) {
    // This component cannot exist without the user model. In this case, the system
    // should already be routing the user to login.
    return null;
  }

  return (
    <>
      {messages
        .sort((...messages) => {
          const [messageDate, nextMessageDate] = messages.map(
            (message) => new Date(message.modstamp)
          );
          if (messageDate > nextMessageDate) {
            return -1;
          }
          if (messageDate < nextMessageDate) {
            return 1;
          }
          return 0;
        })
        .map((messageData, i) => {
          let {
            body,
            // has_content,
            owner_name,
            id,
            is_rich_text,
            modstamp,
            owner_id,
            // parent_id,
            type,
            last_edit_date,
            // title,
          } = messageData;

          // if profile_thumbnail is not found try assigning var to
          // use a photo taken from the active users in the store
          const photoObject = photosGraber(owner_id);

          if (is_rich_text) {
            const document = new DOMParser().parseFromString(body, "text/html")
            document.querySelectorAll("*").forEach((node) => {
              if (node.tagName === "P") {
                if (node.innerHTML === '&nbsp;') {
                  const brTag = document.createElement("br")
                  node.replaceWith(brTag)
                }
              }
            })
            body &&= document.querySelectorAll("body")[0].innerHTML

          }
          return (
            <Grid key={id} display="flex" container flexDirection="column">
              {i === 0 && <div ref={lastMessageScrollRef}></div>}
              <Grid
                item
                container
                display="flex"
                alignItems="flex-start"
                justifyContent={user.id === owner_id ? "flex-end" : "flex-start"}
                minHeight="4rem"
                marginBottom={1}
              >
                {user.id !== owner_id && (
                  <Grid
                    item
                    width="auto"
                    mr={1}
                    display="flex"
                    justifyContent="center"
                    justifySelf="flex-start"
                  >
                    <Tooltip title={owner_name}>
                      <Avatar
                        src={
                          photoObject?.has_profile_photo
                            ? photoObject.profile_thumbnail_url
                            : undefined
                        }
                        alt={owner_name}
                        className={pathname.includes('/details') ? 'profile_Avatar-auto' : 'profile_Avatar-small'}
                      />
                    </Tooltip>
                  </Grid>
                )}
                <Grid item width="80%" justifySelf="center">
                  {/* {type === "ContentPost" && (
                    <Typography sx={{ color: "red" }}>Content Post</Typography>
                  )} */}
                  {type === "TextPost" && (
                    <Grid
                      container
                      display="flex"
                      justifyContent={
                        user.id === owner_id ? "flex-end" : "flex-start"
                      }
                      justifySelf="center"
                      item
                      id={`message_body_${id}`}
                      component="div"
                    >
                      {user?.name && user.name !== owner_name && (
                        <Grid item container>
                          <Typography variant="caption">{owner_name}</Typography>
                        </Grid>
                      )}
                      {user?.name && user.name === owner_name && (
                        <Grid item container justifyContent="flex-end">
                          <Typography variant="caption">{user.name}</Typography>
                        </Grid>
                      )}
                      {is_rich_text && (
                        <Grid item width="100%" display="flex" justifyContent={user.id === owner_id ? "flex-end" : "flex-start"}>
                          <Paper
                            sx={{
                              width: "fit-content",
                              minWidth: "120px",
                              textAlign: user.id === owner_id ? "right" : "left",
                              verticalAlign: "center",
                              minHeight: "1rem",
                              p: 1.5,
                              color: (theme) =>
                                user.id === owner_id
                                  ? theme.palette.common.white
                                  : theme.palette.common.black,
                              backgroundColor: (theme) =>
                                user.id === owner_id
                                  ? theme.palette.primary.light
                                  : undefined,
                            }}
                            component="div"
                            elevation={4}
                            className="message_body"
                            dangerouslySetInnerHTML={{
                              __html: body,
                            }}
                          />
                        </Grid>
                      )}
                      {!is_rich_text && body && (
                        <Grid item>
                          <Paper
                            sx={{
                              width: "100%",
                              minHeight: "14px",
                              p: 1,
                              pt: 0,
                              pb: 0,
                              marginBlockStart: 0,
                              marginBlockEnd: 0,
                              display: "inline",
                              color: (theme) =>
                                user.id === owner_id
                                  ? theme.palette.common.white
                                  : theme.palette.common.black,
                              backgroundColor: (theme) =>
                                user.id === owner_id
                                  ? theme.palette.primary.light
                                  : undefined,
                            }}
                            component="div"
                            elevation={4}
                          >
                            <p>{body}</p>
                          </Paper>
                        </Grid>
                      )}

                    </Grid>
                  )}
                  {modstamp && !last_edit_date && (
                    <Grid
                      item
                      container
                      className="message_created_at_Container"
                      gridRow={2}
                      mt=".25rem"
                      display="flex"
                      alignItems="flex-start"
                      justifyContent={user.id === owner_id ? "flex-end" : "flex-start"}
                    >
                      <Typography
                        variant="caption"
                        fontSize=".6rem"
                        sx={{ color: (theme) => theme.palette.grey[600] }}
                      >
                        {format(new Date(modstamp), "M/d/yyyy h:mm b")}
                      </Typography>
                    </Grid>
                  )}
                  {last_edit_date && (
                    <Grid
                      item
                      container
                      className="message_created_at_Container"
                      gridRow={2}
                      mt=".25rem"
                      display="flex"
                      alignItems="flex-start"
                      justifyContent={user.id === owner_id ? "flex-end" : "flex-start"}
                    >
                      <Typography
                        variant="caption"
                        fontSize=".6rem"
                        sx={{
                          color: (theme) => theme.palette.grey[600],
                          mr: 0.5,
                        }}
                      >
                        Edited
                      </Typography>
                      <Typography
                        variant="caption"
                        fontSize=".6rem"
                        sx={{ color: (theme) => theme.palette.grey[600] }}
                      >
                        {format(new Date(last_edit_date), "M/d/yyyy h:mm b")}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {user.id === owner_id && (
                  <Grid
                    item
                    width="auto"
                    ml={1}
                    justifySelf="flex-end"
                    display="flex"
                    justifyContent="center"
                  >
                    <Tooltip title={user.name}>
                      <Avatar
                        src={
                          user.has_profile_photo
                            ? user.profile_thumbnail_url
                            : undefined
                        }
                        alt={user.name}
                        className={pathname.includes('/details') ? 'profile_Avatar-auto' : 'profile_Avatar-small'}
                      />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
    </>
  );
}
