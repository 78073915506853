import * as React from "react";
import { useSearchParams } from "react-router-dom";
import formatDateParam from "@helpers/formatDateParam";

export function useHandleLegacyUrl() {
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    // sanitize taskStartDate <TASKSEARCH>
    if (searchParams.has("taskStartDate")) {
      let startDate = decodeURIComponent(searchParams.get("taskStartDate")!);
      if (startDate.indexOf("T") !== -1) {
        searchParams.set('taskStartDate', formatDateParam(new Date(decodeURIComponent(startDate))));
        setSearchParams(searchParams);
      }
    }
    // sanitize taskEndDate <TASKSEARCH>
    if (searchParams.has("taskEndDate")) {
      let endDate = decodeURIComponent(searchParams.get("taskEndDate")!);
      if (endDate.indexOf("T") !== -1) {
        searchParams.set('taskEndDate', formatDateParam(new Date(endDate)));
        setSearchParams(searchParams);
      }
    }
    // sanitize taskDate <HOME>
    if (searchParams.has("taskDate")) {
      let taskDate = decodeURIComponent(searchParams.get("taskDate")!);
      if (taskDate.indexOf("T") !== -1) {
        searchParams.set('taskDate', formatDateParam(new Date(taskDate)));
        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line
  }, []);
  return null;
}
