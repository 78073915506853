import Typography from "@mui/material/Typography";
import StarsIcon from "@mui/icons-material/Stars";
import { CardItem } from "@components/Typography/CardItemText";

export default function ContactDetail({ contact }: { contact: ApiContact }) {
  if (contact === undefined) return null;
  return (
    <>
      <div className="Task-Card-Item-Row Task-Card-Item-Border No-Margin">
        <Typography variant="h6">Contact</Typography>
      </div>
      <div className="Task-Card-Item-Row">
        <span>
          <CardItem
            header="Contact"
            text={contact.name || "-"}
            textIcon={contact.is_owner ? StarsIcon : undefined}
          />
        </span>
      </div>
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem
            header="Phone"
            text={contact.phone !== undefined ? contact.phone : "-"}
            textIsPhone={contact.phone !== undefined}
          />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem
            header="Mobile"
            text={contact.mobile_phone !== undefined ? contact.mobile_phone : "-"}
            textIsPhone={contact.mobile_phone !== undefined}
          />
        </div>
      </div>
      <div className="Task-Card-Item-Row">
        <span>
          <CardItem
            header="Email"
            text={contact.email !== undefined ? contact.email : "-"}
            textIsEmail={contact.email !== undefined}
          />
        </span>
      </div>
    </>
  );
}


