import * as React from "react";
import { CLEAR_HISTORY_COMMAND, CLEAR_EDITOR_COMMAND } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Grid, Button } from "@mui/material";
import AttachButton from "../../FileManager/AttachButton";

export default function SubmitPlugin({
  taskId,
  disabled,
  onSubmit,
  disableAttach
}: {
  taskId: string;
  disabled: boolean;
  onSubmit: any;
  disableAttach?: boolean
}) {
  const [editor] = useLexicalComposerContext();
  return (
    <Grid
      container
      sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}
    >
      <Grid item>
        <Button
          disabled={disabled}
          sx={{ mr: 1 }}
          size="small"
          variant="contained"
          onClick={async () => {
            if (!disabled) {
              onSubmit();
            }
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
            editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
          }}
        >
          Save
        </Button>
        <Button
          disabled={disabled}
          size="small"
          variant="text"
          type="button"
          onClick={() => {
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
            editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
          }}
        >
          Clear
        </Button>
      </Grid>
      <Grid item display="flex" justifyContent='flex-end'>
        {disableAttach !== true && <AttachButton taskId={taskId} />}
      </Grid>
    </Grid>
  );
}
