import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import User from "@classes/User";

const userSelector = (state: RootState) =>
  state.session.user;

const useProfile = () => {
  // We allow undefined and null here because useSelector is Redux that can
  // return basically anything.
  const user = useSelector(userSelector);
  if (user instanceof User) {
    return user as User
  } else if (user !== null) {
    return new User(user)
  }

  return null
};

export default useProfile;
