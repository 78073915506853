import { Chip, Grid, Paper, PaperProps, Typography, TypographyProps } from "@mui/material";
import listingStatusColor from "../../../../utils/listingStatusColor";
import './compactCardStyles.css';

type CompactCardVariant = 'primary' | 'secondary' | 'warning' | 'fade'

interface CompactCardProps extends React.PropsWithChildren {
  variant?: CompactCardVariant
  paperProps?: PaperProps
}
/**
 * 
 * @param props fade OPTIONAL. "Opacity 0.5"
 * @param props warning OPTIONAL. "Border left red"
 * @param props high_priority OPTIONAL. "Border left dark blue" [Will override warning]
 * @returns JSX Compact Card Container Pre Styled
 */
export function CompactCard(props: CompactCardProps) {
  let className = "Compact-Card"

  switch (props.variant) {
    case "primary":
      className = `${className} card-variant-primary`
      break
    case "secondary":
      className = `${className} card-variant-secondary`
      break
    case "warning":
      className = `${className} card-variant-warning`
      break
    case "fade":
      className = `${className} card-variant-fade`
      break
    default:
      break
  }

  return (
    <Paper className={className} {...props.paperProps}>
      {props.children}
    </Paper>
  )
}

interface CompactCardHeaderProps extends React.PropsWithChildren {
  titleProps?: TypographyProps;
  text: string;
  priority?: string;
  status?: string;
}

export function CompactCardInfoHeader(props: CompactCardHeaderProps) {
  return (
    <Grid className="Compact-Card-Info-Header" container display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
      <Grid item display='flex' flexDirection='row' alignItems='center'>
        <Typography {...props.titleProps} fontWeight="600" >{`${props.text}${props.priority ? "・" : ""}`}</Typography>
        {props.priority &&
          <Typography
            color='error'
            fontWeight="bold"
            variant="subtitle2"
          >
            {props.priority}
          </Typography>
        }
        {props.status && <Chip size="small" color={listingStatusColor(props.status)} sx={{ typography: 'subtitle2', mx: 1 }} label={props.status} />}
      </Grid>
      <Grid item display='flex' flexDirection='row'>
        {props.children}
      </Grid>
    </Grid>
  )
}