import * as React from "react";
import { createPortal } from "react-dom";
import { NavLink } from "react-router-dom";
import FormatSelectPlugin from "./FormatSelectPlugin";

import "../message.css";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  // $getNodeByKey,
  EditorState,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isAtNodeEnd } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { $isListNode, ListNode } from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
// import {
//   $isCodeNode,
//   getDefaultCodeLanguage,
//   getCodeLanguages,
// } from "@lexical/code";

import {
  Grid,
  TextField,
  // Select as MUISelect,
  // MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  // ArrowDownwardRounded,
  FormatBold,
  FormatClear,
  FormatItalic,
  FormatUnderlined,
  Link,
  Redo,
  Undo,
  StrikethroughS,
} from "@mui/icons-material";

const LowPriority = 1;

type SupportedBlockTypes =
  | "paragraph"
  | "quote"
  // | "code"
  | "h1"
  | "h2"
  | "ul"
  | "ol"


const supportedBlockTypes: Set<SupportedBlockTypes> = new Set([
  "paragraph",
  "quote",
  // "code",
  "h1",
  "h2",
  "ul",
  "ol",

]);

function positionEditorElement(
  editor: any,
  rect: { top: any; height: any; left: number; width: number } | null
) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
      }px`;
  }
}

function FloatingLinkEditor({ editor }: any) {
  const editorRef = React.useRef<any>(null);
  const inputRef = React.useRef<any>(null);
  const mouseDownRef = React.useRef<boolean>(false);
  const [linkUrl, setLinkUrl] = React.useState("");
  const [isEditMode, setEditMode] = React.useState(false);
  const [lastSelection, setLastSelection] = React.useState<any>(null);

  const updateLinkEditor = React.useCallback(() => {
    return editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection);
        const parent = node.getParent();
        if ($isLinkNode(parent)) {
          setLinkUrl(parent.getURL());
        } else if ($isLinkNode(node)) {
          setLinkUrl(node.getURL());
        } else {
          setLinkUrl("");
        }
      }
      const editorElem = editorRef.current;
      const nativeSelection = window.getSelection();
      const activeElement = document.activeElement;

      if (editorElem === null) {
        return;
      }

      const rootElement = editor.getRootElement();
      if (
        selection !== null &&
        nativeSelection !== null &&
        !nativeSelection.isCollapsed &&
        rootElement !== null &&
        rootElement.contains(nativeSelection.anchorNode)
      ) {
        const domRange = nativeSelection.getRangeAt(0);
        let rect;
        if (nativeSelection.anchorNode === rootElement) {
          let inner = rootElement;
          while (inner.firstElementChild != null) {
            inner = inner.firstElementChild;
          }
          rect = inner.getBoundingClientRect();
        } else {
          rect = domRange.getBoundingClientRect();
        }

        if (!mouseDownRef.current) {
          positionEditorElement(editorElem, rect);
        }

        setLastSelection(selection);
      } else if (!activeElement || activeElement.className !== "link-input") {
        positionEditorElement(editorElem, null);
        setLastSelection(null);
        setEditMode(false);
        setLinkUrl("");
      }

      return true;
    }, LowPriority)
  }, [editor]);

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(
        ({ editorState }: { editorState: EditorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }
      ),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      ),

    );
  }, [editor, updateLinkEditor]);

  React.useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  React.useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <Grid container ref={editorRef} className="link-editor">
      {isEditMode ? (
        <TextField
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <Grid container item className="link-input">
          <NavLink to={linkUrl} target="_blank" rel="noopener noreferrer">
            {linkUrl}
          </NavLink>
          <Grid
            className="link-edit"
            role="button"
            tabIndex={0}
            onMouseDown={(event) => event.preventDefault()}
            onClick={() => {
              setEditMode(true);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

// Used as a dropdown for code format
// function Select({ onChange, className, options, value }: any) {
//   return (
//     <MUISelect className={className} onChange={onChange} value={value}>
//       <MenuItem hidden={true} value="" />
//       {options.map((option: any) => (
//         <MenuItem key={option} value={option}>
//           {option}
//         </MenuItem>
//       ))}
//     </MUISelect>
//   );
// }

function getSelectedNode(selection: any) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = React.useRef(null);
  const [canUndo, setCanUndo] = React.useState(false);
  const [canRedo, setCanRedo] = React.useState(false);
  const [blockType, setBlockType] =
    React.useState<SupportedBlockTypes>("paragraph");
  // const [selectedElementKey, setSelectedElementKey] = React.useState<any>(null);
  // const [codeLanguage, setCodeLanguage] = React.useState("");
  const [isLink, setIsLink] = React.useState(false);
  const [isBold, setIsBold] = React.useState(false);
  const [isItalic, setIsItalic] = React.useState(false);
  const [isStrikethrough, setIsStrikethrough] = React.useState(false);
  const [isUnderline, setIsUnderline] = React.useState(false);
  // const matchesOver400px = useMediaQuery("(min-width:400px)")

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        // setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList: ListNode | null = $getNearestNodeOfType(
            anchorNode,
            ListNode
          );
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? (element.getTag() as SupportedBlockTypes)
            : (element.getType() as SupportedBlockTypes);
          setBlockType(type);
          // if ($isCodeNode(element)) {
          //   setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          // }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload: any) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload: any) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      ),
    );
  }, [editor, updateToolbar]);

  // const codeLanguges = React.useMemo(() => getCodeLanguages(), []);
  // const onCodeLanguageSelect = React.useCallback(
  //   (e: any) => {
  //     editor.update(() => {
  //       if (selectedElementKey !== null) {
  //         const node = $getNodeByKey(selectedElementKey);
  //         if ($isCodeNode(node)) {
  //           node.setLanguage(e.target.value);
  //         }
  //       }
  //     });
  //   },
  //   [editor, selectedElementKey]
  // );

  const insertLink = React.useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <Grid container className="editor-Toolbar" justifyContent="space-evenly">
      <Grid item className="editor-FormatButton_container">
        <IconButton
          disableFocusRipple
          disableTouchRipple
          disableRipple
          size="small"
          disabled={!canUndo}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND, undefined);
          }}
          className="toolbar-item"
          aria-label="Undo"
        >
          <Tooltip title="Undo">
            <Undo fontSize="small" />
          </Tooltip>
        </IconButton>
      </Grid>
      <Grid item className="editor-FormatButton_container">
        <IconButton
          disableFocusRipple
          disableTouchRipple
          disableRipple
          size="small"
          disabled={!canRedo}
          onClick={() => {
            editor.dispatchCommand(REDO_COMMAND, undefined);
          }}
          className="toolbar-item"
          aria-label="Redo"
        >
          <Tooltip title="Redo">
            <Redo fontSize="small" />
          </Tooltip>
        </IconButton>
      </Grid>
      {supportedBlockTypes.has(blockType) && (
        <Grid item className="editor-FormatButton_container">
          <Tooltip title="Clear formatting">
            <IconButton
              disableFocusRipple
              disableTouchRipple
              disableRipple
              size="small"
              className="toolbar-item block-controls"
              onClick={() => {
                editor.update(() => {
                  const selection = $getSelection();
                  if (
                    $isRangeSelection(selection) &&
                    selection.hasFormat("bold")
                  ) {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                  }
                  if (
                    $isRangeSelection(selection) &&
                    selection.hasFormat("italic")
                  ) {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                  }
                  if (
                    $isRangeSelection(selection) &&
                    selection.hasFormat("underline")
                  ) {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                  }
                  if (
                    $isRangeSelection(selection) &&
                    selection.hasFormat("strikethrough")
                  ) {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                  }
                  // if (
                  //   $isRangeSelection(selection) &&
                  //   selection.hasFormat("code")
                  // ) {
                  //   editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                  // }
                  setIsBold(false);
                  setIsItalic(false);
                  setIsUnderline(false);
                  setIsStrikethrough(false);
                  // setIsCode(false);
                });
              }}
              aria-label="Formatting Options"
            >
              <FormatClear fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {/* {blockType === "code" ? (
        <Grid item className="editor-FormatButton_container">
          <Select
            className="toolbar-item code-language"
            onChange={onCodeLanguageSelect}
            options={codeLanguges}
            value={codeLanguage}
          />
          <ArrowDownwardRounded color="info" fontSize="small" />
        </Grid>
      ) : ( */}
      {
        <>
          <Grid item className="editor-FormatButton_container">
            <IconButton
              disableFocusRipple
              disableTouchRipple
              disableRipple
              size="small"
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
              }}
              className="format_Button"
              aria-label="Format Bold"
            >
              <Tooltip title="Bold">
                <FormatBold
                  color={isBold ? "primary" : "secondary"}
                  fontSize="small"
                />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item className="editor-FormatButton_container">
            <IconButton
              disableFocusRipple
              disableTouchRipple
              disableRipple
              size="small"
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
              }}
              className="format_Button"
              aria-label="Format Italics"
            >
              <Tooltip title="Italic">
                <FormatItalic
                  color={isItalic ? "primary" : "secondary"}
                  fontSize="small"
                />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item className="editor-FormatButton_container">
            <IconButton
              // disabled
              disableFocusRipple
              disableTouchRipple
              disableRipple
              size="small"
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
              }}
              className="format_Button"
              aria-label="Format Underline"
            >
              <Tooltip title="Underline">
                <FormatUnderlined
                  color={isUnderline ? "primary" : "secondary"}
                  fontSize="small"
                />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item className="editor-FormatButton_container">
            <IconButton
              // disabled
              disableFocusRipple
              disableTouchRipple
              disableRipple
              size="small"
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
              }}
              className="format_Button"
              aria-label="Insert Link"
            >
              <Tooltip title="Strikethrough">
                <StrikethroughS
                  color={isStrikethrough ? "primary" : "secondary"}
                  fontSize="small"
                />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item className="editor-FormatButton_container">
            <IconButton
              disableFocusRipple
              disableTouchRipple
              disableRipple
              size="small"
              onClick={insertLink}
              className="format_Button"
              aria-label="Insert Link"
            >
              <Tooltip title="Link URL">
                <Link
                  color={isLink ? "primary" : "secondary"}
                  fontSize="small"
                />
              </Tooltip>
            </IconButton>
          </Grid>

          {isLink &&
            createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
        </>
      }
      {/* )} */}
      <FormatSelectPlugin
        toolbarRef={toolbarRef}
        setBlockType={setBlockType}
        // setCodeLanguage={setCodeLanguage}
        blockType={blockType}
      />
    </Grid>
  );
}
