import TaskActionBtn from "../../../../TaskControls/ActionButtons/TaskAction"
import { PauseCircle, PlayCircle } from "@mui/icons-material"
import { TaskStatusUpperInProgress, TaskStatusUpperNotStarted, TaskStatusUpperOnHold } from "../../../../../../Strings/TaskStatusUpper"

/**
 * @param props ActionButtonColumnProps
 * @returns a switch case to display the oppropriate TaskActionBtn in column one
 */
export default function TimedButtons(props: TimedActionButtonColumnProps) {
  const userHasPermission = props.curTask && (props.curTask.is_manageable_by_user || props.curTask.is_my_task);

  if (!userHasPermission) {
    return null;
  }

  switch (props.taskStatus.toUpperCase()) {
    case TaskStatusUpperNotStarted:
      return <TaskActionBtn
        curTask={props.curTask}
        setCurTask={props.setCurTask}
        iconOnly={true}
        label="Start"
        icon={<PlayCircle />}
        taskId={props.taskId}
        action={"startTask"}
        color="secondary"
        className="startTask"
        overrideEnabled={userHasPermission}
      />
    case TaskStatusUpperInProgress:
      return <TaskActionBtn
        curTask={props.curTask}
        setCurTask={props.setCurTask}
        iconOnly={true}
        label="Pause"
        icon={<PauseCircle />}
        taskId={props.taskId}
        action="pauseTask"
        color="secondary"
        className="pauseTask"
        overrideEnabled={userHasPermission}
      />
    case TaskStatusUpperOnHold:
      return <TaskActionBtn
        curTask={props.curTask}
        setCurTask={props.setCurTask}
        iconOnly={true}
        label="Resume"
        icon={<PlayCircle />}
        taskId={props.taskId}
        action="startTask"
        color="secondary"
        className="startTask"
        overrideEnabled={userHasPermission}
      />
    default:
      return null
  }
}