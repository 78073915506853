import * as React from "react";
import TaskForm, { TaskFormProps } from "../Sub_Components/TaskFormComponents/TaskForm";

import {
  Box,
  useMediaQuery,
  Backdrop,
  Fade,
  Slide,
  Dialog,
  Paper,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Theme } from "@context/Theme";

interface TaskFormModalProps extends TaskFormProps {
}

const UI_Mobile_Max_Width = "(max-width:428px)"

export default function TaskFormModal(props: TaskFormModalProps) {
  const id = React.useId()
  const { open, close, curTask, setCurTask, taskId, attachmentsMeta, setAttachmentsMeta, refreshAttachments } = props
  const matches = useMediaQuery(UI_Mobile_Max_Width);
  const theme = React.useContext(Theme);
  const mobileRef = React.useRef(null);
  const desktopRef = React.useRef(null);

  if (matches) {
    return (
      <>
        {open &&
          <Helmet>
            <meta
              name="theme-color"
              content={
                theme.palette.background.paper
              }
            />
          </Helmet>
        }
        <Dialog
          ref={mobileRef}
          fullScreen
          fullWidth
          onClose={close}
          scroll="paper"
          open={open}
          className="mobile-task-form"
          TransitionComponent={Slide}
          PaperComponent={Paper}
          id={id}
        >
          <Box
            sx={{
              width: "100%",
              pl: 1,
              pr: 1,
              bgcolor: (theme) => theme.palette.background.paper,
              display: "flex",
              alignItems: "center",
            }}
          >
            {open && <TaskForm refreshAttachments={refreshAttachments} attachmentsMeta={attachmentsMeta} setAttachmentsMeta={setAttachmentsMeta} close={close} open={open} curTask={curTask} setCurTask={setCurTask} taskId={taskId} />}
          </Box>
        </Dialog>
      </>
    );
  }
  return (
    <>
      {open &&
        <Helmet>
          <meta
            name="theme-color"
            content={
              theme.palette.background.paper
            }
          />
        </Helmet>
      }
      <Dialog
        fullWidth
        ref={desktopRef}
        open={open}
        onClose={close}
        BackdropComponent={Backdrop}
        TransitionComponent={Fade}
        PaperComponent={Paper}
        className="desktop-task-form"
        id={id}
      >
        <Box
          sx={{
            height: "100%",
            pl: 2,
            pr: 2,
            bgcolor: (theme) => theme.palette.background.paper,
            display: "flex",
            alignItems: "center",
          }}
        >
          {open && <TaskForm refreshAttachments={refreshAttachments} attachmentsMeta={attachmentsMeta} setAttachmentsMeta={setAttachmentsMeta} close={close} open={open} curTask={curTask} setCurTask={setCurTask} taskId={taskId} />}
        </Box>
      </Dialog>
    </>
  );
}
