import * as React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Copyright from "@config/Copyright";
import ModalBody from "../../Shared/ModalBody";
import ModalHeader from "../../Shared/ModalHeader";
import ModalBackground from "../../Shared/ModalBackground";
import LoginForm from "./LoginForm";
import useMediaQuery from "@mui/material/useMediaQuery";
interface LoginModalProps {
  open: boolean;
}

export default function LoginModal(props: LoginModalProps) {
  // console.log('rendering LoginModal w/ props', props);
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Modal
      open={props.open}
      aria-labelledby="auth_modal"
      aria-describedby="modal used to authenticate user"
    >
      <ModalBackground>
        <ModalBody
          sx={{
            width: matches ? "50%" : "80%",
            minWidth: 250,
            height: "auto",
            maxWidth: "460px",
          }}
        >
          <ModalHeader title="Log In" />
          <Box
            width="80%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LoginForm />
          </Box>
          <Copyright />
        </ModalBody>
      </ModalBackground>
    </Modal>
  );
}
