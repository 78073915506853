import * as React from "react";
import { createRoot } from "react-dom/client";
import { PersistGate } from 'redux-persist/integration/react'
import "./index.css";
import App from "./App";
import LoginModalProvider from "./Context/Modal";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store, { Persistor } from "./Store";
import ThemeProvider from "./Context/Theme";
import SalesforceOauthProvider from "./Context/Auth";
import AppbarProvider from "./Context/Sidebars/SideBar";
import ProfileSidebarProvider from "./Context/Sidebars/ProfileSidebar";
import TaskProvider from "./Context/Task";
import TaskDrawerProvider from "./Context/Task/TaskDrawer";
import SnackBarProvider from "./Context/Snackbar";
import ResetPasswordProvider from "./Context/ResetPassword";
import MobileHeaderProvider from "./Context/MobileHeader";
import AttachmentsProvider from "@context/Attachments";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Provider store={Store}>
    <PersistGate loading={null} persistor={Persistor}>
      <BrowserRouter>
        <ThemeProvider>
          <SnackBarProvider>
            <LoginModalProvider>
              <SalesforceOauthProvider>
                <TaskProvider>
                  <MobileHeaderProvider>
                    <TaskDrawerProvider>
                      <AppbarProvider>
                        <ProfileSidebarProvider>
                          <ResetPasswordProvider>
                            <AttachmentsProvider>
                              <App />
                            </AttachmentsProvider>
                          </ResetPasswordProvider>
                        </ProfileSidebarProvider>
                      </AppbarProvider>
                    </TaskDrawerProvider>
                  </MobileHeaderProvider>
                </TaskProvider>
              </SalesforceOauthProvider>
            </LoginModalProvider>
          </SnackBarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
