import * as React from "react";
import { useParams } from "react-router-dom";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";
import { ApiListingList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import Fallback from "@components/UserPage/Fallback";
import ErrorFallback from "@components/UserPage/Error";
import { ListingPageContent } from "./ListingPageContent";
import "./listingPage.css"
import useDocumentTitle from "@hooks/Window/useDocTitle";

export default function ListingPage() {
  useDocumentTitle("Listing")
  const { listingId } = useParams();
  const dispatch = useAppDispatch();
  const [loadingListing, setLoadingListing] = React.useState<APP_STATE>('unset');
  const [curListing, setCurListing] = React.useState<ApiListing | null>(null);
  const persistedObject = usePersistentObject();

  React.useEffect(() => {
    if (persistedObject.checkListings(ApiListingList['activeListings']))
      return;
    setLoadingListing("loading");
    dispatch(
      updatePersistentObjects(
        PersistentObject.listing,
        ApiListingList.activeListings
      )).then(() => { setLoadingListing("unset") }).catch((e: any) => {
        console.warn("Problem getting listings", e);
        setLoadingListing("error");
      });
    //? We will only want this operation to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (listingId === undefined)
      return;
    if (loadingListing !== 'unset')
      return;
    if (!persistedObject.checkListings(ApiListingList['activeListings']))
      return;
    const listing = persistedObject.getListing(ApiListingList['activeListings']).find(listing => listing.id === listingId) || null;
    if (listing === null) {
      setLoadingListing('error');
      return;
    }
    setCurListing(listing);
    setLoadingListing('success')

  }, [listingId, loadingListing, persistedObject]);


  switch (loadingListing) {
    case "unset":
      return <Fallback message="Loading Listing..." />;
    case "loading":
      return <Fallback message="Loading Listing..." />;
    case "error":
      return <ErrorFallback message="Failed to get listing and related task data." />;
    case "success":
      if (curListing === null)
        return <ErrorFallback message="Failed to get listing and related task data." />;
      return <ListingPageContent onRefresh={() => { setLoadingListing("unset") }} listing={curListing} />;
    default:
      return null;
  }
}


