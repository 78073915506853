import { ArrowBackIosNew } from "@mui/icons-material";
import { Button } from "@mui/material";
import PagenationProps from "../Interfaces";

export function BackButton(props: Pick<PagenationProps, "rollBackward">) {
  return (
    <Button
      className="pagenation-button"
      variant="outlined"
      onClick={() => {
        props.rollBackward();
        return;
      }}
    >
      <ArrowBackIosNew className="pagenation-button-text" />
    </Button>
  )
}