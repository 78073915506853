import * as React from "react";
import { Checkbox, Stack, Tooltip } from "@mui/material";
import AppFormInputAutoComplete from "../../../Inputs/AppFormInputAutoComplete";
import {
  Control,
  FormState,
  UseFormSetValue,
  UseFormGetValues,
} from "react-hook-form";
import AppFormInputText from "../../../Inputs/AppFormInputText";
import AppFormInputTextareaAutosize from "../../../Inputs/AppFormInputTextareaAutosize";
import AppFormInputDropdown from "../../../Inputs/AppFormInputDropdown";
import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { TaskFormLabel } from "./TaskFormLabel";
import { EditTaskForm } from "@classes/Task";
import { PriorityOptions, TaskStatusOptions } from "@classes/Task/taskStrings";
import useProfile from "@hooks/Selectors/useProfile";
import User from "@classes/User";

export interface TaskFormProps {
  mode: "Create" | "Edit";
  setLoading: any;
  control: Control<EditTaskForm, any>;
  setValue: UseFormSetValue<EditTaskForm>;
  getValues: UseFormGetValues<EditTaskForm>;
  formState: FormState<EditTaskForm>;
  taskType: EditTaskForm["type"];
  subTypes: string[];
  relatedToReadOnly?: boolean;
  setDateChange?: React.Dispatch<React.SetStateAction<boolean>>;
  listings: {
    label: string;
    value: string;
    group: string;
  }[] | null;
  taskAssignees: any[] | null;
  taskReporters: {
    label: string;
    value: string;
    group: string;
  }[] | null;
  currentUser: User | null;
  assigneeInputEnabled: boolean;
}

const todayAt5 = new Date();
todayAt5.setHours(17, 0, 0, 0);

export const roleEngineer = 'Engineer'; // 'Application Developer'

export default function TaskFormDefault(props: TaskFormProps) {
  const [lockedIconState, setLockedIconState] = React.useState<boolean>(props.getValues("permission_to_enter"))
  const { mode, currentUser } = props
  const isEngineerEdit = React.useMemo(() => currentUser !== null && currentUser.title === roleEngineer && props.mode === 'Edit', [mode, currentUser])
  //--------------------------- Options ----------------
  const { listings, taskReporters, taskAssignees, taskType, assigneeInputEnabled } = props

  //-------------- This will handle the Header of the form----------
  return (
    <>
      <Stack direction='row' spacing={2}>
        {props.subTypes.length > 0 && (
          <div id="edit_task_sub_type" className="Task-Form-Input-Row">
            <TaskFormLabel label="Sub Type" />
            <AppFormInputDropdown
              defaultValue={
                taskType === "Inspection"
                  ? "Maintenance"
                  : taskType === "Drop"
                    ? "Housekeeping"
                    : taskType === "Clean" ? ''
                      : ""
              }
              control={props.control}
              name="sub_type"
              options={props.subTypes}
              size="small"
            />
          </div>
        )}
        {(taskType === "Drop" || taskType === 'Pickup') &&
          <div id="edit_task_sub_type" className="Task-Form-Input-Row">
            <TaskFormLabel label="Inventory Id" />
            <AppFormInputText
              name="inventory_id"
              control={props.control}
              variant="outlined"
              disablePadding
            />
          </div>
        }
      </Stack>
      <div className="Task-Form-Input-Row"
        id="title_input_and_title_container"
      >
        <TaskFormLabel label="Title" />
        <AppFormInputText
          name="title"
          control={props.control}
          variant="outlined"
          disablePadding
        />
      </div>

      <div id="edit_task_description">
        <AppFormInputTextareaAutosize
          control={props.control}
          label="Description"
          name="description"
        />
      </div>
      {listings && (
        <div id="edit_task_related" className="edit_task_related">
          <TaskFormLabel label="Related To" />
          <div className="flex-row">
            <AppFormInputAutoComplete
              readOnly={props.relatedToReadOnly}
              control={props.control}
              name="related"
              options={listings}
            />
            <Tooltip title="Permission to enter">
              <Checkbox
                name="permission_to_enter"
                inputProps={{
                  "aria-label": "Permission to enter unit",
                  name: "permission_to_enter",
                }}
                checked={lockedIconState}
                onChange={(e) => {
                  props.setValue("permission_to_enter", e.target.checked);
                  setLockedIconState(e.target.checked)
                  return
                }}
                icon={<Lock color="primary" />}
                checkedIcon={<LockOpenOutlined color="disabled" />}
                sx={{ height: 'inherit' }}
              />
            </Tooltip>
          </div>
        </div>

      )
      }
      <div id="edit_task_assigned_to">
        <TaskFormLabel label="Assignee" />
        <AppFormInputAutoComplete
          grouped
          required
          type="text"
          control={props.control}
          name="assignee"
          readOnly={isEngineerEdit || !assigneeInputEnabled}
          options={taskAssignees || [{ label: "Loading...", value: undefined }]} //[[Autocomplete undef. value]]
        />
      </div>
      <div id="edit_task_status">
        <TaskFormLabel label="Status" />
        {TaskStatusOptions && (
          <AppFormInputDropdown
            control={props.control}
            name="status"
            options={TaskStatusOptions}
            sx={{ maxHeight: "41px" }}
          />
        )}
      </div>
      <div id="edit_task_reporter">
        <TaskFormLabel label="Reporter" />
        <AppFormInputAutoComplete
          required={true}
          grouped //TODO: will need to sort options inorder to impliment here
          control={props.control}
          name="reporter"
          loading={taskReporters === null}
          options={taskReporters || [{ label: "Loading...", value: undefined }]} //[[Autocomplete undef. value]]
        />
      </div>
      <div id="edit_task_priority">
        <TaskFormLabel label="Priority" />
        <AppFormInputDropdown
          control={props.control}
          name="priority"
          options={PriorityOptions}
          sx={{ maxHeight: "41px" }}
        />
      </div>
    </>
  );
}
