import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import createDateLabel from "@helpers/dateLabel";
import { format, isThisWeek } from "date-fns";
import { ApiTask } from "../../../../../Types/task";

export function createNameDateLabel(curTask: ApiTask, custom?: Record<string, string | null>) {
  const parts = []
  const revisedTaskData = {
    ...(curTask.related && {
      unitId: custom?.hasOwnProperty('unit_id') ? custom.unit_id : curTask.related.unit_id,
      name: custom?.hasOwnProperty('name') ? custom.name : curTask.related.name,
    }),
    // ...(curTask.is_high_priority && {
    //   priority: curTask.priority
    // })
  }
  if (revisedTaskData.unitId) {
    parts.push(revisedTaskData.unitId)
  }
  if (revisedTaskData.name) {
    parts.push(revisedTaskData.name)
  }
  if (curTask.due_datetime) {
    if (isThisWeek(new Date(curTask.due_datetime))) {
      parts.push(
        createDateLabel(new Date(curTask.due_datetime)) + ` @ ${format(new Date(curTask.due_datetime), "h:mm bb")}`
      )
    } else {
      parts.push(format(new Date(curTask.due_datetime), 'eeee, MMM do'))
    }

  }
  //? we need this to be in read and should not be on the current string
  // if (revisedTaskData.hasOwnProperty('priority')) {
  //   label = label + "・" + revisedTaskData.priority
  // }
  return parts.join('・')
}

export default function RelatedNameDateTime(props: { curTask: ApiTask | null }) {
  const { curTask } = props
  if (!curTask) return null
  if (!curTask.due_datetime) return null;
  if (!curTask.is_overdue) {
    return (
      <Typography
        color={(theme) => theme.palette.mode === 'light' ? "GrayText" : theme.palette.common.white}
        fontWeight="bold"
        variant="subtitle2"
        textTransform="uppercase"
        whiteSpace="nowrap"
        letterSpacing={0}
      >
        {
          /* 
        If unit_id is not defined on related item to task, use the name field. 

        If related object is not defined, do not display at all.

        On task card, if related object is not present do not render that section
        */
          createNameDateLabel(curTask, { name: "" })
        }
      </Typography>
    );
  }
  // if task is overdue present in red chip
  return (
    <div className="Task-Card-Item-Row">
      <Typography color={(theme) => theme.palette.mode === 'light' ? "GreyText" : theme.palette.common.white} fontWeight="bold" variant="subtitle2" letterSpacing={0}>
        {curTask?.related?.unit_id
          ? curTask.related.unit_id + "・"
          : curTask?.related?.name
            ? curTask.related.name + "・"
            : ""}
      </Typography>
      <Chip
        sx={{
          bgcolor: (theme) => theme.palette.error.dark,
          ml: 0.5,
          maxHeight: "1.5em",
          textAlign: "center",
          textJustify: "center",
          textTransform: "uppercase",
          fontSize: ".875rem",
          letterSpacing: 0,
          lineHeight: 1.57,
          p: 0,
          fontWeight: 800,
          color: (theme) => theme.palette.background.paper,
        }}
        label={
          createDateLabel(new Date(curTask.due_datetime)) +
          ` @ ${format(new Date(curTask.due_datetime), "h:mm bb")}`
        }
      />
    </div>
  );
}
