import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { IconButton, Typography } from "@mui/material";
import { TaskChangeShouldRefreshView } from "@context/Task/TaskDrawer";
import ConfirmModal from "../../Sub_Components/TaskCard/Footer/ConfirmModal/DefaultConfimModal";
import { environment } from "@constants";
import { SnackBarData } from "@context/Snackbar";
import { ConfirmActionMessage, createMessageFromAction } from "../../Sub_Components/TaskCard/Footer/Helpers";
import SyncIcon from '@mui/icons-material/Sync';
import "./IconButtonStyle.css"
import useSalesforceRequest from "@request/Salesforce";
import { TaskActionButtonProps } from "../../../../Types/task";


export default function TaskActionBtn(props: TaskActionButtonProps) {
  const [, setSnackData] = React.useContext(SnackBarData);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [, changeShouldRefreshViewInList] = React.useContext(TaskChangeShouldRefreshView)
  const [openConfirmModal, setOpenConfirmModal] =
    React.useState<boolean>(false);
  const taskActionRequest = useSalesforceRequest()
  const { action, label, taskId, toggleLabel, onToggleChange, toggleValue, selectOptions, selectDefaultValue, selectLabel } = props;
  const handleClick = () => {
    setOpenConfirmModal(true);
  };
  const handleConfirmClick = React.useCallback((config?: { groupToReassignToo: string }) => {
    setLoading(true);


    if (props.reset) {
      const resetRequest = taskActionRequest({ endpoint: `/api/task/${taskId}?action=resetTask` })
      const request = taskActionRequest({ endpoint: `/api/task/${taskId}?action=${action}` })
      resetRequest.attemptSend().then((resetRequest) => {
        if (!resetRequest.callSent) return
        request.attemptSend().then((result) => {
          const json = result.GetParsedJson()
          if (json.hasOwnProperty('task')) {
            props.setCurTask(null, json.task)
            return
          }
          throw new Error("Invalid response from task action")
        })
      }).catch((e) => {
        console.error(e);
        props.setCurTask(null);
      })

    } else {
      let endpoint = `/api/task/${taskId}?action=${action}`
      if (action === "moveTask") {
        if (config && config.groupToReassignToo !== undefined && config.groupToReassignToo !== 'doNotReassign') {
          endpoint = endpoint.concat(`&queue_id=${config.groupToReassignToo}`)
        }
      }
      const performTaskAction = taskActionRequest({ endpoint, method: "PATCH" })
      performTaskAction.attemptSend().then((result) => {
        if (!result.callSent) return
        const json = result.GetParsedJson()
        if (json.hasOwnProperty("task")) {
          const { task } = json
          setSnackData({
            message: createMessageFromAction(action),
            color: "success",
            severity: "success",
          });
          props.setCurTask(null, task);
          changeShouldRefreshViewInList(true)
          return
        }
        setSnackData({ message: `Failed to preform ${action}`, color: "error", severity: "error" });
        props.setCurTask(null);
        return

      }).catch((e) => {
        if (environment.env === "development") {
          console.error(e);
        }
        props.setCurTask(null);
      }).finally(() => {
        setLoading(false);
      });
    }
    return;
  }, [action, changeShouldRefreshViewInList, props, setSnackData, taskActionRequest, taskId]);

  const userHasPermission = props.curTask && ((props.curTask as any).is_manageable_by_user || (props.curTask as any).is_my_task);

  return (
    <>
      <ConfirmModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        onConfirmed={handleConfirmClick}
        actionLabel={ConfirmActionMessage(action)}
        toggleLabel={toggleLabel}
        onToggleChange={onToggleChange}
        toggleValue={toggleValue}
        selectOptions={selectOptions}
        selectDefaultValue={selectDefaultValue}
        selectLabel={selectLabel}
      />
      {props.iconOnly &&
        <div className="Icon-Only-Button_Container">
          <IconButton
            color={props.color || "primary"}
            onClick={props.onClick || handleClick}
            disabled={loading}
            className="Icon-Only-Button"
            sx={{ p: 0 }}
          >
            {!loading && props.icon}
            {loading && <SyncIcon
              className="spin-me"
            />}
          </IconButton>
        </div>
      }
      {props.iconOnly !== true && (
        <LoadingButton
          startIcon={props?.icon}
          endIcon={props?.endIcon}
          variant={props.variant || "contained"}
          color={props.color || "primary"}
          onClick={props.onClick || handleClick}
          loading={loading}
          disabled={loading || (userHasPermission === null ? false : !userHasPermission)}
          size={'medium'}
          sx={{ ...props?.sx, height: '36px' }}
          className={props.className}
        >
          <Typography variant="button" whiteSpace={'nowrap'} fontSize={'medium'}>
            {label}
          </Typography>
        </LoadingButton>
      )}
    </>
  );
}

