import * as React from "react";
import { AppBarToggle } from "../SideBar";

export type ProfileSidebarType = boolean;
// Appbar's display state.
export const ProfileSidebarNavigationContext = React.createContext<boolean>(false);
// Function to open the appbar
export const ToggleProfileSidebarContext = React.createContext<(a?: boolean) => void>(() => null);

export default function ProfileSidebarNavigationProvider({
  children,
}: {
  children: any;
}) {
  const [profileSidebarIsOpen, setOpenProfileSidebar] = React.useState(false);
  const [openAppBar, setOpenAppBar] = React.useContext(AppBarToggle);
  const toggleProfileSidebar = (a?: boolean) => {
    if (typeof a === 'boolean') {
      setOpenProfileSidebar(a)
      return
    }
    setOpenProfileSidebar((prevState) => !prevState);
    return
  }

  React.useEffect(() => {
    if (profileSidebarIsOpen === true && openAppBar === true) {
      // this will close the 
      setOpenAppBar(false);
    }
  }, [openAppBar, profileSidebarIsOpen, setOpenAppBar]);

  return (
    <ToggleProfileSidebarContext.Provider value={toggleProfileSidebar}>
      <ProfileSidebarNavigationContext.Provider value={profileSidebarIsOpen}>
        {children}
      </ProfileSidebarNavigationContext.Provider>
    </ToggleProfileSidebarContext.Provider>
  );
}
