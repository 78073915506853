import * as React from "react";
import { Typography, Grid } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";

export function WeekViewNoTaskMask() {
  return (
    <Grid
      container
      item
      display="flex"
      alignItems="center"
      whiteSpace="nowrap"
      direction="row"
      justifyContent="space-between"
      color={(theme) => theme.palette.primary.main}
    >
      <Grid item display="flex">
        <EventIcon style={{ marginRight: 10 }} />
        <Typography
          sx={{
            fontSize: "16px !important",
            fontWeight: "600 !important",
            lineHeight: "1.33333em !important",
            maxHeight: "2.75em",
            display: "flex",
            alignItems: "center",
          }}
        >
          No tasks
        </Typography>
      </Grid>
    </Grid>
  );
}
