import { AirlineSeatIndividualSuite, Autorenew, CallSplit, ContentPasteSearch, DirectionsWalk, Engineering, Input, KingBed, SingleBed, Stars } from "@mui/icons-material";
import { Divider, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  TaskDrawerControl,
  TaskDrawerSubject
} from "../../../Context/Task/TaskDrawer";
import { TaskStatus } from "../../../Strings/TaskStatus";
import { ApiTask } from "../../../Types/task";
import IconBasedOnPriority from "../IconBasedOnPriority";
import {
  AvatarChip, PermissionToEnterIcon, TaskCompactCardDescription
} from "./StyledComponents";
import { CompactCard, CompactCardInfoHeader } from "./StyledComponents/CompactCard";
import { createNameDateLabel } from "./TaskCard/TopRow/RelatedNameDateTime";
import { pluralize } from "./TaskGroup";
import Iconify from "@components/iconify";

interface TaskCompactCardProps {
  task: ApiTask;
  className?: string
}

function instanceOfReservation(object: any): object is ApiReservation {
  return object;
}

interface FastAvatarProps {
  personName: string
}

const bigLetterSize = 24;
const bigLetterRounding = bigLetterSize / 2;
const bigLetterSizeStyle = bigLetterSize + 'px';
const bigLetterRoundingStyle = bigLetterRounding + 'px';

const fastAvatarDivStyle = {
  alignItems: 'center',
  backgroundColor: '#eee',
  borderRadius: '15px',
  boxShadow: '0px 1px 2px #888',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '0.8em',
  marginRight: '5px',
  padding: '5px 10px 5px 0'
};

const fastAvatarBigLetterSpanStyle = {
  backgroundColor: '#8199af',
  borderRadius: bigLetterRoundingStyle,
  color: '#fff',
  display: 'block',
  fontSize: '1.35em',
  height: bigLetterSizeStyle,
  margin: '0 5px',
  textAlign: 'center' as const, // needs to fit the enum
  width: bigLetterSizeStyle
};

const fastAvatarStatusBlipStyle = {
  backgroundColor: '#589450', //NOTE: User status colors would be applied here.
  border: '1px solid #fff',
  borderRadius: '4px',
  marginRight: '-8px', // Correct for the flex account of this div (working hard to keep all at the top level, for perf).
  position: 'relative' as const, // needs to fit the enum
  bottom: '-9px', // These two have to be massaged; no simple dynamic solution.
  right: '12px',  // These two have to be massaged; no simple dynamic solution.
  width: '8px',
  height: '8px'
};

export function FastAvatarChip(props: FastAvatarProps) {
  const { personName } = props;

  if (personName === '') {
    return null;
  }

  return (
    <div style={fastAvatarDivStyle}>
      <span style={fastAvatarBigLetterSpanStyle}>
        {personName.charAt(0)}
      </span>
      <div style={fastAvatarStatusBlipStyle}>
      </div>
      <span>
        {personName}
      </span>
    </div>
  );
};
//^



export default function TaskCompactCard({
  task,
  className
}: TaskCompactCardProps) {
  const {
    id,
    additional_housekeepers,
    assignee,
    description,
    is_closed,
    is_high_priority,
    is_overdue,
    permission_to_enter,
    priority,
    related,
    reporter,
    type,
    status,
    sub_type,
  } = task;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, toggleDrawer] = React.useContext(TaskDrawerControl);
  const [, setCurTask] = React.useContext(TaskDrawerSubject);
  const theme = useTheme();

  const isNextReservationOwner = related !== undefined && related.next_check_in_type === 'Owner';
  const hasAdditionalHousekeepers = additional_housekeepers && additional_housekeepers.length > 0;
  const hasHouseman = sub_type === 'Final' ? related?.hasOwnProperty("houseman") : false;
  const hasInspector = sub_type === 'Glass & Lanai' ? related?.hasOwnProperty("inspector") : false;
  const hasEngineer = sub_type === 'Departure Clean' ? related?.hasOwnProperty("engineer") : false;
  const hasSplitBeds = sub_type === 'Drop & Strip' ? related.split_beds : false
  const isCleaning = type === 'Clean' || sub_type === 'Drop & Strip'
  return (
    <div
      className={className}
      onClick={async (e) => {
        e.preventDefault()
        e.stopPropagation()
        toggleDrawer(true);
        await setCurTask(id);
        return null;
      }}
    >
      <CompactCard variant={is_closed ? "fade" : is_high_priority ? "primary" : is_overdue ? "warning" : undefined}>
        {/* HEADER */}
        <CompactCardInfoHeader
          titleProps={{ fontSize: "small", textTransform: "uppercase", lineHeight: '1.25rem' }}
          text={createNameDateLabel(task, { name: "" })}
          priority={is_high_priority ? priority : undefined}
          status={related?.listing_status}
        >
          <Typography textTransform="uppercase" whiteSpace="nowrap" fontSize="small" lineHeight='1.25rem' fontWeight="600"
            color={(theme) => [TaskStatus.NotStarted, TaskStatus.InProgress].includes(status)
              ? theme.palette.primary.main
              : status === TaskStatus.Completed
                ? theme.palette.success.main
                : theme.palette.error.main}>
            {status}
          </Typography>
        </CompactCardInfoHeader>
        {/* TITLE ROW */}
        <CompactCardInfoHeader
          titleProps={{ fontSize: "medium", textTransform: "capitalize" }} text={
            createTitleForTask(task)
          }>
          <div className="Task-Icon-Section">
            {hasSplitBeds &&
              <Tooltip title="Split beds">
                <CallSplit />
              </Tooltip>
            }
            {related?.turnaround === true && (
              <Tooltip title='Turn around'>
                <Autorenew fontSize="small" color="primary" />
              </Tooltip>
            )}
            <IconBasedOnPriority priority={priority} />
            {permission_to_enter !== undefined && (
              <PermissionToEnterIcon
                permission_to_enter={permission_to_enter}
              />
            )}
            {instanceOfReservation(related) &&
              related?.guest_is_owner === true && (
                <Tooltip title="Owner occupied">
                  <Stars
                    color="primary"
                    fontSize="inherit"
                  />
                </Tooltip>
              )}
          </div>
        </CompactCardInfoHeader>

        {/* DESCRIPTION */}
        <div>
          <TaskCompactCardDescription overflow="ellipsis">
            {description}
          </TaskCompactCardDescription>
        </div>
        <Stack pt={.5} direction='row' alignItems='flex-start' justifyContent='space-between' width='100%' gap={1}>
          {/* ASSIGNEE/REPORTER CHIPS */}
          <Stack flexWrap='wrap' direction='row' flexGrow={1} gap={1}>
            {(assignee?.name !== undefined) && (
              <AvatarChip
                sx={{ height: 'fit-content', p: .5, backgroundColor: theme.palette.secondary.main, color: 'white' }}
                label={assignee.name}
              />
            )}

            {hasAdditionalHousekeepers && additional_housekeepers.map((name) => (
              <AvatarChip
                key={`additional_housekeeper_${name}`}
                sx={{ height: 'fit-content', p: .5, backgroundColor: theme.palette.secondary.main, color: 'white' }}
                label={name}
              />
            ))}
            {reporter?.name !== undefined && (
              <AvatarChip
                sx={{ height: 'fit-content', p: .5, }}
                label={reporter.name}
              />
            )}
            {hasHouseman && (
              <AvatarChip
                sx={{ height: 'fit-content', p: .5, }}
                label={related.houseman}
                icon={<DirectionsWalk fontSize="inherit" sx={{ mr: 1 }} />}
              />
            )}
            {hasInspector && (
              <AvatarChip
                sx={{ height: 'fit-content', p: .5, }}
                label={related.inspector}
                icon={<ContentPasteSearch fontSize="inherit" sx={{ mr: 1 }} />}
              />
            )}
            {hasEngineer && (
              <AvatarChip
                icon={<Engineering fontSize="inherit" sx={{ mr: 1 }} />}
                sx={{ height: 'fit-content', p: .5 }}
                label={related.engineer}
              />
            )}
          </Stack>
          <Stack direction='row' gap={1} flexWrap='wrap' justifyContent='flex-end'>
            {isNextReservationOwner && <AvatarChip
              color="primary"
              size="small"
              sx={{ height: 'fit-content', p: .5, backgroundColor: theme.palette.primary.main, color: 'white' }}
              icon={<Input fontSize="inherit" sx={{ mr: 1 }} />}
              label="Owner" />
            }
            {isCleaning && instanceOfReservation(related) && <NumberBedsLabel reservation={related} taskId={id} />}
          </Stack>
        </Stack>
      </CompactCard>
    </div>
  );
}


export const createTitleForTask = (task: ApiTask) => {
  const { title, sub_type, type } = task
  if (title) return title
  return sub_type + " " + type
};

const NumberBedsLabel = ({ reservation, taskId }: { reservation: ApiReservation, taskId: string }) => {
  const { single_bed_count, queen_bed_count, king_bed_count, full_bed_count } = reservation;
  const totalBeds = single_bed_count + queen_bed_count + king_bed_count + full_bed_count;
  if (!totalBeds) {
    return null
  }
  return (
    <Stack
      id={`nummber-beds-row-${taskId}`}
      direction='row'
      alignItems='center'
      justifyContent='center'
      spacing={.5}
      sx={{ border: '1px solid lightgray', px: 1, py: .5, borderRadius: '16px', cursor: 'default' }}
      divider={<Divider variant="middle" orientation="vertical" flexItem sx={{ borderColor: 'lightgray' }} />}>
      {mapBedsToIcon(reservation, taskId)}
    </Stack>
  );
};

const mapBedsToIcon = (reservation: ApiReservation, taskId: string) => {
  const { single_bed_count, queen_bed_count, king_bed_count, full_bed_count } = reservation;
  const totalBeds = single_bed_count + queen_bed_count + king_bed_count + full_bed_count;
  const icons = [
    <Tooltip title={`Total of ${totalBeds} ${pluralize(totalBeds, 'bed')}`}>
      <Iconify icon='fluent:bed-24-regular' width='.8rem' />
    </Tooltip>
  ];
  if (king_bed_count) {
    icons.push(
      <React.Fragment key={`${taskId}-king-bed-count`}>
        <Tooltip title={pluralize(king_bed_count, "King Bed")}>
          <Stack direction='row' alignItems='center'>
            <Typography variant="caption">
              {king_bed_count}
            </Typography>
            <Typography variant="caption" fontWeight='bold'>K</Typography>
          </Stack>
        </Tooltip>
      </React.Fragment>
    )
  };
  if (queen_bed_count) {
    icons.push(
      <React.Fragment key={`${taskId}-queen-bed-count`}>
        <Tooltip title={pluralize(queen_bed_count, "Queen Bed")}>
          <Stack direction='row' alignItems='center'>
            <Typography variant="caption">
              {queen_bed_count}
            </Typography>
            <Typography variant="caption" fontWeight='bold'>Q</Typography>
          </Stack>
        </Tooltip>
      </React.Fragment>
    )
  };
  if (full_bed_count) {
    icons.push(
      <React.Fragment key={`${taskId}-full-bed-count`}>
        <Tooltip title={pluralize(full_bed_count, "Full Bed")}>
          <Stack direction='row' alignItems='center'>
            <Typography variant="caption">
              {full_bed_count}
            </Typography>
            <Typography variant="caption" fontWeight='bold'>F</Typography>
          </Stack>
        </Tooltip>
      </React.Fragment>
    )
  };
  if (single_bed_count) {
    icons.push(
      <React.Fragment key={`${taskId}-single-bed-count`}>
        <Tooltip title={pluralize(single_bed_count, "Single Bed")}>
          <Stack direction='row' alignItems='center'>
            <Typography variant="caption">
              {single_bed_count}
            </Typography>
            <Typography variant="caption" fontWeight='bold'>S</Typography>
          </Stack>
        </Tooltip>
      </React.Fragment>
    )
  };
  return icons;
};