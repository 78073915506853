import * as React from "react";
import ListingDetail from "./ListingDetail";
import { CardItem } from "@components/Typography/CardItemText";
import DescriptionRow from "../DescriptionRow";

export default function ReservationDetail(props: {
  listing_order_item: ApiListingOrderItem;
}) {
  const [collapseRelatedDescription, setCollapseRelatedDescription] =
    React.useState(false);


  React.useEffect(() => {
    return () => {
      setCollapseRelatedDescription(false);
    };
  }, []);
  if (!props.listing_order_item) return null;

  const handleClick = (__event: any) => {
    setCollapseRelatedDescription((prev) => !prev);
    return collapseRelatedDescription;
  };
  return (
    <>
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Listing Order Item" text={props.listing_order_item?.name || "-"} />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Status" text={props.listing_order_item?.status || "-"} />
        </div>
      </div>
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Type" text={props.listing_order_item?.type || "-"} />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Sub Type" text={props.listing_order_item?.sub_type || "-"} />
        </div>
      </div>
      <ListingDetail listing={props.listing_order_item} forceVerticalStyle />
      <div className="Task-Card-Item-Row">
        <span>
          <CardItem header="Title" text={props.listing_order_item?.title || "-"} />
        </span>
      </div>
      {props.listing_order_item.description &&
        <DescriptionRow description={props.listing_order_item.description}
          handleClick={handleClick} />
      }
    </>
  );
}
