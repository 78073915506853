import * as React from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ListingDetail from "./ListingDetail";
import InputIcon from "@mui/icons-material/Input";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import StarsIcon from "@mui/icons-material/Stars";
import { format, isValid } from "date-fns";
import { CardItem } from "@components/Typography/CardItemText";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

export default function ReservationDetail(props: {
  reservation: ApiReservation;
  alwaysShowDescription: boolean
}) {
  const [showGapDetails, setShow] = React.useState<boolean>(props.alwaysShowDescription);
  if (!props.reservation) return null;

  return (
    <div className="Task-Card-Item-Container">
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Reservation" text={props.reservation.name || "-"} />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem
            header="Type"
            text={props.reservation?.reservation_type ? props.reservation.reservation_type : props.reservation.type || "-"}
            textIcon={props.reservation.type === "Owner" ? InputIcon : undefined}
          />
        </div>
      </div>
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem
            header="Guest"
            text={props.reservation.guest_name || "-"}
            textIcon={props.reservation.guest_is_owner ? StarsIcon : undefined}
          />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem
            header="Next Type"
            text={props.reservation?.next_check_in_type ? props.reservation.next_check_in_type : "-"}
            textIcon={
              props.reservation?.next_check_in_type === "Owner"
                ? InputIcon
                : undefined
            }
          />
        </div>
      </div>

      <ListingDetail listing={props.reservation} hideGapRepeat={true} forceVerticalStyle />

      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem
            header="Check In"
            text={isValid(new Date(props.reservation.check_in)) ? format(new Date(props.reservation.check_in), "MMM dd'・'h:mm a") : "-"}
            textIcon={props.reservation.back_to_back ? AutorenewIcon : undefined}
          />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem
            header="Check Out"
            text={isValid(new Date(props.reservation.check_out)) ? format(new Date(props.reservation.check_out), "MMM dd'・'h:mm a") : "-"}
            textIcon={props.reservation.turnaround ? AutorenewIcon : undefined}
          />
        </div>
      </div>
      <div className="Task-Card-Item-Container">
        {!props.alwaysShowDescription
          && <div className={`Task-Card-Item-Row center ${!showGapDetails && 'No-Margin'}`}>
            <Button
              // sx={{ p: 0, pt: '5px' }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              size="small"
              fullWidth
              onClick={() => {
                setShow((prev) => !prev);
              }}
              endIcon={showGapDetails ? <KeyboardArrowUp fontSize="inherit" /> : <KeyboardArrowDown fontSize="inherit" />}
            >
              {showGapDetails ? "Hide Gap Details" : "Show Gap Details"}
            </Button>
          </div>
        }
        <Collapse in={showGapDetails} sx={{ width: "100%", padding: 0 }}>
          <div className="Task-Card-Item-Row">
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Check In Gap"
                text={props.reservation.check_in_gap
                  ? `${props.reservation.check_in_gap}`
                  : "-"}
              />
            </div>
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Check Out Gap"
                text={props.reservation.check_out_gap
                  ? `${props.reservation.check_out_gap}`
                  : "-"}
              />
            </div>
          </div>
          <div className="Task-Card-Item-Row">
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Previous Check Out"
                text={props.reservation.previous_check_out && isValid(new Date(props.reservation.previous_check_out))
                  ? format(new Date(props.reservation.previous_check_out), "MMM dd'・'h:mm a")
                  : "-"
                }
              />
            </div>
            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Next Check In"
                text={
                  props.reservation.next_check_in && isValid(new Date(props.reservation.next_check_in))
                    ? format(new Date(props.reservation.next_check_in), "MMM dd'・'h:mm a")
                    : "-"
                }
              />
            </div>
          </div>
        </Collapse>
      </div>
    </div >
  );
}
