import { useRef, useEffect } from 'react'

function useDocumentTitle(title?: string, prevailOnUnmount = false) {
  const defaultTitle = useRef('Ohana Portal');

  useEffect(() => {
    if (title) {
      document.title = `${decodeURIComponent(title)} | Ohana Portal`;
    }
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}

export default useDocumentTitle