import React from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller } from "react-hook-form";
import AppFormInputProps, { OptionInterface } from "./AppFormInputProps";

function generateSingleOptions(options: OptionInterface[] | string[]) {
  return options.map((option: OptionInterface | string, index) =>
    typeof option === "string" ? (
      <MenuItem
        sx={option === '' ? { display: 'none' } : undefined}
        key={`${option}_${index}`}
        value={option}
      >
        {option}
      </MenuItem >
    ) : (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    )
  );
}
export default function AppFormInputDropdown({
  name,
  control,
  label,
  options,
  required,
  whenChanged,
  sx,
  defaultValue,
  size,
  className,
  onOpen,
  onClose,
  handleChange
}: AppFormInputProps) {
  return (
    <FormControl sx={{ padding: 0 }} size={size} required={required} fullWidth>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          // console.log('AppFormInputDropdown::render', value);
          return (
            <>
              <FormLabel id={`${label}_label`}>{label}</FormLabel>
              {options && (
                <Select
                  MenuProps={{ sx: { maxHeight: '60vh' } }}
                  onOpen={onOpen}
                  onClose={onClose}
                  className={className}
                  fullWidth
                  onChange={(e) => {
                    if (handleChange) {
                      handleChange(e.target.value);
                    }

                    if (whenChanged) {
                      whenChanged(e);
                    }
                    return onChange(e);
                  }}
                  defaultValue={defaultValue}
                  value={value}
                  variant="outlined"
                  required={required}
                  labelId={`${label}_label`}
                  sx={{
                    padding: "0px 0px 0px 0px",
                    ...sx,
                  }}
                >
                  {generateSingleOptions(options)}
                </Select>
              )}
              {error?.message && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </>
          );
        }}
        control={control}
        name={name}
      />
    </FormControl>
  );
}
