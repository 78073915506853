import * as React from "react";
import { Typography, Grid } from "@mui/material";
import { AssignmentLate } from "@mui/icons-material";
import { Box } from "@mui/system";


export function NoMatchingFilters(props: { componentName: string; }) {
  const { componentName } = props;
  const text = typeof componentName === 'string' ? `No matching ${componentName.toLowerCase()} found.` : 'No matching results.'
  return (
    <Grid
      component={Box}
      container
      display="flex"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        boxSizing: "border-box",
        opacity: 0.2,
        mt: "10rem",
      }}
    >
      <Grid item>
        <AssignmentLate sx={{ fontSize: "5.5rem" }} />
      </Grid>
      <Grid item>
        <Typography
          variant={"h3"}
          textAlign='center'
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );

}
