import { ClearFileInput } from "@context/Attachments";
import * as React from "react";
import { createFileAsDataUrlObject } from "./Helpers/createFileAsDataUrlObjects";

interface InputForFileManagerProps {
  id: string;
  taskAttachmentContentPostRequest: (file: any, taskId?: string) => Promise<any>
  taskId?: string
}

const InputForFileManager = React.forwardRef<HTMLInputElement, InputForFileManagerProps>((props, fileInputRef) => {
  const { taskAttachmentContentPostRequest, id } = props;
  const clearFileInput = React.useContext(ClearFileInput)
  return (
    <input
      hidden
      ref={fileInputRef}
      accept="image/*,application/pdf,video/*"
      style={{ display: "none" }}
      id={id}
      multiple
      type="file"
      onChange={async (e) => {
        const { files } = e.target;
        if (files?.item(0)) {
          let curAttachmentIndex = 0;
          while (files.item(curAttachmentIndex)) {
            createFileAsDataUrlObject(files.item(curAttachmentIndex)).then((payload) => {
              taskAttachmentContentPostRequest(payload, props.taskId);
            });
            curAttachmentIndex++;
          }

        }
        clearFileInput()
        e.target.value = ""
        return e.target.files = null;
      }} />
  );
});

export default InputForFileManager