import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@date-io/date-fns";
import { TaskFormLabel } from "./TaskFormLabel";
import { Control, Controller } from "react-hook-form";

export function DueDateInput(props: { control: Control<any>; label?: string }) {
  return (
    <div id="edit_task_duedate_time">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TaskFormLabel label={props.label || "Due Date"} />
        <Controller name="due_datetime" control={props.control} render={({ field: { value, onChange } }) => <DateTimePicker
          disableMaskedInput
          showToolbar={false}
          showDaysOutsideCurrentMonth
          minutesStep={15}
          inputFormat="eeee, MMM do h:mm a"
          value={value}
          closeOnSelect
          onChange={onChange}
          InputProps={{
            sx: {
              width: "100%",
              height: 41,
              textAlign: "left",
              textIndent: "none",
              ".MuiOutlinedInput-input": { pl: 1 },
            },
          }}
          renderInput={(params) => {
            if (params?.inputProps) {
              params.inputProps.readOnly = true;
            }
            return <TextField fullWidth {...params} name="due_datetime" />;
          }} />} />
      </LocalizationProvider>
    </div>
  );
}
