import { ConfirmActionMessage } from '../Helpers'
import DefaultConfirmModal from './DefaultConfimModal'

//! FINISHED DONT TOUCH
export default function CompleteTaskModal(props: { handleConfirm: any, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, escalateTask: boolean, setEscalateTask: React.Dispatch<React.SetStateAction<boolean>> }) {
  const toggleLabel = "Escalate Task"

  const toggleChange = (val: React.BaseSyntheticEvent | 'reset') => {
    if (val === 'reset') {
      props.setEscalateTask(false)
    } else {
      props.setEscalateTask(val?.target?.checked === true)
    }
  }

  return (
    <DefaultConfirmModal
      actionLabel={ConfirmActionMessage("endTask")}
      open={props.open}
      setOpen={props.setOpen}
      onConfirmed={props.handleConfirm}
      toggleLabel={toggleLabel}
      toggleValue={props.escalateTask}
      onToggleChange={toggleChange}
    />
  )
}