import * as React from "react";
import { useNavigate } from "react-router-dom";

import ContinueWithSalesforce from "./AuthLinks/ContinueWithSalesforce";
import PasswordForm from "./PasswordForm";

import { SignUpAuthContext, SignUpContext } from "@context/Auth";
import { Button, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

interface SalesforceAuthModalBodyProps {
  killAutoRedirect: () => void,
  triggerRedirect: boolean,
  reauthorizeMode: boolean,
  salesforceLoginHint: string,
}

export default function SalesforceAuthModalBody(props: SalesforceAuthModalBodyProps) {
  const userData = React.useContext(SignUpAuthContext);
  const loadingAuth = React.useContext(SignUpContext);
  const navigate = useNavigate();

  return (
    <Grid
      item
      sm={12}
      container
      justifyContent="center"
      alignItems="center"
      height="fit-content"
    >
      {userData === null && (
        <>
          <ContinueWithSalesforce
            loading={loadingAuth}
            reauthorizeMode={props.reauthorizeMode}
            triggerRedirect={props.triggerRedirect}
            salesforceLoginHint={props.salesforceLoginHint}
          />
          <Divider sx={{ width: "85%", height: "1px", mt: 2 }} />
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              fontSize: ".8rem",
              mt: 2,
              color: "GrayText",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            If this is a mistake, return to
            <Button
              variant="text"
              size="small"
              onClick={() => {
                props.killAutoRedirect()
                navigate("/login")
              }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={(theme) => ({
                whiteSpace: "nowrap",
                color: theme.palette.primary.light,
                textTransform: "none",
                minWidth: "unset",
                letterSpacing: theme.typography.body2.letterSpacing,
              })}
            >
              Login
            </Button>
            screen
          </Typography>
        </>
      )}
      {userData !== null && <PasswordForm />}
    </Grid>
  );
}
