import { SvgIconComponent } from "@mui/icons-material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import "./appTypography.css"
import { EmailLink, PhoneLink } from "@components/Task/Sub_Components/TaskCard/ContactRow/ContactLinks";
import { StyledNavLink } from "@components/Task/Sub_Components/StyledComponents";

interface CardItemProps {
  header: string;
  text: string;
  headerIcon?: SvgIconComponent;
  textIcon?: SvgIconComponent;
  textIconProps?: {
    end?: boolean
    color?: "primary" | "error" | "secondary" | "warning"
  }
  textIsEmail?: boolean;
  textIsPhone?: boolean;
  linkProps?: {
    to: string;
    onClick: () => void
  };
  textProps?: TypographyProps;
  splitAsArray?: boolean;
  arrayOfLines?: string[];
}

export function CardItem(props: CardItemProps) {
  if (props.splitAsArray && !Array.isArray(props.arrayOfLines)) {
    console.warn('Using array mode without an array of text.')
  }

  return (
    <>
      <AppTypographyHeader header={props.header} />
      {(props.splitAsArray && props.arrayOfLines)
        ? props.arrayOfLines.map((v, i) =>
      <AppTypographyBody
              key={'line' + i}
              text={v}
              textIcon={props.textIcon}
              textIconProps={props.textIconProps}
              textIsEmail={props.textIsEmail}
              textIsPhone={props.textIsPhone}
              linkProps={props.linkProps}
              textProps={props.textProps}
            />
          )
        : <AppTypographyBody
        text={props.text}
        textIcon={props.textIcon}
        textIconProps={props.textIconProps}
        textIsEmail={props.textIsEmail}
        textIsPhone={props.textIsPhone}
        linkProps={props.linkProps}
        textProps={props.textProps}
      />
      }
    </>
  );
}

interface AppTypographyHeaderProps {
  header: string;
  headerIcon?: SvgIconComponent;
  textIconProps?: {
    end?: boolean
    color?: "primary" | "error" | "secondary" | "warning"
  }
}

export function AppTypographyHeader(props: AppTypographyHeaderProps) {
  const Icon = props.headerIcon;
  const iconPosStart = props.textIconProps === undefined
    ? true
    : props.textIconProps.end === undefined
      ? true
      : !props.textIconProps.end
  return (
    <Typography className="AppTypographyHeader" >
      {Icon && iconPosStart && <Icon className="AppTypographyHeader-Icon" color="primary" />}
      {props.header}
      {Icon && !iconPosStart && <Icon className="AppTypographyHeader-Icon" color="primary" />}
    </Typography>
  );
}

export interface AppTypographyBodyI {
  text: string;
  textIcon?: SvgIconComponent;
  textIconProps?: {
    end?: boolean
    color?: "primary" | "error" | "secondary" | "warning"
  }
  textIsEmail?: boolean;
  textIsPhone?: boolean;
  linkProps?: {
    to: string;
    onClick: () => void
  }
  textProps?: TypographyProps
}

export function AppTypographyBody(props: AppTypographyBodyI) {
  const Icon = props.textIcon;

  const iconColor = props.textIconProps === undefined
    ? 'primary'
    : props.textIconProps.color === undefined
      ? 'primary'
      : props.textIconProps.color

  const iconPosStart = props.textIconProps === undefined
    ? true
    : props.textIconProps.end === undefined
      ? true
      : !props.textIconProps.end

  const Content = props.textIsPhone
    ? <PhoneLink phoneNumber={props.text} />
    : props.textIsEmail
      ? <EmailLink email={props.text} />
      : props.linkProps
        ? <StyledNavLink to={props.linkProps.to} onClick={props.linkProps.onClick}>{props.text}</StyledNavLink>
        : props.text


  return (
    <>
      {!props.linkProps &&
        <Typography className="AppTypographyBody" {...props.textProps} >
          {Icon && iconPosStart === true && <Icon className="AppTypographyHeader-Icon" color={iconColor} />}
          {Content}
          {Icon && iconPosStart === false && <Icon className="AppTypographyHeader-Icon" color={iconColor} />}
        </Typography>
      }
      {props.linkProps &&
        <>
          {Icon && iconPosStart === true && <Icon className="AppTypographyHeader-Icon" color={iconColor} />}
          {Content}
          {Icon && iconPosStart === false && <Icon className="AppTypographyHeader-Icon" color={iconColor} />}
        </>
      }
    </>
  );
}


export function SubTitleText(props: React.PropsWithChildren) {
  return <Typography className="SubTitle-Text">{props.children}</Typography>
}

export function MainHeaderText(props: React.PropsWithChildren) {
  return <Typography className="MainHeader-Text">{props.children}</Typography>
}