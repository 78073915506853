import { salesforce_api } from "@constants";
import { UserConstructor } from "@classes/User";
import { SalesforceRequestParams } from "@request/Salesforce/SalesforceRequestParams";
import { SalesforceRequestAuthParams } from "@request/Salesforce/SalesforceRequestAuthParams";
import { SalesforceRequest } from "@request/Salesforce/SalesforceRequest";
import { auth } from "@config/Firebase/app";

export default async function fetchUserFromApex(
  endPoint: string,
  token: string,
  refreshToken: string,
  firebaseEmail: string,
  issued_at: string,
  sf_username: string
) {
  if (!salesforce_api.rest) throw new Error("Plese configure host");
  if (!endPoint) throw new Error("Plese configure endPoint");

  // -------- OUR HEADERS ----------
  const headers = new Headers();

  headers.set("Authorization", token);
  // deepcode ignore ContentTypeNoCharset: <content type should be application/json>
  headers.set("Content-Type", "application/json");
  headers.set("x-api-key", salesforce_api.x_api_key);
  // ------- Headers END ----------
  const sfRequestAuthParams = new SalesforceRequestAuthParams(
    token,
    refreshToken,
    firebaseEmail,
    issued_at,
    sf_username
  );
  const params = new SalesforceRequestParams(sfRequestAuthParams, {
    endpoint: endPoint,
  });

  const preFetchAccessToken = params.accessToken;
  const request = new SalesforceRequest(params);

  if (!request.isValid()) {
    console.error("Userfetch request not valid.");
    return null;
  }

  const result = await request.attemptSend();

  if (!result.callSent) {
    console.error("No user fetched; call not sent.");
    return null;
  } // session expired
  const salesforceUserJson = result.GetParsedJson();

  if (!salesforceUserJson.hasOwnProperty("user")) {
    console.error(
      "user fetch response has no user",
      salesforceUserJson,
      result
    );
    return null;
  }

  //NOTE: Complicated case here. If an SF access token expired, and we refreshed it, that new
  //      token is written to params.
  //      We need to include that info with this user.

  let response!: UserConstructor;

  if (preFetchAccessToken !== params.accessToken) {
    console.warn("including (apparently) new access token with fetched user");

    const jsonUserDataWithSFInfo = Object.assign(salesforceUserJson.user, {
      sf_access_token: params.accessToken,
      sf_refresh_token: params.getRefreshToken(),
      sf_issued_at: params.issuedAt,
    });

    response = jsonUserDataWithSFInfo;
  } else {
    response = salesforceUserJson.user;
  }

  if (
    auth &&
    auth.currentUser !== null &&
    auth.currentUser.email !== null &&
    auth.currentUser.email !== response.email
  ) {
    console.log("User Salesforce email does not match Firebase auth email");
    //NOTE: This is a rare but valid case. If the user changes their email _in Salesforce_, after signup,
    //      we don't want to break linkage with Firebase. We continue to use the email from the user's
    //      SF account at the time of sign-up.
    response.email = auth.currentUser.email;
  }

  return response;
}
