import * as React from 'react'
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ErrorFallback(props: { message: string, disableRedirect?: true }) {
  const navigate = useNavigate()
  React.useEffect(() => {
    if (props.disableRedirect) return
    const timeout = setTimeout((resolve) => {
      resolve(navigate('/home'))
    }, 5_000)
    return () => { clearTimeout(timeout) }
  }, [navigate, props])
  return (
    <Box width='100%' display="flex" justifyContent="flex-start" flexDirection="column" alignItems="center">
      <Typography color='error'>{props.message}</Typography>
      {!props.disableRedirect && <Typography>Redirecting to the home page in five seconds.</Typography>}
    </Box>
  )
}