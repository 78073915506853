import * as React from "react";
import {
  FormControl,
  FormControlLabel,
  Switch,
  FormGroup,
  FormHelperText,
  Paper,
} from "@mui/material";
import { Controller } from "react-hook-form";
import AppFormInputProps from "./AppFormInputProps";

export default function AppFormInputRadio({
  name,
  control,
  label,
  required,
  disabled = false,
  height,
  width,
  sx
}: AppFormInputProps) {
  return (
    <FormControl
      component="fieldset"
      required={required}
      size="small"
      disabled={disabled}
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          // formState,
        }) => {
          return (
            <>
              <Paper
                sx={{
                  width: width || "fit-content",
                  height: height || "auto",
                  border: (theme) =>
                    value
                      ? `.05px solid ${theme.palette.primary.dark}`
                      : `.05px solid ${theme.palette.text.secondary}`,
                  padding: 0.2,
                  margin: 0,
                  backgroundColor: (theme) => theme.palette.background.paper,
                  opacity: 0.7,
                  ...sx
                }}
              >
                <FormGroup
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                >
                  <FormControlLabel
                    label={label || ""}
                    control={
                      <Switch
                        onChange={onChange}
                        name={label}
                        checked={value}
                        size="small"
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    key={label}
                    labelPlacement="top"
                    sx={(theme) => ({
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.dark
                          : theme.palette.primary.light,
                    })}
                  />
                </FormGroup>
              </Paper>
              {error && (
                <FormHelperText error={error.message !== undefined}>
                  {error.message}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
}
