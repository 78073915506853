import * as React from "react";
import { createSelectableUserObjects } from "@helpers/createSelectableObjects";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import { ApiUserList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";

const useTaskReporters = () => {
  const dispatch = useAppDispatch()
  const persistedObject = usePersistentObject()

  React.useEffect(() => {
    if (!persistedObject.checkUserList(ApiUserList.activeUsers)) {
      dispatch(updatePersistentObjects(PersistentObject.user, ApiUserList.activeUsers))
    }
  }, [dispatch, persistedObject])

  return React.useMemo(() => {
    if (persistedObject.checkUserList(ApiUserList.activeUsers)) {
      return createSelectableUserObjects(persistedObject.getUserList(ApiUserList.activeUsers)).sort((item1, item2) => item1.label > item2.label ? 1 : item1.label < item2.label ? -1 : 0)
    }
    return null
  }, [persistedObject])
};

export default useTaskReporters;
