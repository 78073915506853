import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import useProfile from "@hooks/Selectors/useProfile";
import { AirportShuttleOutlined, DirectionsWalk } from "@mui/icons-material";
import CleaningServices from "@mui/icons-material/CleaningServices";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoveDown from "@mui/icons-material/MoveDown";
import MoveUp from "@mui/icons-material/MoveUp";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { LoadingButton } from "@mui/lab";
import { ButtonGroup, IconButton, Typography } from "@mui/material";
import { styled, SxProps } from "@mui/system";
import { SyncUserSettings } from "@store/Reducers/Session";
import * as React from "react";
import { ApiTask } from "../../../Types/task";
export interface Region {
  // id: number;
  // zone: string;
  group: string;
  tasks: ApiTask[];
  order: number;
  hasTasks: boolean;
  isFirst: boolean;
  isLast: boolean
}
interface TaskCompactCardDividerProps {
  title: ApiTask['type'];
  taskKey?: "type" | "due_date" | "Queue";
  toggleGroup?: React.SetStateAction<any>;
  sx?: SxProps;
  size?: number;
  hidden?: boolean;
  key?: string;
  region?: Region;
  regions?: Region[];
}

export default function TasksGroups({
  title,
  taskKey,
  toggleGroup,
  sx,
  size,
  hidden = false,
  region,
  regions
}: TaskCompactCardDividerProps) {
  const dispatch = useAppDispatch()
  const user = useProfile()
  const [loading, setLoading] = React.useState<"up" | "down" | null>(null)
  const orderCapible = region && regions // if these two props exist we know we will manage an order here.
  const handleClickOrderButton = async (dir: 'up' | 'down') => {
    if (!orderCapible) return
    if (loading !== null) return
    setLoading(dir)
    let keepGoing = true
    const dirKey = dir === 'up' ? -1 : 1
    for (let i = region.order + dirKey; keepGoing; i += dirKey) {
      if (i < 0 || i >= regions.length) {
        // Out of bounds
        break
      }
      if (!regions[i].hasTasks) {
        continue
      }
      // We are at the region we would like to swap with
      const temp = regions[region.order];
      regions[region.order] = regions[i]
      regions[i] = temp

      keepGoing = false
    }
    const newOrder = regions.map((region) => region.group)
    try {
      await dispatch(SyncUserSettings(user, 'task_order', newOrder))
    } catch (e) {
      console.warn("Failed to update firestore.", e)
    }
    setLoading(null)
  }

  return (
    <StyledDividerInnerContainer sx={{ color: (theme) => theme.palette.primary.main, ...sx }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {title.toLowerCase() === "drop" && <PinDropIcon sx={{ mr: 1 }} />}
        {title.toLowerCase() === "maintenance" && (
          <DirectionsWalk sx={{ mr: 1 }} />
        )}
        {title.toLowerCase() === "inspection" && (
          <FactCheckIcon sx={{ mr: 1 }} />
        )}
        {title.toLowerCase() === "clean" && (
          <CleaningServices sx={{ mr: 1 }} />
        )}
        {title.toLowerCase() === "pickup" && (
          <LocalShippingIcon sx={{ mr: 1 }} />
        )}
        {title.toLowerCase() === "shuttle service" && (
          <AirportShuttleOutlined sx={{ mr: 1 }} />
        )}
        <Typography component="span" fontWeight="bold" >
          {taskKey !== "due_date" ? pluralize(size, title, `s (${size})`) : `${title} (${size})`}
        </Typography>
        {orderCapible && <StyledDividerToggle
          name={title}
          onClick={() => toggleGroup()}
          type="button"
        >
          {hidden ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </StyledDividerToggle>}
      </div>
      {orderCapible ?
        <ButtonGroup>
          <LoadingButton disabled={region.isLast || loading === 'down'} loading={loading === 'down'} onClick={() => handleClickOrderButton('down')}><MoveDown /></LoadingButton>
          <LoadingButton disabled={region.isFirst || loading === 'up'} loading={loading === 'up'} onClick={() => handleClickOrderButton('up')}><MoveUp /></LoadingButton>
        </ButtonGroup>
        :
        <StyledDividerToggle
          name={title}
          onClick={() => toggleGroup()}
          type="button"
        >
          {hidden ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </StyledDividerToggle>
      }
    </StyledDividerInnerContainer>
  );
}

const StyledDividerToggle = styled(
  IconButton,
  {}
)({
  textTransform: "none",
  ":hover": { backgroundColor: "transparent" },
});

const StyledDividerInnerContainer = styled(
  "div",
  {}
)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "auto",
  minWidth: 350,
}));

export const pluralize = (count = 0, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;
