import fetchUserFromApex from '@components/Auth/SalesforceOauth/Callback/userFetch';
import { ClearSignUpContext, SFReauthUserData } from '@context/Auth';
import * as React from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import User from '@classes/User';
import useAppDispatch from '@hooks/Dispatch/useAppDispatch';
import { logOut, setUser } from '@store/Reducers/Session';
import { writeToLSReauthHold } from '@request/Salesforce/SalesforceRequest';
import { Typography } from '@mui/material';
import "./reauthorizingView.css"

//TEMP (probably)
const DEV_REAUTH_TAG = '[[REAUTH]]';

export default function ReauthorizeLanding() {
  const dispatch = useAppDispatch()
  const clearSignUpContext = React.useContext(ClearSignUpContext)
  const [reauthContext] = React.useContext(SFReauthUserData)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate();

  if (!searchParams.has("code")) {
    console.warn(DEV_REAUTH_TAG, "No code found in auth parameters.")
    return <Navigate to="login" replace />
  }

  if (!searchParams.has("state")) {
    console.warn(DEV_REAUTH_TAG, "No email found in auth parameters.")
    return <Navigate to="login" replace />
  }

  const state = JSON.parse(searchParams.get("state")!)
  console.log(DEV_REAUTH_TAG, 'reauth url has state', state)

  if (reauthContext !== null) {
    console.log(DEV_REAUTH_TAG, "CONTEXT", reauthContext)

    // clear any pending re-auth hold
    writeToLSReauthHold(false);

    const { access_token, id, issued_at, refresh_token, token_type } = reauthContext

    if (typeof id !== 'string') {
      throw new Error('Invalid ID on reauthorize; non string: ' + (typeof id));
    }

    const idParts = id.split("/");

    if (idParts.length < 1) {
      throw new Error('Invalid ID on reauthorize; not enough components; length ' + idParts.length);
    }

    const candidateUserID = idParts[idParts.length - 1];

    console.log(DEV_REAUTH_TAG, 'sending re-auth user fetch')

    fetchUserFromApex(
      `/api/user/${candidateUserID}`,
      `${token_type} ${access_token}`,
      refresh_token,
      state.email,
      issued_at,
      state.salesforceUsername)
    .then(async (response) => {
      console.log(DEV_REAUTH_TAG, 're-auth user fetch responded', response)
      if (response !== null) {
        const user = new User({ ...response, sf_access_token: `${token_type} ${access_token}`, sf_issued_at: issued_at, sf_refresh_token: refresh_token })
        const firestoreWriteResult = await user.initializeFireStore();
        if (firestoreWriteResult === null) {
          dispatch(logOut());
          navigate('/login');
          return;
        }

        dispatch(setUser(user))
      }
    }).catch(e => {
      console.error(DEV_REAUTH_TAG, 're-auth user fetch error!', e)
      //TODO: Does this need an on-screen element to communicate to the user?
    });
  }
  else {
    console.log('not ready for reauth; reauthContext is null');
  }

  clearSignUpContext()
  return <ReauthorizingViewComponent />
}

export function ReauthorizingViewComponent() {
  return (
    <div className="reauthorizing">
      <Typography fontSize="large" fontWeight="bold" textAlign="center">Reauthorizing...</Typography>
    </div>
  )
}