import useWindowDimensions from "@hooks/Window/useWindowDimensions";
import * as React from "react";
import appMenuItems from "../../../Components/AppSideBar/appMenuItems";

import { cleaningsTabFilterOptions, specialProjectsTabFilterOptions } from "@classes/Task/taskStrings";
import { isEndpointsAllowedToRequest } from "@context/Task";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";
import { AirportShuttleOutlined, BeachAccess, ConfirmationNumberOutlined, Deck, DirectionsWalk, DoneAll, IntegrationInstructions, LocalLaundryService, ManageSearch, PendingActions, PinDrop } from "@mui/icons-material";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import AttractionsIcon from "@mui/icons-material/Attractions";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import { ApiQueueList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";

export const cleansSubtypeOptions = cleaningsTabFilterOptions.map((subType) => subType.replace(' & ', ' And ')).join('&Subtype=')
export const specialSubtypeOptions = specialProjectsTabFilterOptions.map((subType) => subType.replace(' & ', ' And ')).join('&Subtype=')
export const PATH_TASKS = {
  MY_TASKS: "/tasks/my-tasks",
  ALL_TASKS: "/tasks/all",
  DROP_STRIPS: `/tasks/DropStrips?Types=Clean&Subtype=Drop And Strip`,
  MAINTENANCE_INSPECTION: `/tasks/Maintenance Inspections?Types=Inspection&Subtype=Maintenance`,
  GLASS_LANAI: `/tasks/GlassLanai?Types=Clean&Subtype=Glass And Lanai`,
  FINAL_INSPECTION: `/tasks/Final Inspections?Types=Inspection&Subtype=Final`,
  CLEANINGS: `/tasks/Cleanings?Types=Clean&Subtype=${cleansSubtypeOptions}`,
  SPECIAL_PROJECTS: `/tasks/Special Projects?Types=Clean&Subtype=${specialSubtypeOptions}`
}

type AppBarToggleType = [
  state: boolean,
  setState: (a?: boolean) => void
]

export const AppBarToggle = React.createContext<AppBarToggleType>(undefined!);
export const AppBarMenuItems = React.createContext(appMenuItems);

/**
 *
 * @returns AppBarToggle an Array where param-1 is a boolean representing open and param-2 being a setter
 * @returns CollapseAppBar an Array where param-1 is a boolean representing a slim or uncollapsed sidebar view and param-2 being a setter
 */

export default function AppBarProvider(props: React.PropsWithChildren<any>) {
  const { width } = useWindowDimensions();
  const persistedObject = usePersistentObject()
  const dispatch = useAppDispatch()
  const menuItems = React.useRef(appMenuItems);
  const [open, setOpen] = React.useState(width > 820);
  React.useEffect(() => {
    if (!persistedObject.checkQueues(ApiQueueList.allQueues)) {
      dispatch(updatePersistentObjects(PersistentObject.queue, ApiQueueList.allQueues))
    }
    if (!persistedObject.checkQueues(ApiQueueList.myQueues)) {
      dispatch(updatePersistentObjects(PersistentObject.queue, ApiQueueList.myQueues))
    }
  }, [dispatch, persistedObject])
  const allQueues = React.useMemo(() => {
    if (persistedObject.checkQueues(ApiQueueList.allQueues)) {
      return persistedObject.getQueue(ApiQueueList.allQueues)
    }
    return null
  }, [persistedObject])
  const myQueues = React.useMemo(() => {
    if (persistedObject.checkQueues(ApiQueueList.myQueues)) {
      return persistedObject.getQueue(ApiQueueList.myQueues)
    }
    return null
  }, [persistedObject])

  const toggleOpen = (open?: boolean) => {
    if (open !== undefined) {
      setOpen(open)
      return
    }
    setOpen(prev => !prev)
    return
  }

  const reduceMenuItems = React.useCallback(
    (subMenuItems: MenuItem[], parentMenuItemTitle?: MenuItem["title"]) => {
      if (!parentMenuItemTitle) {
        menuItems.current = [...menuItems.current, ...subMenuItems]
      }
      if (subMenuItems.length === 0) return menuItems.current;
      const res = menuItems.current.reduce((accum: MenuItem[], cur) => {
        if (cur.title === parentMenuItemTitle) {
          cur.subMenuItems = subMenuItems;
        }
        return [...accum, cur];
      }, []);
      menuItems.current = res;
    },
    [menuItems]
  );

  React.useEffect(() => {
    if (myQueues === null) {
      reduceMenuItems(
        [
          {
            title: "My Tasks",
            href: PATH_TASKS.MY_TASKS,
            topDivider: true,
            bottomDivider: false,
            icon: AssignmentIndOutlinedIcon,
            subMenuItems: [],
          },
          {
            title: "All Tasks",
            href: PATH_TASKS.ALL_TASKS,
            topDivider: true,
            bottomDivider: false,
            icon: FormatListBulletedOutlinedIcon,
            subMenuItems: [],
          },
          {
            title: "Drop/Strips",
            href: PATH_TASKS.DROP_STRIPS,
            topDivider: true,
            bottomDivider: false,
            icon: PinDrop,
            subMenuItems: [],
          },
          {
            title: "Maintenance Inspections",
            href: PATH_TASKS.MAINTENANCE_INSPECTION,
            topDivider: true,
            bottomDivider: false,
            icon: ManageSearch,
            subMenuItems: [],
          },
          {
            title: "Cleanings",
            href: PATH_TASKS.CLEANINGS,
            topDivider: true,
            bottomDivider: false,
            icon: CleaningServicesIcon,
            subMenuItems: [],
          },
          {
            title: "Glass & Lanai",
            href: PATH_TASKS.GLASS_LANAI,
            topDivider: true,
            bottomDivider: false,
            icon: Deck,
            subMenuItems: [],
          },
          {
            title: "Final Inspections",
            href: PATH_TASKS.FINAL_INSPECTION,
            topDivider: true,
            bottomDivider: false,
            icon: DoneAll,
            subMenuItems: [],
          },
          {
            title: "Special Projects",
            href: PATH_TASKS.SPECIAL_PROJECTS,
            topDivider: true,
            bottomDivider: false,
            icon: PendingActions,
            subMenuItems: [],
          },
          {
            title: "Unassigned",
            href: "/tasks/unassigned",
            topDivider: true,
            bottomDivider: false,
            icon: NoAccountsOutlinedIcon,
            subMenuItems: [],
          },
          {
            title: "Overdue",
            href: "/tasks/overdue",
            topDivider: true,
            bottomDivider: false,
            icon: AssignmentLateOutlinedIcon,
            subMenuItems: [],
          },
        ],
        "Tasks"
      );
    } else {
      const titles = myQueues.map((queuse: any) => queuse.name).filter((name) => isEndpointsAllowedToRequest(name));

      const taskMenuItems: MenuItem[] = titles.map((title: string) => {

        return {
          title: title,
          href: `/tasks/${title.replaceAll(' ', '')}`,
          topDivider: false,
          bottomDivider: false,
          icon: mapIconToTitle(title),
          subMenuItems: [],
        }
      });

      reduceMenuItems(
        [
          {
            title: "My Tasks",
            href: PATH_TASKS.MY_TASKS,
            topDivider: true,
            bottomDivider: false,
            icon: AssignmentIndOutlinedIcon,
            subMenuItems: [],
          },
          {
            title: "All Tasks",
            href: PATH_TASKS.ALL_TASKS,
            topDivider: true,
            bottomDivider: false,
            icon: FormatListBulletedOutlinedIcon,
            subMenuItems: [
            ],
          },
          {
            title: "Drop/Strips",
            href: PATH_TASKS.DROP_STRIPS,
            topDivider: true,
            bottomDivider: false,
            icon: PinDrop,
            subMenuItems: [],
          },
          {
            title: "Maintenance Inspections",
            href: PATH_TASKS.MAINTENANCE_INSPECTION,
            topDivider: true,
            bottomDivider: false,
            icon: ManageSearch,
            subMenuItems: [],
          },
          {
            title: "Cleanings",
            href: PATH_TASKS.CLEANINGS,
            topDivider: true,
            bottomDivider: false,
            icon: CleaningServicesIcon,
            subMenuItems: [],
          },
          {
            title: "Glass & Lanai",
            href: PATH_TASKS.GLASS_LANAI,
            topDivider: true,
            bottomDivider: false,
            icon: Deck,
            subMenuItems: [],
          },
          {
            title: "Final Inspections",
            href: PATH_TASKS.FINAL_INSPECTION,
            topDivider: true,
            bottomDivider: false,
            icon: DoneAll,
            subMenuItems: [],
          },
          {
            title: "Special Projects",
            href: PATH_TASKS.SPECIAL_PROJECTS,
            topDivider: true,
            bottomDivider: false,
            icon: PendingActions,
            subMenuItems: [],
          },
          ...taskMenuItems,
          {
            title: "Unassigned",
            href: "/tasks/unassigned",
            topDivider: true,
            bottomDivider: false,
            icon: NoAccountsOutlinedIcon,
            subMenuItems: [],
          },
          {
            title: "Overdue",
            href: "/tasks/overdue",
            topDivider: true,
            bottomDivider: false,
            icon: AssignmentLateOutlinedIcon,
            subMenuItems: [],
          },
        ],
        "Tasks"
      );
    }
    // ------------ TEAMS -------------
    if (allQueues !== null) {
      const filteredTaskQueues = allQueues.filter((queue) => queue.objects.includes("Task"))
      const teams = filteredTaskQueues.map((queuse: any) => queuse.name);
      const teamMenuItems: MenuItem[] = teams.map((title: string) => {
        return {
          title: title,
          href: `/teams/${title.replaceAll(' ', '')}`,
          topDivider: false,
          bottomDivider: false,
          icon: mapIconToTeam(title),
          subMenuItems: [],
        }
      });
      reduceMenuItems(teamMenuItems, "Teams");
      // ------------ TEAMS -------------
    }


  }, [allQueues, myQueues, reduceMenuItems]);


  const { children } = props;
  const memoizedValues = React.useMemo(() => [open, toggleOpen] as AppBarToggleType, [open, toggleOpen])
  return (
    <AppBarToggle.Provider value={memoizedValues}>
      <AppBarMenuItems.Provider value={menuItems.current}>
        {children}
      </AppBarMenuItems.Provider>
    </AppBarToggle.Provider>
  );
}

function mapIconToTeam(teamName: string) {
  switch (teamName) {
    case "Engineering":
      return EngineeringIcon
    case "Guest Experience":
      return AttractionsIcon
    case "Housekeepers":
      return CleaningServicesIcon
    case "Housekeeping":
      return LocalLaundryService
    case "Housemen":
      return DirectionsWalk
    case "Operations Delivery":
      return IntegrationInstructions
    case "Owner Experience":
      return BeachAccess
    case "Shuttle Drivers":
      return AirportShuttleOutlined
    case "Reservations":
      return ConfirmationNumberOutlined
    default:
      return () => null
  }
}

function mapIconToTitle(title: string) {
  switch (title) {
    case "Engineering":
      return EngineeringIcon
    case "Guest Experience":
      return AttractionsIcon
    case "Shuttle Drivers":
      return AirportShuttleOutlined
    default:
      return CleaningServicesIcon
  }
}