import * as React from 'react';
import AppFormInputAutoComplete from "../../../Inputs/AppFormInputAutoComplete";
import { roleEngineer, TaskFormProps } from "./TaskFormDefault";
import AppFormInputDropdown from "../../../Inputs/AppFormInputDropdown";
import { TaskFormLabel } from "./TaskFormLabel";
import { PriorityOptions, TaskStatusOptions } from "@classes/Task/taskStrings";

export default function TaskFormInspection(props: TaskFormProps) {
  const { listings, taskAssignees, mode, currentUser } = props
  const isEngineerEdit = React.useMemo(() => currentUser !== null && currentUser.title === roleEngineer && props.mode === 'Edit', [mode, currentUser])
  //-------------- This will handle the Header of the form----------

  return (
    <div id="task-inspection-form">
      {props.subTypes && (
        <div id="edit_task_sub_type">
          <TaskFormLabel label="Sub Type" />
          <AppFormInputDropdown
            control={props.control}
            name="sub_type"
            options={props.subTypes}
            sx={{ maxHeight: "41px" }}
          />
        </div>
      )}
      <div className="edit_task_related">
        <TaskFormLabel label="Related To" />
        <AppFormInputAutoComplete
          readOnly={props.relatedToReadOnly}
          control={props.control}
          name="related"
          options={listings || [{ label: "Loading...", value: undefined }]}
        />
      </div>
      <div id="edit_task_assigned_to">
        <TaskFormLabel label="Assignee" />
        <AppFormInputAutoComplete
          grouped
          required
          type="text"
          control={props.control}
          name="assignee"
          readOnly={isEngineerEdit || !props.assigneeInputEnabled}
          options={taskAssignees || [{ label: "Loading...", value: undefined }]}
        />
      </div>
      {TaskStatusOptions && (
        <div id="edit_task_status">
          <TaskFormLabel label="Status" />
          <AppFormInputDropdown
            control={props.control}
            name="status"
            options={TaskStatusOptions}
            sx={{ maxHeight: "41px" }}
          />
        </div>
      )}
      <div id="edit_task_priority">
        <TaskFormLabel label="Priority" />
        <AppFormInputDropdown
          control={props.control}
          name="priority"
          options={PriorityOptions}
          sx={{ maxHeight: "41px" }}
        />
      </div>
    </div>
  );
}

