import {
  AppBarToggle
} from "@context/Sidebars/SideBar";
import useResponsive from "@hooks/useResponsive";
import * as React from "react";
import { AppList } from "./AppList";
import StyledDrawer from "./Containers/StyledDrawer";

export default function AppDrawer() {
  const [open, setOpen] = React.useContext(AppBarToggle);
  const isXLbreakpoint = useResponsive('up', 1800);
  const drawerVariant = !isXLbreakpoint ? 'temporary' : 'permanent'

  const handleClose = () => {
    setOpen(false)
  }
  if (!open) return null
  return (
    <StyledDrawer
      variant={drawerVariant}
      open={open}
      onClose={handleClose}>
      <AppList />
    </StyledDrawer>
  );
}
