export function ConfirmActionMessage(action: string) {
  switch (action) {
    case 'moveTask':
      return 'Move task to next check-out?';
    case 'resetTask':
      return 'Are you sure you would like to reset this task?'
    case 'startTask':
      return 'Would you like to begin this task?'
    case 'pauseTask':
      return 'Would you like to pause this task?'
    case 'endTask':
      return 'Are you sure you would like to complete this task?'
    case 'editTask':
      return 'Would you like to edit this task?'
    case 'deferTask':
      return 'Would you like to defer this task?'
    case 'claimTask':
      return 'Would you like to claim this task?'
    case 'followUpTask':
      return 'Would you like to create a follow up task?'
    case 'endTask&escalate=true':
      return 'Are you sure you would like to escalate this task?'
    default:
      return 'Are you sure?'
  }
}

export function createMessageFromAction(actionStr: string) {
  if (actionStr === 'endTask&escalate=true') {
    return 'Escalated task'
  }
  let [action, taskStr] = actionStr.split("T");
  taskStr = "T" + taskStr;
  action = action !== "reset" ? action.endsWith("e") ? action + "d" : action + "ed" : action;
  return `${action?.charAt(0)?.toUpperCase() + action.slice(1)} ${taskStr}`;
}