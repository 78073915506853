import * as React from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import startOfWeek from "date-fns/startOfWeek";
import previousMonday from "date-fns/previousMonday";
import { addDays, nextMonday, previousSunday, startOfDay, parseISO } from 'date-fns'
import endOfWeek from "date-fns/endOfWeek";
import formatDateParam from "@helpers/formatDateParam";
import { localStorage_sortParamKey, sortParamKey } from "@components/Task/Sub_Components/SearchPagenation/useSelectedSort";
import { TaskSortKey, TaskSortKeySet } from "../../Components/Task/Sub_Components/sortTasks";

type ScopeType = [scope: string, setScope: React.Dispatch<React.SetStateAction<string>>
]

interface TaskControlsInterface {
  rollToday: () => URLSearchParams
  rollForward: () => URLSearchParams;
  rollBackward: () => URLSearchParams;
  setFilterBy: (value: "day" | "week") => URLSearchParams;
}

export const TaskStartDate = React.createContext<string>("");
export const TaskEndDate = React.createContext<string>("");
export const TaskScope = React.createContext<ScopeType | null>(null);
export const TaskControls = React.createContext<TaskControlsInterface>(undefined!);
export const TaskFormDisplay = React.createContext<boolean>(false);

// ------------- Date Variables ------------
const today = startOfDay(new Date());
const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 })
// ------------- Date Variables end ------------
export const isEndpointsAllowedToRequest = (name: string) => ['my-tasks',
  'all',
  'Housekeeping',
  'Engineering',
  "GuestExperience",
  "unassigned",
  "ShuttleDrivers",
  "OwnerExperience",
  "Cleanings",
  "FinalInspections",
  "DropStrips",
  "GlassLanai",
  "MaintenanceInspections",
  "SpecialProjects"
].includes(name.replaceAll(' ', ''))
export default function TaskProvider({ children }: any) {
  const { pathname } = useLocation(); // this will be split in the use affect to seporate route and selector
  const [scope, setScope] = React.useState<string>("Day"); // scope here is refering to Day or week view
  const [searchParams, setSearchParams] = useSearchParams();
  const filterBy = searchParams.get("filterBy") || "day"; // this function will return the filterBy var from the url or default to filtering by day
  const startDate = searchParams.get("taskStartDate") || formatDateParam(today);  // if no start date in url then set the start date to today
  const endDate =
    searchParams.get("taskStartDate") || filterBy === "day"
      ? formatDateParam(today)
      : formatDateParam(weekEnd);

  /**
   * @params_from decoded url pathname
   * the pathname has been split to two values route representing the route and target the selector ie. /tasks/my-tasks
   * 
   * @sets this use effect will set the search params needed for task sorting
   */
  React.useEffect(() => {
    const [, route, target] = decodeURIComponent(pathname).split("/") // route => tasks target => task list
    if (
      route === 'tasks' && isEndpointsAllowedToRequest(target)
    ) {
      // sortBy must be added for these pages
      const urlSortParam = searchParams.get(sortParamKey)

      const LSSortParam = window.localStorage.getItem(localStorage_sortParamKey);

      // We prioritize an LS value. Due to load order, the url param can be null, even
      // though our hook has set the preferred value. Storage is the only way to keep
      // a reliable preference (unless we go to the session and/or user).
      const effectiveSortParam = (LSSortParam !== null && TaskSortKeySet.has(LSSortParam))
        ? LSSortParam
        : urlSortParam;

      // console.log('>> url builder found: url', urlSortParam, 'LS', LSSortParam, '; using', effectiveSortParam);

      if (effectiveSortParam === null) {
        // creating url sort key on NULL
        searchParams.set(sortParamKey, TaskSortKey.Priority)
      }
      else if (!TaskSortKeySet.has(effectiveSortParam)) {
        // replacing bad url sort key
        searchParams.set(sortParamKey, TaskSortKey.Priority)
      }
      else {
        // we have a valid sort key (a member of our enum)
        searchParams.set(sortParamKey, effectiveSortParam)
      }

      // if filterBy has not been set we want to add it
      if (!searchParams.has("filterBy")) {
        searchParams.set("filterBy", filterBy); // then set the search params to be equal to the week/day drop down select
        // setSearchParams(searchParams); // add filter by to search params

        /**
         * @min this conditional will identify if filter by is day
         */
        //! Left off here.
        if (filterBy === "day") {
          //----------- TASK DEFAULT START DATE -----------------
          if (!searchParams.has("taskStartDate")) {
            // in this case the filter is by day and there are no taskStartDate params
            searchParams.set("taskStartDate", formatDateParam(today));
          }

          //----------- TASK DEFAULT END DATE -----------------
          if (!searchParams.has("taskEndDate")) {
            // in this case the filter is by day and there are no taskStartDate params
            searchParams.set("taskEndDate", formatDateParam(today));
          }
        }

        if (filterBy === "week") {
          // in this case the filter is by week and there are no taskStartDate params so we use the todayStart
          const filterStartDateByWeek = startOfWeek(new Date(startDate), {
            weekStartsOn: 1,
          });
          filterStartDateByWeek.setHours(0, 0, 0, 0);
          searchParams.set("taskStartDate", formatDateParam(filterStartDateByWeek));
          // file deepcode ignore DuplicateIfBody: <readability!!!>

          //----------- TASK DEFAULT END DATE -----------------
          if (!searchParams.has("taskEndDate")) {
            // in this case the filter is by week and there are no taskStartDate params
            searchParams.set("taskEndDate", formatDateParam(endDate));
          } else {
            // if the task end date is already in the url params then do nothing
          }
        }
      }
      setSearchParams(searchParams);
    }
    else {
      //! NOTE: This preferred sort key gets reset when going to overdue. That's technically a
      //      tasks page, but it doesn't have filters or sort (always by date, since they're overdue).

      //! NOTE: There is a chance we'll decide we want this to live on the session, or even
      //        persisted on the user in Firestore. That's how we treat the task-group order.

      // clear this whenever a non-tasks route is loaded
      window.localStorage.removeItem(localStorage_sortParamKey);
    }
  }, [endDate, filterBy, pathname, searchParams, setSearchParams, startDate]);


  /**
   * @body this funtion will move the filtered date forward
   * @returns updated searchParams
   */
  function rollForward() {
    // create a date object based off startDate
    let dateHolder = parseISO(startDate);
    switch (filterBy) {
      // this will set the dateHolder to tomorrow
      case "day":
        dateHolder = addDays(dateHolder, 1);
        searchParams.set("taskStartDate", formatDateParam(dateHolder));
        searchParams.set("taskEndDate", formatDateParam(dateHolder));
        setSearchParams(searchParams);
        return searchParams
      case "week":
        dateHolder = nextMonday(dateHolder);
        searchParams.set("taskStartDate", formatDateParam(dateHolder));
        dateHolder = endOfWeek(dateHolder, { weekStartsOn: 1 })
        searchParams.set("taskEndDate", formatDateParam(dateHolder))
        setSearchParams(searchParams);
        return searchParams
      // set search params
      default:
        return searchParams;
    }
  }
  /**
 * @body this funtion will move the filtered date backwords
 * @returns updated searchParams
 */
  function rollBackward() {
    switch (filterBy) {
      case "day":
        // create date for the day before selected
        const yesterday = parseISO(startDate);
        yesterday.setDate(yesterday.getDate() - 1);
        searchParams.set("taskStartDate", formatDateParam(yesterday));
        // set end date to end of day
        searchParams.set("taskEndDate", formatDateParam(yesterday));
        setSearchParams(searchParams);
        return searchParams;
      case "week":
        const newEndDate = previousSunday(parseISO(startDate))
        const newStartDate = previousMonday(newEndDate)
        searchParams.set('taskStartDate', formatDateParam(newStartDate))
        searchParams.set("taskEndDate", formatDateParam(newEndDate))
        setSearchParams(searchParams)
        return searchParams
      default:
        return searchParams
    }
  }
  // this function is called by the TODAY button in searchPageination
  function rollToday() {
    let today = new Date();
    switch (filterBy) {
      case "day":
        searchParams.set("taskStartDate", formatDateParam(today));
        searchParams.set("taskEndDate", formatDateParam(today));
        setSearchParams(searchParams);
        return searchParams;
      case "week":
        today = startOfWeek(today, { weekStartsOn: 1 })
        searchParams.set("taskStartDate", formatDateParam(today))
        searchParams.set("taskEndDate", formatDateParam(endOfWeek(today, { weekStartsOn: 1 })))
        setSearchParams(searchParams)
        return searchParams
      default:
        return searchParams
    }
  }
  //This function is controlled by the Day/Week select in searchPageination
  function setFilterBy(value: "day" | "week") {
    searchParams.set("filterBy", value);
    const weekDate = parseISO(startDate); // this will be for the week switch case
    const dayDate = parseISO(startDate); // this will be for the day select
    switch (value) {
      case "week": // in the case that we are on week view; adjust the start and end date in the url params to be a total of 7 days starting on monday
        searchParams.set(
          "taskStartDate",
          formatDateParam(startOfWeek(weekDate, { weekStartsOn: 1 }))
        );
        searchParams.set(
          "taskEndDate",
          formatDateParam(endOfWeek(weekDate, { weekStartsOn: 1 }))
        );
        setSearchParams(searchParams);
        return searchParams;
      case "day": // default case is day view
        searchParams.set("taskStartDate", formatDateParam(dayDate));
        searchParams.set("taskEndDate", formatDateParam(dayDate));
        setSearchParams(searchParams);
        return searchParams;
      default:
        // needed to set filterBy
        setSearchParams(searchParams);
        return searchParams;
    }
  }

  return (
    <TaskStartDate.Provider value={startDate}>
      <TaskEndDate.Provider value={endDate}>
        <TaskScope.Provider value={[scope, setScope]}>
          <TaskControls.Provider
            value={{
              rollToday,
              rollForward,
              rollBackward,
              setFilterBy,
            }}
          >
            {children}
          </TaskControls.Provider>
        </TaskScope.Provider>
      </TaskEndDate.Provider>
    </TaskStartDate.Provider>
  );
}

