import { styled, SxProps } from "@mui/system";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {
  Typography,
  Avatar,
  Chip,
  FormLabel,
  useTheme,
  Divider,
  Grid,
  Link,
  Tooltip,
} from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardDoubleArrowUp,
  Lock,
  LockOpenOutlined,
} from "@mui/icons-material";
import React from "react";
import { TaskChangeShouldRefreshView, TaskDrawerSubject } from "../../../../Context/Task/TaskDrawer";
import { environment } from "../../../../Config";
import { SnackBarData } from "../../../../Context/Snackbar";
import useSalesforceRequest from "@request/Salesforce";
import { AppTypographyBodyI } from "@components/Typography/CardItemText";
import { TaskStatus } from "../../../../Strings/TaskStatus";
import useProfile from "../../../../Hooks/Selectors/useProfile";
import usePersistentObject from "../../../../Hooks/Selectors/usePersistentObject";
import { addTaskPermissionFields } from "../../../../Context/Task/taskUtils";

export const StyledNavLink = function (props: AppTypographyBodyI['linkProps'] & React.PropsWithChildren) {
  const [active, setActive] = React.useState(false)
  const onMouseHandler = (a: boolean) => {
    setActive(a)
  }
  return <Link
    color={active ? "secondary" : "primary"}
    onMouseOver={() => onMouseHandler(true)}
    onMouseLeave={() => onMouseHandler(false)}
    component={NavLink} to={props.to}
    onClick={props.onClick}
  >
    {props.children}
  </Link>
}

export const TaskFooter = styled(
  "div",
  {}
)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  right: 0,
  width: "100% !important",
  maxWidth: "420px !important",
  minWidth: "350px !important",
  height: "4rem",
  background: "#efefef",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "nowrap",
  paddingLeft: '10px',
  paddingRight: '10px',
}));

export const TaskCardIconCloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  opacity: 0.3,
  left: 5,
  top: 52,
  backgroundColor: "lightgray"
}));

export const TaskCompactCardStatus = (status: any) =>
  styled(
    Typography,
    {}
  )(({ theme }) => ({
    "&": {
      whiteSpace: "nowrap",
      overflowWrap: "ellipsis !important",
      textOverflow: "ellipsis !important",
      // fontSize: "12px !important",
      fontSize: "small !important",
      fontWeight: "800 !important",
      lineHeight: "1.33333em !important",
      textTransform: "uppercase !important",
      color: [TaskStatus.NotStarted, TaskStatus.InProgress].includes(status)
        ? theme.palette.primary.main
        : status === TaskStatus.Completed
          ? theme.palette.success.main
          : theme.palette.error.main,
    },
  }));

export const TaskCompactCardTitle = styled(Typography)({
  "&": {
    overflowWrap: "ellipsis !important",
    textOverflow: "ellipsis !important",
    fontSize: "16px !important",
    fontWeight: "600 !important",
    lineHeight: "1.33333em !important",
    maxHeight: "2.75em",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    color: "#666666"
  },
});

export const TaskCompactCardDescription = styled(Typography)({
  "&": {
    lineHeight: "1.33333em !important",
    margin: "0 !important",
    textAlign: "start !important",
    fontSize: "14px !important",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
});
export const TaskCardCollapsedDescription = styled(Typography)({
  "&": {
    margin: "0 !important",
    textAlign: "start !important",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
});

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: "1.222em",
  height: "1.222em",
}));

export const AvatarChip = styled(Chip)(({ theme }) => ({
  height: "3em",
  width: "auto",
  boxShadow: theme.shadows ? (theme.shadows as any)[1] : '',
  display: "flex",
  alignItems: "center",
}));

export const BadgeStyle = (theme: any) => ({
  color: theme.palette.success.main,
  fontSize: 'small',
  backgroundColor: "#efefef",
  borderRadius: "50%",
});

interface LockIconButtonProps {
  onClick?: (a: React.SyntheticEvent<any>) => any;
  locked: boolean;
}


export function LockIconButton(props: LockIconButtonProps) {
  const user = useProfile();
  const persistedObject = usePersistentObject()
  const [, startTransition] = React.useTransition()
  const [, changeShouldRefreshViewInList] = React.useContext(TaskChangeShouldRefreshView)
  const [, setSnackData] = React.useContext(SnackBarData)
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const { onClick, locked } = props;
  const updateTask = useSalesforceRequest()
  const theme = useTheme();
  const [curTask, setCurTask] = React.useContext(TaskDrawerSubject);
  const defaultClickListener = async (event: React.SyntheticEvent<any>) => {
    if (!curTask) return null
    const relatedObject = curTask.related as ApiListing
    if (!relatedObject) return null
    setDisabled(true);
    let message = !locked ? `Locking unit ${relatedObject.name_detailed}` : `Unlocking unit ${relatedObject.name_detailed}`
    if (curTask !== null) {
      try {
        startTransition(() => setSnackData({
          message,
          color: 'warning'
        }))
        const body = JSON.stringify({
          id: curTask.id,
          permission_to_enter: locked === true,
        })
        const request = updateTask({
          endpoint: `/api/task/${curTask.id}?action=updateTask`,
          method: "PATCH",
          body
        })
        const task = await request.attemptSend()
        if (!task.callSent) return
        const json = task.GetParsedJson()
        if (json.hasOwnProperty('task')) {
          addTaskPermissionFields(json.task, user, persistedObject)
          changeShouldRefreshViewInList(true)
          setCurTask(null, json.task);
          startTransition(() => {
            message = !locked ? `Locked unit ${relatedObject.name_detailed}` : `Unlocked unit ${relatedObject.name_detailed}`
            setSnackData({
              message,
              color: 'success',
              severity: 'success',
            })
          })
          return
        }
        throw new Error('Failed to lock unit.')
      } catch (e) {
        if (environment.env === "development") {
          console.error(e);
        }
        message = locked ? `Failed locking unit ${relatedObject.name_detailed}` : `Failed unlocking unit ${relatedObject.name_detailed}`
        setSnackData({
          message,
          color: 'error',
          severity: 'error',
        })
      }
    }
    setDisabled(false);
  };
  return (
    <>
      <IconButton
        disabled={disabled}
        aria-label={`Permission to enter ${curTask?.related?.unit_id} ${locked ? "denied" : "allowed"
          }`}
        onClick={
          onClick
            ? (event) => {
              setDisabled(true);
              onClick(event);
              setDisabled(false);
              return;
            }
            : async (event) => {
              setDisabled(true);
              await defaultClickListener(event);
              setDisabled(false);
            }
        }
      >
        {locked ? (
          <Lock
            style={{
              width: ".7em",
              height: ".7em",
              color: disabled
                ? theme.palette.grey[200]
                : theme.palette.grey[400],
            }}
          />
        ) : (
          <LockOpenOutlined
            style={{
              width: ".7em",
              height: ".7em",
              color: disabled
                ? theme.palette.grey[200]
                : theme.palette.primary.main,
            }}
          />
        )}
      </IconButton>
    </>
  );
}

export function PermissionToEnterIcon(props: { permission_to_enter: boolean }) {
  const { permission_to_enter } = props;
  const theme = useTheme();
  return (
    <>
      {!permission_to_enter ? (
        <Tooltip title='Do not enter'>
          <Lock
            style={{
              width: ".7em",
              height: ".7em",
              marginLeft: 1,
              color: theme.palette.grey[400],
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title='Permission to enter'>
          <LockOpenOutlined
            style={{
              marginLeft: 1,
              width: ".7em",
              height: ".7em",
              color: theme.palette.primary.main,
            }}
          />
        </Tooltip>
      )}
    </>
  );
}

export function IsUrgentPriorityIcon(props: { is_high_priority: boolean }) {
  const { is_high_priority } = props;
  if (!is_high_priority) return null;
  return (
    // <SmallAvatar
    //   sx={(theme: any) => ({
    //     width: ".7em",
    //     height: ".7em",
    //     color: theme.palette.error.main,
    //     ml: 0.5,
    //   })}
    // >
    <KeyboardDoubleArrowUp
      color="error"
      style={{
        width: ".7em",
        height: ".7em",
      }}
    />
    // </SmallAvatar>
  );
}
export function IsHighPriorityIcon(props: { is_high_priority: boolean }) {
  const { is_high_priority } = props;
  if (!is_high_priority) return null;
  return (
    // <SmallAvatar
    //   sx={(theme: any) => ({
    //     width: ".7em",
    //     height: ".7em",
    //     color: theme.palette.warning.main,
    //     ml: 0.5,
    //   })}
    // >
    <KeyboardArrowUp
      color="warning"
      style={{
        width: ".7em",
        height: ".7em",
      }}
    />
    // </SmallAvatar>
  );
}

export const AppFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.common.main,
  fontWeight: "bold"
}));


export const TaskCardDividerComponent = styled(Divider)({
  width: "100%",
  opacity: 0.5,
  height: "1px",
});

export function TaskCardDivider({ children }: { children?: any }) {
  return (
    <Grid item container>
      <TaskCardDividerComponent>{children}</TaskCardDividerComponent>
    </Grid>
  );
}
