import { Container } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import AppSideBar from "../Components/AppSideBar";
import ProfileSidebar from "../Components/ProfileSideBar";
import { ProtectedRouteI } from "./index";

export default function ProtectedRoute(props: ProtectedRouteI) {
  /*
  NOTE ON ROUTING:
  We need the user model to be loaded before any of these session pages is loaded. That's why all
  of the post-auth routes below are gated on (user !== null).
  Upon login form submit, we route the user to '/authorizing', which serves as a 'waiting room' until
  the user model is ready. This delay in the waiting room should be extremely short. Basically, this
  component is a fallback. It should never be seen under normal circumstances.
  Whenever (user !== null), the 'authorizing' page reroutes the user to the 'home' page.
  */
  if (props.user !== null) {
    return (
      <>
        <ProfileSidebar />
        <AppSideBar />
        <Container
          maxWidth='lg'
          sx={{ p: 0 }}
        >
          <Outlet />
        </Container>
      </>
    );
  }
  return <Navigate to="/login" replace />;
}
