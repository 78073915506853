/**
 * @param string <Task_Type>
 *
 * @return String || String[] --- "engineering"...
 */

import User from "../Classes/User";
import { ApiTask } from "../Types/task";

// this function will take in a task_type and return a users string for which group of employees
export default function taskAssigneeList(task_type: string) {
  switch (task_type) {
    case "Maintenance":
      return "engineering";
    case "Drop":
      return ["housekeeping", "guestExperience"];
    case "Inspection":
      return ["housekeeping", "guestExperience"];
    default:
      return "activeUsers";
  }
}

export function checkTaskAsigneeMembership(
  task: ApiTask,
  user: User,
  userQueues: ApiQueue[]
) {
  if (task.assignee_type !== "Group") {
    return false;
  }
  for (let i = 0; i < userQueues.length; ++i) {
    const entry = userQueues[i];
    if (entry.id === task.assignee_id) {
      return true;
    }
  }
  return false;
}
