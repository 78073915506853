import { CheckBoxOptions } from "@classes/Filter";

/**
 * @param SelectableObject[]
 *
 * @return returns an array of objects used for app autocomplete input
 */
export const createSelectableUserObjects = (
  objects: ApiUser[],
  valueSameAsLabel?: boolean
) => {
  if (!objects) return [];
  const res = objects.reduce(
    (accum: { label: string; value: string; group: string }[], object) => {
      const group = "User";
      return [
        ...accum,
        {
          label: object.name,
          value: valueSameAsLabel ? object.name : object.id,
          group,
        },
      ];
    },
    []
  );

  return res;
};
export const createSelectableQueues = (
  queses: ApiQueue[],
  valueSameAsLabel?: boolean
) => {
  if (!queses) return [];
  return queses.map((queue) => ({
    label: queue.name,
    value: valueSameAsLabel ? queue.name : queue.id,
    group: "Group",
  }));
};

export enum ListingFilterOptions {
  Listings = "Listings",
  Resort = "Resort",
  Beds = "Beds",
  Baths = "Baths",
  Status = "Status",
}

export const bedroomParamKey = (listing: ApiListing) =>
  listing.bedrooms === 0 ? "Studio" : `${listing.bedrooms}BR`;

export const createSelectableListingNameObjects = (
  objects: ApiListing[],
  key: "unit_id" | "id"
) => {
  const clone = objects; // we do not want to mutate objects
  const res = clone.map((object) => {
    return {
      label: object.name || object.unit_id,
      value: object[key],
      group: object.resort_name || "Other",
    };
  });

  return res;
};
export const createSelectableListingResortNameObjects = (
  objects: ApiListing[]
) => {
  let result: {
    label: string;
    value: string;
    group: string;
  }[] = [];
  const uniqueResortNames = new Map<string, boolean>();

  objects.forEach((v) => {
    if (uniqueResortNames.has(v.resort_name)) {
      return;
    }

    uniqueResortNames.set(v.resort_name, true);
  });

  uniqueResortNames.forEach((v, key) => {
    result = [...result, { label: key, value: key, group: "" }];
  });

  return result;
};

const createCheckboxListingStatusObject = (objects: ApiListing[]) => {
  const res = objects.reduce((accum, object) => {
    if (!object.listing_status) {
      return accum;
    }
    Object.assign(accum, {
      [ListingFilterOptions["Status"]]: {
        [object.listing_status]: false,
        ...accum[ListingFilterOptions["Status"]],
      },
    });
    return accum;
  }, {} as CheckBoxOptions);

  return res;
};

export const createCheckboxListingBedroomsObject = (objects: ApiListing[]) => {
  const res = objects.reduce((accum, object) => {
    Object.assign(accum, {
      [ListingFilterOptions["Beds"]]: {
        [object.bedrooms === 0 ? "Studio" : `${object.bedrooms}BR`]: false,
        ...accum[ListingFilterOptions.Beds],
      },
    });
    return accum;
  }, {} as CheckBoxOptions);

  // Note we are explicetly adding "Studio" here
  if (!res[ListingFilterOptions["Beds"]].hasOwnProperty("Studio")) {
    res[ListingFilterOptions["Beds"]].Studio = false;
  }

  return res;
};
// export const createCheckboxListingBathroomsObject = (objects: ApiListing[]) => {
//   const res = objects.reduce((accum, object) => {
//     Object.assign(accum, {
//       [ListingFilterOptions["Baths"]]: {
//         [`${object.bathrooms} bath`]: false,
//         ...accum[ListingFilterOptions["Baths"]],
//       },
//     });
//     return accum;
//   }, {} as CheckBoxOptions);

//   return res;
// };

export const listingCheckboxFilters = (listings: ApiListing[]) => ({
  ...createCheckboxListingStatusObject(listings),
  ...createCheckboxListingBedroomsObject(listings),
  // ...createCheckboxListingBathroomsObject(listings),
});

export const listingSelectableFilters = (listings: ApiListing[]) => ({
  [ListingFilterOptions["Resort"]]:
    createSelectableListingResortNameObjects(listings),
  [ListingFilterOptions["Listings"]]: createSelectableListingNameObjects(
    listings,
    "unit_id"
  ),
});
