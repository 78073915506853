import formatDateParam from "@helpers/formatDateParam";
import { Button, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import PagenationProps from "../Interfaces";

export function TodayButton(props: Pick<PagenationProps, "rollToday" | "today">) {
  const [searchParams] = useSearchParams()
  return (
    <Button
      className="pagenation-button"
      variant="outlined"
      disabled={searchParams.get("taskStartDate") === formatDateParam(props.today)}
      onClick={() => props.rollToday()}
    >
      <Typography variant="button" className="pagenation-button-text">Today</Typography>
    </Button>
  )
}