import {
  legacy_createStore,
  combineReducers,
  applyMiddleware,
  compose,
  StoreEnhancer,
  PreloadedState,
} from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to local storage for web.
import sessionReducer from "./Reducers/Session";
import appStateReducer, { AppState } from "./Reducers/AppState";
import { environment } from "../Config";
import PersistentObjectReducer, {
  ApiPersistentObjectStore,
} from "./Reducers/PersistentObject";
import { Session } from "./Reducers/Session/Session";
import { logger } from "redux-logger";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: "session",
  storage,
  whitelist: ["user", "userSettings"],
};

const persistentReducer = persistReducer(persistConfig, sessionReducer);
const rootReducer = combineReducers({
  session: persistentReducer,
  state: appStateReducer,
  persistentObject: PersistentObjectReducer,
});

let enhancer: StoreEnhancer;

if (environment.env === "production") {
  enhancer = compose(applyMiddleware(thunk));
} else {
  const composeEnhancers: typeof compose =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk)); //, logger));
}

const configureStore = (preloadedState?: PreloadedState<any>) =>
  legacy_createStore(rootReducer, preloadedState, enhancer);
const Store = configureStore();
export const Persistor = persistStore(Store);
export type AppDispatch = typeof Store.dispatch;
export type RootState = {
  session: Session & { _persist: { version: number; rehydrated: boolean } };
  state: AppState;
  persistentObject: ApiPersistentObjectStore;
};
export default Store;
