import * as React from 'react'
import MultiActionBtn from '../../../../TaskControls/ActionButtons/TaskMultiAction';
import TaskActionBtn from '../../../../TaskControls/ActionButtons/TaskAction';
import { ModeEditOutline } from '@mui/icons-material';
import { TaskStatusUpperInProgress, TaskStatusUpperNotStarted, TaskStatusUpperOnHold } from '../../../../../../Strings/TaskStatusUpper';

// This is a constanct of the third columns "Primary Actions" options
const _primaryActionsOptions = [
  { label: "Complete", action: "endTask" },
  { label: "Edit", action: "editTask" }
]

const _primaryActionsOptionsCompleteOnly = [
  { label: "Complete", action: "endTask" }
]

/**
 * @param props ActionButtonColumnProps
 * @returns a dynamic switch case that can return either the "single/orginal" taskActionBtn or the MuiltiOptionButton
 */
export default function PrimaryActions(props: ActionButtonColumnProps) {
  const userHasManagerPermission = props.curTask && props.curTask.is_manageable_by_user;
  const userHasPermission = props.curTask && (props.curTask.is_manageable_by_user || props.curTask.is_my_task);
  // console.warn('create primary: ' + userHasPermission, props.taskStatus, props.is_timed)

  if (!userHasPermission) {
    return null;
  }

  if (props.is_closed) {
    return (userHasManagerPermission
              ? <TaskActionBtn
                  curTask={props.curTask}
                  setCurTask={props.setCurTask}
                  label="Reset Task"
                  taskId={props.taskId}
                  action="resetTask"
                  variant="text"
                  className="resetTask"
                  overrideEnabled={userHasManagerPermission}
                />
              : null);
  }

  const createPrimaryActionButton = () => {
    return (userHasManagerPermission
              ? <MultiActionBtn
                  openForm={props.openForm}
                  setOpenForm={props.setOpenForm}
                  curTask={props.curTask}
                  setCurTask={props.setCurTask}
                  options={_primaryActionsOptions}
                  defaultAction={"endTask"}
                  label="Complete"
                  taskId={props.taskId}
                  overrideEnabled={userHasManagerPermission}
                />
              : <MultiActionBtn
                  openForm={props.openForm}
                  setOpenForm={props.setOpenForm}
                  curTask={props.curTask}
                  setCurTask={props.setCurTask}
                  options={_primaryActionsOptionsCompleteOnly}
                  defaultAction={"endTask"}
                  label="Complete"
                  taskId={props.taskId}
                  overrideEnabled={userHasPermission}
                />);
  };

  const createTaskActionButton = () => {
    return (userHasManagerPermission
            ? <TaskActionBtn
                curTask={props.curTask}
                setCurTask={props.setCurTask}
                label="Edit"
                icon={<ModeEditOutline />}
                taskId={props.taskId}
                action="editTask"
                color="primary"
                onClick={() => { props.setOpenForm(true) }}
                className="editTask"
                overrideEnabled={userHasManagerPermission}
              />
            : null);
  };

  switch (props.taskStatus.toUpperCase()) {
    case TaskStatusUpperNotStarted:
      if (props.is_timed) {
        return createTaskActionButton();
      }
      // in the case task is neither timed nor started.
      return createPrimaryActionButton()
    case TaskStatusUpperInProgress:
      return createPrimaryActionButton()
    case TaskStatusUpperOnHold:
      return createTaskActionButton();
    default:
      return null
  }
}
