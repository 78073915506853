import * as React from 'react';
import { ListingCompactCard } from './ListingCompactCard';
import { useSearchParams } from 'react-router-dom';
import { Button, IconButton, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import RefreshButton from '@components/RefreshButton';
import { ListingFilterModal } from './ListingFilterModal';
import { NoMatchingFilters } from '@components/Task/Sub_Components/NoMatchingFilters';
import useDocumentTitle from '@hooks/Window/useDocTitle';
import { filterListing } from '@helpers/filterListings';
import "../Task/Sub_Components/SearchPagenation/Styles/styles.css"

const verboseLogging = false
const listingsLogger = 'color:#b342f5'

// ----- List of Cards --------

export class ListingsController {
  sortedListings: ApiListing[]
  filteredDataEmpty: boolean = false
  constructor(unsortedListings: ApiListing[]) {
    this.sortedListings = ListingsController.sortListingsByNameDetail(unsortedListings, 'asc')
  }
  static sortListingsByNameDetail = (listings: ApiListing[], direction: "asc" | "desc") => listings.sort((listingA, listingB) => {
    // name_detail is a string "Whaler 1224"
    const nameUnitA = listingA.name_detailed;
    const nameUnitB = listingB.name_detailed;
    //! sort will put "Whaler 1024" before "Whaler 104"
    // Split the name string from the Unit and save the unit
    const nameA = nameUnitA.split(" ");
    const unitA: string[] = [];
    while (!isNaN((parseInt(nameA[nameA.length - 1])))) {
      unitA.push(nameA.pop()!);
    }
    // Repeat steps from the second unit that is being compared
    const nameB = nameUnitB.split(" ");
    const unitB: string[] = [];
    while (!isNaN((parseInt(nameB[nameB.length - 1])))) {
      unitB.push(nameB.pop()!);
    }
    // put the names back together ["Honua", "Kai"] ===> "Honua Kai"
    const A = nameA.join(" ");
    const B = nameB.join(" ");
    // Grab the last varible from the saved unit array and parse it into an integer for compare
    const UA = parseInt(unitA[unitA.length - 1]);
    const UB = parseInt(unitB[unitB.length - 1]);
    if (A < B) {
      // Name A is before Name B
      return direction === 'asc' ? -1 : 1;
    }
    if (A > B) {
      // Name B is before Name A
      return direction === 'asc' ? -1 : 1;
    }
    // Here NameA === NameB
    if (UA < UB) {
      // Unit A is less than Unit B
      return direction === 'asc' ? -1 : 1;
    }
    if (UA > UB) {
      // Unit B is less than Unit A
      return direction === 'asc' ? 1 : -1;
    }
    // the two values are an exact match.
    return 0;
  });

  get groupByNameAndUnit() {
    const unfilteredListings: ApiListing[] = JSON.parse(JSON.stringify(this.sortedListings))
    const searchParams = new URLSearchParams(window.location.search)
    const filteredListings = filterListing(searchParams, unfilteredListings)
    this.filteredDataEmpty = filteredListings.length === 0
    return filteredListings.reduce((accum, cur) => {
      const resortName = cur.resort_name || "Other"
      if (Array.isArray(accum[resortName])) {
        // Group exist add to group
        accum[resortName] = [...accum[resortName], cur]
      } else {
        // Group does not exits
        accum[resortName] = [cur]
      }

      return accum
    }, {} as Record<string, ApiListing[]>)
  }

}

interface ListOfListingsProps {
  listings: ApiListing[];
  refresh: () => void
}
export function ListOfListings(props: ListOfListingsProps) {
  useDocumentTitle("Listings")
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFilterModal, setShowFilterModal] = React.useState(false)
  const listingsController = React.useMemo(() => new ListingsController(props.listings), [props.listings])
  const groupedListings = listingsController.groupByNameAndUnit
  const groupKeys = Object.keys(groupedListings).sort()
  const filterGroupOnToggleClick = (groupKey: string) => {
    // Lets fix this to be "Resort"="The+Whaler"
    if (searchParams.has(groupKey)) {
      verboseLogging && console.log(`%cListOfListings: showing ${groupKey}`, listingsLogger)
      searchParams.delete(groupKey)
    } else {
      verboseLogging && console.log(`%cListOfListings: hiding ${groupKey}`, listingsLogger)
      searchParams.set(groupKey, 'hide')
    }
    setSearchParams(searchParams)
  }

  return (
    <>
      <ListingFilterModal listings={props.listings} open={showFilterModal} onClose={() => { setShowFilterModal(false) }} />
      <div className="pagenation-container">
        <div className="pagenation-flex-row">
          <Button
            className="pagenation-button"
            sx={{ ml: 1, }}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={() => {
              setShowFilterModal(prev => !prev)
            }}
            variant="contained"
            color="primary"
          >
            <Typography variant="button" className="pagenation-button-text">Filter</Typography>
          </Button>
          <RefreshButton asyncRefresh={props.refresh} />
        </div>
      </div>
      {listingsController.filteredDataEmpty
        ? <NoMatchingFilters componentName='listings' />
        : groupKeys.map((groupKey) => {
          return (
            <React.Fragment key={groupKey}>
              <div className="Group-Container">
                <Typography variant='h6' color={(theme) => theme.palette.mode === "light" ? theme.palette.common.black : theme.palette.common.white}>{`${groupKey}・(${groupedListings[groupKey].length})`}</Typography>
                <IconButton size='small' onClick={() => { filterGroupOnToggleClick(groupKey) }}>{searchParams.has(groupKey) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
              </div>
              <div className={searchParams.has(groupKey) ? "hidden-group" : ""}>
                {groupedListings[groupKey].map((listing) => {
                  return <ListingCompactCard key={listing.unit_id} listing={listing} />
                })}
              </div>
            </React.Fragment>
          )
        })
      }
    </>
  );
}

