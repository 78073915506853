import * as React from "react";
import { Paper, Grid, Typography, useMediaQuery, Stack } from "@mui/material";
import { formatPhoneNumber } from "../Inputs/AppFormInputPhone";

export default function TeamsCard({ user }: { user: ApiUser; }) {
    const mobileView = useMediaQuery("(max-width:600px)");
    return (
        <Paper
            className="Teams_User_Card-Paper"
            elevation={4}
        >
            <Grid
                container
                width={mobileView ? "40%" : "100%"}
                display='flex'
                flexDirection={mobileView ? 'column' : 'row'}
                className="Teams_Pull_Left"
            >
                <Grid
                    item
                    component="div"
                    className="User_Profile_Photo"
                    style={{
                        backgroundImage: `url(${user.profile_picture_url})`,
                    }} />
                <Grid item mt={mobileView ? 1 : 0} ml={!mobileView ? '10px' : '0px'}>
                    <Stack spacing={.5}>
                        <Typography fontWeight="bold" fontSize="small">
                            {user.name}
                        </Typography>
                        {user.alias && <Typography fontWeight="bold" fontSize="small">
                            {user.alias}
                        </Typography>}
                        {user.title && <Typography fontWeight="bold" fontSize="small">
                            {user.title}
                        </Typography>}
                        {user.department && <Typography fontWeight="bold" fontSize="small">
                            {user.department}
                        </Typography>}
                        {user.timezone && <Typography fontWeight="bold" fontSize="small">
                            {user.timezone}
                        </Typography>}
                    </Stack>
                </Grid>
            </Grid>
            <Grid
                container
                className="User_Contact_Info-Container"
                width={mobileView ? "60%" : "100%"}
            >
                <Stack>
                    <Typography variant="caption" fontSize="small">
                        Email
                    </Typography>

                    <Typography fontWeight="bold" fontSize="small" color="primary">
                        <a href={`mailTo:${user.email}`}>{user.email}</a>
                    </Typography>

                    <Typography variant="caption" fontSize="small">
                        Username
                    </Typography>

                    <Typography fontWeight="bold" fontSize="small" color="primary">
                        <a href={`mailTo:${user.username}`}>{user.username}</a>
                    </Typography>

                    <Typography variant="caption" fontSize="small">
                        Phone
                    </Typography>

                    <Typography fontWeight="bold" fontSize="small" color="primary">
                        {user.phone ? (
                            <a href={`tel:${formatPhoneNumber(user.phone)}`}>{formatPhoneNumber(user.phone)}</a>
                        ) : (
                            "-"
                        )}
                    </Typography>
                    <Typography variant="caption" fontSize="small">
                        Mobile Phone
                    </Typography>

                    <Typography fontWeight="bold" fontSize="small" color="primary">
                        {user.mobile_phone ? (
                            <a href={`tel:${formatPhoneNumber(user.mobile_phone)}`}>
                                {formatPhoneNumber(user.mobile_phone)}
                            </a>
                        ) : (
                            "-"
                        )}
                    </Typography>
                </Stack>
            </Grid>
        </Paper >
    );
}
