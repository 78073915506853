import FilterOptions from "@classes/Filter";
import { TaskStatus } from "../../Strings/TaskStatus";


// These are arranged in a specific order (for user-understanding, only).
export const definedStatuses = [
  TaskStatus.NotStarted,
  TaskStatus.InProgress,
  TaskStatus.OnHold,
  TaskStatus.Completed,
  TaskStatus.Deferred,
  TaskStatus.Escalated
] as const;
export const definedTypes = [
  "Maintenance",
  "Drop",
  "Pickup",
  "Clean",
  "Inspection",
  "Shuttle Service"
] as const;
export const definedPriorities = ["URGENT", "High", "Normal", "Low", "Lowest"] as const;
const taskCheckboxFilters: FilterOptions['checkBoxOptions'] = {
  Status: {},
  Types: {},
  Priority: {},
};
Object.assign(taskCheckboxFilters.Status, definedStatuses.reduce((accum, cur) => {
  accum[cur] = false
  return accum
}, {} as Record<string, boolean>))
Object.assign(taskCheckboxFilters.Types, definedTypes.reduce((accum, cur) => {
  accum[cur] = false
  return accum
}, {} as Record<string, boolean>))
Object.assign(taskCheckboxFilters.Priority, definedPriorities.reduce((accum, cur) => {
  accum[cur] = false
  return accum
}, {} as Record<string, boolean>))

export default taskCheckboxFilters;
