import * as React from "react";

import { Modal } from "@mui/material";
import { StyledBoxContainer } from "@containers/StyledBoxContainer";
import "./filterModal.css"
import taskCheckboxFilters from "@context/Task/taskCheckboxFilters";
import FilterOptions, { TaskFilterKeys } from "@classes/Filter";
import { FilterModalTemplate } from "../../Filter/FilterModalTemplate";
// import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
// import usePersistentObject from "@hooks/Selectors/usePersistentObject";
// import { ApiQueueList, ApiUserList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
// import { createSelectableQueues, createSelectableUserObjects } from "@helpers/createSelectableObjects";
import useListingOptions from "@hooks/Selectors/Serializers/useListingOptions";
import useTaskAssignees from "@hooks/Selectors/Serializers/useTaskAssignees";
import { ApiTask } from "../../../Types/task";
import { useSearchParams } from "react-router-dom";
// const useTasks = false
// Will return an array of all the listings in this task array for dropdown
// const useTaskAssignees = (tasks: ApiTask[]) => {
//   const dispatch = useAppDispatch()
//   const persistentObject = usePersistentObject()
//   // if we are using tasks to create assignee list
//   const assignees: string[] = React.useMemo(() => {
//     if (Array.isArray(tasks)) {
//       return tasks.map((task: ApiTask) =>
//         [task.assignee.name, task.assignee_id].join("&id=")
//       );
//     }
//     return []
//   }, [tasks]);
//   const uniqueUsers = React.useMemo(() => [...new Set(assignees)], [assignees]);
//   // if we are using active users and queues
//   React.useEffect(() => {
//     if (!persistentObject.checkUserList(ApiUserList.activeUsers)) {
//       dispatch(updatePersistentObjects(PersistentObject.user, ApiUserList.activeUsers))
//     }
//     if (!persistentObject.checkQueues(ApiQueueList.allQueues)) {
//       dispatch(updatePersistentObjects(PersistentObject.queue, ApiQueueList.allQueues))
//     }
//   }, [dispatch, persistentObject])
//   const users = React.useMemo(() => {
//     if (persistentObject.checkUserList(ApiUserList.activeUsers)) {
//       return persistentObject.getUserList(ApiUserList.activeUsers)
//     }
//     return null
//   }, [persistentObject])
//   const queues = React.useMemo(() => {
//     if (persistentObject.checkQueues(ApiQueueList.allQueues)) {
//       return persistentObject.getQueue(ApiQueueList.allQueues)
//     }
//     return null
//   }, [persistentObject])
//   // using tasks
//   if (useTasks) {
//     return uniqueUsers.map((userIdPair: string) => {
//       const [label, value] = userIdPair?.split("&id=");
//       return { label, value };
//     });
//   }
//   // using active users
//   if (users === null) return [{ label: "loading", value: "" }]
//   if (queues === null) return [{ label: "loading", value: "" }]
//   return [...createSelectableUserObjects(users).sort((item1, item2) => item1.label > item2.label ? 1 : item1.label < item2.label ? -1 : 0), ...createSelectableQueues(queues).sort((item1, item2) => item1.label > item2.label ? 1 : item1.label < item2.label ? -1 : 0)]
// };

// Will return an array of all the listings in this task array
// const useTaskListings = (tasks: ApiTask[]) => {

//   const listings: any[] = React.useMemo(() => {
//     if (Array.isArray(tasks)) {
//       return tasks
//         .map((task: ApiTask) => {
//           if (task.related !== undefined) {
//             return [task.related.unit_id, task.related.unit_id].join("&unit_id=");
//           }
//           return [];
//         })
//         .filter((items: string | any[]) => items?.length > 0);
//     }
//     return []
//   }, [tasks]);

//   const uniqueListings = React.useMemo(
//     () => [...new Set(listings)],
//     [listings]
//   );

//   return uniqueListings.map((userIdPair: string) => {
//     const [label, value] = userIdPair.split("&unit_id=");
//     return { label, value };
//   });
// };

// Here the filter modal will now recive a list of task so that it will not have to grab then on its own
//! reducing the extra call to task in useTaskListings and useTaskAssignees
export default function TaskFilterModal({ tasks, open, onClose }: { tasks: ApiTask[], open: boolean, onClose: () => void }) {
  const userOptions = useTaskAssignees(true);
  const activeListings = useListingOptions("unit_id");
  const [curSearchParams] = useSearchParams()

  if (!open) {
    return null
  }

  const checkBoxFilters = taskCheckboxFilters
  if (curSearchParams.has(TaskFilterKeys.Subtype)) {
    delete checkBoxFilters[TaskFilterKeys.Subtype]
    curSearchParams.getAll(TaskFilterKeys.Subtype).forEach((param) => {
      checkBoxFilters[TaskFilterKeys.Subtype] = { ...checkBoxFilters[TaskFilterKeys.Subtype], [param]: true }
    })
  } else {
    delete checkBoxFilters[TaskFilterKeys.Subtype]
  }

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      onClose={onClose}
      open={open}
      classes={{ root: "Filter-Modal" }}>
      <div id="Mui-Ref-Holder">
        <StyledBoxContainer title="Filters" borderBottom disableScrollToo>
          <FilterModalTemplate closeModal={onClose} options={new FilterOptions({ Assignee: userOptions || [{ label: "Loading", value: "", group: "" }], Listing: activeListings || [{ label: "Loading", value: "", group: "" }] }, checkBoxFilters)} />
        </StyledBoxContainer>
      </div>
    </Modal>
  );
}

