import { useSelector } from "react-redux";
import { RootState } from "../../Store";

const persistSelector = (state: RootState) => state.session._persist;

const usePersist = () => {
  const persistState = useSelector(persistSelector);
  return persistState;
};

export default usePersist;
