import {
  Messaging,
  Unsubscribe,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import FirebaseAppConfig from "../../Config/Firebase/app";
import { firebase_notification_key } from "../../Config";
import { removeMessageIdFromFirestore } from "./removeMessageIdFromFirestore";
import { writeMessageIdToFirestore } from "./writeMessageIdToFirestore";

const verboseLogging = false;

// const NOTIFICATION_URL = "gcm.notification.url";
const genericError =
  "Messaging has not been initialized or is not supported. FCMSubscription";
const genericEmailError =
  "An authorized user must be logged in to preform this action.";

type FCMPermissionType = "granted" | "denied" | "default";

//TODO: Find a proper type for this <any>.
const processForegroundMessage = (payload: any) => {
  if (payload.data) {
    // const { title, ...notificationData } = JSON.parse(
    //   payload.data["gcm.notification.data"]
    // );
    // console.log("foreground", notificationData);
    //NOTE: There may be custom processing required here per platform (iOS vs Android vs whatever).
    // let { title, ...data } = JSON.parse(payload.data.gmc.notification.data);
    // const notification = new Notification(title || "", notificationData);
    // notification.onclick = () => {
    //   if (data) {
    //     if (data[NOTIFICATION_URL]) {
    //       window.location.assign(data[NOTIFICATION_URL]);
    //       notification.close();
    //     }
    //   }
    // };
  }
};

export class FCMSubscription {
  private readonly vapidKey: string = firebase_notification_key;
  public firebaseMessaging: Messaging | undefined;
  public permission: FCMPermissionType = "default";
  public token: string | undefined;
  private _unsubscribeCallback: Unsubscribe | Error = new Error(
    "No subscription exists."
  );
  public error: Error | undefined = undefined;
  public isSubscribed: boolean = false;
  constructor() {
    isSupported()
      .then(async (status: boolean) => {
        if (status) {
          this.firebaseMessaging = getMessaging(FirebaseAppConfig);
          return;
        }

        // class will use this variable moving forward too not have to check isSupported.
        this.firebaseMessaging = undefined;
      })
      .catch((e) => {
        throw new Error("Error initializing Notifications class.");
      });
  }

  async getMessagingToken(subscriptionEmail: string) {
    if (this.firebaseMessaging === undefined) {
      console.warn("exit no messaging");
      return;
    }
    if (typeof this.token === "string" && this.token !== "") return this.token;
    if (typeof subscriptionEmail !== "string" || subscriptionEmail === "") {
      throw new Error(genericEmailError);
    }
    try {
      this.token = await getToken(this.firebaseMessaging, {
        vapidKey: this.vapidKey,
      });
      await writeMessageIdToFirestore(subscriptionEmail, this.token);
    } catch (e) {
      this.token = undefined;
      throw new Error(
        "Problem getting FCM Subscription token. <getMessagingToken>"
      );
    }

    return this.token;
  }

  async requestPermission() {
    verboseLogging &&
      console.log(
        "%cRequesting client permission for notifications...",
        "color:#aaf"
      );

    if (!window.hasOwnProperty("Notification")) {
      // console.warn(genericError);
      return false;
    }

    this.permission = await Notification.requestPermission();

    return this.permission;
  }

  async unsubscribe(subscriptionEmail: string) {
    if (!this.isSubscribed) return;
    if (!this._unsubscribeCallback) return;
    if (!this.token) return;

    verboseLogging && console.log("unsubscribing", this.token);

    if (typeof subscriptionEmail !== "string" || subscriptionEmail === "")
      throw new Error(genericError);
    // remove token from firebase store
    try {
      await removeMessageIdFromFirestore(subscriptionEmail, this.token);
    } catch (e) {
      if (e instanceof Error) {
        this.error = new Error("Problem unsubscribing key from firestore.", e);
      }
    }
    if (typeof this._unsubscribeCallback === "function") {
      this._unsubscribeCallback();
    }
    if (this.error instanceof Error) {
      return this.error;
    }

    return true;
  }

  async subscribe(subscriptionEmail: string) {
    if (typeof subscriptionEmail !== "string" || subscriptionEmail === "")
      return;
    if (this.firebaseMessaging === undefined) return;
    if (this.token === undefined) return;
    try {
      verboseLogging && console.log("subscribing to messaging");
      this._unsubscribeCallback = onMessage(
        this.firebaseMessaging,
        processForegroundMessage
      );
      await writeMessageIdToFirestore(subscriptionEmail, this.token);
      this.isSubscribed = true;
    } catch (e) {
      this.isSubscribed = false;
      this._unsubscribeCallback = new Error(genericError);
      console.error(e);
      throw new Error(genericError);
    }
    return (subscriptionEmail: string) => {
      this.unsubscribe(subscriptionEmail);
    };
  }

  async send(payload: { body: string; title?: string; subtitle?: string }) {
    console.log("FCMSubscription.send NOT YET IMPLEMENTED", payload);
    // this function will make a request to our web worker
  }
}
