import React from "react";
import { ButtonGroup, Box, useMediaQuery, Stack } from "@mui/material";
import PagenationProps from "./Interfaces";

import RefreshButton from "../../../RefreshButton"
import { useTheme } from "@mui/material";
import { NewTaskButton } from "./Items/NewTaskButton";
import { OpenFilterModal } from "./Items/OpenFilterModal";
import { FilterBySelect } from "./Items/FilterBySelect";
import { PagenationDatePicker } from "./Items/PagenationDatePicker";
import { TodayButton } from "./Items/RollTodayButton";
import { BackButton } from "./Items/BackButton";
import { ForwardButton } from "./Items/ForwardButton";
import { OpenSortTasksMenu } from "./Items/OpenSortMenu";
import { SortTasksMenu } from "./Items/SortTasksMenu";

/* 
order per screen size.
! fullscreen: @media (min-width:1200px)
| filterBy | back/forward | Date Selector | today/filter | New Task | Refresh| Sort Btn/Menu
! tablet: @media (min-width:600px) and (max-width:1199.95px)
| Date Selector | back/forward | today |
| filterBy | Filter | New Task | Refresh| Sort Btn/Menu
! mobile: @media (max-width:599.95px)
| back | Date Selector | forward |
| filterBy | today | filter | Refresh | Sort Btn/Menu
*/

export function PagenationDynamic(props: PagenationProps) {
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [openDatePicker, setOpenDatePicker] = React.useState(false)
  const [openSort, setOpenSort] = React.useState(false)
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.up("lg"))
  const tablet = useMediaQuery(theme.breakpoints.between("sm", "lg"))
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))
  const {
    filterBy,
    handleDayChange,
    handleWeekChange,
    openFilterModal,
    openForm,
    rollBackward,
    rollForward,
    rollToday,
    setCurTask,
    setFilterBy,
    startDate,
    today,
    curTask,
  } = props;

  return (
    <Box className="pagenation-container">
      <div className="pagenation-row">
        {fullscreen && (
          //! Wide enough for just a single row
          <>
            {/* | filterBy | back/forward | Date Selector | today/filter | New Task | Refresh| Sort Btn/Menu */}
            <FilterBySelect filterBy={filterBy} setFilterBy={setFilterBy} />
            <ButtonGroup disableFocusRipple disableRipple className="pagenation-button-group">
              <BackButton rollBackward={rollBackward} />
              <ForwardButton rollForward={rollForward} />
            </ButtonGroup>
            <PagenationDatePicker filterBy={filterBy} handleDayChange={handleDayChange} handleWeekChange={handleWeekChange} openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker} startDate={startDate} />
            <ButtonGroup disableFocusRipple disableRipple className="pagenation-button-group">
              <TodayButton rollToday={rollToday} today={today} />
              <OpenFilterModal openFilterModal={openFilterModal} />
            </ButtonGroup>
            <NewTaskButton curTask={curTask} openForm={openForm} setCurTask={setCurTask} />
            <div style={{ marginLeft: 1, }}>
              <RefreshButton asyncRefresh={props.onRefresh} />
            </div>
            <div className="row-end">
              <SortTasksMenu openSort={openSort} setOpenSort={setOpenSort} anchorRef={anchorRef} />
            </div>
          </>
        )
        }
        {tablet && (
          //! Row One
          <>
            {/* | Date Selector | back/forward | today | */}
            <PagenationDatePicker filterBy={filterBy} handleDayChange={handleDayChange} handleWeekChange={handleWeekChange} openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker} startDate={startDate} />
            <ButtonGroup disableFocusRipple disableRipple className="pagenation-button-group">
              <BackButton rollBackward={rollBackward} />
              <ForwardButton rollForward={rollForward} />
            </ButtonGroup>
            <TodayButton rollToday={rollToday} today={today} />
          </>
        )}
        {mobile && (
          <>
            {/* | back | Date Selector | forward | */}
            <BackButton rollBackward={rollBackward} />
            <PagenationDatePicker filterBy={filterBy} handleDayChange={handleDayChange} handleWeekChange={handleWeekChange} openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker} startDate={startDate} />
            <ForwardButton rollForward={rollForward} />
          </>
        )}
      </div>
      <div className={`pagenation-row ${tablet && 'bottom-row '}`}>
        {tablet && (
          //! Row Two
          <>
            {/* | filterBy | Filter | New Task | Refresh| Sort Btn/Menu */}
            <FilterBySelect filterBy={filterBy} setFilterBy={setFilterBy} />
            <OpenFilterModal openFilterModal={openFilterModal} />
            <NewTaskButton openForm={openForm} setCurTask={setCurTask} />
            <div style={{ marginLeft: 1, }}>
              <RefreshButton asyncRefresh={props.onRefresh} />
            </div>
            <div className="row-end">
              <SortTasksMenu openSort={openSort} setOpenSort={setOpenSort} anchorRef={anchorRef} />
            </div>
          </>
        )}
        {mobile && (
          <>
            {/* | filterBy | today | filter | Refresh | Sort Btn/Menu */}
            <FilterBySelect filterBy={filterBy} setFilterBy={setFilterBy} />
            <TodayButton rollToday={rollToday} today={today} />
            <OpenFilterModal openFilterModal={openFilterModal} />
            <div style={{ marginLeft: 1, }}>
              <RefreshButton asyncRefresh={props.onRefresh} />
            </div>
          </>
        )}
      </div>
      {mobile && (
        <div className="pagenation-row bottom-row">
          <div>
            <SortTasksMenu openSort={openSort} setOpenSort={setOpenSort} anchorRef={anchorRef} />
          </div>
        </div>
      )}
    </Box >
  )
}