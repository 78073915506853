import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import "./appLinkStyle.css"

interface AppLinkProps extends LinkProps {
  text: string;
  startIcon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  }
  endIcon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  }
  fontSize?: `${string}px`
}
export function AppLink(props: AppLinkProps) {
  const StartIcon = props.startIcon || null
  const EndIcon = props.endIcon || null
  const fontSize = props.fontSize || '10px'
  const styleRoot = document.documentElement!
  styleRoot.style.setProperty("--link-font-size", fontSize)
  return (
    <Link {...props} id="App-Link" className={`${props.className || ""}`}>
      {StartIcon && <StartIcon id="App-Link-Icon" />}
      {props.text}
      {EndIcon && <EndIcon id="App-Link-Icon" />}
    </Link>
  );
}
