import { useSearchParams } from "react-router-dom";

export default function useTaskFilters() {
  const [searchParams] = useSearchParams();

  const startDateKey = "taskStartDate"
  let startDate = searchParams.get(startDateKey)
  // if (typeof startDate === 'string' && startDate.indexOf("T")) {
  //   startDate = startDate.slice(0, startDate.indexOf("T"))
  // }

  const endDateKey = "taskEndDate"
  let endDate = searchParams.get(endDateKey)
  // if (typeof endDate === 'string' && endDate.indexOf("T")) {
  //   endDate = endDate.slice(0, endDate.indexOf("T"))
  // }

  const filterByKey = 'filterBy'
  const filterBy = searchParams.get(filterByKey)


  return [
    startDate,
    endDate,
    filterBy,
  ];
}
