import * as React from "react";
import { Grid, Avatar, Box, Tooltip, Typography } from "@mui/material";
import useProfile from "@hooks/Selectors/useProfile";
import AppRichTextEditor from "./AppRichTextEditor";
import SubmitPlugin from "./plugins/SubmitPlugin";
import { OnChangePlugin as LexicalOnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { POST_MESSAGE, LOW_PRIORITY } from "../Messages/plugins/config";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { SnackBarData } from "@context/Snackbar";
import { useLocation } from "react-router-dom";
import { $getRoot } from "lexical";
import { $generateHtmlFromNodes } from '@lexical/html'

import MessageList from "./MessageList"
import useSalesforceRequest from "@request/Salesforce";
import { format } from "date-fns"
import useExponentialPoll from "@hooks/useExponentialPoll";
import { FollowUpTask } from "@components/Task/TaskControls/ActionButtons/TaskFollowUpButton";
import { ApiTask } from "../../Types/task";

const verboseLogging = false

interface AppTextEditorProps {
  curTaskOrListing: ApiTask | FollowUpTask | ApiListing;
  disableAttach?: boolean
}

export default function AppTextEditor({
  curTaskOrListing: curTask,
  disableAttach
}: AppTextEditorProps) {
  const user = useProfile();
  const { pathname } = useLocation()
  const [editor] = useLexicalComposerContext();
  const [disabledSave, setDisabledSave] = React.useState<boolean>(true);
  const fetchingMessages = React.useRef(true)
  const [submitting, setSubmitting] = React.useState(false)
  const [taskMessages, setTaskMessages] = React.useState<ApiFeedItem[]>([]);
  const [, setSnackData] = React.useContext(SnackBarData);
  const messageFeedRequest = useSalesforceRequest()
  const [updatedAt, setUpdatedAt] = React.useState(format(new Date(), 'p'))
  const [shouldPoll, setShouldPoll] = React.useState(false)
  const [setOperation, resetInterval] = useExponentialPoll()
  const pollingOperation = React.useCallback(() => {
    if (shouldPoll === false) {
      setShouldPoll(true)
    }
  }, [shouldPoll])

  setOperation(pollingOperation)
  React.useEffect(() => {
    if (curTask === null) return
    const typedTaskCheck = curTask as FollowUpTask
    if (typedTaskCheck.follow_up_task) return
    if (fetchingMessages.current || shouldPoll) {
      setShouldPoll(false)
      const request = messageFeedRequest({ endpoint: `/api/feed/${curTask.id}` });
      request.attemptSend().then((result) => {
        if (!result.callSent) {
          return; // session expired
        }
        const json = result.GetParsedJson();
        if (json.status !== 200) {
          throw new Error('bad status on get feed messages: ' + json.status);
        }
        if (!Array.isArray(json.feed_items)) {
          throw new Error('bad/missing feed_items on get messages: ' + json.feed_items);
        }
        if (taskMessages.length !== json.feed_items.length) {
          // force a restart of the feed polling timer
          resetInterval(prev => (prev + 1))
          setTaskMessages(json.feed_items);
        }
        setUpdatedAt(format(new Date(), 'p'))
      })
        .catch((e) => {
          console.warn('get feed failed', e);
          setSnackData({
            message: "Failed to get messages.",
            color: "error",
            severity: "error",
          });
          setTaskMessages([]);
        })
    }
    return () => { fetchingMessages.current = false }
  }, [fetchingMessages, messageFeedRequest, setSnackData, shouldPoll, curTask, taskMessages, resetInterval, setOperation, pollingOperation]);

  //! This use effect is important and will add functionality to posting a message with our rte plugin
  React.useEffect(() => {
    return editor.registerCommand(
      POST_MESSAGE,
      (_allMessages: ApiFeedItem[], currentEditor) => {
        //NOTE: For full RTE up and down, we should be using this: https://lexical.dev/docs/concepts/serialization
        //      That's beyond the scope of the current bug fix, however.
        //      Salesforce puts their own limitations on the HTML, so we would have to map Lexical to Salesforce,
        //      and vice versa.
        const editorNodeMap = currentEditor.getEditorState()._nodeMap;
        if (!editorNodeMap) {
          console.warn('failed to access RTE editor nodemap');
          return true;
        }

        const payload = {
          type: "TextPost",
          body: '',
          is_rich_text: true,
          parent_id: curTask.id,
        };
        let htmlString = $generateHtmlFromNodes(currentEditor, null)
        htmlString = htmlString.replaceAll(`</p><p class="editor-paragraph">`, "<br>")
        htmlString = htmlString.replaceAll(/^<span(.*?)>/g, "")
        htmlString = htmlString.replaceAll(/<span>/g, "")
        htmlString = htmlString.replaceAll(/<\/span>/g, "")
        verboseLogging && console.log("payload before altering", htmlString)
        const htmlDocument = new DOMParser().parseFromString(htmlString, "text/html")
        htmlDocument.querySelectorAll('*').forEach((node) => {
          if (node.classList.contains("editor-text-italic")) {
            const iTag = document.createElement('i')
            iTag.innerHTML = node.innerHTML
            node.replaceWith(iTag)
          }
          if (node.classList.contains("editor-text-strikethrough")) {
            const sTag = document.createElement('s')
            sTag.innerHTML = node.innerHTML
            node.replaceWith(sTag)
          }
          if (node.classList.contains("editor-text-underline")) {
            const uTag = document.createElement('u')
            uTag.innerHTML = node.innerHTML
            node.replaceWith(uTag)
          }
          if (node.tagName) {
            switch (node.tagName) {
              case "STRONG":
                const bTag = document.createElement("b")
                bTag.innerHTML = node.innerHTML
                node.replaceWith(bTag)
                break;
              case "BR":
                const lineBreak = document.createElement("p")
                lineBreak.innerHTML = '&nbsp;'
                node.replaceWith(lineBreak)
                break
            }
          }
          node.removeAttribute("class")
          node.removeAttribute("value")
        })
        let payloadBody = htmlDocument.querySelector("body")!.innerHTML
        verboseLogging && console.log('new payload', payloadBody)

        if (user === null) {
          // no salesforce call with an expired session
          //NOTE: This check informs TypeScript so that we don't have to repeatedly cast user below.
          return false;
        }

        payload.body = payloadBody

        const postRequest = messageFeedRequest({ endpoint: '/api/feed', method: "POST", body: JSON.stringify(payload) })
        postRequest.attemptSend().then((result) => {
          if (!result.callSent) { return } // session has expired
          const json = result.GetParsedJson()
          if (json.hasOwnProperty("feed_item")) {
            const request = messageFeedRequest({ endpoint: `/api/feed/${curTask.id}` });
            request.attemptSend().then((result) => {
              if (!result.callSent) {
                return; // session expired
              }
              const json = result.GetParsedJson();
              if (json.status !== 200) {
                throw new Error('bad status on get feed messages: ' + json.status);
              }
              if (!Array.isArray(json.feed_items)) {
                throw new Error('bad/missing feed_items on get messages: ' + json.feed_items);
              }
              setTaskMessages(json.feed_items);
              // NOTE: we only update the "Last update" component when we are able to refresh the whole list (see request above)
              return json.feed_items
            }).catch(() => {
              //! Note that we are fetching for all feed items and using that to drive display
              const updatedMessageList: ApiFeedItem[] = [
                {
                  ...json.feed_item,
                  owner_name: user.name,
                  owner_id: user.id,
                  body: json.feed_item.body,
                  modstamp: new Date().toJSON(),
                },
                ..._allMessages,
              ];
              setSnackData({
                message: "Message sent",
                color: "success",
                severity: "success",
              });
              setTaskMessages(updatedMessageList);
              // NOTE: we do not change the updated at component although we handle the error by appending the newley posted message to the old response
              return updatedMessageList
            })
          } else {
            throw new Error("Failed to create feed message.")
          }
        }).catch(() => {
          console.error("Failed to create feed message.")
          setSnackData({
            message: "Message failed to send",
            color: "error",
            severity: "error",
          });
        });

        return false;
      },
      LOW_PRIORITY
    );
  });

  const onSubmit = async () => {
    setSubmitting(true)
    // command was attached to the editor in above dispatch
    await editor.dispatchCommand(POST_MESSAGE, taskMessages);
    setSubmitting(false)
  };

  if (user === null) {
    // This component cannot exist without the user model. In this case, the system
    // should already be routing the user to login.
    return null;
  }

  return (
    <>
      <Grid container display="flex" flexWrap="nowrap">
        <Tooltip title={user.name}>
          <Grid item className="messages_editor_Container" mr={pathname.includes('/details') ? 1 : 0}>
            <Avatar
              src={user.profile_thumbnail_url}
              alt={user.name}
              className={pathname.includes('/details') ? "profile_Avatar-auto" : "profile_Avatar"}
            />
          </Grid>
        </Tooltip>
        <Grid item className="messages_editor_Box" component={Box} display="flex" flexDirection="column">
          <AppRichTextEditor taskId={curTask.id} placeholder="Enter Message..." />
          <LexicalOnChangePlugin
            onChange={(_, lexEditor) => {
              // force a restart of the feed polling timer on each edit
              resetInterval(prev => (prev + 1))
              lexEditor.getEditorState().read(() => {
                const rootState = $getRoot()
                if (rootState.__cachedText?.trim() !== "") {
                  setDisabledSave(false);
                } else {
                  setDisabledSave(true);
                }
              })
            }}
          />
          {/* Save and cancel buttons */}
          <SubmitPlugin
            taskId={curTask.id}
            disabled={disabledSave && submitting === false}
            onSubmit={onSubmit}
            disableAttach={disableAttach}
          />
        </Grid>
      </Grid>

      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: "flex-end"
      }}>
        {submitting ? <Typography fontSize='small' color='GrayText'>Updating message list...</Typography> : <Typography fontSize='small' color='GrayText'>{`Last update ${updatedAt}`}</Typography>}
      </div>
      {!fetchingMessages.current && (
        <Grid item container className="messages_Container" mt={1}>
          {taskMessages.length > 0 && <MessageList messages={taskMessages} />}
        </Grid>
      )}
    </>
  );
}
