/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RedirectWithToken from "./Components/Auth/SalesforceOauth/Redirect";
import useOnAuthStateChange from "./Config/Firebase/onAuthStateChange";
import { Theme } from "./Context/Theme";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { environment, api_keys } from "./Config";
import { Box, Typography, LinearProgress, Grid } from "@mui/material";
import { HourglassBottom } from "@mui/icons-material";
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';
import { setUser } from "@store/Reducers/Session";

import Topbar from "./Components/TopBar"
import SalesforceAuthModal from "./Components/Auth/SalesforceOauth/Modal/SalesforceOauth"
import AuthLoader from "./Components/Auth/Loader"
import LoginModal from "./Components/Auth/Login/Modal"
import TaskPage from "./Components/Task/Sub_Components/TaskPage/TaskPage"
import Main from "./Containers/Main"
import Home from "./Components/Home"
import UnknownPage from "./Components/ErrorPages/UnknownPage"
import TaskSearch from "./Components/Task"
import TaskList from "./Components/Task/Sub_Components/TaskList"
import UserPage from "./Components/UserPage"
import TeamsList from "./Components/Teams"
import { ErrorBoundaryFallback } from "./Components/Error/ErrorBoundaryFallback";
import ReauthorizeLanding from "@components/Auth/Login/Modal/ReauthorizeLanding";
import { AuthRoutes, ProtectedRoute } from "@routes"
import ChangePassword from "@components/Auth/ChangePassword/ChangePassword";
import ListingsCardContainer from "@components/Listings";
import ListingPage from "@components/Listings/ListingPage";
// import { useNotifications } from "./Hooks/Notifications/useNotifications";
import { useSession } from "@hooks/Selectors/useSession";

Sentry.init({
  dsn: api_keys.sentry_dns,
  integrations: [new BrowserTracing()],
  environment: environment.mrrEnv,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  autoSessionTracking: false,
  // ignoreErrors: [/ChunkLoadError/g]
});


export default function App() {
  const theme = React.useContext(Theme);
  const { user, _persist } = useSession()
  const dispatch = useDispatch()
  useOnAuthStateChange();
  // useNotifications()
  // !! THIS BREAKS (Joe has a repro)
  // React.useEffect(() => {
  //   console.log('[[AUTH SUBSCRIBE]] hit effect');
  //   if (unsubscribe) {
  //     console.log('[[AUTH SUBSCRIBE]] returning truthy value', unsubscribe);
  //     return unsubscribe;
  //   }
  //   console.log('[[AUTH SUBSCRIBE]] no return (falsey)', unsubscribe);
  // }, [unsubscribe]);

  React.useLayoutEffect(() => {
    window.scrollTo(0, 1);
  });

  // console.log('-------app main persist------', persist.rehydrated)
  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onReset={(e) => {
        console.log('ErrorBoundary reset called!', e);

        // kill the session, and return to root
        dispatch(setUser(null));
        window.location.assign('/');

        console.log('boundary reset handler complete');
      }}
    >
      <div
        className="App"
        style={{
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
          overflow: "hidden",
        }}
      >
        {(!_persist.rehydrated) &&
          (
            <Box width='100%' height='100vh'>
              <Helmet>
                <meta
                  name="theme-color"
                  content={
                    theme.palette.background.default
                  }
                />
              </Helmet>
              <Grid container height="100%" display="flex" justifyContent='center' alignItems='center' flexDirection='column'>
                <Grid item display='flex' flexDirection="row">
                  <Typography variant="h5">Loading...</Typography>
                  <HourglassBottom className="spin-me" />
                </Grid>
                <Grid item mt={1}>
                  <LinearProgress color="primary" variant="indeterminate" sx={{ width: '300px', borderRadius: "4px" }} />
                </Grid>
              </Grid>
            </Box>
          )
        }
        {_persist.rehydrated &&
          <>
            <meta
              name="theme-color"
              content={
                user !== null
                  ? theme.palette.mode === "light"
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark
                  : theme.palette.primary.dark
              }
            />
            <Topbar />
            <Main>
              <Routes>
                <Route path="/change_password" element={<ChangePassword />} />
                <Route element={<AuthRoutes user={user} />}>
                  <Route path="/oauth2/reauthorize" element={<ReauthorizeLanding />} />
                  <Route path="/oauth2/signup" element={<RedirectWithToken />} />
                  <Route path="/reconnect" element={<SalesforceAuthModal open={true} reconnect={true} />} />
                  <Route path="/signup" element={<SalesforceAuthModal open={true} reconnect={false} />} />
                  <Route path="/login" element={<LoginModal open={true} />} />
                </Route>
                <Route
                  path="/authorizing"
                  element={<AuthLoader user={user} />}
                />
                <Route element={<ProtectedRoute user={user} />}>
                  <Route path="/home/*" element={<Home />} />
                  <Route path="/users/*" element={<UserPage />} />
                  <Route path="/tasks/all" element={<TaskSearch taskGroupKey={'allTasks'} />} />
                  <Route path={`/tasks/DropStrips`} element={<TaskSearch taskGroupKey={'allTasks'} />} />
                  <Route path={`/tasks/GlassLanai`} element={<TaskSearch taskGroupKey={'allTasks'} />} />
                  <Route path={`/tasks/Maintenance Inspections`} element={<TaskSearch taskGroupKey={'allTasks'} />} />
                  <Route path={`/tasks/Cleanings`} element={<TaskSearch taskGroupKey={'allTasks'} />} />
                  <Route path={`/tasks/Final Inspections`} element={<TaskSearch taskGroupKey={'allTasks'} />} />
                  <Route path={`/tasks/Special Projects`} element={<TaskSearch taskGroupKey={'allTasks'} />} />
                  <Route path="/tasks/my-tasks" element={<TaskSearch taskGroupKey={'assignedTasks'} />} />
                  <Route path="/tasks/unassigned" element={<TaskSearch taskGroupKey={'unassignedTasks'} />} />
                  <Route path="/tasks/overdue" element={<TaskList taskGroupKey={'overdueTasks'} />} />
                  <Route path="/tasks/Engineering" element={<TaskSearch taskGroupKey={'Engineering'} />} />
                  <Route path="/tasks/Housekeeping" element={<TaskSearch taskGroupKey={'Housekeeping'} />} />
                  <Route path="/tasks/GuestExperience" element={<TaskSearch taskGroupKey={'Guest Experience'} />} />
                  <Route path="/tasks/OwnerExperience" element={<TaskSearch taskGroupKey={'Owner Experience'} />} />
                  <Route path="/tasks/ShuttleDrivers" element={<TaskSearch taskGroupKey={'Shuttle Drivers'} />} />
                  <Route path="/tasks/details/:taskId" element={<TaskPage />} />
                  <Route path="/teams/*" element={<TeamsList />} />
                  <Route index element={<Navigate to="/home" />} />
                  {/* Fallbacks and redirects */}
                  {/* Note that react-router-dom v6 does not support regex in paths */}
                  <Route path="/tasks/*" element={<Navigate to="/tasks/all" />} />
                  <Route path="/task/*" element={<Navigate to="/tasks/all" />} />
                  <Route path="/listings" element={<ListingsCardContainer />} />
                  <Route path="/listing/:listingId" element={<ListingPage />} />
                  <Route path="*" element={<UnknownPage />} />
                </Route>
              </Routes>
            </Main>
          </>
        }
      </div >
    </ErrorBoundary>
  );
}
