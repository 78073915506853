import Box from "@mui/material/Box";
import React from "react";

export default function Main(props: React.PropsWithChildren<any>) {
  return (
    <Box
      boxSizing="border-box"
      sx={{
        overflow: "hidden",
        minHeight: "100dvh",
        backgroundColor: (theme) => theme.palette.background.default,
        pt: 8,
        px: { xs: 2, md: 4 },
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {props.children}
    </Box>
  );
};
