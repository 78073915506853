import * as React from 'react'
import { Paper, Typography } from '@mui/material';
import "./styleBoxContainer.css"

type StyleBoxContainerInheritedProps = React.PropsWithChildren<any> & Partial<HTMLDivElement>

export interface StyleBoxContainerI extends StyleBoxContainerInheritedProps {
  title: string;
  subTitle?: string;
  borderBottom?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  elevation?: number;
  chipComponent?: React.ReactElement
}

/**
 * @param title: String "An h5 element by default that will be the header"
 * @size this element will take up the full dimentions of the container it is in.
 */

export default React.forwardRef<HTMLDivElement, StyleBoxContainerI>((props, ref) => {
  const elevation = props.elevation || 3
  return (
    <Paper ref={ref} className={`OuterBox_Paper ${props.className}`} elevation={elevation} sx={{ overflowY: 'scroll', maxHeight: '650px' }}>
      <div className="BoxContent">
        <div className={`BoxContent_Title-Container ${props.borderBottom && "BoxContent_Title-Container-bb"}`}>
          <div className="BoxContent_Titles">
            <Typography className='BoxContent_Title No-Margin' variant='h6'>{props.title}</Typography>
            <Typography className='BoxContent_Sub-Title' variant='caption' color="GrayText">{props.subTitle}</Typography>
          </div>
          {props.chipComponent && <div className="Title-Chip_Container">
            {props.chipComponent}
          </div>}
        </div>
        <div className="BoxContent_Body">
          {props.children}
        </div>
      </div>
    </Paper>
  )
})