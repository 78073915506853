import * as React from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TaskFormModal from "@components/Task/Modals/TaskFormModal";
import { TaskDrawerControl, TaskDrawerSubject } from "@context/Task/TaskDrawer";
import { ApiTask } from "../../../../Types/task";

export default function NewTaskActionButton() {
  const [open, setOpen] = React.useState(false)
  const [, setCurTask] = React.useContext(TaskDrawerSubject)
  const [, toggleDrawer] = React.useContext(TaskDrawerControl)

  const setTask = (taskId: ApiTask['id'] | null, newTask?: ApiTask) => {
    const id = taskId || newTask?.id
    if (id) {
      setCurTask(id)
      toggleDrawer(true)
    }
    return null
  }
  return (
    <>
      <TaskFormModal
        refreshAttachments={() => undefined}
        setAttachmentsMeta={() => undefined}
        attachmentsMeta={[]}
        curTask={null}
        setCurTask={setTask}
        close={() => { setOpen(false) }}
        taskId={undefined}
        open={open}
      />
      <Fab
        color="primary"
        aria-label="add"
        name="/tasks/create"
        sx={{ position: "fixed", right: 16, bottom: 16, zIndex: 1 }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
