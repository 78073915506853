import * as React from "react";
import Typography from "@mui/material/Typography";
import { LockIconButton } from "../../StyledComponents";
import IconBasedOnPriority from "../../../IconBasedOnPriority";
import { createTitleForTask } from "../../TaskCompactCard";
import { ApiTask } from "../../../../../Types/task";
import { Chip, Stack } from "@mui/material";
import listingStatusColor from "../../../../../utils/listingStatusColor";

export default function TitleRow(props: { permission_to_enter?: boolean, curTask: ApiTask }) {
  const priority = React.useMemo(() => {
    if (props.curTask?.related) {
      const related = props.curTask.related as ApiCase
      if (related.case_number) {
        return related.priority
      }
    }
    return props.curTask.priority
  }, [props])
  if (!props.curTask) return null
  return (
    // <div className="Task-Card-Item-Row">
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography
          variant="h5"
          fontWeight="600"
          // fontSize='1.5rem'
          // className="Top-Row-Title"
          whiteSpace="pre-wrap"
          flexWrap="wrap"
        >
          {createTitleForTask(props.curTask)}
        </Typography>
      </Stack>
      <div className="priority-pte-container">
        <IconBasedOnPriority priority={priority} />
        {(props.permission_to_enter !== undefined && (props.curTask as any).is_manageable_by_user) && (
          <LockIconButton locked={!props.permission_to_enter} />
        )}
      </div>
    </Stack>
  );
}
