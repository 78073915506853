import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { add, addDays, differenceInDays, endOfYesterday, format, isValid, parseISO, subDays } from "date-fns";

import createDateLabel from "@helpers/dateLabel";
import useDocumentTitle from "@hooks/Window/useDocTitle";
import RefreshButton from "../RefreshButton";
import TaskSkeleton from "../Task/Sub_Components/Skeleton";

import TaskDrawer from "@components/Task/Sub_Components/TaskDrawer";
import { useHandleLegacyUrl } from "@components/Task/useHandleLegacyUrl";
import { TaskChangeShouldRefreshView } from "@context/Task/TaskDrawer";
import formatDateParam from "@helpers/formatDateParam";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useSalesforceRequest from "@request/Salesforce";
import { ApiTask } from "../../Types/task";
import processError from "../../utils";
import { AppFriendlyErrorMessage } from "../AppFriendlyErrorMessage";
import NoTaskMask from "../Task/Sub_Components/NoTaskMask";
import TaskCompactCard from "../Task/Sub_Components/TaskCompactCard";
import NewTaskActionButton from "../Task/TaskControls/ActionButtons/NewTaskActionButton";

// this is so that the date control wont scroll out of range
const today = new Date()
const limit = add(today, { days: 6 });
const overdueDayLimit = 7

export default function Home() {
  useHandleLegacyUrl()
  useDocumentTitle("Home")
  const [taskState, setTaskState] = React.useState<APP_STATE>("unset");
  const [myTasks, setMyTasks] = React.useState<ApiTask[]>([])
  const [appFriendlyErrorMessageState, setAppFriendlyErrorMessageState] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const notOverdueTaskDateParam = searchParams.get("taskDate");
  const isOverdueTaskTypeParam = searchParams.get("taskType");
  const [disableForwardToggle, setDisableForwardToggle] = React.useState(false);
  const [shouldRefreshTaskList, setShouldRefreshTaskList] = React.useContext(TaskChangeShouldRefreshView)
  const getAssignedTasks = useSalesforceRequest()
  const shouldUseDatePrefix = useMediaQuery("(min-width:435px)")
  const shouldUseFullDateStringFormat = useMediaQuery("(min-width:600px)")
  // ---------- Filtered Tasks -----------------
  // Will be undefined if there is no user or my-tasks in the store
  // ^ default for my-tasks is undefined
  //  const filteredMyTasks = myTasks.filter(
  //     (task: ApiTask) => isSameDay(new Date(task.due_datetime), new Date(notOverdueTaskDateParam || ""))
  //   )

  //   const filteredOverdueTasks = myTasks.filter(
  //     (task: ApiTask) =>
  //       task.is_overdue
  //   )

  // ----------Filtered Tasks end-----------------
  React.useEffect(() => {
    if (notOverdueTaskDateParam) {
      if (!isValid(new Date(notOverdueTaskDateParam))) {
        searchParams.set("taskDate", formatDateParam(today))
        setSearchParams(searchParams)
      }
    }
  }, [notOverdueTaskDateParam, setSearchParams, searchParams]);

  // in case of an error or invalid session, cleanup the error message on component dismount
  React.useEffect(() => {
    return () => {
      if (appFriendlyErrorMessageState !== '') {
        console.log('cleanup friendly error message on component dismount');
        setAppFriendlyErrorMessageState('');
      }
    }
  }, [appFriendlyErrorMessageState]);

  // ------------ Date Controls and url params -----------------
  React.useEffect(() => {
    if (notOverdueTaskDateParam && parseISO(notOverdueTaskDateParam) > limit) {
      setDisableForwardToggle(true);
    } else {
      setDisableForwardToggle(false);
    }
  }, [notOverdueTaskDateParam]);
  React.useEffect(() => {
    if (notOverdueTaskDateParam === null && isOverdueTaskTypeParam === null) {
      searchParams.set("taskDate", formatDateParam(new Date()));
      setSearchParams(searchParams);
    }
    setTaskState('unset')
  }, [searchParams, notOverdueTaskDateParam, setSearchParams, isOverdueTaskTypeParam]);
  // max 6 days forward
  const rollForward = React.useCallback(() => {
    if (notOverdueTaskDateParam) {
      const nextDay = addDays(parseISO(notOverdueTaskDateParam), 1);
      if (differenceInDays(nextDay, limit) > 6) {
        console.warn("hit next day limit")
        searchParams.set("taskDate", formatDateParam(limit));
        return searchParams;
      }
      searchParams.set("taskDate", formatDateParam(nextDay));
      setSearchParams(searchParams);
    }
    if (isOverdueTaskTypeParam) {
      searchParams.delete("taskType");
      searchParams.set("taskDate", formatDateParam(new Date()));
      setSearchParams(searchParams);
    }
    setTaskState('unset')
    return searchParams;
  }, [searchParams, notOverdueTaskDateParam, isOverdueTaskTypeParam, setSearchParams]);
  // max backwards to yesterday
  const rollBackward = React.useCallback(() => {
    if (isOverdueTaskTypeParam) return null;
    if (notOverdueTaskDateParam && parseISO(notOverdueTaskDateParam) > new Date()) {
      const nextDay = parseISO(notOverdueTaskDateParam);
      nextDay.setDate(nextDay.getDate() - 1);
      searchParams.set("taskDate", formatDateParam(nextDay));
      setSearchParams(searchParams);
    }
    if (notOverdueTaskDateParam && parseISO(notOverdueTaskDateParam) <= new Date()) {
      searchParams.delete("taskDate");
      searchParams.set("taskType", "overdue");
      setSearchParams(searchParams);
    }
    setTaskState('unset')
    return searchParams
  }, [searchParams, notOverdueTaskDateParam, isOverdueTaskTypeParam, setSearchParams]);

  React.useEffect(() => {
    if (shouldRefreshTaskList === true) {
      setShouldRefreshTaskList(false)
      setTaskState('unset')
    }
  }, [shouldRefreshTaskList])

  // ------ Forced update -------------
  const forceUpdate = () => {
    setTaskState('unset')
  }
  // Here we automatically refresh our tasks data on page load (or anytime responsePending.current is false)
  React.useEffect(() => {
    if (taskState !== 'unset') {
      return
    }
    const endpoint = `/api/task?action=assignedTasks`

    let dateParamRequestUrl = endpoint
    if (searchParams.has("taskDate") && isValid(new Date(searchParams.get("taskDate")!))) {
      let searchParamDate = searchParams.get("taskDate")!
      // This will handle any user with old url params already open on their browser
      if (typeof searchParamDate === 'string' && searchParamDate.indexOf("T") !== -1) {
        searchParamDate = searchParamDate.slice(0, searchParamDate.indexOf("T"))
      }
      //----------------------------------
      dateParamRequestUrl = dateParamRequestUrl.concat(`&start_date=${searchParamDate}&end_date=${searchParamDate}`)
    } else if (searchParams.has("taskType")) {
      dateParamRequestUrl = dateParamRequestUrl.concat(`&start_date=${formatDateParam(subDays(new Date(), overdueDayLimit))}&end_date=${formatDateParam(endOfYesterday())}`)
    }

    const request = getAssignedTasks({ endpoint: dateParamRequestUrl });
    setTaskState('loading')
    request.attemptSend().then((result) => {
      if (!result.callSent) {
        //TODO: Confirm whether this is needed (even though these states are being removed)
        // session expired
        return;
      }
      const json = result.GetParsedJson();
      if (json.hasOwnProperty("tasks")) {
        setMyTasks(json.tasks.filter((taskJson: ApiTask) => !taskJson.is_closed))
        setTaskState('success')
        return
      }
      throw new Error("Error requesting for my tasks")
    }).catch((e: any) => {
      const appFriendlyErrorMessage = processError(e, `Failed to get my tasks for homepage.`)
      setAppFriendlyErrorMessageState(appFriendlyErrorMessage)
      setMyTasks([])
      setTaskState('error')
    })

  }, [getAssignedTasks, searchParams, taskState])
  // ------ Forced update end -------------
  // ------------ Date Controls and url params -----------------
  return (
    <>
      <TaskDrawer />
      <NewTaskActionButton />
      <Grid
        item
        position="sticky"
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        whiteSpace="nowrap"
      >
        <Grid item>
          <RefreshButton asyncRefresh={forceUpdate} />
        </Grid>
        {notOverdueTaskDateParam !== null && (
          <Grid item>
            {isValid(new Date(notOverdueTaskDateParam)) &&
              (

                <Typography fontWeight="bold" color={(theme) => theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black}>
                  {shouldUseDatePrefix && "My Tasks for "}
                  {`${format(parseISO(notOverdueTaskDateParam), shouldUseFullDateStringFormat ? `eee, LLLL d` : `eee, LLL d`)}`}
                </Typography>
              )
            }
          </Grid>
        )}
        {isOverdueTaskTypeParam !== null && (
          <Grid item>
            <Typography fontWeight="bold" color={(theme) => theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black}>My Overdue Tasks</Typography>
          </Grid>
        )}
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          minWidth="120px"
          className="My_Tasks_Toolbar"
        >
          <IconButton
            aria-label="move date back"
            disabled={isOverdueTaskTypeParam !== null}
            onClick={() => rollBackward()}
            type="button"
            size="medium"
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography fontWeight="bold" minWidth="80px" color={(theme) => theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black}>
            {notOverdueTaskDateParam && isValid(new Date(notOverdueTaskDateParam)) ? createDateLabel(notOverdueTaskDateParam || "") : "Overdue"}
          </Typography>
          <IconButton
            aria-label="move date forward"
            disabled={disableForwardToggle}
            onClick={() => rollForward()}
            type="button"
            size="medium"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      {taskState === 'success' &&
        notOverdueTaskDateParam !== null && (
          <>
            {myTasks.length > 0 && <Grid item mt={1}>
              {myTasks.map((task: ApiTask) => (
                <TaskCompactCard task={task} key={task.id} />
              ))}
            </Grid>}
            {myTasks.length === 0 && <Grid container minWidth="100%">
              <NoTaskMask />
            </Grid>}
          </>
        )
      }
      {taskState === 'success' &&
        isOverdueTaskTypeParam !== null && (
          <>
            {myTasks.length > 0 && <Grid item mt={1}>
              {myTasks.map((task: ApiTask) => (
                <TaskCompactCard task={task} key={task.id} />
              ))}
            </Grid>}
            {myTasks.length === 0 && <Grid container minWidth="100%">
              <NoTaskMask />
            </Grid>}
          </>
        )
      }
      {taskState === 'loading' && (
        <Grid container direction="column" mt={1}>
          <Grid item mb={1}>
            <TaskSkeleton />
          </Grid>
          <Grid item mb={1}>
            <TaskSkeleton />
          </Grid>
          <Grid item mb={1}>
            <TaskSkeleton />
          </Grid>
        </Grid>
      )}

      {appFriendlyErrorMessageState !== '' &&
        <AppFriendlyErrorMessage appFriendlyErrorMessageState={appFriendlyErrorMessageState} />
      }
    </>
  );
}
