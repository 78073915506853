import * as React from "react";
import {
  Skeleton,
  Paper,
  Grid,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";

export default function TaskSkeleton() {
  return (
    <Paper elevation={4} sx={{ mb: '20px', width: '100%' }}>
      <Grid
        container
        height="auto"
        sx={{ minHeight: '20vh', minWidth: 350, width: "100%", padding: 1 }}
      >
        <Grid
          item
          container
          sx={{ opacity: 0.8 }}
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <CircularProgress
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "50px",
              color: "lightgray",
            }}
          />
        </Grid>
        <Grid item container mb={1}>
          <Grid item width="40%" height="1rem">
            <Skeleton variant="rectangular" width="100%" height="1rem">
              <Typography>Date Holder</Typography>
            </Skeleton>
          </Grid>
          <Grid item width="60%" display="flex" justifyContent="flex-end">
            <Skeleton variant="rectangular" sx={{ height: "1rem" }}>
              <Chip label="Urgency" variant="filled" />
            </Skeleton>
          </Grid>
        </Grid>
        <Grid item container mb={1}>
          <Grid item container mb={1}>
            <Skeleton
              variant="rectangular"
              sx={{ maxHeight: 40, width: "100%" }}
            >
              <Typography>Status ・ Date ・ Holder</Typography>
            </Skeleton>
          </Grid>
          <Grid item container>
            <Skeleton
              variant="rectangular"
              sx={{ maxHeight: 150, minWidth: 325 }}
            >
              <Typography>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
              </Typography>
            </Skeleton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
