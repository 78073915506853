import * as React from "react";
import { IconButton, Pagination, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./tabStyles.css"
import { Error as MuiError } from "@mui/icons-material";
import { TabPanel } from "./TabPanel";
import useSalesforceRequest from "@request/Salesforce";
import LoadingCompactCard from "@components/LoadingCompactCard";
import { SortDirectionKey } from "../Task/Sub_Components/sortTasks";

export type AppTabI = Record<string, any[]>
export type AppRefreshTabI = Record<string, () => void>

interface AppTabsI {
  tabEndpoints: Record<string, string>
  sortDirection: SortDirectionKey
  component: (props: any) => React.ReactElement<any>
  componentPropKey: string
  sortFunction: (a: any[], b: SortDirectionKey) => any[] | null
  refreshData: React.MutableRefObject<boolean>
  pagination?: boolean
  maxPerPage?: number
}

export function AppTabs(props: AppTabsI) {

  const {
    sortDirection,
    sortFunction,
    component: Component,
    componentPropKey,
    tabEndpoints,
    maxPerPage,
    refreshData,
    pagination
  } = props

  if (pagination === true && typeof maxPerPage !== "number") {
    throw new Error("Please include a maxPerPage prop.")
  }
  const [tabIndex, setTabIndex] = React.useState(0) // will control which tab that is selected
  const [tabPage, setTabPage] = React.useState(0) // will control which tab contents are displated at a time from within the tab "Pageination."

  const request = useSalesforceRequest()
  const [loadingState, setLoadingState] = React.useState<APP_STATE>("unset")

  const tabLabels = React.useMemo(() => Object.keys(tabEndpoints), [tabEndpoints])
  const [tabPageValues, setTabPageValues] = React.useState<AppTabI>({})

  //? This will control refreshing tabs content from the "PARENT Component"
  if (refreshData.current === true) {
    setLoadingState('unset')
    refreshData.current = false
  }

  React.useEffect(() => {
    // base case for tab request. Setting loadingState to 'unset' will refresh content
    if (loadingState !== 'unset') return
    setLoadingState("loading")

    const tabLabel = tabLabels[tabIndex]

    if (tabLabel === undefined) {
      setLoadingState("error")
    }

    // here we can be certain we are matching up the two indexs by nature
    const tabRequest = request({ endpoint: tabEndpoints[tabLabel] })
    tabRequest.attemptSend().then((tabResponse) => {
      if (!tabResponse.callSent) return
      const json = tabResponse.GetParsedJson()
      if (json.hasOwnProperty(`${componentPropKey}s`)) {
        const value = json[`${componentPropKey}s`]
        if (Array.isArray(value)) {
          setTabPageValues((prev) => {
            const newState = Object.assign(prev, { [tabLabel]: value })
            return newState
          })
        }
        setLoadingState("success")
      }
    }).catch((error) => {
      console.error(error)
      setLoadingState("error")
    })

  }, [componentPropKey, loadingState, request, sortDirection, sortFunction, tabEndpoints, tabLabels, tabIndex])


  const handleTabChange = (__event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setLoadingState("unset")
    setTabPage(0)
  };

  const sortedItems = Array.isArray(tabPageValues[tabLabels[tabIndex]]) ? sortFunction(tabPageValues[tabLabels[tabIndex]], sortDirection)! : []
  const count = maxPerPage ? Math.ceil(sortedItems.length / maxPerPage) : 1
  const [sliceStart, sliceEnd] = [tabPage * maxPerPage!, Math.min(tabPage * maxPerPage! + maxPerPage!, sortedItems.length)]

  return (
    <Box className="Tabs-Container">
      <Box className="Tabs-Switch-Container">
        <Tabs
          className="Tabs-Switch"
          variant="standard"
          value={tabIndex}
          onChange={handleTabChange}
        >
          {tabLabels.map((tabLabel, tabIndex) => (<Tab className="Tab" key={`Tab_${tabLabel}_${tabIndex}`} label={tabLabel} {...a11yTabProps(tabIndex)} />))}
        </Tabs>
      </Box>
      <TabPanel key={`failed-tab_${tabIndex}`} className="Tab-Container" value={tabIndex} index={tabIndex}>
        {loadingState === 'error' &&
          <div className="ListingPageError">
            <IconButton onClick={() => null}><MuiError color="error" /></IconButton>
            <Typography color="error" textAlign='center'>{`Error loading ${tabLabels[tabIndex]} tab.`}</Typography>
          </div>
        }
        {loadingState === 'loading' &&
          <LoadingCompactCard />
        }
        {loadingState === "success"
          && sortedItems.slice(sliceStart, sliceEnd).map((slicedTab, slicedTabIndex) => {
            return (
              <div className="Limited-Sorted-Tab" key={'tab' + slicedTabIndex}>
                <Component key={`${tabLabels[tabIndex]}_${componentPropKey}_${tabPage}_${slicedTabIndex}`} {...{ [componentPropKey]: slicedTab }} />
              </div>
            )
          })
        }
        <div className="App_Tabs-Pagination-Container">
          <Pagination onChange={(__, newTabPage) => {
            setTabPage(newTabPage - 1)
          }} count={count} shape="rounded" variant="text" classes={{ ul: "App_Tabs-Pagination" }} color="primary" />
        </div>
      </TabPanel>
    </Box>
  );
}

/**
* 
* @param index Number that will be used in id
* @returns props that are shared with all tabs
*/
function a11yTabProps(index: number) {
  return {
    id: `app_tabs-${index}`,
    'aria-controls': `apptabs-tabpanel-${index}`,
  };
}