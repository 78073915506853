import * as React from "react";
import { Button, Collapse, IconButton, Stack } from "@mui/material";
import { TaskCardCollapsedDescription } from "../../StyledComponents";
import { AppTypographyBody, AppTypographyHeader } from "@components/Typography/CardItemText";
import { Expand, ExpandLess, ExpandMore } from "@mui/icons-material";

export default function DescriptionRow(props: {
  handleClick: (__event: any) => boolean;
  description?: string;
}) {
  const [collapsedDescription, setCollapseDescription] = React.useState(
    typeof props.description === 'string'
    && (props.description.length - 120) < 0
  );
  if (!props.description) return null;
  const showButton = (props.description.length - 120) > 4
  return (
    <Stack justifyContent='flex-start' width={1} mb={showButton ? 0 : 1}>
      <Stack>
        <AppTypographyHeader header="Description" />
        {
          !collapsedDescription && (
            <TaskCardCollapsedDescription className="AppTypographyBody">
              {props.description}
            </TaskCardCollapsedDescription>
          )
        }
        <Collapse in={collapsedDescription} collapsedSize='10%'>
          {collapsedDescription && <AppTypographyBody text={props.description} />}
        </Collapse>
      </Stack>
      {showButton &&
        <Stack direction='row' justifyContent='center' >
          <IconButton size="small" onClick={() => { setCollapseDescription(prev => !prev) }}>
            {!collapsedDescription ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </Stack>
      }
    </Stack>
  );
}

