import { initializeApp } from "@firebase/app";
import { Auth, browserLocalPersistence, getAuth } from "@firebase/auth";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore, Firestore } from "@firebase/firestore";
import { firebase_api } from "..";

const FirebaseAppConfig = initializeApp({
  ...firebase_api,
});

export let firestore: Firestore;
export let auth: Auth;

firestore = getFirestore(FirebaseAppConfig);
auth = getAuth(FirebaseAppConfig);
auth.setPersistence(browserLocalPersistence);

// export const analytics = getAnalytics(FirebaseAppConfig);

export default FirebaseAppConfig;
