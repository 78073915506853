import { TaskStatus } from "../../Strings/TaskStatus";

export const PriorityOptions = ["URGENT", "High", "Normal", "Low", "Lowest"];
export const taskTypeOptions = [
  "Maintenance",
  "Drop",
  "Pickup",
  "Clean",
  "Inspection",
  "Shuttle Service",
];

export const TaskStatusOptions = Object.values(TaskStatus).map((v) => v);

export const TaskShuttleNumPersonsOptions = ["0", "1", "2", "3", "4", "5", "6"];

export const ShuttleLocationOption = [
  "Honua Kai - Hokulani Tower",
  "Honua Kai - Konea Tower",
  "Honua Kai - Luana Gardens",
  "Kaanapali - Resort Golf Courses",
  "Kaanapali - The Alii",
  "Kaanapali - The Whaler",
  "Kaanapali - Whalers Village",
  "Kahului Airport",
  "Kapalua - Bay Course",
  "Kapalua - Plantation Course",
  "Kapalua - Village Center",
  "Lahaina - Banyan Tree",
  "Lahaina - Cannery Mall",
  "Lahaina - Outlets of Maui",
  "Lahaina - Puunoa Beach Estates",
  "Maui Resort Rentals - North",
  "Maui Resort Rentals - South",
  "The Mahana",
];

export const dropOptions = [
  "Bedding",
  "Consumables",
  "Inventory",
  "Rentals",
  "Towels",
];
export const pickupOptions = [
  "Bedding",
  "Consumables",
  "Inventory",
  "Rentals",
  "Towels",
];
export const maintenanceOptions = [
  "Installation",
  "Paint",
  "Parts",
  "Patch & Paint",
  "Repair",
  "Replacement",
  "Service",
  "Other",
];
export const cleanOptions = [
  "Carpet Encapsulation",
  "Carpet Shampooing",
  "Drop & Strip",
  "Deep Clean",
  "Departure Clean",
  "Drapery Cleaning",
  "Full Clean",
  "Glass & Lanai",
  "Glass Restoration",
  "Grill Cleaning",
  "Grout Cleaning",
  "Hood Cleaning",
  "Interior Tile Cleaning",
  "Lanai Clean",
  "Lanai Tile Cleaning",
  "Linens & Towels",
  "Linens",
  "Tidy",
  "Towels",
  "Upholstery Cleaning",
  "Other",
];
export const cleaningsTabFilterOptions = [
  "Deep Clean",
  "Departure Clean",
  "Full Clean",
  "Lanai Clean",
  "Linens & Towels",
  "Linens",
  "Tidy",
  "Towels",
  "Other",
];
export const specialProjectsTabFilterOptions = [
  "Carpet Encapsulation",
  "Carpet Shampooing",
  "Drapery Cleaning",
  "Glass Restoration",
  "Grill Cleaning",
  "Grout Cleaning",
  "Hood Cleaning",
  "Interior Tile Cleaning",
  "Lanai Tile Cleaning",
  "Upholstery Cleaning",
];

export const inspectionOptions = ["Maintenance", "Quality Assurance", "Final"];

export const shuttleServiceOptions = ["Guest", "Owner", "Other"];

export const taskFormSubtypeOptions = (key: string) => {
  switch (key) {
    case "Drop":
      return dropOptions;
    case "Maintenance":
      return maintenanceOptions;
    case "Clean":
      return cleanOptions;
    case "Pickup":
      return pickupOptions;
    case "Shuttle Service":
      return shuttleServiceOptions;
    default:
      return maintenanceOptions;
  }
};
