import * as redux from "redux";
import { SalesforceRequest } from "@request/Salesforce/SalesforceRequest";
import { SalesforceRequestAuthParams } from "@request/Salesforce/SalesforceRequestAuthParams";
import { SalesforceRequestParams } from "@request/Salesforce/SalesforceRequestParams";
import Store from "../..";
import { captureException } from "@sentry/browser";
import User from "@classes/User";
const verboseLogging = false;
//** <--------- Enums Start   ------------->
enum PersistentObjectAction {
  REMOVE_ALL = "persistentObject/removeAllPersistentObjects",
  WRITE_TO_STORE_ALL = "persistentObject/writeToStoreAll",
  WRITE_TO_STORE_CATEGORY = "persistentObject/writeToStoreCategory",
}
export enum PersistentObject {
  listing = "listing",
  queue = "queue",
  user = "user",
}
export enum ApiUserList {
  activeUsers = "activeUsers",
  housekeeping = "housekeeping",
  housemen = "housemen",
  housekeepers = "housekeepers",
  engineering = "engineering",
  shuttleDrivers = "shuttleDrivers",
  guestExperience = "guestExperience",
}
export enum ApiQueueList {
  myQueues = "myQueues",
  allQueues = "allQueues",
}
export enum ApiListingList {
  activeListings = "activeListings",
}
//** <--------- Enums End   ------------->
//** <--------- Iterables Start   ------------->
const persistentObjectKeys = [
  PersistentObject.listing,
  PersistentObject.queue,
  PersistentObject.user,
] as const;
const actionsArray = [
  PersistentObjectAction.REMOVE_ALL,
  PersistentObjectAction.WRITE_TO_STORE_ALL,
  PersistentObjectAction.WRITE_TO_STORE_CATEGORY,
] as const;
//** <--------- Iterables End     ------------->
//** <--------- Types Start ------------->
type PersistentObjectReducerAction = (typeof actionsArray)[number];
type subCategoryType = ApiUserList | ApiListingList | ApiQueueList;
//** <--------- Types End   ------------->
const fetchEndpointURLs: Map<PersistentObject, string> = new Map();

for (const k in persistentObjectKeys) {
  const persistentObjectKey = persistentObjectKeys[k];
  switch (persistentObjectKey) {
    case PersistentObject.listing:
      fetchEndpointURLs.set(persistentObjectKey, `/api/listing?`);
      break;
    case PersistentObject.queue:
      fetchEndpointURLs.set(persistentObjectKey, `/api/group?`);
      break;
    case PersistentObject.user:
      fetchEndpointURLs.set(persistentObjectKey, `/api/user?`);
      break;

    default: {
      throw new Error("unknown persistent object key: " + persistentObjectKey);
    }
  }
}

export class ApiPersistentObjectStore {
  private static s_runninId = 0;
  private readonly _instanceId = ApiPersistentObjectStore.s_runninId++;
  private _timestamp: number = 0;
  // If ApiPersistentObjectStore.has === false then we have not yet attempted to fetch
  constructor(
    private readonly _userList: Map<ApiUserList, ApiPersistentObjectList>,
    private readonly _queue: Map<ApiQueueList, ApiPersistentObjectList>,
    private readonly _listing: Map<ApiListingList, ApiPersistentObjectList>
  ) {
    verboseLogging &&
      console.log("%cinstantiating ApiPersistentObjectStore", "color:#5994c5");
  }

  static validateJson(json: any) {
    if (!Array.isArray(json)) {
      throw new Error("Invalid json is not of type array.");
    }
  }

  setTimeStamp(time: number) {
    if (time < 0) {
      throw new Error("Invalid time stamp " + time);
    }
    this._timestamp = time;
  }

  createDeepCopy() {
    const userList = new Map<ApiUserList, ApiPersistentObjectList>();
    const queue = new Map<ApiQueueList, ApiPersistentObjectList>();
    const listing = new Map<ApiListingList, ApiPersistentObjectList>();

    this._userList.forEach((v, k) => {
      userList.set(k, v);
    });
    this._queue.forEach((v, k) => {
      queue.set(k, v);
    });
    this._listing.forEach((v, k) => {
      listing.set(k, v);
    });
    const clonedApiObjectedStore = new ApiPersistentObjectStore(
      userList,
      queue,
      listing
    );
    verboseLogging &&
      console.log(
        "%cSetting timestamp on clone to",
        "color:#5994c5",
        this._timestamp
      );
    clonedApiObjectedStore.setTimeStamp(this._timestamp);
    return clonedApiObjectedStore;
  }

  getUserList(subCategory: ApiUserList) {
    return this._getBySubCategory(
      PersistentObject.user,
      subCategory
    ) as ApiUser[];
  }
  //TYPES NEED UPDATING
  getListing(subCategory: ApiListingList) {
    return this._getBySubCategory(
      PersistentObject.listing,
      subCategory
    ) as ApiListing[];
  }
  getQueue(subCategory: ApiQueueList) {
    return this._getBySubCategory(
      PersistentObject.queue,
      subCategory
    ) as ApiQueue[];
  }
  private _getBySubCategory(
    category: PersistentObject,
    subCategory: ApiUserList | ApiQueueList | ApiListingList
  ) {
    let categoryMap: Map<
      ApiUserList | ApiQueueList | ApiListingList,
      ApiPersistentObjectList
    >;
    switch (category) {
      case PersistentObject.user:
        categoryMap = this._userList;
        break;
      case PersistentObject.listing:
        categoryMap = this._listing;
        break;
      case PersistentObject.queue:
        categoryMap = this._queue;
        break;
      default:
        throw new Error("Incorrect category to map.");
    }
    if (!categoryMap.has(subCategory)) {
      throw new Error(
        "Attempting to get subCategory that does not exist " + subCategory
      );
    }

    const persistentObjectList = categoryMap.get(subCategory);
    if (persistentObjectList === undefined) {
      throw new Error("Could not identify persistent object list");
    }
    if (Array.isArray(persistentObjectList.list)) {
      switch (category) {
        case PersistentObject.user:
          return persistentObjectList.list as ApiUser[];
        case PersistentObject.listing:
          return persistentObjectList.list as ApiListing[];
        case PersistentObject.queue:
          return persistentObjectList.list as ApiQueue[];
        default:
          throw new Error("Incorrect category on return.");
      }
    }
    throw new Error("Corrupted list object.");
  }
  checkUserList(subCategory: ApiUserList) {
    return this._userList.has(subCategory);
  }
  checkQueues(subCategory: ApiQueueList) {
    return this._queue.has(subCategory);
  }
  checkListings(subCategory: ApiListingList) {
    return this._listing.has(subCategory);
  }

  updateCategory(
    categoryToUpdate: PersistentObject,
    subCategory: subCategoryType,
    json: any
  ) {
    ApiPersistentObjectStore.validateJson(json);
    const currentTime = Date.now();
    this.setTimeStamp(currentTime);
    switch (categoryToUpdate) {
      case PersistentObject.user:
        subCategory = subCategory as ApiUserList;
        this._userList.set(
          subCategory,
          new ApiPersistentObjectList(json, currentTime, false)
        );
        return;
      case PersistentObject.listing:
        subCategory = subCategory as ApiListingList;
        this._listing.set(
          subCategory,
          new ApiPersistentObjectList(json, currentTime, false)
        );
        return;
      case PersistentObject.queue:
        subCategory = subCategory as ApiQueueList;
        this._queue.set(
          subCategory,
          new ApiPersistentObjectList(json, currentTime, false)
        );
        return;
      default:
        throw new Error(`Invalid category ${categoryToUpdate}`);
    }
  }
}
class ApiPersistentObjectList {
  constructor(
    readonly list: ApiUser[] | ApiQueue[] | ApiListing[],
    readonly timestamp: number,
    readonly error: boolean
  ) {
    verboseLogging && console.log("instantiating ApiPersistentObject");
  }
}

const INITIALSTATE: ApiPersistentObjectStore = new ApiPersistentObjectStore(
  new Map<ApiUserList, ApiPersistentObjectList>(),
  new Map<ApiQueueList, ApiPersistentObjectList>(),
  new Map<ApiListingList, ApiPersistentObjectList>()
);

export const removeAllPersistentObjects = () => ({
  type: PersistentObjectAction.REMOVE_ALL,
});

const writeToStoreCategory = (
  newData: any,
  category: PersistentObject,
  subCategory: string
) => {
  return {
    type: PersistentObjectAction.WRITE_TO_STORE_CATEGORY,
    newData,
    category,
    subCategory,
  };
};

//NOTE: This gets called at startup.
export const updatePersistentObjects = (
  category: PersistentObject,
  subCategory: string
) => {
  verboseLogging &&
    console.log(
      "producing a fetch callback for persistent objects ",
      category,
      subCategory
    );
  return async (dispatch: redux.Dispatch) => {
    verboseLogging && console.log("persistent objects fetch valid");

    //NOTE: Lift this out of the try so that we can use it in the catch.
    let fetchPersistentObjectsRequest!: SalesforceRequest;
    try {
      const currentUserData = await Store.getState().session.user;
      if (currentUserData === null) return;
      const currentUser = new User(currentUserData);
      const sfRequestAuthParams = new SalesforceRequestAuthParams(
        currentUser.getAccess_Token(),
        currentUser.getRefresh_Token(),
        currentUser.email,
        currentUser.sf_issued_at,
        currentUser.username
      );
      const usersFetchRequest = new SalesforceRequestParams(
        sfRequestAuthParams,
        {
          endpoint:
            (fetchEndpointURLs.get(category) as string) +
            `action=${subCategory}`,
        }
      );

      fetchPersistentObjectsRequest = new SalesforceRequest(usersFetchRequest);
      const result = await fetchPersistentObjectsRequest.attemptSend();
      if (!result.callSent) {
        return;
      } // session expired
      //NOTE: TypeScript can't tell, but we have confirmed result.httpResponse is a Response.
      const json = result.GetParsedJson();

      if (json.status !== 200) {
        throw new Error("unsuccessful fetch active users: " + json.status);
      }

      //! IMPORTANT: If you are crashing here on 'group does not exist', ensure you _check_ the
      //             for the group before using it, e.g. check group.lenth === 0.
      //             These are created on demand, so the array/map might not be there.
      //
      //    EXAMPLE: This is the correct usage:
      //
      //          if (!persistentObject.checkUserList(queryString)) {
      //            return <ThisGroupDoesNotExist />;
      //          }
      //
      //          if (persistentObject.getUserList(queryString).length === 0) {
      //            return <ThisGroupDoesNotHaveAnyMembers />
      //          }

      switch (category) {
        case PersistentObject.user:
          return dispatch(
            writeToStoreCategory(json.users, category, subCategory)
          );
        case PersistentObject.listing:
          return dispatch(
            writeToStoreCategory(json.listings, category, subCategory)
          );
        case PersistentObject.queue:
          return dispatch(
            writeToStoreCategory(json.queues, category, subCategory)
          );
        default:
          throw new Error(
            "Unsuccessful dispatch in persistent object reducer."
          );
      }
    } catch (error) {
      console.error(error);
      captureException(error);

      //NOTE: We rethrow, so the components listening can show a 'Call failed!' error message.
      throw error;
    }
  };
};

// In the instance of login set store | update one list
const PersistentObjectReducer = (
  state = INITIALSTATE,
  action: {
    type: PersistentObjectReducerAction;
    newData: any;
    category: PersistentObject;
    subCategory: ApiUserList;
  }
) => {
  switch (action.type) {
    case PersistentObjectAction.WRITE_TO_STORE_ALL:
      throw new Error("Not yet implemented");
    case PersistentObjectAction.WRITE_TO_STORE_CATEGORY:
      const newPersistentObjectStore = state.createDeepCopy();
      newPersistentObjectStore.updateCategory(
        action.category,
        action.subCategory,
        action.newData
      );
      return newPersistentObjectStore;
    case PersistentObjectAction.REMOVE_ALL:
      return INITIALSTATE;
    default:
      return state;
  }
};

export default PersistentObjectReducer;
