import { Reducer, AnyAction } from "redux";

const SET_APP_STATE = "appState/setAppState";
const REMOVE_APP_STATE = "appState/removeAppState";
const UPDATE_APP_STATE = "appState/updateAppState";

export interface AppState {
  user: USER_STATE;
  authError: string | null;
}
export const INITIALAPPSTATE: AppState = {
  user: "loggedOut",
  authError: null,
};

export const setAppState = (state: Partial<AppState>) => ({
  type: SET_APP_STATE,
  state,
});

export const removeAppState = (incomingState = INITIALAPPSTATE) => {
  return {
    type: REMOVE_APP_STATE,
    incomingState,
  };
};

export const updateAppState = (updatedAppState: Partial<AppState>) => {
  return {
    type: UPDATE_APP_STATE,
    updatedAppState,
  };
};

const appStateReducer: Reducer = (state: AppState, action?: AnyAction) => {
  switch (action?.type) {
    case SET_APP_STATE:
      return action.state;
    case UPDATE_APP_STATE:
      const clonedState = Object.assign({}, state);
      const updateAppState = Object.assign(clonedState, action.updatedAppState);
      return updateAppState;
    case REMOVE_APP_STATE:
      const removedAppState = {
        user: "loggedOut",
        authError: null,
      };
      return removedAppState;
    default:
      return state ?? INITIALAPPSTATE;
  }
};

export default appStateReducer;
