import { RootState } from "../../Store";
import { useSelector } from "react-redux";
import { ApiPersistentObjectStore } from "../../Store/Reducers/PersistentObject/index";

const verboseLogging = false;

const persistentObjectSelector = ({ persistentObject }: RootState) =>
  persistentObject;

export default function usePersistentObject() {
  const persistentObject: ApiPersistentObjectStore = useSelector(
    persistentObjectSelector
  );
  verboseLogging &&
    console.log("%cPersistent Object", "color: limegreen", persistentObject);
  return persistentObject;
}
