import * as React from "react";
import { createSelectableQueues, createSelectableUserObjects } from "@helpers/createSelectableObjects";
import { ApiQueueList, ApiUserList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";

export default function useTaskAssignees(valueSameAsLabel?: boolean) {
  const dispatch = useAppDispatch()
  const persistentObject = usePersistentObject()
  const [payload, setPayload] = React.useState<any[] | null>(null)

  React.useEffect(() => {
    if (!persistentObject.checkUserList(ApiUserList.activeUsers)) {
      dispatch(updatePersistentObjects(PersistentObject.user, ApiUserList.activeUsers))
    }
    if (!persistentObject.checkQueues(ApiQueueList.allQueues)) {
      dispatch(updatePersistentObjects(PersistentObject.queue, ApiQueueList.allQueues))
    }
  }, [dispatch, persistentObject])

  const queues = React.useMemo(() => {
    if (persistentObject.checkQueues(ApiQueueList.allQueues)) {
      return persistentObject.getQueue(ApiQueueList.allQueues)
    }
    return null
  }, [persistentObject])
  const users = React.useMemo(() => {
    if (persistentObject.checkUserList(ApiUserList.activeUsers)) {
      return persistentObject.getUserList(ApiUserList.activeUsers)
    }
    return null
  }, [persistentObject])

  React.useEffect(() => {
    if (users === null || queues === null) return
    const sortedResult = [
      ...createSelectableUserObjects(users, valueSameAsLabel).sort((item1, item2) => item1.label > item2.label ? 1 : item1.label < item2.label ? -1 : 0),
      ...createSelectableQueues(queues, valueSameAsLabel).sort((item1, item2) => item1.label > item2.label ? 1 : item1.label < item2.label ? -1 : 0)
    ];
    setPayload(sortedResult)
  }, [queues, users])

  return payload
}
