import { AppTypographyBody } from "@components/Typography/CardItemText";
import { format } from "date-fns";
import { ApiTask } from "../../../../../Types/task";

export default function DateFooter({ curTask, disableBorder }: { curTask: ApiTask | null; disableBorder?: boolean }) {
  if (!curTask) return null;
  disableBorder ??= false
  return (
    <div className={`Task-Card-Item-Container ${!disableBorder && 'Task-Card-Item-Border'} Task-Card-Date-Footer`}>
      <div className="Task-Card-Row">
        {curTask?.created_date &&
          <span>
            <AppTypographyBody textProps={{ fontSize: "small" }} text={"Created " + format(new Date(curTask.created_date), "PPP, p")} />
          </span>
        }
        {curTask?.last_modified_date &&
          <span>
            <AppTypographyBody textProps={{ fontSize: "small" }} text={"Updated " + format(new Date(curTask.last_modified_date), "PPP, p")} />
          </span>
        }
      </div>
    </div>
  );
}
