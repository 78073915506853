import * as React from "react";
import { auth, firestore } from "./app";
import { getDoc, doc } from "firebase/firestore";
import useProfile from "@hooks/Selectors/useProfile";
import fetchUserFromApex from "../../Components/Auth/SalesforceOauth/Callback/userFetch";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import { readUserSettings, setUser } from "../../Store/Reducers/Session";
import User from "@classes/User";
import { updateAppState } from "../../Store/Reducers/AppState";
import { SignUpAuthUserData } from "../../Context/Auth";
import { SnackBarData } from "../../Context/Snackbar";
import { ThemeToggle } from "../../Context/Theme";
import useAppState from "@hooks/Selectors/useAppState";
import usePersist from "@hooks/Selectors/usePersist";
import { Unsubscribe, User as FirebaseUser } from "@firebase/auth";
import { captureException } from "@sentry/browser";
import {
  ApiListingList,
  ApiQueueList,
  ApiUserList,
  PersistentObject,
  updatePersistentObjects,
} from "@store/Reducers/PersistentObject";
import { checkLSReauthHold } from "@request/Salesforce/SalesforceRequest";

async function getFirebaseUserRecord(firebaseUser: FirebaseUser) {
  console.log("getting firebase user record for email: " + firebaseUser.email);
  try {
    if (!firebaseUser.email || !firestore) {
      throw new Error(
        `Problem in getFirebaseUserRecord email: ${firebaseUser.email}`
      );
    }
    const userRef = doc(firestore, "Users", firebaseUser.email);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      return userDoc.data();
    }

    //In this case there is a user in Firebase Auth but no record in FireStore.
    //We want to delete this user from auth so that they can resign up.
    await firebaseUser.delete();

    throw Error("Failed to get user record from firestore");
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

const CONSOLE_COLOR = "color:#f88";
const verboseLogging = false;

let firebaseInitialized = false;
let userFetchPending = false;

const useOnAuthStateChange = () => {
  const randomIdForReactHit = React.useId();
  const persist = usePersist();
  const dispatch = useAppDispatch();
  const [, setSnackData] = React.useContext(SnackBarData);
  const user = useProfile();
  const userState = useAppState("user");
  const toggleTheme = React.useContext(ThemeToggle);
  const [firestoreUserData, setFirestoreUserData] =
    React.useContext(SignUpAuthUserData);

  let unsubscribe: Unsubscribe | null = null;

  // this 'unique', random tag lets us parse out what each React pass is doing
  const reactPassTag = "auth-pass-" + randomIdForReactHit;

  verboseLogging &&
    console.log(`%c${reactPassTag}`, "color:orange", "hit onAuthStateChange");

  // initialize the firebase listener, one time only
  if (firestoreUserData === null) {
    if (firebaseInitialized) {
      verboseLogging &&
        console.log(
          `%c${reactPassTag}`,
          "color:orange",
          "ignoring, firebase already initialized."
        );
      return unsubscribe;
    }

    verboseLogging &&
      console.log(
        `%c${reactPassTag}`,
        "color:orange",
        "attempting firebase initialization."
      );

    firebaseInitialized = true;

    unsubscribe = auth.onAuthStateChanged(
      (firebaseUser) => {
        if (!firebaseUser) {
          //In the case of no firebase user
          verboseLogging &&
            console.log(
              `%c${reactPassTag}`,
              "color:orange",
              "Firestore reported no user session.",
              user
            );
          //NOTE: We now enforce a strict (User | null) type on the user model.

          //TODO: This will probably move into helper on a to-be-written SessionManager. It will look
          //      something like session.Logout() or session.CleanupExpired().

          dispatch(setUser(null));
          dispatch(updateAppState({ user: "loggedOut", authError: null }));

          return;
        }
        if (!firebaseUser.email) {
          throw new Error("No email on firebase user response.");
        }

        verboseLogging &&
          console.log(
            `%c${reactPassTag}`,
            "color:orange",
            "Firebase User logged in onAuthStateChange",
            firebaseUser
          );

        //NOTE: When refresh tokens are invalid, and the user and firebase are still in LS, we need to abort here.
        //			If we went through with this logic (firebase pulls down clean), the app would log the user back in
        //			before the Oauth flow. They would be in a death spiral, unable to ever get valid tokens.
        //			But! It carries risk. If we _don't_ clear it, the user could be stuck on a given device, unable to
        //			ever log in. This would be the scenario:
        //				- user's SF tokens become invalid.
        //				- user fails to reauth (for whatever reason), after we've set this LS flag.
        //				- user then loses their Firebase session (for whatever reason).
        //				- From this point, the user will not be able to log in until this LS flag is cleared.
        //
        //			These are our solutions to that problem:
        //				- Clear this flag on a successful re-auth (or signup; any time Oauth2 is completed).
        //				- Clear this flag on any click of the login or change/reset pwd (in the AuthRoutes bottleneck).
        //				- Give the flag an expiration (say 1 hour), after which we let the user log back in,
        //					potentially with bad SF tokens, in which case they'd start over w/ the reauth flow.

        if (checkLSReauthHold()) {
          // step aside for reauth; the user will be automatically logged back in afterward
          console.log(
            "dropping out of auth state change due to rejected SF tokens"
          );
          return;
        }

        dispatch(updateAppState({ user: "loading" }));
        getFirebaseUserRecord(firebaseUser)
          .then(async (data) => {
            verboseLogging &&
              console.log(
                `%c${reactPassTag}`,
                "color:orange",
                "received firestore data for " + firebaseUser.email,
                data
              );
            if (!data) {
              throw new Error("Bad data received from firestore!");
            }
            if (data.sf_access_token) {
              verboseLogging &&
                console.log(
                  `%c${reactPassTag}`,
                  "color:orange",
                  "setting firestoreUserData context"
                );

              setFirestoreUserData({ ...data, email: firebaseUser.email });
              if (data.user_settings) {
                toggleTheme(data.user_settings.dark_mode ? "dark" : "light", {
                  sync: false,
                });
              }

              // note that this (^^) state setter is async; we can't use firestoreUserDate just yet
            } else {
              verboseLogging &&
                console.log(
                  `%c${reactPassTag}`,
                  "color:orange",
                  "Firestore user data missing salesforce access token."
                );
              dispatch(
                updateAppState({
                  user: "loggedOut",
                  authError: "Invalid user document.",
                })
              );
            }
          })
          .catch((e) => {
            console.log(
              `%c${reactPassTag}`,
              CONSOLE_COLOR,
              "Failed to gathered firestore data",
              e
            );

            // In this case we could not retrive a firestore record. Perhaps becasue user is signing out.
            dispatch(
              updateAppState({
                user: "error",
                authError: "Failed to get user document.",
              })
            );

            captureException(e);
          });
      },
      (error) => {
        console.error("onAuthStateChange", error);
      }
    );
  }

  if (
    !firestoreUserData ||
    !firestoreUserData.sf_user_id ||
    !firestoreUserData.sf_refresh_token ||
    !firestoreUserData.sf_access_token ||
    !firestoreUserData.email ||
    !firestoreUserData.sf_username
  ) {
    verboseLogging &&
      console.log(
        `%c${reactPassTag}`,
        "color:orange",
        "Waiting on firestore user."
      );
    return unsubscribe;
  }

  if (persist.rehydrated !== true) {
    verboseLogging &&
      console.log(
        `%c${reactPassTag}`,
        "color:orange",
        "Auth waiting on presist rehydrate."
      );
    return unsubscribe;
  }

  if (user !== null || userState === "signedIn") {
    verboseLogging &&
      console.log(
        `%c${reactPassTag}`,
        "color:orange",
        "User already signed in."
      );
    // pure sanity check
    if (user !== null && !user.id) {
      throw new Error(
        "Bad user model in onAuthStateChange; id is missing/empty"
      );
    }

    if (userState !== "signedIn") {
      verboseLogging &&
        console.log(
          `%c${reactPassTag}`,
          "color:orange" +
            " Overwriting userState: '" +
            userState +
            "' with 'signedIn'"
        );
    }
    dispatch(
      updateAppState({
        user: "signedIn",
        authError: null,
      })
    );
    return unsubscribe;
  }

  if (userState === "loggedOut") {
    verboseLogging &&
      console.log(`%c${reactPassTag}`, "color:orange", "User logged out.");
    return unsubscribe;
  }

  if (userFetchPending) {
    verboseLogging &&
      console.log(
        `%c${reactPassTag}`,
        "color:orange",
        "User fetch pending; early out"
      );
    return unsubscribe;
  }

  userFetchPending = true;

  verboseLogging &&
    console.log(
      `%c${reactPassTag}`,
      "color:orange",
      "Sending user fetch",
      firestoreUserData
    );

  fetchUserFromApex(
    `/api/user/${firestoreUserData.sf_user_id}`,
    firestoreUserData.sf_access_token,
    firestoreUserData.sf_refresh_token,
    firestoreUserData.email,
    firestoreUserData.sf_issued_at,
    firestoreUserData.sf_username
  )
    .then(async (fetchedUser: any) => {
      verboseLogging &&
        console.log(
          "received userFetch response",
          fetchedUser,
          firestoreUserData
        );

      if (fetchedUser === undefined) {
        throw new Error("user record missing");
      }

      if (fetchedUser.sf_access_token !== undefined) {
        verboseLogging &&
          console.log(
            "the user appears to have an updated SF access token",
            CONSOLE_COLOR
          );
      }

      //NOTE: The order of these spreads is important! If fetchedUser has updated fields, we want them to overwrite
      //      the same fields on firestoreUserData.
      const newUser = new User({
        ...firestoreUserData,
        ...fetchedUser,
      });

      verboseLogging &&
        console.log(
          `%c${reactPassTag}`,
          "color:orange",
          "cleaning up firestore user data."
        );

      setFirestoreUserData(null);

      newUser.setProvider("firebase");
      if (newUser.getAccess_Token() === undefined) {
        throw new Error("missing access token");
      }
      if (newUser.id) {
        await dispatch(setUser(newUser));
        await Promise.allSettled([
          dispatch(
            updatePersistentObjects(
              PersistentObject.user,
              ApiUserList.activeUsers
            )
          ),
          dispatch(
            updatePersistentObjects(
              PersistentObject.listing,
              ApiListingList.activeListings
            )
          ),
          dispatch(
            updatePersistentObjects(
              PersistentObject.queue,
              ApiQueueList.allQueues
            )
          ),
          dispatch(
            updatePersistentObjects(
              PersistentObject.queue,
              ApiQueueList.myQueues
            )
          ),
        ]);
        await dispatch(readUserSettings(newUser));
        dispatch(
          updateAppState({
            user: "signedIn",
            authError: null,
          })
        );
        return;
      }

      throw new Error("invalid user record");
    })
    .catch(async (e) => {
      console.error(e);

      let errorMessage = "Error on sign-in";

      if (typeof e === "string" && e !== "") {
        errorMessage += " (" + e + ")";
      }

      setSnackData({
        message: errorMessage,
        severity: "error",
        color: "error",
      });

      dispatch(
        updateAppState({
          user: "error",
          authError: errorMessage,
        })
      );

      setFirestoreUserData(null);

      // send error to sentry
      captureException(e);
    })
    .finally(() => {
      userFetchPending = false;
    });

  return unsubscribe;
};

export default useOnAuthStateChange;
