import * as React from 'react'
import { SnackBarData } from '@context/Snackbar';
import useSalesforceRequest, { useSalesforceRequestHookParams } from '@request/Salesforce';

const verboseLogging = true

export type PreparedAttachmentType = {
  meta: ApiAttachment;
  value: string;
}

type SetPreparedAttachmentType = React.Dispatch<React.SetStateAction<{
  meta: ApiAttachment;
  value: string;
}[]>>

type GetTaskAttachmentsType = (fetchedAttachmentMeta: any) => Promise<void | {
  meta: ApiAttachment;
  value: unknown;
}>

export const TaskAttachmentsDelayedAttachments = React.createContext<
  [
    a: PreparedAttachmentType[],
    b: SetPreparedAttachmentType
  ]
>([] as any)
export const TaskAttachmentContentRequest = React.createContext<GetTaskAttachmentsType>(async () => undefined)
export const TaskAttachmentContentPostRequest = React.createContext<(file: any, taskId?: string) => Promise<any>>(async (a: any) => Promise.reject("Function not yet defined."))
export const FileManagerWorking = React.createContext<{ fileManagerWorking: boolean, setFileManagerWorking: (string: 'on' | 'off') => void }>({ fileManagerWorking: false, setFileManagerWorking: () => undefined })
export const ClearFileInput = React.createContext<() => void>(() => null)
export const RemoveDelayedAttachment = React.createContext<(a?: string) => void>(() => null)

const ErrorResponse = new Response("Missing required fields", {
  status: 500,
  statusText: 'ERROR',
})

/**
 * @provides TaskAttachmentsMeta will be all the meta data of every attachment for a specific task id <GET Attachments Meta>
 * @provides TaskAttachmentContentRequest is an async function that has already been binded with the logged in user. <GET Attachments>
 * @provides ClearTaskAttachments this will clear out our attachments array
 */
export default function AttachmentsProvider({ children }: React.PropsWithChildren) {
  const [, setSnackData] = React.useContext(SnackBarData)
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const [numberOfPostFileManagerIsCurrentlyStillProcessing, setNumberOfPostFileManagerIsCurrentlyStillProcessing] = React.useState(0)
  const [delayedAttachments, setDelayedAttachments] = React.useState<PreparedAttachmentType[]>([])
  const fileManagerWorking = React.useMemo(() => { return numberOfPostFileManagerIsCurrentlyStillProcessing !== 0 }, [numberOfPostFileManagerIsCurrentlyStillProcessing])
  const attachmentsRequestToSalesforce = useSalesforceRequest()
  const clearFileInput = React.useCallback(() => {
    // this function is used to clean up our input
    if (fileInputRef.current) {
      fileInputRef.current.files = null
    }

  }, [fileInputRef])


  const postTaskAttachmentsContent = React.useCallback(async (file: PreparedAttachmentType, taskId?: string) => {
    if (file.meta?.name === undefined) return ErrorResponse
    verboseLogging && console.log("file", file, taskId)
    if (taskId) {
      setNumberOfPostFileManagerIsCurrentlyStillProcessing(prev => prev + 1)
      const params: useSalesforceRequestHookParams = {
        endpoint: `/api/attachment/${taskId}?filename=${file.meta.name}`,
        method: "POST",
        body: file.meta || file,
        contentType: file.meta.type
      }
      const postAttachmentContent = attachmentsRequestToSalesforce(params)
      const result = await postAttachmentContent.attemptSend()
      if (!result.callSent) return ErrorResponse
      if (result.httpResponse?.status === 200) {
        await result.AttachJson()
        const json = result.GetParsedJson()
        if (json.hasOwnProperty("attachment")) {
          setNumberOfPostFileManagerIsCurrentlyStillProcessing(prev => prev - 1)
          return json.attachment
        }
        setNumberOfPostFileManagerIsCurrentlyStillProcessing(prev => prev - 1)
        return result
      } else {
        setSnackData({
          message: `Failed to attach ${file.meta.name}`,
          severity: "error"
        })
        setNumberOfPostFileManagerIsCurrentlyStillProcessing((prev) => prev - 1)
        return ErrorResponse
      }

    } else {
      // in this case attachments have been add but the task to attach them too has yet to be created
      verboseLogging && console.log("Setting delayed Attachments array")
      setDelayedAttachments((otherDelayedAttachments) => [...otherDelayedAttachments, file])
    }

    return file
  }, [attachmentsRequestToSalesforce, setSnackData])


  const removeDelayedAttachment = (fileName?: string) => {
    if (!fileName) return
    setDelayedAttachments((delayedAttachmentsArray) => {
      return delayedAttachmentsArray.filter(({ meta }) => meta.name !== fileName)
    })
  }

  const setFileManagerWorking = (setting: "on" | "off") => {
    if (setting === 'on') {
      setNumberOfPostFileManagerIsCurrentlyStillProcessing(prev => prev + 1)
    }
    if (setting === 'off') {
      setNumberOfPostFileManagerIsCurrentlyStillProcessing(prev => prev - 1)
    }
  }



  return (
    <TaskAttachmentsDelayedAttachments.Provider value={[delayedAttachments, setDelayedAttachments]}>
      <TaskAttachmentContentPostRequest.Provider value={postTaskAttachmentsContent}>
        <FileManagerWorking.Provider value={{ fileManagerWorking, setFileManagerWorking }}>
          <RemoveDelayedAttachment.Provider value={removeDelayedAttachment}>
            <ClearFileInput.Provider value={clearFileInput}>
              {children}
            </ClearFileInput.Provider>
          </RemoveDelayedAttachment.Provider>
        </FileManagerWorking.Provider>
      </TaskAttachmentContentPostRequest.Provider>
    </TaskAttachmentsDelayedAttachments.Provider>
  )
}

export function useGetTaskAttachments() {
  const attachmentsRequestToSalesforceForMeta = useSalesforceRequest()
  const attachmentsRequestToSalesforceForContent = useSalesforceRequest()
  /**
  * @param attachmentMetaData - single item from the return type of _getTaskAttachmentsMeta[]
  * @returns - a single attachments data for display and preview component
  */
  const getTaskAttachmentsContent = async (fetchedAttachmentMeta: ApiAttachment) => {
    try {
      const params: useSalesforceRequestHookParams = {
        endpoint: `/api/attachment/${fetchedAttachmentMeta.id}`,
        contentType: fetchedAttachmentMeta.content_type
      }
      const getAttachmentContent = attachmentsRequestToSalesforceForContent(params)
      const result = await getAttachmentContent.attemptSend()
      if (!result.callSent) {
        return
      }
      if (result.httpResponse instanceof Response && result.httpResponse.ok) {
        return {
          meta: fetchedAttachmentMeta,
          value: await result.ProcessBlob()
        }
      }
      throw new Error('Attachment HTTP response failed.')
    } catch (e: any) {
      console.warn(e)
      throw new Error(e)
    }
  }

  /**
  * @returns 0- will return a list of attachments meta data that we can now request for with _getTaskAttachmentsContent
  */
  const getTaskAttachments = async (taskId: string) => {
    if (typeof taskId !== 'string' || taskId === "") return
    const params: useSalesforceRequestHookParams = {
      method: "GET",
      endpoint: `/api/attachment/${taskId}`,
    }
    const getTaskMeta = attachmentsRequestToSalesforceForMeta(params)

    return await getTaskMeta.attemptSend().then((result) => {
      if (!result.callSent) return
      const json = result.GetParsedJson()
      if (json.status === 200) {
        return json.attachments
      }
      throw new Error("Failed to get attachments meta data.")
    }).catch((e) => {
      console.error(e)
      throw new Error(e)
    })
  }

  return { getTaskAttachments, getTaskAttachmentsContent }
}


export function useRemoveTaskAttachments() {
  const { setFileManagerWorking } = React.useContext(FileManagerWorking)
  const attachmentsRequestToSalesforce = useSalesforceRequest()
  /**
* @param attachmentId - identifier to delete task.
* @returns void - with delete actions preformed
*/
  const removeAttachmentsMeta = React.useCallback((attachmentMeta: any, setFetchedAttachmentsMeta: React.Dispatch<React.SetStateAction<any[]>>) => {
    if (!attachmentMeta) return
    setFileManagerWorking("on")
    const params: useSalesforceRequestHookParams = {
      endpoint: `/api/attachment/${attachmentMeta.id}`,
      method: "DELETE"
    }
    const deleteAttachment = attachmentsRequestToSalesforce(params)
    deleteAttachment.attemptSend().then((result) => {
      setFileManagerWorking("off")
      if (!result.callSent) return
      setFetchedAttachmentsMeta((prev: any) => prev?.filter((attachment: any) => attachment !== attachmentMeta))
    }).catch(() => {
      setFileManagerWorking("off")
    })
  }, [attachmentsRequestToSalesforce, setFileManagerWorking])

  return removeAttachmentsMeta
}