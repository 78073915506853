import * as React from "react";
import { Typography, Box } from "@mui/material";

export default function NoUsers() {
    return (
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection="column" height='80vh'>
            <Typography variant="h5" color="GrayText">Sorry, No users found.</Typography>
        </Box>
    );
}
