import * as React from 'react'

import {
  Card, Box, CardActions, IconButton,
} from '@mui/material';

import PDFCard from './PDFCard';
import ImageCard from './ImageCard';
import VideoCard from './VideoCard';
import DefaultConfirmModal from '../Task/Sub_Components/TaskCard/Footer/ConfirmModal/DefaultConfimModal'

import './styles.css'
import {
  RemoveDelayedAttachment,
  useGetTaskAttachments,
} from '@context/Attachments';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import {
  Delete,
  Error,
} from '@mui/icons-material';

export interface ImageCardProps {
  meta: ApiAttachment;
  delete?: Function;
  openPreview: (a: string) => void;
  delayed?: boolean;
  value?: string
}

interface PDFCardProps extends ImageCardProps { }

interface VideoCardProps extends ImageCardProps {
}

type ContentStates = 'loading' | 'success' | 'error' | 'fetch not started'

export default function MultiMediaCard(props: ImageCardProps | PDFCardProps | VideoCardProps) {
  const id = React.useId()
  const { meta, openPreview } = props
  const { getTaskAttachmentsContent } = useGetTaskAttachments()
  const [src, setSrc] = React.useState<string | undefined>(props?.value)
  const [loadingState, setLoadingState] = React.useState<ContentStates>("fetch not started")
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false)
  const deleteDelayedAttachment = React.useContext(RemoveDelayedAttachment)
  React.useEffect(() => {
    if (loadingState === 'fetch not started') {
      if (meta?.id !== undefined) {
        setLoadingState("loading")
        getTaskAttachmentsContent(meta).then((data: any) => {
          if (data?.value) {
            setLoadingState("success")
            setSrc(data.value)
          } else {
            setLoadingState("error")
          }
        }).catch(() => {
          setLoadingState("error")
        })
      }
    }
  }, [meta, getTaskAttachmentsContent, loadingState])
  return (
    <>
      <DefaultConfirmModal
        actionLabel={`Are you sure you want to delete ${meta.name?.slice(0, 10)}..${meta.name?.slice(meta.name.indexOf("."))}?`}
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        onConfirmed={() => {
          if (props.delayed) {
            deleteDelayedAttachment(meta.name)
          } else {
            if (props.delete) {
              props.delete()
            }
          }
        }}
      />
      <Card
        id={id}
        className={`Multi_Media-Card Multi_Media-Card-${loadingState}`}
        raised
        elevation={loadingState === "loading" ? 1 : 2}
        sx={{ marginRight: 1 }}
        aria-haspopup="true"
      >
        <Box
          className='Multi-Media-Card-Inner'
        >
          {src !== undefined && isMediaContent(src, "data:video") && (
            <VideoCard src={src} meta={meta} handleClick={() => { openPreview(src) }} />
          )}
          {src !== undefined && isMediaContent(src, 'data:image') && (
            <ImageCard src={src} meta={meta} handleClick={() => { openPreview(src) }} />
          )}
          {src !== undefined && meta?.content_type === "application/pdf" && (
            <PDFCard src={src} meta={meta} handleClick={() => { openPreview(src) }} />
          )}
          {src === undefined && loadingState !== 'error' && <HourglassBottom className='spin-me' />}
          {loadingState === 'error' && <Error color='error' />}
          {src !== undefined && <CardActions sx={{ position: "absolute", right: '2%', bottom: '2%', p: 0, m: 0 }}>
            <IconButton
              disableFocusRipple
              disableRipple
              disableTouchRipple
              color='primary'
              size='small'
              disabled={openConfirmModal}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setOpenConfirmModal(true)
              }}
              sx={{ borderRadius: "100px", backgroundColor: "rgba(255,255,255,0.4)" }}
            >
              <Delete />
            </IconButton>
          </CardActions>}
        </Box>
      </Card>
    </>
  )
}

type AcceptedMediaContent = "data:video" | "data:image" | "data:application/pdf"

function isMediaContent(src: string | undefined, contentType: AcceptedMediaContent) {
  if (typeof src !== "string" || typeof contentType !== 'string') return false

  return src.startsWith(contentType)
}
