import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../Config/Firebase/app";
import validateAndRetriveUserDoc from "./validateAndRetriveUserDoc";

const verboseLogging = false;

export async function writeMessageIdToFirestore(
  emailIdentifier: string,
  message_id: string
) {
  // throw an error if message_id is invalid
  if (typeof message_id !== "string" || message_id === "")
    throw new Error("Please provide a valid message id.");

  try {
    // parse data off of user document (from firestore)
    const existingUserData = await validateAndRetriveUserDoc(emailIdentifier);
    // If there is no user in the firestore something has gone a major rye. The user wouldn't even be able to make it to this point or there db entry is currupted.
    if (!existingUserData) throw new Error("User document does not exist.");
    // users who have logged in before Jan 31st will not have the message_ids array on their user document yet.
    // Create Ref to user in firestore
    const userRef = doc(firestore, "Users", emailIdentifier);
    if (existingUserData.message_ids) {
      const ids = new Set(existingUserData.message_ids);
      if (!ids.has(message_id)) {
        ids.add(message_id);

        const messageIdPayload = {
          ...existingUserData,
          message_ids: Array.from(ids),
        };

        verboseLogging &&
          console.log(
            "Writing message_ids to firestore with pre-existing ids."
          );
        //update firestore
        await setDoc(userRef, messageIdPayload);
        return messageIdPayload;
      }
    } else {
      // for users who have not logged in after Jan 31st will not have the message_ids array on their user document yet. So we will create one here
      const messageIdPayload = {
        ...existingUserData,
        message_ids: [message_id],
      };
      verboseLogging && console.log("Writing message_ids to firestore.");
      //update firestore
      await setDoc(userRef, messageIdPayload);
      return messageIdPayload;
    }
  } catch (e) {
    console.error(e);
    // There is really no need to return null here. Just for consistancy.
    return null;
  }
}
