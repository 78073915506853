import * as React from "react";
import { TaskControls } from "../../../../Context/Task";
import {
  TaskDrawerSubject,
} from "../../../../Context/Task/TaskDrawer";
import { useNavigate, useSearchParams } from "react-router-dom";
import startOfWeek from "date-fns/startOfWeek";
import { endOfWeek, isValid } from "date-fns";
import TaskFormModal from "@components/Task/Modals/TaskFormModal";
import TaskFilterModal from "@components/Task/Modals/TaskFilterModal";
import formatDateParam from "@helpers/formatDateParam";
import { PagenationDynamic } from "./PagenationDynamic";
import './Styles/styles.css'
import { ApiTask } from "../../../../Types/task";
const today = new Date();
today.setHours(0, 0, 0, 0);

export default function Pagenation(props: { allTask: ApiTask[], onRefresh: () => any }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openForm, setOpenForm] = React.useState(false)
  const {
    rollForward,
    rollBackward,
    rollToday,
    setFilterBy,
  } = React.useContext(TaskControls);
  const [curTask, setCurTask] = React.useContext(TaskDrawerSubject);
  const navigate = useNavigate()
  const filterBy = searchParams.get("filterBy") || "day";
  const [showFilterModal, setShowFilterModal] = React.useState(false)

  let startDate = searchParams.get("taskStartDate");
  let endDate = searchParams.get("taskEndDate")
  // This would call refresh on any of these functions
  // React.useEffect(() => {
  //   props.onRefresh()
  //   // eslint-disable-next-line
  // }, [rollForward, rollBackward, rollToday, setFilterBy])
  // Handle invalid startDate
  if (startDate && !isValid(new Date(startDate))) {
    navigate("/unknown")
  }

  if (endDate && !isValid(new Date(endDate))) {
    navigate("/unknown")
  }

  // These function will control the changing of a date from the date selector
  const handleWeekChange = (date: any) => {
    if (date) {
      const DATE = new Date(date);
      const weekStart = startOfWeek(DATE, { weekStartsOn: 1 });
      const weekend = endOfWeek(DATE, { weekStartsOn: 1 });
      weekend.setHours(23, 59, 59, 999);
      searchParams.set("taskStartDate", formatDateParam(weekStart));
      searchParams.set("taskEndDate", formatDateParam(weekend));
      setSearchParams(searchParams)
    }
  };
  const handleDayChange = (date: any) => {
    if (date) {
      const filterDate = new Date(date);
      filterDate.setHours(0, 0, 0, 0);
      searchParams.set("taskStartDate", formatDateParam(filterDate));
      filterDate.setHours(23, 59, 59, 999);
      searchParams.set("taskEndDate", formatDateParam(filterDate));
      setSearchParams(searchParams);
    }
  };
  return (
    <>
      <TaskFilterModal tasks={props.allTask} open={showFilterModal} onClose={() => { setShowFilterModal(false) }} />
      <TaskFormModal refreshAttachments={() => undefined} setAttachmentsMeta={() => undefined} attachmentsMeta={[]} curTask={curTask} setCurTask={setCurTask} close={() => { setOpenForm(false) }} taskId={curTask?.id} open={openForm} />
      <PagenationDynamic
        rollForward={rollForward}
        rollBackward={rollBackward}
        openFilterModal={() => { setShowFilterModal(true) }}
        rollToday={rollToday}
        setFilterBy={setFilterBy}
        openForm={() => setOpenForm(true)}
        setCurTask={setCurTask}
        filterBy={filterBy}
        handleWeekChange={handleWeekChange}
        handleDayChange={handleDayChange}
        today={today}
        curTask={curTask}
        startDate={startDate}
        onRefresh={props.onRefresh}
      />
      {/* <PagenationMinWidth992
        rollForward={rollForward}
        rollBackward={rollBackward}
        openFilterModal={() => { setShowFilterModal(true) }}
        rollToday={rollToday}
        setFilterBy={setFilterBy}
        openForm={() => setOpenForm(true)}
        setCurTask={setCurTask}
        filterBy={filterBy}
        handleWeekChange={handleWeekChange}
        handleDayChange={handleDayChange}
        today={today}
        curTask={curTask}
        startDate={startDate}
        onRefresh={props.onRefresh}
      />
      <Pagenation768To991px
        rollForward={rollForward}
        rollBackward={rollBackward}
        openFilterModal={() => { setShowFilterModal(true) }}
        rollToday={rollToday}
        setFilterBy={setFilterBy}
        openForm={() => { setOpenForm(true) }}
        setCurTask={setCurTask}
        filterBy={filterBy}
        handleWeekChange={handleWeekChange}
        handleDayChange={handleDayChange}
        today={today}
        curTask={curTask}
        startDate={startDate}
        onRefresh={props.onRefresh}
      />
      <PagenationMaxWidth767px
        rollForward={rollForward}
        rollBackward={rollBackward}
        openFilterModal={() => { setShowFilterModal(true) }}
        rollToday={rollToday}
        setFilterBy={setFilterBy}
        openForm={() => { setOpenForm(true) }}
        setCurTask={setCurTask}
        filterBy={filterBy}
        handleWeekChange={handleWeekChange}
        handleDayChange={handleDayChange}
        today={today}
        curTask={curTask}
        startDate={startDate}
        onRefresh={props.onRefresh}
      /> */}
    </>
  );

}
