import * as React from "react";
import { useSearchParams } from "react-router-dom";
import fetchUserFromApex from "../../Components/Auth/SalesforceOauth/Callback/userFetch";
import User, { UserConstructor } from "@classes/User";
import { salesforce_api } from "../../Config";
import AuthorizationResponse from "@classes/Auth";
import useSalesforceRequest from "@request/Salesforce";

type SFAuthUserDataType = [
  firestoreData: any,
  setFirestoreData: React.Dispatch<React.SetStateAction<any>>
]

export const SFReauthUserData = React.createContext<SFAuthUserDataType>(undefined!);
export const SignUpAuthContext = React.createContext<User | null>(null);
export const SignUpAuthUserData = React.createContext<SFAuthUserDataType>(undefined!);
export const SignUpContext = React.createContext<boolean>(false);
export const SignUpErrorContext = React.createContext({});
export const ClearSignUpContext = React.createContext<() => void>(() => null);

const CONSOLE_COLOR = 'color:#fc2';
const CONSOLE_COLOR_BAD = 'color:#fa6';
const CONSOLE_COLOR_GOOD = 'color:#6f6';

export default function SalesforceOauthProvider({ children }: any) {
  const [searchParams] = useSearchParams();
  const [authError, setAuthError] = React.useState<any>({});
  const [firebaseAppData, setFirebaseAppData] = React.useState<any>(null);
  const [sfReauthUserData, setSFReauthUserData] = React.useState<any>(null);
  const [userData, setUserData] = React.useState<User | null>(null); //? Salesforce & firebase user data
  const authAlreadyProcessed = React.useRef<boolean>(false)
  const postOauthToken = useSalesforceRequest();


  React.useEffect(() => {
    if (window.location.pathname !== "/oauth2/reauthorize"
      && window.location.pathname !== "/signup") {
      // oauth is only checked (and allowed) on certain routes
      return;
    }

    if (searchParams.has("code") && !authAlreadyProcessed.current) {
      // we only execute this code if we're on one of our special oath2 routes

      authAlreadyProcessed.current = true
      const code = searchParams.get("code")!;

      //NOTE: This is a rare pre-auth SF call. It does not require a user session. The SalesforceRequest we instantiate
      //      will not carry auth params.
      //      It also uses a different api path than the vast majority of our calls ("hostPath" in the params below).

      let Path = `/services/oauth2/token?client_id=${salesforce_api.client_id}&grant_type=authorization_code&code=${code}&secret=${salesforce_api.secret}`;

      if (searchParams.has('state')) {
        const state = JSON.parse(searchParams.get("state")!)
        if (state.email) {
          Path = Path.concat(`&redirect_uri=${window.location.origin}/oauth2/reauthorize`)
        } else {
          Path = Path.concat(`&redirect_uri=${window.location.origin}/oauth2/signup`)
        }
      } else {
        Path = Path.concat(`&redirect_uri=${window.location.origin}/oauth2/signup`)
      }

      const request = postOauthToken({
        endpoint: Path,
        hostPath: salesforce_api.api_host,
        method: 'POST',
        requiresAuth: false
      });
      console.log("%cRefresh Request", `${request.isValid() ? CONSOLE_COLOR_GOOD : CONSOLE_COLOR_BAD}`)
      request.attemptSend().then((result) => {
        console.log("%cRefresh Result", `${request.isValid() ? CONSOLE_COLOR_GOOD : CONSOLE_COLOR_BAD}`, result)
        if (!result.callSent) {
          authAlreadyProcessed.current = false // re-allow on recovery from this throw
          //NOTE: Since this is _not_ an auth call, there is no ended-session state to prevent send.
          //      It could, in theory, fail on the SalesforceRequest killswitch, but that's only set
          //      when an auth token fails to refresh (currently). It's not a factor.
          //      So this is currently only possible with invalid params. We'll throw as a precaution,
          //      until we actually have a survivable failure case.
          throw new Error('Failed to send pre-auth call (post token)');
        }

        const json = result.GetParsedJson();
        if (json.error) {
          authAlreadyProcessed.current = false // re-allow on recovery from this throw
          throw new Error(json.error);
        }

        const { id, access_token, refresh_token, issued_at, token_type } = json;

        const auth = new AuthorizationResponse({
          searchParams,
          access_token,
          id,
          token_type,
          refresh_token,
          issued_at,
        });
        setSFReauthUserData(auth);
        setFirebaseAppData(auth);
        console.log("%cAuth", CONSOLE_COLOR, auth)

        try {
          fetchUserFromApex(
            `/api/user/${auth.getUser_Id()}`,
            `${token_type} ${access_token}`,
            refresh_token,
            firebaseAppData?.email,
            issued_at,
            firebaseAppData?.sf_username
          )
            .then((user: UserConstructor | null) => {
              if (user?.id !== undefined) {
                const constructor = Object.assign(user, {
                  sf_access_token: auth.getAccess_Token(),
                  sf_refresh_token: auth.getRefresh_Token(),
                  sf_issued_at: auth.getIssued_At(),
                });
                const newUser = new User(constructor);
                setUserData(newUser);
                return user;
              } else {
                setAuthError({
                  error: "No user response from Salesforce!",
                  status: 400,
                });
                return null;
              }
            })
            .catch((error) => {
              console.error(error);
              setAuthError({
                error: error?.message || error,
                status: error?.status || 400,
              });
            });
        } catch (e: any) {
          console.error(e);
          setAuthError({ error: e?.message || e, status: e?.status || 400 });
        }
      })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [firebaseAppData, authAlreadyProcessed, postOauthToken, searchParams]);


  const clearSignUpContext = () => {
    setUserData(null);

    // allow sign-ups and re-auths again
    authAlreadyProcessed.current = false
  };
  return (
    <SignUpErrorContext.Provider value={authError}>
      <ClearSignUpContext.Provider value={clearSignUpContext}>
        <SignUpAuthContext.Provider value={userData}>
          <SignUpContext.Provider value={authAlreadyProcessed.current}>
            <SignUpAuthUserData.Provider value={[firebaseAppData, setFirebaseAppData]}>
              <SFReauthUserData.Provider value={[sfReauthUserData, setSFReauthUserData]}>
                {children}
              </SFReauthUserData.Provider>
            </SignUpAuthUserData.Provider>
          </SignUpContext.Provider>
        </SignUpAuthContext.Provider>
      </ClearSignUpContext.Provider>
    </SignUpErrorContext.Provider>
  );
}
