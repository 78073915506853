import { Button, Typography } from "@mui/material";
import PagenationProps from "../Interfaces";

export function NewTaskButton(props: Pick<PagenationProps, "setCurTask" | "openForm" | "curTask">) {
  return (
    <Button
      className="pagenation-button"
      onClick={() => {
        if (props.curTask) {
          props.setCurTask(null);
        }
        props.openForm();
      }}
      variant="outlined"
    >
      <Typography
        variant="button"
        className="pagenation-button-text"
      >
        New Task
      </Typography>
    </Button>
  )
}