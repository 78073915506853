import * as React from "react";
import {
  TaskDrawerControl,
} from "../../../../Context/Task/TaskDrawer";
import CloseIcon from "@mui/icons-material/Close";
import {
  TaskCardIconCloseButton,
} from "../StyledComponents";
import { editorConfig } from "../../../Messages/plugins/config";

import { LexicalComposer } from "@lexical/react/LexicalComposer"
import MessagesTextEditor from "../../../Messages/MessageComponent"
import TopRow from "./TopRow"
import TitleRow from "./TitleRow"
import DescriptionRow from "./DescriptionRow"
import RelatedRow from "./RelatedRow"
import DateFooter from "./DateFooter"
import ContactDetail from "./ContactRow"
import TaskDetail from "./TaskDetail"
import TaskDrawerFooter from "./Footer";

import "./style.css"
import { FileManagerWorking, useGetTaskAttachments } from "@context/Attachments";
import FileManager from "@components/FileManager";
import { ApiTask } from "../../../../Types/task";
import { Stack } from "@mui/material";

interface TaskCardProps {
  curTask: ApiTask | null;
  setCurTask: (
    taskId: string | null,
    newTask?: ApiTask | undefined
  ) => ApiTask | null;
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TaskCard(props: TaskCardProps) {
  const { curTask, setCurTask, openForm, setOpenForm } = props
  const { fileManagerWorking } = React.useContext(FileManagerWorking)
  const [attachmentsMeta, setAttachmentsMeta] = React.useState<any[]>([])
  const [loadingAttachments, setLoadingAttachments] = React.useState<APP_STATE>('unset')
  const { getTaskAttachments } = useGetTaskAttachments()
  const [, toggleDrawer] = React.useContext(TaskDrawerControl);
  const [displayDescription, setDisplayDescription] =
    React.useState<boolean>(false);
  const handleClick = (__event: any) => {
    setDisplayDescription((prev) => !prev);
    return displayDescription;
  };

  // NOTE: This useEffect will cause the attachments to reload after a post attachments request has been preformed.
  React.useEffect(() => {
    if (fileManagerWorking === false) {
      setLoadingAttachments('unset')
    } else {
      setLoadingAttachments("loading")
    }
  }, [fileManagerWorking])

  React.useEffect(() => {
    if (curTask !== null && loadingAttachments === 'unset') {
      setLoadingAttachments('loading')
      getTaskAttachments(curTask.id).then((meta) => {
        if (Array.isArray(meta) && meta.length) {
          setAttachmentsMeta(meta)
          // Note that content will be grabbed in the multi-media card
          // meta.forEach(value => getTaskAttachmentsContent(value))
        }
      })
    }
  }, [curTask, getTaskAttachments, loadingAttachments])

  if (curTask === null) return null

  return (

    <Stack width='100dvw' height='100%' sx={{ position: 'relative', pt: '100px', px: '10px', maxWidth: 428 }}>
      <TaskCardIconCloseButton
        onClick={() => {
          toggleDrawer(false);
        }}
      >
        <CloseIcon fontSize="large" color={"primary"} />
      </TaskCardIconCloseButton>
      <div style={{ paddingBottom: '60px' }}>
        <TopRow curTask={curTask} />
        <TitleRow permission_to_enter={curTask.permission_to_enter} curTask={curTask} />
        <TaskDetail curTask={curTask} href={`/tasks/details/${curTask.id}`} linkText="(View full task)" />
        <DescriptionRow
          handleClick={handleClick}
          description={curTask.description}
        />
        <RelatedRow related={curTask.related} type={curTask.related_type} alwaysShowDescription={false} />
        <ContactDetail contact={curTask.contact} />
        <div>
          <FileManager setAttachmentsMeta={setAttachmentsMeta} attachmentsMeta={attachmentsMeta} delayedAttachments={[]} />
          <LexicalComposer initialConfig={editorConfig}>
            <MessagesTextEditor
              curTaskOrListing={curTask}
            />
          </LexicalComposer>
        </div>
        <DateFooter curTask={curTask} />
      </div>
      <Stack
        width='100%'
        position='sticky'
        bottom={0}
        left={0}
        right={0}
        p={0}
        bgcolor={(theme) => theme.palette.background.paper}>
        <TaskDrawerFooter
          maxWidth={false}
          disableGutters
          openForm={openForm}
          setOpenForm={setOpenForm}
          curTask={curTask}
          setCurTask={setCurTask}
          is_timed={curTask.is_timed}
          is_closed={curTask.is_closed}
          taskId={curTask.id}
          taskStatus={curTask.status}
          can_claim={curTask.is_claimable}
        />
      </Stack>
    </Stack>

  );
}
