import { createTheme } from "@mui/material";

const fontFamily = ["Heebo", "Roboto", "sans-serif"].join(", ");

interface AppCache {
  mode: "light" | "dark";
  fontSize: "regular" | "large";
}

const AppTheme = (appCache: AppCache) =>
  createTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
      unit: "px",
    },
    palette: {
      mode: appCache.mode,
      common:
        appCache.mode === "light"
          ? { black: "#404040", white: "#efefef" }
          : { black: "#404040", white: "#e0e0e0" },
      primary: {
        main: appCache.mode === 'light' ? "#1b3664" : "#5994C5",
        light: "#5994C5" ,
        dark: "#1b3664" ,
        contrastText: "#e0e0e0",
      },
      secondary: {
        main: "#5994C5",
        light: "#83afd4",
        dark: "#1b3664",
      },
      error: {
        main: "#d32f2f", // all lowercase is the default MUI configuration
        light: "#ef5350",
        dark: "#c62828",
        contrastText: "#efefef",
      },
      warning: {
        main: "#ed6c02",
        light: "#ff9800",
        dark: "#c65100",
        contrastText: "#efefef",
      },
      info: {
        main: "#0288d1",
        light: "#03a9f4",
        dark: "#01579b",
        contrastText: "#efefef",
      },
      success: {
        main: "#589450",
        light: "#70BD65",
        dark: "#589450",
        contrastText: "#efefef",
      },
      grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#424242",
        900: "#212121",
        A100: "#f5f5f5",
        A200: "#eeeeee",
        A400: "#bdbdbd",
        A700: "#616161",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      text: {
        primary: appCache.mode === "light" ? "#404040" : "#efefef",
        secondary: appCache.mode === "light" ? "#404040" : "#efefef",
        disabled:
          appCache.mode === "light"
            ? "rgba(64,64,64,0.38)"
            : "rgba(0,0,0,0.58)",
      },
      divider: "rgba(64,64,64,.5)",
      background: {
        paper: appCache.mode === "light" ? "#ffffff" : "#404040",
        default: appCache.mode === "light" ? "#f1f1f1" : "#404040",
      },
      action: {
        active: "#3A6595",
        hover: "rgba(0,0,0,0.04)",
        hoverOpacity: 0.004,
        selected: "rgba(0,0,0,0.08)",
        selectedOpacity: 0.08,
        disabled: "rgba(0,0,0,0.26)",
        disabledBackground: "rgba(0,0,0,0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0,0,0,0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
    },
    shape: {
      borderRadius: 4,
    },
    mixins: {
      toolbar: {
        minHeight: 50,
        height: 50,
        width: "100%",
      },
    },
    shadows: [
      "none",
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
      "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
      "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
      "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
      "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2), 0px 13px 19px 2 px rgba(0,0,0,0.14), 0px 5px 24px 4px rgba(0,0,0,0.12)",
      "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
      "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
      "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
      "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
      "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
      "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
      "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
      "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
      "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
      "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
      "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
    ],
    typography: {
      htmlFontSize: 16,
      fontFamily,
      fontSize: appCache.fontSize === "regular" ? 14 : 18,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontFamily,
        fontWeight: 300,
        fontSize: appCache.fontSize === "regular" ? "6rem" : "6.25rem",
        lineHeight: 1.167,
        letterSpacing: "-0.01562em",
      },
      h2: {
        fontFamily,
        fontWeight: 300,
        fontSize: appCache.fontSize === "regular" ? "3.75rem" : "4rem",
        lineHeight: 1.2,
        letterSpacing:
          appCache.fontSize === "regular" ? "-0.00833em" : "-0.00733em",
      },
      h3: {
        fontFamily,
        fontWeight: 400,
        fontSize: appCache.fontSize === "regular" ? "3rem" : "3.25rem",
        lineHeight: appCache.fontSize === "regular" ? 1.167 : 1.467,
        letterSpacing: "0em",
      },
      h4: {
        fontFamily,
        fontWeight: 400,
        fontSize: appCache.fontSize === "regular" ? "2.125rem" : "2.375rem",
        lineHeight: appCache.fontSize === "regular" ? 1.235 : 1.535,
        letterSpacing:
          appCache.fontSize === "regular" ? "0.00735em" : "0.00835em",
      },
      h5: {
        fontFamily,
        fontWeight: 400,
        fontSize: appCache.fontSize === "regular" ? "1.5rem" : "1.75rem",
        lineHeight: appCache.fontSize === "regular" ? 1.334 : 1.534,
        letterSpacing: "0em",
      },
      h6: {
        fontFamily,
        fontWeight: 500,
        fontSize: appCache.fontSize === "regular" ? "1.25rem" : "1.5rem",
        lineHeight: appCache.fontSize === "regular" ? 1.6 : 1.8,
        letterSpacing:
          appCache.fontSize === "regular" ? "0.0075em" : "0.0085em",
      },
      subtitle1: {
        fontFamily,
        fontWeight: 300,
        fontSize: appCache.fontSize === "regular" ? "1rem" : "1.25rem",
        lineHeight: appCache.fontSize === "regular" ? 1.75 : 1.85,
        letterSpacing:
          appCache.fontSize === "regular" ? "0.00938em" : "0.01038em",
      },
      subtitle2: {
        fontFamily,
        fontWeight: 500,
        fontSize: appCache.fontSize === "regular" ? "0.875rem" : "1.125rem",
        lineHeight: appCache.fontSize === "regular" ? 1.57 : 1.67,
        letterSpacing:
          appCache.fontSize === "regular" ? "0.00714em" : "0.00814em",
      },
      body1: {
        fontFamily,
        fontWeight: 400,
        fontSize: appCache.fontSize === "regular" ? "1rem" : "1.25rem",
        lineHeight: appCache.fontSize === "regular" ? 1.5 : 1.6,
        letterSpacing:
          appCache.fontSize === "regular" ? "0.00938em" : "0.01038em",
      },
      body2: {
        fontFamily,
        fontWeight: 400,
        fontSize: appCache.fontSize === "regular" ? "0.875rem" : "1.125rem",
        lineHeight: appCache.fontSize === "regular" ? 1.43 : 1.53,
        letterSpacing:
          appCache.fontSize === "regular" ? "0.01071em" : "0.01171em",
      },
      button: {
        fontFamily,
        fontWeight: 800,
        fontSize: appCache.fontSize === "regular" ? "0.875rem" : "1.125rem",
        lineHeight: 1.75,
        letterSpacing: appCache.fontSize === "regular" ? "0.02857em" : "0.03em",
        textTransform: "none",
      },
      caption: {
        fontFamily,
        fontWeight: 400,
        fontSize: appCache.fontSize === "regular" ? "0.75rem" : "1rem",
        lineHeight: appCache.fontSize === "regular" ? 1.66 : 1.76,
        letterSpacing:
          appCache.fontSize === "regular" ? "0.03333em" : "0.03433em",
      },
      overline: {
        fontFamily,
        fontWeight: 400,
        fontSize: appCache.fontSize === "regular" ? "0.75rem" : "1rem",
        lineHeight: appCache.fontSize === "regular" ? 2.66 : 2.76,
        letterSpacing:
          appCache.fontSize === "regular" ? "0.08333em" : "0.08433em",
        textTransform: "none",
      },
    },
    transitions: {
      easing: {
        easeInOut: "cubic-bezier(0.4,0,0.2,1)",
        easeOut: "cubic-bezier(0,0,0.2,1)",
        easeIn: "cubic-bezier(0.4,0,1,1)",
        sharp: "cubic-bezier(0.4,0,0.6,1)",
      },
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 225,
      },
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 110,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderWidth: "1px",
            borderColor: appCache.mode === "light" ? "#1b3664" : "#5994C5",
          },
        },
        defaultProps: {
          color: "primary",
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },

      MuiButton: {
        variants: [
          {
            props: {
              variant: "outlined",
            },
            style: {
              ...(appCache.mode === "dark" && { outline : "#efefef"}),
              boxSizing: "border-box",
              // color: appCache.mode === "light" ? "#5994C5" : "#1b3664",
              border: "-2px solid",
              // borderColor: appCache.mode === "light" ? "#5994C5" : "#1b3664",
              // backgroundColor: "transparent",
              fontWeight: 800,
              fontSize: "0.8125rem",
              lineHeight: 1.5,
              letterSpacing: 0,
              WebkitFontSmoothing: "subpixel-antialiased",
              // "&:hover, &.Mui-focusVisible": {
              //   backgroundColor:
              //     appCache.mode === "light" ? "#5994C5" : "#1b3664",
              //   color: "#efefef",
              //   "& .MuiButton-endIcon": {
              //     color: "#efefef",
              //   },
              // },
              // "& .MuiButton-startIcon": {
              //   color: "#B2BAC2",
              // },
              // "& .MuiButton-endIcon": {
              //   display: "inline-block",
              //   position: "absolute",
              //   right: 0,
              //   marginRight: 10,
              //   color: "#B2BAC2",
              // },
            },
          },
        ],
      },
    },
  });

export default AppTheme;
