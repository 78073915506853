import { verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@config/Firebase/app";

export async function handleResetPassword(actionCode: string, newPassword: string, cb: (success: boolean, message: string, email: string) => void) {
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  // Verify the password reset code is valid.
  let accountEmail = "";
  verifyPasswordResetCode(auth, actionCode).then((email) => {
    accountEmail = email;

    // TODO: Show the reset screen with the user's email and ask the user for
    // the new password.
    console.log("before confirmPassword")
    // Save the new password.
    confirmPasswordReset(auth, actionCode, newPassword).then(async (resp) => {
      console.log("Response Obj-", resp)
      await signInWithEmailAndPassword(auth, accountEmail, newPassword)
      cb(true, "", accountEmail)

    }).catch((error) => {
      throw error
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
    });
  }).catch((error) => {
    console.error("verifyPasswordResetCode", error)
    let message = ""
    switch (error.code) {
      case "auth/expired-action-code":
        message = "Reset expired, please try again."
        break
      case "auth/invalid-action-code":
        message = "Reset already attempted, please try again."
        break
      default:
        message = "Unable to reset password."
        break
    }
    cb(false, message, accountEmail)
    // Invalid or expired action code. Ask user to try to reset the password
    // again.
  });
}
