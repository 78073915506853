import * as React from "react";

import Grid from "@mui/material/Grid";

import useAppDispatch from "@hooks/Dispatch/useAppDispatch";

import TaskSkeleton from "./Sub_Components/Skeleton";
import NewTaskActionButton from "./TaskControls/ActionButtons/NewTaskActionButton"
import TaskSearchFilterList from "./Sub_Components/TaskSearchFilterList"
import TaskControls from "./Sub_Components/SearchPagenation/SearchPagenation"

import processError from '../../utils'
import { TaskChangeShouldRefreshView } from "@context/Task/TaskDrawer";
import { AppFriendlyErrorMessage } from "../AppFriendlyErrorMessage";
import useSalesforceRequest from "@request/Salesforce";
import { checkActionIsTypeQueue } from "./Sub_Components/TaskList";
import { ApiQueueList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
import usePersistentObject from "@hooks/Selectors/usePersistentObject"
import useTaskFilters from "./Sub_Components/useTaskFilters";
import { useHandleLegacyUrl } from "./useHandleLegacyUrl";
import { ApiTask, TaskGroupKey } from "../../Types/task";

interface TasksSearchProps {
  taskGroupKey: TaskGroupKey,
}

export default function TasksSearch(props: TasksSearchProps) {
  useHandleLegacyUrl()
  const { taskGroupKey } = props
  const [
    startDate,
    endDate,
    filterBy
  ] = useTaskFilters();

  const dispatch = useAppDispatch()
  const persistedObject = usePersistentObject()
  const [taskState, setTaskState] = React.useState<APP_STATE>("unset")
  const [taskFriendlyErrorMessageState, setTaskFriendlyErrorMessageState] = React.useState("")
  const [tasksFromResponse, setTasksFromResponse] = React.useState<ApiTask[]>([])
  const [shouldRefreshTaskList, setShouldRefreshTaskList] = React.useContext(TaskChangeShouldRefreshView)
  const tasksSearchRequest = useSalesforceRequest()
  const taskDateRangeUrlText = React.useMemo<string | null>(() => {
    if (!startDate) return null
    if (!endDate) return null
    setTaskState('unset')
    return `&start_date=${startDate}&end_date=${endDate}`
  }, [startDate, endDate])

  // This will get my queues which is need for its id to form an endpoint for tasks
  React.useEffect(() => {
    if (!persistedObject.checkQueues(ApiQueueList.myQueues)) {
      dispatch(updatePersistentObjects(PersistentObject.queue, ApiQueueList.myQueues))
    }
  }, [dispatch, persistedObject])

  //Needed to refresh with refresh button
  const forceUpdate = () => {
    setTaskState('unset')
  }
  React.useEffect(() => {
    if (shouldRefreshTaskList === true) {
      setShouldRefreshTaskList(false)
      forceUpdate()
    }
  }, [shouldRefreshTaskList])

  //This is our fetch request
  React.useEffect(() => {
    if (taskDateRangeUrlText === null) return
    if (taskState !== 'unset') return
    // if the past taskGroupKey is equal to the new taskGroupKey from props.

    // Create a string ref that is of the last fetched taskGroupKey
    // compare that to the current taskGroupKey to see if we can see if we should ignore the superceded call.
    if (persistedObject.checkQueues(ApiQueueList.myQueues)) {
      const curQueue = persistedObject.getQueue(ApiQueueList.myQueues).find((queue: ApiQueue) => {
        return queue.name === taskGroupKey
      })
      setTaskState("loading")
      let endpoint = `/api/task?action=${checkActionIsTypeQueue(taskGroupKey) ? "queueTasks" : taskGroupKey}`

      // here we will add our daterange to request
      let datePramRequestUrl = endpoint.concat(taskDateRangeUrlText)

      if (curQueue) {
        endpoint = endpoint.concat(`&queue_id=${curQueue.id}`)
        datePramRequestUrl = datePramRequestUrl.concat(`&queue_id=${curQueue.id}`)
      }
      const getTasks = tasksSearchRequest({ endpoint: datePramRequestUrl })
      //! currently I am handling curQueue with an undefined value. Try and make this defined to better handle in getTaskAction
      getTasks.attemptSend().then((tasksSearchResponse) => {
        if (!tasksSearchResponse.callSent) { return; } // session expired
        const json = tasksSearchResponse.GetParsedJson();
        if (json.hasOwnProperty("tasks")) {
          setTasksFromResponse(json.tasks)
          setTaskState("success")
        } else {
          throw new Error("Failed to get tasks")
        }
      }).catch((e: any) => {
        const taskFriendlyErrorMessage = processError(e, `Failed to get ${taskGroupKey}`)
        setTaskFriendlyErrorMessageState(taskFriendlyErrorMessage)
        setTasksFromResponse([])
        setTaskState("error")
      })
    }
  }, [persistedObject, taskDateRangeUrlText, taskGroupKey, tasksSearchRequest, taskState])
  return (
    <Grid container spacing={1} width="100%" id="Tasks_root_div">
      <Grid item sm={12} position="absolute" right={20} bottom={10}>
        <NewTaskActionButton />
      </Grid>
      {typeof props.taskGroupKey !== 'undefined' && props.taskGroupKey.toLowerCase() !== "overdue" && (
        <Grid item container id="Tasks_root_container">
          <TaskControls allTask={tasksFromResponse} onRefresh={forceUpdate} />
        </Grid>
      )}
      <Grid
        item
        container
        className={`Task_Search_Reducer-${tasksFromResponse ? "active" : "hidden"}`}
      >
        {taskState === 'success' && <TaskSearchFilterList startDate={startDate} endDate={endDate} filterBy={filterBy} tasks={tasksFromResponse} taskGroupKey={taskGroupKey} />}
        {taskState === 'loading' && <TaskSkeleton />}
      </Grid>
      {taskState === 'error' && <AppFriendlyErrorMessage appFriendlyErrorMessageState={taskFriendlyErrorMessageState} />}
    </Grid>
  );
}

