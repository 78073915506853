import * as React from 'react'

const verboseLogging = false

type ExponentialPollFuncton = () => [React.Dispatch<any>, React.Dispatch<React.SetStateAction<number>>, boolean]

/**
 * 
 * @param POLLING_INTERVAL number in seconds to set as default <if none is default 60>
 * @param INTERVAL_MULTIPLIER number to exponentially increase polling interval <Interval * Multiplyer = Next Interval>
 * @param ignoreWarning boolean to ignore warnings caused by passing a large multiplyer param
 * @returns an Array [A , B] where A is a setter for the operation the timeout should preform and B is a setter that takes a boolean on if interval should be reset to POLLING_INTERVAL
 * @default MAX_AGE is 15 minutes or 900 s 
 * @note by default calling reset interval with true will clear out the current timmer
 */
const useExponentialPoll: ExponentialPollFuncton = (POLLING_INTERVAL = 10, INTERVAL_MULTIPLIER = 2, MAX_AGE = 900, ignoreWarning = false) => {
  const interval = React.useRef<number>(POLLING_INTERVAL)
  const operation = React.useRef<Function | null>(null)
  const [shouldResetInterval, setShouldResetInterval] = React.useState(0)
  const _timeOutFunction = React.useRef<NodeJS.Timeout>()
  const [active, setActive] = React.useState(true)
  const lastOperationTime = React.useRef(Date.now())

  if (typeof POLLING_INTERVAL !== 'number' || POLLING_INTERVAL <= 1) {
    throw new Error(`Please configure a valid interval, [${typeof POLLING_INTERVAL} is not equal too or greater than 1]`)
  }
  if (typeof INTERVAL_MULTIPLIER !== 'number' || INTERVAL_MULTIPLIER <= 1) {
    throw new Error(`Please configure a valid interval, [${typeof INTERVAL_MULTIPLIER} is not equal too or greater than 1]`)
  }
  if ((typeof INTERVAL_MULTIPLIER !== 'number' || INTERVAL_MULTIPLIER > 3) && !ignoreWarning) {
    console.warn(`An interval multiplyer of ${INTERVAL_MULTIPLIER} may cause unwanted results`)
  }
  if ((typeof MAX_AGE !== 'number') || MAX_AGE < 1) {
    throw new Error(`Please provide a valid MAX_AGE parameter. ${MAX_AGE} must be greader than one. Remove MAX_AGE for default of 15 minutes.`)
  }

  // reset
  React.useEffect(() => {
    interval.current = POLLING_INTERVAL
    clearTimeout(_timeOutFunction.current)
    _timeOutFunction.current = undefined
    setActive(true)

    if (verboseLogging) {
      console.log("Resetting Interval", interval.current)
    }
  }, [shouldResetInterval, POLLING_INTERVAL])

  // stop
  React.useEffect(() => {
    if (interval.current < MAX_AGE) {
      setActive(true)
    } else {
      setActive(false)
      clearTimeout(_timeOutFunction.current)
      _timeOutFunction.current = undefined
    }
  }, [interval, MAX_AGE])

  // main timer
  React.useEffect(() => {
    // if our interval's next step is longer than MAX_AGE minutes we will not set the timeout
    if (typeof operation.current !== 'function' || active !== true) {
      return;
    }

    _timeOutFunction.current = setTimeout(() => {
      if (typeof operation.current !== 'function') {
        console.warn('no timeout function in backoff tick @ ' + Date.now() + '; interval: ' + interval.current);
        return;
      }

      if (Date.now() - MAX_AGE * 1000 > lastOperationTime.current) {
        // it's been too long since the last interval; maybe the phone went to sleep
        return;
      }

      lastOperationTime.current = Date.now();
      operation.current()
      interval.current = interval.current * INTERVAL_MULTIPLIER
    }, interval.current * 1000)
    return () => {
      //---CLEAN UP---
      clearTimeout(_timeOutFunction.current)
      _timeOutFunction.current = undefined
    }
  })

  // This function will validate the incoming parameters to help handle react when it is dispatching state "DONT SET TO UNDIFINDED WHILE FUNCTION IS BEING CREATED"
  const setPollingOperation = (a: any) => {
    if (typeof a === 'function') {
      operation.current = a
    } else {
      operation.current = null
    }
  }

  return [setPollingOperation, setShouldResetInterval, active]
}

export default useExponentialPoll