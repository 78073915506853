import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { signInWithEmailAndPassword } from "@firebase/auth";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import useYupValidationResolver from "@components/Inputs/Validators";
import AppFormInputText from "@inputs/AppFormInputText";
import { auth } from "@config/Firebase/app";
import { environment } from "@constants";
import { OpenResetPasswordDialog } from "@context/ResetPassword";
import useProfile from "@hooks/Selectors/useProfile";

import { LoadingButton } from "@mui/lab";
import { Divider, Button, Grid, Typography } from "@mui/material";
import useAppState from "@hooks/Selectors/useAppState";
import { writeToLSReauthHold } from "@request/Salesforce/SalesforceRequest";
import { SnackBarData } from "@context/Snackbar";

const defaultValues = {
  Email: "",
  Password: "",
};

const LoginFormValidator = Yup.object().shape({
  Email: Yup.string()
    .email("Please provide a valid email for use as a Username.")
    .required("Please provide an Email."),
  Password: Yup.string().min(6).required("Please provide an password."),
});

interface LoginFormFields {
  Email: string;
  Password: string;
}

export default function LoginForm() {
  const [, setSnackData] = React.useContext(SnackBarData);
  // const [instructionalMessage, setInstructionMessage] = React.useState<string | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const user = useProfile();
  const authErrorMessage = useAppState("authError")
  const navigate = useNavigate();
  const [, setOpenResetPasswordDialog] = React.useContext(
    OpenResetPasswordDialog
  );
  const resolver = useYupValidationResolver(LoginFormValidator);
  const [loginAttemptPending, setLoginAttemptPending] = React.useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    reset
  } = useForm<LoginFormFields>({
    resolver,
    defaultValues,
    mode: "onChange",
    shouldFocusError: true,
  });

  if (searchParams.has("existingUser")) {
    if (searchParams.get("existingUser") === 'true') {
      setSnackData({ message: "You already have an account. Please log in or click Forgot Password.", severity: "warning", autoHideDuration: 10000 })
    }
    searchParams.delete("existingUser")
    setSearchParams(searchParams)
  }
  if (searchParams.has("email")) {
    reset({ "Email": decodeURIComponent(searchParams.get("email")!) })
    searchParams.delete("email")
    setSearchParams(searchParams)
  }
  if (searchParams.has("error")) {
    const errorMessage = searchParams.get("error")!
    searchParams.delete("error")
    setSnackData({ message: decodeURIComponent(errorMessage), severity: "error", autoHideDuration: 10000 })
    setSearchParams(searchParams)
  }
  React.useEffect(() => {
    if (user !== null) {
      console.log('AUTH: Login form navigating to \'loading\' page on user loaded');
      navigate("/authorizing");
    }
  }, [user, navigate]);

  React.useEffect(() => {
    if (authErrorMessage !== null) {
      if (typeof authErrorMessage === "string") {
        setError("Email", { message: authErrorMessage.length > 0 ? authErrorMessage : "There was a problem. Please sign up again!" })
      }
      setLoginAttemptPending(false)
    }
  }, [authErrorMessage, setError])

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const onSubmit = async (formData: LoginFormFields) => {
    console.log('AUTH: LoginForm submit');

    // clear any pending re-auth hold
    writeToLSReauthHold(false);

    setLoginAttemptPending(true);

    let errorsOnLoginAttempt = false;

    try {
      if (!auth) {
        setError("Email", {
          message: "Failure to connect to Firebase!",
        });
        setLoginAttemptPending(false);
        return;
      }
      await signInWithEmailAndPassword(
        auth,
        formData.Email,
        formData.Password
      );
    } catch (e: any) {
      if (environment.env === "development") {
        console.error(e);
      }
      errorsOnLoginAttempt = true;
      if (e?.message.includes("user-not-found")) {
        setError("Email", { message: "Email not found. Example: example@example.com" });
      } else if (e?.message.includes("wrong-password")) {
        setError("Password", { message: "Incorrect password. If you forgot it, please click reset password." });
      } else {
        setError("Email", { message: "Unable to grant you access! Please change the Email/Password values and try again." });
      }
    }

    if (!errorsOnLoginAttempt && Object.keys(errors).length === 0) {
      //NOTE: We explicitly leave 'loginAttemptPending' true here! Otherwise a successful
      //      login will re-enable the button before we get into the home page. It makes for
      //      an uncomfortable moment for the user. They think 'wait, did it work?'.
      return;
    }

    setLoginAttemptPending(false);
  };
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      display="flex"
      spacing={2}
      component="form"
      name="loginForm"
      id="loginForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* <Grid item><Typography fontWeight="bold">{instructionalMessage}</Typography></Grid> */}
      <Grid item>
        <AppFormInputText
          autoFocus
          type="text"
          control={control}
          name="Email"
          label="Email"
          required
          variant="outlined"
          {...register}
        />
      </Grid>
      <Grid item>
        <AppFormInputText
          type="password"
          control={control}
          name="Password"
          label="Password"
          required
          variant="outlined"
          {...register}
        />
      </Grid>
      <Grid item display="flex" justifyContent="flex-end" p={0} m={0}>
        <Button
          aria-label="forgot password"
          size="small"
          sx={{ padding: 0, margin: 0 }}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          onClick={() => {
            setOpenResetPasswordDialog(true);
          }}
        >
          <Typography fontSize="small">Forgot Password?</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Divider
          sx={{ color: (theme) => theme.palette.common.black, opacity: 0.5 }}
        />
      </Grid>
      <Grid item display="flex" justifyContent="center" alignItems="center">
        <Grid container item justifyContent="center" alignItems="center" mb={2}>
          <LoadingButton
            aria-label="login"
            disabled={loginAttemptPending}
            loading={loginAttemptPending}
            variant="contained"
            type="submit"
            form="loginForm"
            color="secondary"
            sx={{ height: "auto", width: "auto", alignSelf: "center", mr: 2 }}
          >
            <Typography variant="button">Log In</Typography>
          </LoadingButton>
          <Button
            aria-label="signup"
            color="primary"
            variant="text"
            sx={{
              textTransform: "none",
              height: "auto",
              width: "auto",
            }}
            onClick={() => {
              // clear any pending re-auth hold
              writeToLSReauthHold(false);

              navigate("/signup");
            }}
          >
            <Typography variant="button" whiteSpace="nowrap">
              Sign Up
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
