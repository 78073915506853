import { KeyboardArrowDown, KeyboardDoubleArrowDown, KeyboardArrowUp, KeyboardDoubleArrowUp } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ApiTask } from "../../Types/task";

/**
 * If there is a case_id then we use the related.priority field
 * @param URGENT: double up arrow
 * @param High: single up arrow
 * @param Normal: nothing
 * @param Low: double down arrow
 * @param Lowest: single down arrow
 * @returns 
 */
export default function IconBasedOnPriority({ priority }: { priority: ApiTask['priority'] }) {
  if (typeof priority !== "string") {
    return null
  }
  switch (priority.toLowerCase()) {
    case "urgent":
      return (
        <Tooltip title={priority}>
          <KeyboardDoubleArrowUp
            color="error"
            style={{
              width: ".7em",
              height: ".7em",
            }} />
        </Tooltip>
      );
    case "high":
      return (
        <Tooltip title={priority}>
          <KeyboardArrowUp
            color="warning"
            style={{
              width: ".7em",
              height: ".7em",
            }} />
        </Tooltip>
      );
    case "normal":
      return null;
    case "medium":
      return null;
    case "low":
      return (
        <Tooltip title={priority}>
          <KeyboardArrowDown
            color="secondary"
            style={{
              width: ".7em",
              height: ".7em",
            }} />
        </Tooltip>
      );
    case "Lowest":
      return <KeyboardDoubleArrowDown
        color="disabled"
        style={{
          width: ".7em",
          height: ".7em",
        }} />;
    default:
      return null;
  }
}
