import { Grid, Menu, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { OpenSortProps } from "./OpenSortMenu";
import AppFormInputDropdown from "@components/Inputs/AppFormInputDropdown";
import { useSelectedSort } from "../useSelectedSort";
import { Sort } from '@mui/icons-material';
import "../Styles/sortFilterStyles.css"
import { TaskSortKey, TaskSortKeySet } from "../../sortTasks";

export const sortOptions = Array.from(TaskSortKeySet);

export interface SortTaskFormFieldValues {
  task_sort: TaskSortKey
}

const defaultSortTaskFormValues: SortTaskFormFieldValues = {
  task_sort: TaskSortKey.Priority
}

export function SortTasksMenu(props: OpenSortProps) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const formData = useForm<SortTaskFormFieldValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultSortTaskFormValues
  })

  const selectedSort = formData.watch("task_sort")
  useSelectedSort(selectedSort)

//  const [searchParams, setSearchParams] = useSearchParams();
  // console.log('>>>>', 'selectedSort', selectedSort, '(default)', formData.formState.defaultValues)

  // useEffect(() => {
  // })

  return (
    <Grid container id="TaskSort_FullWidth_Container" component="form">
      <Sort color={props.openSort ? "secondary" : "primary"} />
      <AppFormInputDropdown
        onOpen={() => { props.setOpenSort(true) }}
        onClose={() => { props.setOpenSort(false) }}
        className="pagenation-select"
        options={sortOptions}
        // control={control}
        control={formData.control}
        name="task_sort"
      />
    </Grid>
  );


  /*
  if (mobile) {
    return (
      <Grid PaperProps={{ id: "TaskSort_Menu" }} component={Menu} container item anchorEl={props.anchorRef.current} open={props.openSort} onClose={() => { props.setOpenSort(false) }}>
        {sortOptions.map((option) => {
          return (
            <MenuItem id="TaskSort_Mobile_Menu_Item" selected={option === selectedSort} key={`${option}_menu_item_sort`} disableGutters disableTouchRipple disableRipple className="TaskSort_Container" value={option} onClick={() => {
              formData.setValue("task_sort", option as SortTaskFormFieldValues['task_sort'])
            }}>
              <Typography>{option}</Typography>
            </MenuItem>
          )
        })
        }
      </Grid>
    )
  }
  // Fullscreen and Mobile screen size
  return (
    <FormProvider {...formData}>
      <FullScreenSortMenu {...props} />
    </FormProvider>
  )
  */
}

/*
export function FullScreenSortMenu(props: OpenSortProps) {
  const { control } = useFormContext<SortTaskFormFieldValues>()
  return (
    <Grid container id="TaskSort_FullWidth_Container" component="form">
      <Sort color={props.openSort ? "secondary" : "primary"} />
      <AppFormInputDropdown onOpen={() => { props.setOpenSort(true) }} onClose={() => { props.setOpenSort(false) }} className="pagenation-select" options={sortOptions} control={control} name="task_sort" />
    </Grid>
  )
}
*/