import * as React from 'react';

import CardContent from '@mui/material/CardContent'



const _IMAGE = "data:image"

/**
 * 
 * @param props ImageCardProps
 * @returns JSX.Element for image "data:image"
 */
export default function ImageCard(props: FileData) {
  const id = React.useId()

  const { src, handleClick } = props //meta


  if (!src.startsWith(_IMAGE)) {
    console.error(`Not Image: ${src.slice(0, 100)}...`)
    return null
  }

  return (
    <CardContent
      onClick={handleClick}
      style={{ padding: 0, margin: 0 }}
      component="img"
      alt={`image_content_${id}`}
      src={src}
      title={`image_content_${id}`}
      height={75}
      width={100}
    />
  )
}