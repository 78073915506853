import { Grid, Typography, Box } from "@mui/material";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";

export default function NoTaskMask() {
  return (
    <Grid
      component={Box}
      container
      display="flex"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        boxSizing: "border-box",
        opacity: 0.2,
        mt: "10rem",
      }}
    >
      <Grid item>
        <AssignmentLateIcon sx={{ fontSize: "5.5rem" }} />
      </Grid>
      <Grid item>
        <Typography
          variant={"h3"}
          whiteSpace="nowrap"
        >
          No Tasks
        </Typography>
      </Grid>
    </Grid>
  );
}
