import { Autorenew, KeyboardDoubleArrowUp } from '@mui/icons-material';
import { format, isValid } from 'date-fns';
import { CardItem } from '@components/Typography/CardItemText';

export default function CaseDetail(props: {
  case_detail: ApiCase;
}) {
  if (!props.case_detail) return null;

  return (
    <div className="Task-Card-Item-Container">
      <div className="Task-Card-Item-Row">
        <span>
          <CardItem header='Subject' text={props.case_detail.subject ? props.case_detail.subject : "-"} />
        </span>
      </div>
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem header='Case Number' text={props.case_detail.name || "-"} textIcon={KeyboardDoubleArrowUp} textIconProps={{ end: true, color: "error" }} />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem header='Status' text={props.case_detail.status || "-"} />
        </div>
      </div>
      <div className="Task-Card-Item-Row">
        {props.case_detail.type &&
          <div className="Task-Card-Item-Row-Split">
            <CardItem header='Type' text={props.case_detail.type || "-"} />
          </div>}
        {props.case_detail.sub_type && <div className="Task-Card-Item-Row-Split">
          <CardItem header='Sub Type' text={props.case_detail.sub_type || "-"} />
        </div>}
      </div>

      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Check Out" text={isValid(new Date(props.case_detail.check_out)) ? format(new Date(props.case_detail.check_out), "MMM dd'・'h:mm a") : "-"} />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem
            header="Next Check In"
            text={isValid(new Date(props.case_detail.next_check_in)) ? format(new Date(props.case_detail.next_check_in), "MMM dd'・'h:mm a") : "-"}
            textIcon={props.case_detail.turnaround ? Autorenew : undefined}
          />
        </div>
      </div>
    </div >
  );
}
