import * as React from "react";

import { salesforce_api } from "@constants";
import { ClearSignUpContext } from "@context/Auth";

import { WbCloudy } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { writeToLSReauthHold } from "@request/Salesforce/SalesforceRequest";

const SalesforceWindowLauncher = (
  reauthorize: boolean,
  salesforceUsername: string | null) => {

  console.log('SalesforceWindowLauncher called with '
    + 'reauthorize ' + reauthorize
    + ', salesforceUsername ' + salesforceUsername);

  if (reauthorize && salesforceUsername === null) {
    throw new Error("No username found to reauthorize.")
  }

  const REDIRECT_URI = salesforce_api.domain.concat(
    "/services/oauth2/authorize"
  );
  const urlSearchParams = new URL(REDIRECT_URI);
  const { pathname } = window.location;
  let { href } = window.location;

  // strip any query-string text from the current URL
  const questionMarkIndex = href.indexOf('?');
  if (questionMarkIndex !== -1) {
    href = href.slice(0, questionMarkIndex);
  }

  urlSearchParams.searchParams.append("client_id", salesforce_api.client_id);
  urlSearchParams.searchParams.append("response_type", "code");
  urlSearchParams.searchParams.append(
    "redirect_uri",
    `${href.replace(pathname, "")}/oauth2/${reauthorize ? "reauthorize" : "signup"}`
  );
  urlSearchParams.searchParams.append("issued_at", `${performance.now()}`);
  urlSearchParams.searchParams.append("display", "touch");
  urlSearchParams.searchParams.append("secret", salesforce_api.secret);
  if (reauthorize && salesforceUsername !== null) {
    //NOTE: A username is not necessarily included. Certain characters in a username cause the 
    //      request to be blocked by Salesforce.

    if (salesforceUsername.indexOf("+") < 0) {
      // This needs to be done inorder to ignore usernames with "+", such as aliased emails. ( Invalid salesforce loginHint )
      urlSearchParams.searchParams.append("login_hint", salesforceUsername);
    }
  }
  urlSearchParams.searchParams.append("state", encodeURIComponent(JSON.stringify({ pathname, salesforceUsername })));
  console.warn(urlSearchParams.href)
  const redirect_uri = decodeURIComponent(urlSearchParams.href);
  console.warn(redirect_uri)
  return redirect_uri;
};

const StyledIcon = styled(
  WbCloudy,
  {}
)({
  padding: 0,
  margin: 0,
});

const StyledButton = styled(
  LoadingButton,
  {}
)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  minWidth: 200,
  width: theme.breakpoints.up("sm") ? 300 : 250,
  height: theme.breakpoints.up("sm") ? 55 : 35,
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  justifyContent: "center",
  fontSize: theme.breakpoints.up("sm") ? "1rem" : ".8rem",
  ":hover": { backgroundColor: theme.palette.primary.dark },
  whiteSpace: "nowrap",
  color: theme.palette.common.white,
}));

export default function ContinueWithSalesforce({
  loading,
  reauthorizeMode,
  triggerRedirect,
  salesforceLoginHint
}: {
  loading: boolean;
  reauthorizeMode: boolean;
  triggerRedirect: boolean;
  salesforceLoginHint: string // needed for login_hint
}) {
  const clearSignUpContext = React.useContext(ClearSignUpContext);
  const sfWindowLaunched = React.useRef(false);

  const launchSFWindow = () => {
    if (sfWindowLaunched.current) {
      console.warn('redundant Salesforce window launch attempted; ignoring');
      return;
    }

    // clear any pending re-auth hold
    writeToLSReauthHold(false);

    // prevent more than one launch attempt
    sfWindowLaunched.current = true;

    clearSignUpContext();
    window.open(
      SalesforceWindowLauncher(
        reauthorizeMode,
        reauthorizeMode === false ? null : salesforceLoginHint
      ), "_self");
  };

  if (triggerRedirect && !sfWindowLaunched.current) {
    // we've been instructed to launch from outside, without the user clicking
    launchSFWindow();
    return null;
  }

  return (
    <Grid
      item
      container
      sm={12}
      justifyContent="center"
      alignItems="center"
      pr={2}
      pl={2}
    >
      <StyledButton
        aria-label="continue with salesforce"
        startIcon={<StyledIcon sx={{ mr: 0.5 }} />}
        loading={loading || sfWindowLaunched.current}
        disabled={loading || sfWindowLaunched.current}
        onClick={launchSFWindow}
      >
        <Typography
          variant="button"
          sx={{
            display: "flex",
            whiteSpace: "nowrap",
            minWidth: 100,
          }}
        >
          Continue with Salesforce
        </Typography>
      </StyledButton>
    </Grid>
  );
}
