const listingStatusColor = (status: string) => {
  switch (status) {
    case "Vaccant":
    case "Occupied":
      return "secondary";
    case "Dirty":
    case "Maintenance":
      return "error";
    case "Cleaning":
    case "Inspecting":
      return "warning";
    default:
      return "primary";
  }
};

export default listingStatusColor;
