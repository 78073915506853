import { URLSearchParams } from "url";
import { TaskFilterKeys } from "../Classes/Filter/index";
import { ApiTask } from "../Types/task";

export function filterTasks(
  searchParams: URLSearchParams,
  unfilteredTasks: ApiTask[]
) {
  // What filters exists
  const filterByListing = searchParams.has(TaskFilterKeys.listing);
  const filterByAssignee = searchParams.has(TaskFilterKeys.assignee);
  const filterByStatus = searchParams.has(TaskFilterKeys.status);
  const filterByType = searchParams.has(TaskFilterKeys.type);
  const filterByPriority = searchParams.has(TaskFilterKeys.priority);
  const filterBySubtype = searchParams.has(TaskFilterKeys.Subtype);
  const listingTerms = filterByListing
    ? new Set(searchParams.getAll(TaskFilterKeys.listing))
    : null;
  const assigneeTerms = filterByAssignee
    ? new Set(searchParams.getAll(TaskFilterKeys.assignee))
    : null;
  const statusTerms = filterByStatus
    ? new Set(searchParams.getAll(TaskFilterKeys.status))
    : null;
  const typeTerms = filterByType
    ? new Set(searchParams.getAll(TaskFilterKeys.type))
    : null;
  const priorityTerms = filterByPriority
    ? new Set(searchParams.getAll(TaskFilterKeys.priority))
    : null;
  const subTypeTerms = filterBySubtype
    ? new Set(
        searchParams
          .getAll(TaskFilterKeys.Subtype)
          .map((subT) => subT.replace(" And ", " & "))
      )
    : null;

  return unfilteredTasks.filter((task) => {
    //? Is there a listing filter?
    if (filterByListing) {
      if (!task.related?.unit_id) {
        return false;
      }
      if (!listingTerms?.has(task.related.unit_id)) {
        return false;
      }
    }
    //? Is there an assignee filter?
    if (filterByAssignee) {
      const filterMatchesAdditionalHousekeepers =
        task.additional_housekeepers &&
        typeof task.additional_housekeepers.find((housekeeper) =>
          assigneeTerms?.has(housekeeper)
        ) === "string";

      if (filterMatchesAdditionalHousekeepers) {
        return true;
      }

      if (
        !assigneeTerms?.has(task.assignee.name) &&
        !assigneeTerms?.has(task.assignee.id)
      ) {
        return false;
      }
    }
    //? Is there a status filter?
    if (filterByStatus) {
      if (!statusTerms?.has(task.status)) {
        return false;
      }
    }
    //? Is there a type filter?
    if (filterByType) {
      if (!typeTerms?.has(task.type)) {
        return false;
      }
    }
    //? Is there a priority filter?
    if (filterByPriority) {
      if (!task.priority) {
        return false;
      }
      if (!priorityTerms?.has(task.priority)) {
        return false;
      }
    }
    if (filterBySubtype) {
      if (!task.sub_type) {
        return false;
      }
      if (!subTypeTerms?.has(task.sub_type)) {
        return false;
      }
    }
    return true;
  });
}
