import * as React from 'react'
import usePersistentObject from '@hooks/Selectors/usePersistentObject'
import { ApiListingList, updatePersistentObjects, PersistentObject } from '@store/Reducers/PersistentObject'
import useAppDispatch from '@hooks/Dispatch/useAppDispatch'
import { Stack } from '@mui/material'
import LoadingCompactCard from '@components/LoadingCompactCard'
import { ListOfListings } from './ListOfListings'
import "./listingCompactCardStyle.css"
import ErrorFallback from '@components/UserPage/Error'

export default function ListingsCardContainer() {
  const dispatch = useAppDispatch()
  const persistedObject = usePersistentObject()
  const [listingsState, setListingState] = React.useState<APP_STATE>(persistedObject.checkListings(ApiListingList.activeListings) ? "success" : "unset")

  React.useEffect(() => {
    if (listingsState !== "unset") return

    setListingState("loading")

    dispatch(updatePersistentObjects(PersistentObject['listing'], ApiListingList.activeListings)).then(() => {
      setListingState("success")
    }).catch((problemWithDispatch: any) => {
      console.error(problemWithDispatch)
      setListingState("error")
    })

  }, [listingsState, dispatch])

  switch (listingsState) {
    case "error":
      return <ErrorFallback message='Failed to load listings!' />;
    case "success":
      return (
        <ListOfListings refresh={() => { setListingState("unset") }} listings={persistedObject.getListing(ApiListingList.activeListings)} />
      );
    case "loading" || "unset":
      return (
        <Stack gap={'10px'}>
          <LoadingCompactCard />
          <LoadingCompactCard />
          <LoadingCompactCard />
          <LoadingCompactCard />
        </Stack>
      );
    default:
      return null
  }
}


