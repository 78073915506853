import User from "@classes/User";
import { Navigate } from "react-router-dom";
interface AuthLoaderI {
  user: User | null
}
export default function AuthLoader(props: AuthLoaderI) {
  return (
    <>
      <div
        style={{
          color: "#555",
          fontSize: "1.5rem",
          top: "40vh",
          position: "absolute",
          textAlign: "center",
          width: "100%",
        }}
      >
        {props.user === null ? "Signing in..." : "Loading..."}
      </div>
      {props.user !== null && <Navigate to="/home" />}
    </>
  );
}
