import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";

const ModalBody = styled(
  Paper,
  {}
)(({ theme }) => ({
  width: theme.breakpoints.down("sm") ? "55%" : "75%",
  height: "fit-content",
  borderRadius: "4px",
  boxSizing: "border-box",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  boxShadow: `3px 2px ${theme.palette.primary.light}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export default ModalBody;
