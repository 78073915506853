import { AppBarMenuItems } from "@context/Sidebars/SideBar";
import {
  ExpandLess,
  ExpandMore,
  SvgIconComponent
} from "@mui/icons-material";
import { ListItemButton, ListItemText, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function AppList() {
  const appMenuItems = React.useContext<MenuItem[]>(AppBarMenuItems);
  return (
    <Box
      sx={{
        pt: 5.3 // pading for toolbar
      }}
      role="presentation"
    >
      <List
        sx={{
          width: '100%',
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {appMenuItems.map((menuItem) => <MenuItemList key={menuItem.href} menuItem={menuItem} />)}
      </List>
    </Box>
  );
}

function MenuItemList({ menuItem }: { menuItem: MenuItem }) {
  const navigateApp = useNavigate();
  const { pathname } = useLocation();
  const MenuIcon: SvgIconComponent = menuItem.icon;
  const isSelected = menuItem.href.replace('/', '').includes(decodeURIComponent(pathname).replace('/', ''))
  const hasSubMenuItems = menuItem.subMenuItems.length > 0
  const [showSubMenu, setShowSubMenu] = React.useState(isSelected)
  return (
    <React.Fragment key={menuItem.title}>
      {/* {menuItem.bottomDivider === true ? <Divider /> : null} */}
      <ListItemButton
        aria-label={`navigate to ${menuItem.href}`}
        key={menuItem.href}
        onClick={() => {
          setShowSubMenu(prev => !prev)
          if (!menuItem?.noNav) {
            navigateApp(menuItem.href);
          }
        }}
        sx={(theme) => ({
          pl: isSelected ? 1 : 2,
          borderLeft: isSelected
            ? `6px ${theme.palette.primary.main} solid`
            : "",
          color: isSelected
            ? theme.palette.primary.main
            : theme.palette.text.primary,
          backgroundColor: isSelected
            ? theme.palette.background.paper
            : "",
          height: "2.75rem",
          minWidth: 200
        })}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
          <Stack direction='row' alignItems='center'>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <MenuIcon sx={{ color: (theme) => theme.palette.primary.main }} fontSize="medium" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontWeight: isSelected ? 'bold' : 'normal'
              }}
              sx={{ fontSize: "small" }}
              primary={menuItem.title} />
          </Stack>
          {hasSubMenuItems && !showSubMenu && <ExpandMore sx={{ color: (theme) => theme.palette.primary.main }} fontSize="medium" />}
          {hasSubMenuItems && showSubMenu && <ExpandLess sx={{ color: (theme) => theme.palette.primary.main }} fontSize="medium" />}
        </Stack>
      </ListItemButton>
      {showSubMenu &&
        menuItem.subMenuItems.length > 0 &&
        menuItem.subMenuItems.map((subMenuItem) =>
          <SubMenuListItem key={subMenuItem.href} subMenuItem={subMenuItem} />
        )}
    </React.Fragment>
  )
}

function SubMenuListItem({ subMenuItem, gutter }: { subMenuItem: MenuItem, gutter?: boolean }) {
  const navigateApp = useNavigate()
  const { pathname } = useLocation();
  const SubMenuIcon: SvgIconComponent = subMenuItem.icon;
  const isSelectedSubMenuItem = subMenuItem.href.replace('/', '').includes(decodeURIComponent(pathname).replace('/', ''))
  const hasSubMenuItems = subMenuItem.subMenuItems.length > 0
  const [showSubMenu, setShowSubMenu] = React.useState(isSelectedSubMenuItem)
  return (
    <>
      <ListItemButton
        aria-label={`navigate to ${subMenuItem.href}`}
        key={subMenuItem.href}
        onClick={() => {
          setShowSubMenu(prev => !prev)
          navigateApp(subMenuItem.href)
        }}
        sx={(theme) => ({
          pl: isSelectedSubMenuItem ? 1 : gutter ? 4 : 3,
          borderLeft: isSelectedSubMenuItem
            ? `6px ${theme.palette.primary.light} solid`
            : "",
          color: isSelectedSubMenuItem
            ? theme.palette.primary.main
            : theme.palette.text.primary,
          backgroundColor: isSelectedSubMenuItem
            ? theme.palette.background.default
            : "",
          height: "2.5rem",
          mb: .25,
          width: '100%'
        })}
      >
        <ListItemIcon sx={{ minWidth: 35 }}>
          <SubMenuIcon sx={{
            color: (theme) => theme.palette.primary.light,
            ml: isSelectedSubMenuItem ? 1 : 0
          }}
            fontSize="small" />
        </ListItemIcon>
        <ListItemText
          secondaryTypographyProps={{
            fontWeight: isSelectedSubMenuItem ? 'bold' : 'normal'
          }}
          sx={{ fontSize: "small" }}
          secondary={subMenuItem.title} />
        {hasSubMenuItems && !showSubMenu && <ExpandMore sx={{ color: (theme) => theme.palette.primary.main }} fontSize="medium" />}
        {hasSubMenuItems && showSubMenu && <ExpandLess sx={{ color: (theme) => theme.palette.primary.main }} fontSize="medium" />}
      </ListItemButton>
      {showSubMenu && subMenuItem.subMenuItems.map((nextMenuItem) => <SubMenuListItem gutter key={nextMenuItem.href} subMenuItem={nextMenuItem} />)}
    </>
  );
}