import * as React from "react";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import { ApiUserList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";

export default function useUserPhotos() {
  const dispatch = useAppDispatch()
  const persistedObject = usePersistentObject()

  React.useEffect(() => {
    if (!persistedObject.checkUserList(ApiUserList.activeUsers)) {
      dispatch(updatePersistentObjects(PersistentObject.user, ApiUserList.activeUsers))
    }
  }, [dispatch, persistedObject])
  const users = React.useMemo(() => {
    if (persistedObject.checkUserList(ApiUserList.activeUsers)) {
      return persistedObject.getUserList(ApiUserList.activeUsers)
    }
    return []
  }, [persistedObject])
  const photos = React.useMemo(() => {
    if (users.length) {
      return users.map((user: ApiUser) => (
        {
          owner_name: user.name,
          owner_id: user.id,
          profile_thumbnail_url: user.profile_thumbnail_url,
          profile_picture_url: user.profile_picture_url,
          has_profile_photo: user.has_profile_photo,
        }))
    }
    return {
      owner_name: undefined,
      owner_id: undefined,
      profile_thumbnail_url: undefined,
      profile_picture: undefined,
      has_profile_photo: false,
    };
  }, [users]);
  const userPhotoFilter = React.useCallback(
    (identifier: string) => {
      if (Array.isArray(photos)) {
        const item = photos?.find((photo) => {
          return photo.owner_id === identifier || photo.owner_name === identifier;
        });
        return item;
      }
      return photos
    },
    [photos]
  );

  return userPhotoFilter;
}
