import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import { acceptPlusCharInURLString } from "@helpers/acceptPlusCharInEmailString";
import { auth as FirebaseAuth } from "./app";

export class AuthModalSignUpCredentials {
  constructor(
    public readonly email: string,
    public readonly password: string,
    public readonly confirmPassword: string
  ) {}

  isValid() {
    if (this.password !== "" && this.email !== "") {
      if (this.password === this.confirmPassword) {
        return true;
      }
    }
    return false;
  }
}

export default class FirebaseSignUpAndSignInSequence {
  errorMessage: string | null = null;
  shouldNavigate: boolean = false;
  constructor(
    private readonly _credentials: AuthModalSignUpCredentials,
    private readonly _auth: Auth = FirebaseAuth
  ) {}
  isValid() {
    if (!this._credentials.isValid()) return false;
    if (!this._auth) return false;
    if (typeof this.errorMessage === "string") return false;
    return true;
  }

  private async _attemptExistingUserLogIn() {
    //NOTE: This user already exists. Rather than fail Sign-Up, we try to log them in.
    return signInWithEmailAndPassword(
      this._auth,
      this._credentials.email,
      this._credentials.password
    )
      .then(({ user }) => {
        this.shouldNavigate = true;
        return user;
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/wrong-password":
            // send the user back to the login form, so they can attempt to login with the correct password
            this.errorMessage =
              "You are already signed up. We will navigate you to login.";
            this.shouldNavigate = true;
            break;
          case "invalid operation":
            this.errorMessage =
              "Please reload. Failed to perform 'Invalid Operation'";
            this.shouldNavigate = true;
            break;
          default:
            this.errorMessage = error.message;
            break;
        }
        return null;
      });
  }

  async attemptSignUp() {
    return createUserWithEmailAndPassword(
      this._auth,
      this._credentials.email,
      this._credentials.password
    )
      .then(({ user }) => {
        if (!user) throw Error("Unable to sign up");
        this.shouldNavigate = true;
        return user;
      })
      .catch(async (error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            this.errorMessage = `Email address ${this._credentials.email} already in use.`;
            console.log("Redirecting existing user to log in.");
            // let userData = await this._attemptExistingUserLogIn();
            // Instead we want to update the user to use the new password and confirmed password
            // auth.updateCurrentUser()

            window.location.assign(
              `/login?existingUser=true&email=${acceptPlusCharInURLString(
                encodeURIComponent(this._credentials.email)
              )}`
            );
            return null;
          case "auth/invalid-email":
            this.errorMessage = `Email address ${this._credentials.email} is invalid.`;
            throw new Error(
              `Email address ${this._credentials.email} is invalid.`
            );
          case "auth/operation-not-allowed":
            this.errorMessage = "Error during sign up.";
            throw new Error(`Error during sign up.`);
          case "auth/weak-password":
            this.errorMessage = "Password is not strong enough.";
            throw new Error("Password is not strong enough.");
          default:
            this.errorMessage = error.message;
            throw new Error(error.message);
        }
      });
  }
}
