import HomeIcon from "@mui/icons-material/Home";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import { LocationCity } from "@mui/icons-material";

const appMenuItems: MenuItem[] = [
  {
    title: "Home",
    href: "/home",
    topDivider: false,
    bottomDivider: true,
    icon: HomeIcon,
    subMenuItems: [],
  },
  {
    title: "Listings",
    href: "/listings",
    topDivider: false,
    bottomDivider: false,
    icon: LocationCity,
    subMenuItems: [],
  },
  {
    title: "Tasks",
    href: "/tasks",
    topDivider: false,
    bottomDivider: false,
    icon: AssignmentOutlinedIcon,
    subMenuItems: [],
    noNav: true,
  },
  {
    title: "Teams",
    href: "/teams",
    noNav: true,
    topDivider: false,
    bottomDivider: false,
    icon: GroupsIcon,
    subMenuItems: [],
  },
];

export default appMenuItems;
