import { ArrowForwardIos } from "@mui/icons-material";
import { Button } from "@mui/material";
import PagenationProps from "../Interfaces";

export function ForwardButton(props: Pick<PagenationProps, "rollForward">) {
  return (
    <Button
      className="pagenation-button"
      variant="outlined"
      onClick={() => {
        props.rollForward();
        return;
      }}
    >
      <ArrowForwardIos className="pagenation-button-text" />
    </Button>
  )
}