// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { createSelectableListingNameObjects } from "@helpers/createSelectableObjects";
import { ApiListingList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";

const useListingOptions = (key: "unit_id" | "id") => {
  const dispatch = useAppDispatch()
  const persistentObject = usePersistentObject()

  React.useEffect(() => {
    if (!persistentObject.checkListings(ApiListingList.activeListings)) {
      dispatch(updatePersistentObjects(PersistentObject.listing, ApiListingList.activeListings))
    }
  }, [dispatch, persistentObject])

  return React.useMemo(() => {
    if (persistentObject.checkListings(ApiListingList.activeListings)) {
      return createSelectableListingNameObjects(persistentObject.getListing(ApiListingList.activeListings), key)
    }
    return null
  }, [persistentObject, key])
};

export default useListingOptions;
