import * as React from 'react';
import ListingOrderItemDetail from "./ListingOrderItemDetail";
import ReservationDetail from "./ReservationDetail";
import ListingDetail from "./ListingDetail";
import CaseDetail from "./CaseDetail";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { KeyboardArrowDown, Try } from "@mui/icons-material";
import SpecialRequest from './SpecialRequest';

// file deepcode ignore DuplicateCaseBody: <To be explicit with case!>
export default function RelatedRow({
  related,
  type,
  alwaysShowDescription
}: {
  related?: ApiListingOrderItem | ApiCase | ApiReservation | ApiListing;
  type?: string;
  alwaysShowDescription: boolean
}) {
  const [speacialRequest, setSpecialRequest] = React.useState(true)
  if (!type || related === undefined) return null;

  switch (type) {
    case "Listing__c":
      return (
        <>
          <div className="Task-Card-Item-Row Task-Card-Item-Border No-Margin">
            <Typography variant="h6">Related to Listing</Typography>
          </div>
          <ListingDetail listing={related as ApiListing} forceVerticalStyle />
        </>
      );
    case "Listing_Order_Item__c":
      return (
        <>
          <div className="Task-Card-Item-Row Task-Card-Item-Border No-Margin">
            <Typography variant="h6">Related to Listing Order Item</Typography>
          </div>
          <ListingOrderItemDetail
            listing_order_item={related as ApiListingOrderItem}
          />
        </>
      );
    case "Reservation__c":
      let Reservation = related as ApiReservation
      return (
        <>
          <div onClick={() => setSpecialRequest(prev => !prev)} className="Task-Card-Item-Row Split-Title-Row Task-Card-Item-Border No-Margin">
            <Typography variant="h6">Related to Reservation</Typography>
            {(Reservation.has_check_in_requests || Reservation.check_in_requests_detail) && speacialRequest === false &&
              <Tooltip title="Special Request">
                <IconButton id="Task-Card-Icon" onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setSpecialRequest(prev => !prev)
                }}>
                  <Try />
                </IconButton>
              </Tooltip>
            }
            {Reservation.has_check_in_requests === true && speacialRequest === true &&
              <IconButton className='slide'><KeyboardArrowDown /></IconButton>
            }
          </div>
          <SpecialRequest open={speacialRequest} request={{ has_check_in_requests: Reservation.has_check_in_requests, check_in_requests: Reservation.check_in_requests, check_in_requests_detail: Reservation.check_in_requests_detail }} />
          <ReservationDetail reservation={Reservation} alwaysShowDescription={alwaysShowDescription} />
        </>
      );
    case "Reservation_Order_Item__c":
      return (
        <>
          <div className="Task-Card-Item-Row Task-Card-Item-Border No-Margin">
            <Typography variant="h6">Related to Reservation</Typography>
          </div>
          <ReservationDetail alwaysShowDescription={alwaysShowDescription} reservation={related as ApiReservationOrderItem} />
        </>
      );
    case "Case":
      return (
        <>
          <div className="Task-Card-Item-Row Task-Card-Item-Border No-Margin">
            <Typography variant="h6">Related to Case</Typography>
          </div>
          <CaseDetail case_detail={related as ApiCase} />
        </>
      )
    default:
      return null
  }
}
