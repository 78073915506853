import * as React from "react";
import { Skeleton, Paper } from "@mui/material";
import './styles.css'

export default function LoadingCompactCard() {
  return (
    <Paper className="CompactCard-Skeleton-Container">
      <div className="CompactCard-Skeleton-row">
        <Skeleton className="CompactCard-Skeleton">
          <div className="CompactCard-Skeleton-sm-item"></div>
        </Skeleton>
      </div>
      <div className="CompactCard-Skeleton-row">
        <Skeleton>
          <div className="CompactCard-Skeleton-md-item"></div>
        </Skeleton>
        <Skeleton>
          <div className="CompactCard-Skeleton-md-item"></div>
        </Skeleton>
      </div>
      <div className="CompactCard-Skeleton-row">
        <Skeleton>
          <div className="CompactCard-Skeleton-sm-item"></div>
        </Skeleton>
        <Skeleton>
          <div className="CompactCard-Skeleton-lg-item"></div>
        </Skeleton>
      </div>
      <div className="CompactCard-Skeleton-row">
        <Skeleton>
          <div className="CompactCard-Skeleton-lg-item"></div>
        </Skeleton>
        <Skeleton>
          <div className="CompactCard-Skeleton-sm-item"></div>
        </Skeleton>
      </div>
    </Paper>
  );
}
