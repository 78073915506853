import * as React from "react";
import AppSnackBar, { AppSnackBarProps } from "../../Components/SnackBars";


export const initialConfig = {
  message: undefined,
  title: undefined,
  color: undefined,
  transitionDuration: undefined,
  anchorOrigin: undefined,
  autoHideDuration: undefined,
  children: undefined,
  variant: undefined,
  elevation: undefined,
  severity: undefined,
};

type SnackBarDataType = [snackData: AppSnackBarProps, setSnackData: React.Dispatch<React.SetStateAction<AppSnackBarProps>>]

export const SnackBarData = React.createContext<SnackBarDataType>(undefined!);
export const SnackBarDisplay = React.createContext({});

export default function SnackBarProvider({ children }: { children: any }) {
  const [snackData, setSnackData] = React.useState<AppSnackBarProps>(initialConfig);
  const [openSnack, setOpenSnack] = React.useState(false);

  React.useEffect(() => {
    if (Object.values(snackData).every((val) => val === undefined)) {
      setOpenSnack(false);
    } else {
      setOpenSnack(true);
    }
  }, [snackData]);

  const onClose = () => {
    setOpenSnack(false);
  };

  return (
    <SnackBarData.Provider value={[snackData, setSnackData]}>
      <SnackBarDisplay.Provider value={[openSnack, setOpenSnack]}>
        <AppSnackBar open={openSnack} {...snackData} onClose={onClose} />
        {children}
      </SnackBarDisplay.Provider>
    </SnackBarData.Provider>
  );
}
