import User from "../../Classes/User";
import { checkTaskAsigneeMembership } from "../../Helpers/taskAssigneeList";
import {
  ApiPersistentObjectStore,
  ApiQueueList,
} from "../../Store/Reducers/PersistentObject";
import { TaskStatusUpperNotStarted } from "../../Strings/TaskStatusUpper";

export function addTaskPermissionFields(
  taskJson: any,
  user: User | null,
  persistedObject: ApiPersistentObjectStore
) {
  if (user === null || !user.role_name) {
    console.log("user missing/incomplete; no task permissions will be set");
    return;
  }
  if (!taskJson || typeof taskJson !== "object") {
    console.warn("missing/invalid task object on set permissions");
    return;
  }
  if (
    taskJson.is_claimable_by_user !== undefined ||
    taskJson.is_my_task !== undefined ||
    taskJson.is_manageable_by_user !== undefined
  ) {
    // This feature is purely client-side. This could mean an API field is getting stomped!
    console.warn("permission field encountered on receive (unexpected)");
  }

  taskJson.is_claimable_by_user = false;
  taskJson.is_my_task = false;
  taskJson.is_manageable_by_user = false;

  const userIsAdditionalHousekeeper =
    Array.isArray(taskJson.additional_housekeepers) &&
    taskJson.additional_housekeepers.includes(user.name);

  // without managemeable permissions, only those assigned to a task may make edits
  taskJson.is_my_task =
    user.id === taskJson.assignee_id || userIsAdditionalHousekeeper;

  taskJson.is_manageable_by_user =
    user.checkIsManagementRole() || user.id === taskJson.reporter_id;

  // claimable tasks can override these permissions under certain conditions
  taskJson.is_claimable_by_user = false;
  if (
    taskJson.is_claimable &&
    taskJson.status.toUpperCase() === TaskStatusUpperNotStarted
  ) {
    // check queue membership for ability to claim
    if (user !== null && persistedObject.checkQueues(ApiQueueList.myQueues)) {
      const myQueues = persistedObject.getQueue(ApiQueueList.myQueues);
      taskJson.is_claimable_by_user = checkTaskAsigneeMembership(
        taskJson,
        user,
        myQueues
      );
    }
  }
}
