import * as React from 'react'
import TimedButtons from './Components/TimedButtons';
import SecondaryActions from './Components/SecondaryActions';
import PrimaryActions from './Components/PrimaryActions';
import { Theme } from '@context/Theme';
import { addTaskPermissionFields } from '../../../../../Context/Task/taskUtils';
import useProfile from '../../../../../Hooks/Selectors/useProfile';
import usePersistentObject from '../../../../../Hooks/Selectors/usePersistentObject';
import { Container, Stack, SxProps } from '@mui/material';

/**
 *
 * @param props FinalFooterProps
 * @returns This components nicley orginizes our three action button columns and maintains a space in column 2 of 4
 */
export default function TaskDrawerFooter(props: FinalFooterProps & { maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false, disableGutters?: boolean, sx?: SxProps }) {
  const user = useProfile();
  const persistedObject = usePersistentObject()
  const { palette: { mode } } = React.useContext(Theme);
  if (props.taskStatus === null) return null

  const updateTaskModel = (taskId: string | null, newTask?: any) => {
    // console.log('updateTaskModel', taskId, newTask)
    addTaskPermissionFields(newTask, user, persistedObject)
    props.setCurTask(taskId, newTask)
  }

  return (
    <Container maxWidth={props.maxWidth || 'lg'} disableGutters={props.disableGutters} sx={props.sx}>
      <Stack width='100%' direction='row' justifyContent='space-between' whiteSpace='nowrap' bgcolor='inherit' py={1} alignItems='center'>
        <div>
          {props.is_timed && <TimedButtons openForm={props.openForm} setOpenForm={props.setOpenForm} taskStatus={props.taskStatus} taskId={props.taskId} curTask={props.curTask}
            setCurTask={updateTaskModel} />}
        </div>
        <Stack direction='row' spacing={1}>
          <SecondaryActions openForm={props.openForm} setOpenForm={props.setOpenForm} is_timed={props.is_timed} taskStatus={props.taskStatus} taskId={props.taskId} can_claim={props.can_claim} curTask={props.curTask}
            setCurTask={updateTaskModel} />
          <PrimaryActions openForm={props.openForm} setOpenForm={props.setOpenForm} is_timed={props.is_timed} taskStatus={props.taskStatus} taskId={props.taskId} is_closed={props.is_closed} curTask={props.curTask}
            setCurTask={updateTaskModel} />
        </Stack>
      </Stack>
    </Container>
  )
}


