import MultiActionBtn from "../../../../TaskControls/ActionButtons/TaskMultiAction";
import { TaskStatusUpperInProgress, TaskStatusUpperNotStarted, TaskStatusUpperOnHold } from "../../../../../../Strings/TaskStatusUpper";

/**
* @param hasClaim - boolean on if we should include claim in the options
* @returns Record<label: string, action: string>[]
*/
function buildSecondaryActions(hasClaim: boolean | undefined, includeMove: boolean = true) {
  //NOTE: We carefully assemble these options in a very specific order (Claim, Defer, Move, Follow-up),
  //      but some of them are optional.
  const baseOptions = [{ label: 'Defer', action: "deferTask" }];
  if (includeMove) {
    baseOptions.push({ label: 'Move', action: "moveTask" });
  }
  baseOptions.push({ label: 'Follow Up', action: "followUpTask" });
  if (hasClaim === false) {
    return baseOptions;
  }
  return [{ label: 'Claim', action: 'claimTask' }].concat(baseOptions);
}

/**
 * @param props ActionButtonColumnProps
 * @returns will use a switch case to return the appropriate button
 */
export default function SecondaryActions(props: ActionButtonColumnProps) {
  // Default view for secondary action buttons--------
  const userHasPermission = props.curTask && props.curTask.type !== 'Shuttle Service' && (props.curTask.is_manageable_by_user || props.curTask.is_my_task)
  const defaultAction = (props.can_claim && props.curTask.is_claimable_by_user) ? "claimTask" : "deferTask"
  const defaultLabel = (props.can_claim && props.curTask.is_claimable_by_user) ? "Claim" : "Defer"
  if (!userHasPermission) {
    if (!props.curTask.is_claimable_by_user) {
      return null;
    }
    // fall-through to create a Claim button
  }

  const createSecondaryActionButton = (inProgress: boolean) => {
    if (inProgress) {
      return (
        <MultiActionBtn
          openForm={props.openForm}
          setOpenForm={props.setOpenForm}
          curTask={props.curTask}
          setCurTask={props.setCurTask}
          options={buildSecondaryActions(false, props.curTask.is_manageable_by_user)}
          defaultAction={"deferTask"}
          label="Defer"
          taskId={props.taskId}
          overrideEnabled={userHasPermission}
        />
      )
    }
    return (
      <MultiActionBtn
        openForm={props.openForm}
        setOpenForm={props.setOpenForm}
        curTask={props.curTask}
        setCurTask={props.setCurTask}
        options={buildSecondaryActions(props.can_claim && props.curTask.is_claimable_by_user, props.curTask.is_manageable_by_user)}
        defaultAction={defaultAction}
        label={defaultLabel}
        taskId={props.taskId}
        overrideEnabled={userHasPermission}
        bypassOverrideWithNoOptions={userHasPermission
          ? null
          : props.curTask.is_claimable_by_user
            ? true
            : null}
      />
    );
  };

  // In this case we can return early
  if (!props.is_timed && props.taskStatus.toUpperCase() === TaskStatusUpperNotStarted) {
    return createSecondaryActionButton(false)
  }

  switch (props.taskStatus.toUpperCase()) {
    case TaskStatusUpperNotStarted:
      return createSecondaryActionButton(false)
    case TaskStatusUpperInProgress:
      return createSecondaryActionButton(true)
    case TaskStatusUpperOnHold:
      return createSecondaryActionButton(false)
    default:
      return null
  }
}
