import * as React from "react";
import useSalesforceRequest from "@request/Salesforce";
import { ApiTask } from "../../../Types/task";
import useProfile from "../../../Hooks/Selectors/useProfile";
import usePersistentObject from "../../../Hooks/Selectors/usePersistentObject";
import { addTaskPermissionFields } from "../taskUtils";

export const TaskDrawerControl = React.createContext<[boolean, (a?: boolean) => void]>([false, () => null]);
export const TaskDrawerSubject = React.createContext<[a: ApiTask | null, b: (taskId: ApiTask['id'] | null, newTask?: ApiTask) => ApiTask | null]>([null, () => null]);
export const TaskDrawerLoading = React.createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>([false, () => null]);
export const TaskChangeShouldRefreshView = React.createContext<any[]>([]);


export default function TaskDrawerProvider(props: React.PropsWithChildren) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [curTask, setThisTask] = React.useState<ApiTask | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false)
  const getTask = useSalesforceRequest()
  const user = useProfile();
  const persistedObject = usePersistentObject()

  const setTask = (taskId: ApiTask['id'] | null, newTask?: ApiTask) => {
    if (newTask) {
      // in the case we want to set a whole task like for a follow-up form
      // console.log('setting current task from: ', curTask, " to: ", newTask)
      setThisTask(newTask)
      return newTask
    }

    if (!taskId) {
      // Exit early!
      setThisTask(null)
      return null
    }
    setLoading(true)
    // if anything needs to be done to a task before it is set that can happen here
    const taskGetRequest = getTask({ endpoint: `/api/task/${taskId}` })
    taskGetRequest.attemptSend().then((result) => {
      if (!result.callSent) { return null; } // session expired
      const json = result.GetParsedJson();

      if (json.hasOwnProperty("task")) {
        addTaskPermissionFields(json.task, user, persistedObject);
        if (json.task.id === taskId) {
          setThisTask(json.task)
          return
        }
        // In this instance the current task has switched before our respone was recived
      }
      throw Error('Failed to get current task')
    }).catch(() => {
      console.warn("Failed to get task!")
      //TODO: Handle error! We need to get something on the screen, so the user understands.
    }).finally(() => {
      setLoading(false)
    })
    return null;
  };

  const toggleTaskDrawer = (open?: boolean) => {
    setOpen((prev) => {
      switch (open) {
        case true:
          return true
        case false:
          setThisTask(null)
          return false
        default:
          if (prev) {
            setThisTask(null)
          }
          return !prev
      }
    })
  }

  return (
    <TaskDrawerSubject.Provider value={[curTask, setTask]}>
      <TaskDrawerControl.Provider value={[open, toggleTaskDrawer]}>
        <TaskDrawerLoading.Provider value={[loading, setLoading]}>
          <TaskChangeShouldRefreshView.Provider value={[shouldRefresh, setShouldRefresh]}>
            {props.children}
          </TaskChangeShouldRefreshView.Provider>
        </TaskDrawerLoading.Provider>
      </TaskDrawerControl.Provider>
    </TaskDrawerSubject.Provider >
  );
}
