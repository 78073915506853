import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as Yup from "yup";

import AppFormInputText from "@inputs/AppFormInputText";
import useYupValidationResolver from "@components/Inputs/Validators";
import FirebaseSignUpAndSignInSequence, { AuthModalSignUpCredentials } from "@config/Firebase/signUpAndSignInUserTooFirebase";
import {
  ClearSignUpContext,
  SignUpAuthContext,
} from "@context/Auth";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Divider, Grid
} from "@mui/material";

import "./style.css"
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import { logOut } from "@store/Reducers/Session";

interface PasswordFormInputProps {
  SignUpEmail: string;
  SignUpPassword: string;
  SignUpConfirmPassword: string;
}

const FormValidator = Yup.object().shape({
  SignUpPassword: Yup.string()
    .min(6, "Too Short!")
    .required("Please enter a password!"),
  SignUpConfirmPassword: Yup.string()
    .min(6, "Too Short!")
    .required("Please enter a password that matches above!")
    .oneOf([Yup.ref("SignUpPassword")], "Passwords must match!"),
});

export default function PasswordForm() {
  const [loading, setLoading] = React.useState(false);
  const userData = React.useContext(SignUpAuthContext);
  const clearSignUpContext = React.useContext(ClearSignUpContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const resolver = useYupValidationResolver(FormValidator);
  const defaultValues = {
    SignUpEmail: userData?.email,
    SignUpPassword: "",
    SignUpConfirmPassword: "",
  };
  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    clearErrors,
  } = useForm<PasswordFormInputProps>({
    resolver,
    mode: "onChange",
    defaultValues,
  });

  React.useEffect(() => {
    if (userData && userData.email) {
      setValue("SignUpEmail", userData.email);
    }
  }, [setValue, userData]);

  const isDisabled = React.useCallback(() => {
    if (Object.keys(errors).length > 0) {
      return true;
    }
    if (dirtyFields) {
      if (dirtyFields?.SignUpConfirmPassword && dirtyFields?.SignUpPassword) {
        return false;
      }
    }
    return true;
  }, [dirtyFields, errors]);

  const onSubmit = (Data: PasswordFormInputProps) => {
    if (userData) {
      setLoading(true);
      const formCredentials = new AuthModalSignUpCredentials(userData.email, Data.SignUpPassword, Data.SignUpConfirmPassword)
      const requestToSignUpOrLoginUser = new FirebaseSignUpAndSignInSequence(formCredentials)
      if (requestToSignUpOrLoginUser.isValid()) {
        requestToSignUpOrLoginUser.attemptSignUp().then(async (FirebaseUser) => {
          if (FirebaseUser !== null) {
            userData.setProvider(FirebaseUser.providerId)
            const firestoreWriteResult = await userData.initializeFireStore()
            if (firestoreWriteResult === null) {
              dispatch(logOut());
              navigate('/login');
              return;
            }

            reset({
              SignUpEmail: userData?.email,
              SignUpConfirmPassword: "",
              SignUpPassword: "",
            })
            clearSignUpContext()
            clearErrors()
            setLoading(false)
            return navigate("/home")
          }
          throw new Error(requestToSignUpOrLoginUser.errorMessage || "Failed to sign up user in Firebase.")
        }).catch((e) => {
          console.log('ERROR SET TO VIEW', e)
          setLoading(false)
          setError("SignUpEmail", { message: e.message })
          if (requestToSignUpOrLoginUser.shouldNavigate) {
            setTimeout(() => {
              navigate('/login')
            }, 5000)
          }
        })
      }
    }
    return;
  };
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      direction="column"
      spacing={2}
      height="auto"
      minWidth="240"
      px={2}
      pb={2}
      component="form"
      id="signUpForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item width="100%" mb={1}>
        <Alert severity="info" sx={{ display: 'flex', alignItems: 'center', borderRadius: '8px' }}>To complete sign-up, please create a password for Ohana Portal access.</Alert>
      </Grid>
      <Grid item width="100%">
        <AppFormInputText
          name="SignUpEmail"
          type="text"
          control={control}
          label="Email"
          required
          disabled={userData?.email !== undefined}
          variant="outlined"
          readOnly
          id={errors.SignUpEmail ? "SignUpEmail_Error" : "SignUpEmail_Disabled"}
        // helperText="To complete sign-up, please create a password for Ohana Portal Access."
        />
      </Grid>
      <Grid item width="100%">
        <AppFormInputText
          name="SignUpPassword"
          type="password"
          control={control}
          label="Password"
          required
          setValue={setValue}
          disabled={loading}
          variant="outlined"
          readOnly
        />
      </Grid>
      <Grid item width="100%">
        <AppFormInputText
          name="SignUpConfirmPassword"
          type="password"
          control={control}
          label="Confirm Password"
          required
          setValue={setValue}
          disabled={loading}
          variant="outlined"
          readOnly
        />
      </Grid>
      <Grid
        item
        width="100%"
        sx={{ color: (theme) => theme.palette.common.black, opacity: 0.5 }}
      >
        <Divider />
      </Grid>
      <Grid item width="100%" display="flex" justifyContent="center">
        <LoadingButton
          aria-label="finish"
          disabled={isDisabled()}
          type="submit"
          loading={loading}
          variant="contained"
          form="signUpForm"
          sx={(theme) => ({
            height: "auto",
            width: "auto",
            textTransform: "none",
            bgcolor: theme.palette.primary.light,
            "&:hover": { backgroundColor: theme.palette.primary.dark },
          })}
        >
          Finish
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
