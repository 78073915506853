import { taskTypeOptions } from "@classes/Task/taskStrings";

export const defaultTaskOrder = taskTypeOptions;
export class UserSettings {
  constructor(
    public readonly dark_mode: boolean = false,
    public readonly task_order = defaultTaskOrder
  ) {}
  createFirestorePayload() {
    return {
      dark_mode: this.dark_mode,
      task_order: this.task_order,
    };
  }
  //! Talk to Joe about which would be better,
  //! 1: creating new settings when dispatching to the store [current]
  //! 2: using a setter to maintain the settings when calling update.
  // set(setting: "dark_mode" | "task_order", value: boolean | string[]) {
  //   switch (setting) {
  //     case "dark_mode":
  //       const mode = !!value;
  //       this.dark_mode = mode;
  //       return;
  //     case "task_order":
  //       const order = Array.isArray(value) ? value : defaultTaskOrder;
  //       this.task_order = order;
  //       return;
  //     default:
  //       throw new Error("Please provide a valid setting.");
  //   }
  // }
  // get settings() {
  //   return {
  //     dark_mode: this.dark_mode,
  //     task_order: this.task_order,
  //   };
  // }
}
