import { TaskDrawerControl } from "@context/Task/TaskDrawer";
import { Typography } from "@mui/material";
import AssigneeReporterRow from "../AssigneeReporterRow";
import TimeRow from "../TimeRow";
import * as React from 'react'
import { AppLink } from "../TitleRow/AppLink";
import InventoryRow from "../InventoryRow";
import { ApiTask } from "../../../../../Types/task";
import { CardItem } from "@components/Typography/CardItemText";

interface TaskDetailProps {
  curTask: ApiTask | null
  hideTitle?: true;
  href?: `/${string}`;
  linkText?: string;
}

export default function TaskDetail(props: TaskDetailProps) {
  const [, toggleDrawer] = React.useContext(TaskDrawerControl);
  if (props.curTask === null) return null
  if (
    (props.href && props.linkText === undefined) ||
    (props.href === undefined && props.linkText)
  ) {
    console.warn("Missing fields required to display link.")
  }
  return (
    <div className={`Task-Card-Item-Container ${!props.hideTitle && 'Task-Card-Item-Border'}`}>
      {(!props.hideTitle && props.linkText) && (
        <div className="Task-Card-Item-Row No-Margin">
          <Typography variant="h6" mr={'5px'}>Task Details</Typography>
          {props.href && props.linkText &&
            <AppLink
              className="full-task-link"
              onClick={() => { toggleDrawer(false) }}
              text={props.linkText}
              to={props.href}
            />

          }
        </div>
      )}
      <AssigneeReporterRow curTask={props.curTask} />
      <InventoryRow curTask={props.curTask} />
      <TimeRow curTask={props.curTask} />
      <ShuttleDetails task={props.curTask} />
    </div>
  );
}


function ShuttleDetails({ task }: { task: ApiTask | null }) {
  if (!task || task.type !== 'Shuttle Service') return null
  return (
    <div className="Task-Card-Item-Container">
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Pick Up Location" text={task.pickup_location || "-"} />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Drop Off Location" text={task.dropoff_location || "-"} />
        </div>
      </div>
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Adults" text={task.number_of_adults || "-"} />
        </div>
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Children" text={task.number_of_children || "-"} />
        </div>
      </div>
      <div className="Task-Card-Item-Row">
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Waiver" text={task.waiver_verified ? "Verified" : "Unverified"} />
        </div>
      </div>
    </div>
  )
}