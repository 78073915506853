import { captureException } from '@sentry/react'

const verboseLogging = true;

export default function processError(error: any, extraDetails: string) {
  if (!error) {
    console.warn("processing invalid error", error)
    return ""
  }

  let message = "There was a problem. Please check your connection, then reload the page.";
  let stackTrace = "Stack trace not found."; // default; hopefully this is overwritten
  if (error instanceof Error) {
    verboseLogging && console.log('processError received an instance of Error');
    if (error?.stack) {
      verboseLogging && console.log('processError found a stack');
      //TODO: investigate truncated stack trace
      stackTrace = error.stack;
    }
    if (error?.message) {
      verboseLogging && console.log('processError found a message');
      // message = `${message} ${error.message}`;
    }
  } else if (typeof error === "string") {
    verboseLogging && console.log('processError received a string (not an Error)');
    message = `${message} ${error}`;
  }
  else {
    verboseLogging && console.log('processError received an unknown error object (' + (typeof error) + ')');
  }

  console.log('extraDetails', extraDetails);
  console.log('message', message);

  // Some well-known errors go unsent (far easier than managing Sentry filter rules).
  let sendToSentry = true;

  if (error instanceof Error) {
    if (error.name === 'TypeError') {
      switch (error.message) {
        case 'The network connection was lost.':
          sendToSentry = false;
          break;

        //NOTE: Intentionally no default case.
      }
    }
  }

  console.warn(stackTrace);

  // send errors to Sentry unless they're known/acceptable, e.g. network disconnect
  if (sendToSentry) {
    captureException(error);
  }

  return message;
}