import { AppState } from "@store/Reducers/AppState";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

type appStateSelectors = keyof AppState



const useAppState = (selector: appStateSelectors) => {
  const appStateSelector = (reduxState: RootState) => reduxState.state[selector]
  const appState = useSelector(appStateSelector);
  return appState
};

export default useAppState;
