import * as React from "react";
import { Typography, useTheme } from "@mui/material";

export function TaskFormTitle(props: { title: string }) {
  if (typeof props.title !== 'string' || props.title === "") {
    throw new Error("Please provide a title prop to TaskFormTitle.")
  }
  return (
    <Typography fontWeight={800} variant="h5">
      {props.title}
    </Typography>
  )
}

export function TaskFormLabel(props: { label: string; }) {
  const theme = useTheme();
  if (typeof props.label !== 'string' || props.label === "") {
    throw new Error("Please provide a label to TaskFormLabel");
  }
  const { palette: { mode } } = theme;

  return (
    <Typography fontWeight={800} className={`Task-Form-Label ${mode === "dark" && "Task-Form-Label-Dark"}`}>
      {props.label}
    </Typography>
  );
}
