import * as React from "react";
import { useParams } from "react-router-dom";
import { SnackBarData } from "../../Context/Snackbar";
import User from "@classes/User";
import useDocumentTitle from "@hooks/Window/useDocTitle";


import UserInfo from "./UserInfo"
import useSalesforceRequest from "@request/Salesforce";

import "./userPage.css"
import ErrorFallback from './Error'
import Fallback from './Fallback'
import { StyledBoxContainer } from "@containers/StyledBoxContainer";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";
import { ApiQueueList } from "@store/Reducers/PersistentObject";
import { Control, FormState, useForm } from "react-hook-form";
import { formatPhoneNumber } from "@components/Inputs/AppFormInputPhone";
import useProfile from "@hooks/Selectors/useProfile";
import useYupValidationResolver from "@components/Inputs/Validators";
import * as Yup from 'yup'


interface EditableUsersContentInterface {
  users_phone: string;
  users_mobile_phone: string;
}


export interface UserContactProps {
  id: string;
  email: string;
  phone?: string;
  mobile_phone?: string;
  username: string;
  timezone: string;
}


export interface UserInfoProps extends ReturnType<User['buildUserInfo']> {
  role: string;
  control: Control<any>,
  editField: () => void,
  editableFields: boolean,
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>,
  readonly: boolean,
  formState: FormState<any>
}

export const PhoneRegex = /^(\+[1]?\s)\(?\d{3}\)?[\s.-]?\d{3}[\s.-]\d{4}$/m

const UserUpdateValidator = Yup.object().shape({
  users_phone: Yup.string().trim().matches(PhoneRegex, "Please enter a valid 10 digit plus contry code phone number."),
  users_mobile_phone: Yup.string().trim().matches(PhoneRegex, "Please enter a valid 10 digit plus contry code phone number."),
})

/**
 *
 * @params /users/<employee_id> url params
 * @returns JSX.Element | null
 */
export default function UserPage() {
  // ------------ Processes Start ----------------
  useDocumentTitle("Users Lookup")
  const [, setSnackData] = React.useContext(SnackBarData);
  // ------------ Processes End ----------------

  // --------- Async Request Constructors Start -------------
  const getUserByEmployeeId = useSalesforceRequest();
  const userPutRequest = useSalesforceRequest()
  const persistedObjectStore = usePersistentObject();
  // --------- Async Request Constructors Start -------------

  // -------- Client and target User Data Start --------------
  const { "*": employeeId } = useParams();
  const currentlyLoggedInUser = useProfile()
  const [targetUser, setTargetUser] = React.useState<User | null>(null);
  const readonly = currentlyLoggedInUser !== null && currentlyLoggedInUser.id !== employeeId
  // -------- Client and target User Data End --------------

  // --------- Component States Start ---------
  const [enableEditMode, setEnableEditMode] = React.useState<boolean>(false)
  const [groupsThatPossesUserId, setGroupsThatPossesUserId] = React.useState<ApiQueue[]>([])
  // --------- Component States End ---------


  // ---------- Loading States (async blockers) Start ------------
  const [userRequestState, setUserRequestState] = React.useState<APP_STATE>('unset');
  const [groupsRequestState, setGroupsRequestState] = React.useState<APP_STATE>('unset')
  // ---------- Loading States End ------------

  // ------------ Form Components Start --------------
  const resolver = useYupValidationResolver(UserUpdateValidator)
  const { reset, control, handleSubmit, formState } = useForm<EditableUsersContentInterface>({
    mode: "all",
    reValidateMode: "onChange",
    resolver
  })
  // ------------ Form Components End --------------

  React.useEffect(() => {
    if (targetUser === null && userRequestState === 'unset') {
      setUserRequestState("loading")
      const request = getUserByEmployeeId({ endpoint: `/api/user/${employeeId}` });
      request.attemptSend().then(async (result) => {
        if (!result.callSent) { return; } // session expired
        const json = result.GetParsedJson();
        if (json.status !== 200) {
          throw new Error('failed to get user; status: ' + json.status);
        }
        if (!json.hasOwnProperty("user")) {
          throw new Error('user not attached to sucessful response');
        }
        if (employeeId !== json.user.id) return
        reset({
          users_phone: formatPhoneNumber(json.user.phone),
          users_mobile_phone: formatPhoneNumber(json.user.mobile_phone)
        })
        setTargetUser(new User(json.user));
        setUserRequestState("success")
      })
        .catch((e) => {
          console.warn(e);
          setSnackData({
            message: `Failed to get user with id: ${employeeId}`,
            severity: "error",
            color: "error",
          });
          setUserRequestState("error");
        });
    }
  }, [employeeId, reset, getUserByEmployeeId, userRequestState, setSnackData, targetUser, persistedObjectStore]);

  React.useEffect(() => {
    if (groupsRequestState === 'unset' && targetUser !== null && persistedObjectStore.checkQueues(ApiQueueList['allQueues'])) {
      setGroupsRequestState("loading")
      try {
        setGroupsThatPossesUserId(persistedObjectStore.getQueue(ApiQueueList['allQueues']).filter((queue) => queue.user_ids.includes(targetUser.id)))
        setGroupsRequestState("success")
      } catch (error) {
        setGroupsRequestState("error")
      }
    }
  }, [groupsRequestState, persistedObjectStore, targetUser])

  if (userRequestState === 'loading') return (
    <StyledBoxContainer title="">
      <Fallback minWidth="350px" message="Loading User..." />
    </StyledBoxContainer>
  )
  if (userRequestState === 'error') return <ErrorFallback message={`Faild to find a user with that id.`} />

  // TS null check for targetUser

  if (targetUser === null) {
    return null
  }

  if (groupsRequestState === 'error') {
    setSnackData({
      message: "Problem finding user groups.",
      severity: "error"
    })
  }

  const editField = () => {
    if (readonly) return
    setEnableEditMode((prev) => {
      return !prev
    })
  }

  const onSubmit = handleSubmit(async (data) => {
    if (!targetUser === null) return
    if (currentlyLoggedInUser === null) return
    if (readonly) return

    // Redundant way to disable the fields. the input will be disabled also on formState.isSubmitting
    editField()
    const updatedUserPayload = { ...targetUser.generateSalesforceUpdatePayload(), phone: data.users_phone, mobile_phone: data.users_mobile_phone }
    const putRequest = userPutRequest({ endpoint: `/api/user/${targetUser.id}`, method: "PUT", body: JSON.stringify(updatedUserPayload) })
    try {
      const putResult = await putRequest.attemptSend()
      if (!putResult.callSent) return // expired session
      if (putResult.httpResponse && putResult.httpResponse.ok) {
        const json = putResult.GetParsedJson()
        if (json.status === 200) {
          if (json.user.id === employeeId) {
            const updatedUser = new User(Object.assign(targetUser, json.user))
            setTargetUser(updatedUser)
            console.log(updatedUser)
          }
          return json.user
        }
      }
      throw new Error("Failed to update user.")
    } catch (e) {
      console.log(e)
      return null
    }
  })

  const onEsc: React.KeyboardEventHandler<HTMLDivElement> = (buttonClicked) => {
    if (readonly) return
    if (buttonClicked.key === "Escape") {
      editField()
      reset({
        users_phone: targetUser.phone,
        users_mobile_phone: targetUser.mobile_phone
      })
    }
  }

  return (
    <div className="User-Page" onKeyDown={onEsc}>
      <div className="User-Page-Container">
        <StyledBoxContainer borderBottom title={targetUser.name} subTitle={targetUser.title}>
          <UserInfo role="users" readonly={readonly} {...targetUser.buildUserInfo()} control={control} editField={editField} editableFields={enableEditMode} onSubmit={onSubmit} formState={formState} />
        </StyledBoxContainer>
        {groupsRequestState === "success" && groupsThatPossesUserId.length >= 1 &&
          (
            <StyledBoxContainer borderBottom title={`Groups`} subTitle={`${targetUser.first_name} is a member of the following teams.`}>
              <div className="User-Page-Groups-Container">
                {groupsThatPossesUserId.map((group) => {
                  return (
                    <div key={`Group_${group.name}`}>
                      {group.name}
                    </div>
                  )
                })}
              </div>
            </StyledBoxContainer>
          )
        }
      </div>
    </div>
  );
}
