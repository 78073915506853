import { salesforce_api } from "@config/index";
import { useSalesforceRequestHookParams } from "./index";
import { SalesforceRequestAuthParams } from "./SalesforceRequestAuthParams";

const CONSOLE_COLOR = "color:#f8f";

export class SalesforceRequestParams {
  public readonly headers: Headers;
  public readonly path: string;

  constructor(
    private readonly _authParams: SalesforceRequestAuthParams | null,
    private readonly _requestParams: useSalesforceRequestHookParams
  ) {
    // validate and establish default request params, many of which are optional
    if (
      _requestParams.contentType === undefined ||
      _requestParams.contentType === ""
    ) {
      _requestParams.contentType = "application/json";
    }

    this.headers = new Headers();

    if (_requestParams.requiresAuth === undefined) {
      _requestParams.requiresAuth = true;
    }

    // We need to check two things here to validate this request. If authParams are present,
    // then be sure the the request params is valid.
    if (this._authParams !== null) {
      if (this._requestParams.requiresAuth !== true) {
        console.log(this.writeDevDetailsReport());
        console.log("%cauthParams", CONSOLE_COLOR, this._authParams); // these will likely get filtered by Sentry
        throw new Error(
          "malformed call; sending auth params with non-true requiresAuth (" +
            this._requestParams.requiresAuth +
            ")"
        );
      }

      this.headers.set("Authorization", this._authParams.accessToken);
    }

    this.headers.set("Content-Type", _requestParams.contentType);
    this.headers.set("x-api-key", salesforce_api.x_api_key);

    if (_requestParams.headers !== undefined) {
      for (let key in _requestParams.headers) {
        this.headers.set(key, _requestParams.headers[key]);
      }
    }

    if (
      _requestParams.hostPath === undefined ||
      _requestParams.hostPath === ""
    ) {
      _requestParams.hostPath = salesforce_api.rest;
    }

    this.path = `${_requestParams.hostPath}${_requestParams.endpoint}`;

    if (_requestParams.method === undefined) {
      _requestParams.method = "GET";
    }

    // at this point, all SF, user and http params should be ready to go
  }

  //NOTE: 'accessToken' and 'issuedAt' are writable. They can change, e.g. in the event of an SF token refresh.

  get accessToken() {
    if (this._authParams === null) {
      throw new Error("invalid accessToken getter; auth params not present");
    }

    return this._authParams.accessToken;
  }

  get issuedAt() {
    if (this._authParams === null) {
      throw new Error("invalid issuedAt getter; auth params not present");
    }

    return this._authParams.issuedAt;
  }

  set accessToken(newToken: string) {
    if (this._authParams === null) {
      throw new Error("invalid accessToken setter; auth params not present");
    }

    this._authParams.accessToken = newToken;
  }

  set issuedAt(newTime: string) {
    if (this._authParams === null) {
      throw new Error("invalid issuedAt setter; auth params not present");
    }

    this._authParams.issuedAt = newTime;
  }

  checkUsesAccessToken() {
    return this._requestParams.requiresAuth === true;
  }

  getBody() {
    return this._requestParams.body;
  }

  getContentType() {
    return this._requestParams.contentType;
  }

  getEmail() {
    if (this._authParams === null) {
      throw new Error("invalid get; auth params not present, on getEmail()");
    }

    return this._authParams.email;
  }

  getMethod() {
    return this._requestParams.method;
  }

  getRefreshToken() {
    if (this._authParams === null) {
      throw new Error("invalid get; auth params not present");
    }

    return this._authParams.refreshToken;
  }

  getSalesforceUsername() {
    if (this._authParams === null) {
      throw new Error(
        "invalid get; auth params not present, on getSalesforceUsername()"
      );
    }

    return this._authParams.salesforceUsername;
  }

  isValid() {
    //NOTE: Some of this validation is redundant, but it's a negligible cost. This is core to every call; better safe than sorry.

    if (!this._requestParams.endpoint) {
      console.warn("bad endpoint");
      return false;
    }

    if (this._requestParams.requiresAuth === false) {
      // we will allow (a request with) these params to be sent
      return true;
    }

    if (this._authParams === null) {
      console.warn("auth params missing");
      return false;
    }

    if (this._authParams.accessToken === "") {
      console.warn("bad access token");
      return false;
    }

    if (this._authParams.refreshToken === "") {
      console.warn("bad refresh token");
      return false;
    }

    return true;
  }

  writeDevDetailsReport() {
    let report =
      "SF Request Params: " +
      "ct: " +
      this._requestParams.contentType +
      ", req auth: " +
      this._requestParams.requiresAuth +
      ", body: " +
      String(this._requestParams.body).slice(0, 200);

    report += "; auth: ";
    report +=
      this._authParams === null
        ? "n/a"
        : "valid: " + this._authParams.isValid();

    return report;
  }
}
