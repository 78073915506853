import * as React from "react";
import { Box } from "@mui/system";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      className={props.className}
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`apptabs-tabpanel-${index}`}
      id={`app_tabs-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="Tab-Inner-Container">
          {children}
        </Box>
      )}
    </div>
  );
}
