import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import LockOutlined from "@mui/icons-material/LockOutlined";
import { logos } from "@constants";
import { Grid } from "@mui/material";

interface HeaderProps {
  title: string;
}

export default function ModalHeader(headerProps: HeaderProps) {
  return (
    <Grid
      container
      direction="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={2}
      mb={2}
    >
      <Avatar
        sx={{
          bgcolor: (theme) => theme.palette.primary.light,
          width: "3rem",
          height: "auto",
        }}
        src={logos.MRR_Logo_White_Icon}
        alt="MRR_Logo_White_Icon"
      >
        <LockOutlined />
      </Avatar>
      <Typography
        component="h4"
        variant="h4"
        color={(theme) => theme.palette.common.black}
        mt={1}
      >
        {headerProps.title}
      </Typography>
    </Grid>
  );
}
