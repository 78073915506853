import { ApiTask } from "../../Types/task";
import { SortDirectionKey } from "../Task/Sub_Components/sortTasks";

//PERF: This sort is not optimal, in that it creates several Date objects on each iteration.
//      We should use the (new) faster sort in TaskSearchFilterList. However, that would mean
//      converting the flow to use SortableTask. Since this is for _listing_ tasks, the
//      slowdown will never perceived (never a meaningfully large data set).
//TODO: Just don't use it for anything else, and if there is good opportunity, replace it.
export function sortTasksByDueDateForListing(tasks: ApiTask[] | null, key: SortDirectionKey) {
  if (tasks === null)
    return null;

  return tasks.sort((a: ApiTask, b: ApiTask) => {
    if (new Date(a.due_datetime) > new Date(b.due_datetime)) {
      if (key === 'asc') {
        return 1;
      }
      return -1;
    }
    if (new Date(a.due_datetime) < new Date(b.due_datetime)) {
      if (key === 'desc') {
        return 1;
      }
      return -1;
    }
    return 0;
  });
}
