import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { parseISO } from "date-fns";
import { useMediaQuery, useTheme } from "@mui/material";
import PagenationProps from "../Interfaces";

export function PagenationDatePicker(props: Pick<PagenationProps, "handleDayChange" | "handleWeekChange" | "startDate" | "filterBy"> & { openDatePicker: boolean, setOpenDatePicker: React.Dispatch<React.SetStateAction<boolean>> }) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        className="pagenation-date-picker"
        disableMaskedInput
        open={props.openDatePicker}
        onClose={() => {
          props.setOpenDatePicker(false)
        }}
        inputFormat={mobile ? "eee, MMM do" : "eeee, MMMM do"}
        showDaysOutsideCurrentMonth
        views={['year', 'month', 'day']}
        onChange={(date) => {
          switch (props.filterBy) {
            case "day":
              return props.handleDayChange(date);
            case "week":
              return props.handleWeekChange(date)
            default:
              console.error("Bad input provided to pagenation")
              return
          }
        }}
        value={props.startDate ? parseISO(props.startDate).toString() : null}
        InputProps={{
          readOnly: true
        }}
        renderInput={(params) => {
          params.value = props.startDate;
          if (params?.inputProps) {
            params.inputProps.readOnly = true;
          }
          return (
            <TextField
              {...params}
              className="pagenation-date-picker-input"
              variant="outlined"
              aria-readonly
              contentEditable={false}
              onClick={() => {
                props.setOpenDatePicker(true)
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  )
}