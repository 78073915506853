export class SalesforceRequestAuthParams {
  constructor(
    //TODO: Do we _have_ to default to empty strings? Could we require values here, or are they unavailable?
    private _accessToken: string = "",
    public readonly refreshToken: string = "",
    public readonly email: string = "",
    private _issuedAt: string = "",
    public readonly salesforceUsername: string = ""
  ) {}

  get accessToken() {
    return this._accessToken;
  }
  get issuedAt() {
    return this._issuedAt;
  }
  set accessToken(newToken: string) {
    this._accessToken = newToken;
  }
  set issuedAt(newTime: string) {
    this._issuedAt = newTime;
  }

  isValid() {
    return [
      this._accessToken !== "",
      this.refreshToken !== "",
      this.email !== "",
      this._issuedAt !== "",
      this.salesforceUsername !== "",
    ].includes(true);
  }
}
