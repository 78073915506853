import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { pluralize } from "../../TaskGroup";
import "./modalStyles.css"
export default function SpecialRequest(props: { open: boolean; request: Pick<ApiReservation, "has_check_in_requests" | "check_in_requests" | "check_in_requests_detail">; }) {
  if (!props.open)
    return null;
  if (!props.request.has_check_in_requests && !props.request.check_in_requests_detail)
    return null;

  const requestTitles = props.request.has_check_in_requests ? props.request.check_in_requests!.split(";").sort() : []
  return (
    <Stack direction='row' className='slide' mb={1}>
      {requestTitles.length > 0 &&
        <div className="Task-Card-Item-Row-Split">
          <Typography fontWeight="bold" variant="caption">{pluralize(requestTitles.length, 'Request', 's') + ` (${requestTitles.length})`}</Typography>
          {requestTitles.map((checkInRequest) => {
            return <Typography key={checkInRequest}>{checkInRequest}</Typography>;
          })}
        </div>
      }
      {props.request.check_in_requests_detail &&
        <div>
          <Typography fontWeight="bold" variant="caption">Request Details</Typography>
          <Typography>{props.request.check_in_requests_detail}</Typography>
        </div>
      }
    </Stack>
  );
}
// export function SpecialRequestModal(props: { close: () => void, open: boolean; request: Pick<ApiReservation, "has_check_in_requests" | "check_in_requests" | "check_in_requests_detail">; }) {
//   if (props.request.has_check_in_requests === false)
//     return null;
//   if (props.request.check_in_requests === undefined) return null
//   const requestTitles = props.request.check_in_requests.split(";")
//   return (
//     <Modal onClose={props.close} open={props.open} sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100vw', height: '100vh' }}>
//       <Paper
//         elevation={2}
//         className="modal-paper">
//         <header className="modal-header">
//           <IconButton onClick={props.close}><Close /></IconButton>
//           <Typography variant="h5">Special Request</Typography>
//         </header>
//         <div className="modal-content">
//           <ul className="check_in_request">
//             <Typography whiteSpace="nowrap" variant="h5">{`Request${requestTitles.length <= 1 ? "" : `s (${requestTitles.length})`}`}</Typography>
//             {requestTitles.map((checkInRequest) => {
//               return (
//                 <li className="check_in_request-item">
//                   <Typography>{checkInRequest}</Typography>
//                 </li>
//               )
//             })}
//           </ul>
//           <ul className="check_in_request">
//             <Typography whiteSpace="nowrap" variant="h5">Request Details</Typography>
//             <li className="check_in_request-item details">
//               <Typography>{props.request.check_in_requests_detail}</Typography>
//             </li>
//           </ul>
//         </div>
//       </Paper>
//     </Modal>
//   );
// }
