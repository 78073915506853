import * as redux from "redux";
import User from "@classes/User";
import { Dispatch } from "redux";
import { signOut } from "@firebase/auth";
import { auth, firestore } from "../../../Config/Firebase/app";
import { removeAppState } from "../AppState";
import storage from "redux-persist/lib/storage";
import { SalesforceRequest } from "@request/Salesforce/SalesforceRequest";
import { SalesforceRequestParams } from "@request/Salesforce/SalesforceRequestParams";
import { SalesforceRequestAuthParams } from "@request/Salesforce/SalesforceRequestAuthParams";
import { captureException } from "@sentry/browser";
import { removeAllPersistentObjects } from "@store/Reducers/PersistentObject";
import { Session } from "./Session";
import { UserSettings } from "./UserSettings";
import { getDoc, setDoc, doc } from "firebase/firestore";

const SET_USER = "session/setUser";
const REMOVE_USER = "session/removeUser";
const UPDATE_USER = "session/updateUser";
const SET_USER_SETTINGS = "session/setUserSettings";
const UPDATE_USER_SETTINGS = "session/updateUserSettings";

export const setUser = (user: User | null) => ({
  type: SET_USER,
  user,
});

export const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};

export const updateUser = (user: User) => ({
  type: UPDATE_USER,
  user,
});

const setUserSettings = (settings: UserSettings) => ({
  type: SET_USER_SETTINGS,
  settings,
});

export const updateUserSettings = (settings: UserSettings) => ({
  type: UPDATE_USER_SETTINGS,
  settings,
});

export const putUserData =
  (updatedUser: User) => async (dispatch: Dispatch) => {
    try {
      const sfRequestAuthParams = new SalesforceRequestAuthParams(
        updatedUser.getAccess_Token(),
        updatedUser.getRefresh_Token(),
        updatedUser.email,
        updatedUser.sf_issued_at,
        updatedUser.username
      );
      const putUserRequestParams = new SalesforceRequestParams(
        sfRequestAuthParams,
        {
          endpoint: `/api/user/${updatedUser.id}`,
          method: "PUT",
          body: JSON.stringify(updatedUser.generateSalesforceUpdatePayload()),
        }
      );
      const putUserRequest = new SalesforceRequest(putUserRequestParams);
      const result = await putUserRequest.attemptSend();
      if (!result.callSent) {
        return;
      } // session expired
      const json = result.GetParsedJson();
      if (json.status !== 200) {
        throw new Error("bad response to PUT user", json.status);
      }
      if (!json.hasOwnProperty("user")) {
        throw new Error(
          "user missing in response json from PUT user",
          json.status
        );
      }
      const newUser = new User({
        ...json.user,
        sf_access_token: updatedUser.getAccess_Token(),
        sf_refresh_token: updatedUser.getRefresh_Token(),
        sf_issued_at: updatedUser.sf_issued_at,
      });
      return await dispatch(updateUser(newUser));
    } catch (error) {
      console.error(error);
      captureException(error);
    }
  };

export const logOut = () => async (dispatch: Dispatch) => {
  if (auth) {
    //Signing out of firebase here...
    await signOut(auth);
  }
  dispatch(removeAppState());
  dispatch(removeUser());
  dispatch(removeAllPersistentObjects());
  storage.removeItem("persist:session");
  window.sessionStorage.clear();
  window.localStorage.clear();
};

export const readUserSettings =
  (user: User, options?: { dark_mode?: boolean; task_order?: string[] }) =>
  async (dispatch: Dispatch) => {
    // do work
    if (!user) {
      console.warn("No user found to read settings from.");
      return;
    }
    if (!auth.currentUser) {
      console.warn(
        "Currently unable to communicate with firestore do to lack of auth.currentUser."
      );
      return;
    }
    const docRef = doc(firestore, "Users", user.email);
    const document = await getDoc(docRef);
    if (document.exists()) {
      const documentData = document.data();
      if (documentData.hasOwnProperty("user_settings")) {
        let { dark_mode, task_order } = documentData.user_settings;
        return dispatch(
          setUserSettings(new UserSettings(dark_mode, task_order))
        );
      } else {
        const user_settings = new UserSettings(
          options?.dark_mode,
          options?.task_order
        );
        await setDoc(docRef, {
          ...documentData,
          user_settings: user_settings.createFirestorePayload(),
        });
        return dispatch(setUserSettings(user_settings));
      }
    } else {
      throw new Error("No user document found! Unable to sync user settings.");
    }
  };

export const SyncUserSettings =
  (user: User | null, key: keyof UserSettings, value: any) =>
  async (dispatch: Dispatch) => {
    if (!user) {
      console.warn("There is no user avalible to sync with.");
      return;
    }
    if (!auth.currentUser) {
      console.warn(
        "Currently unable to communicate with firestore do to lack of auth.currentUser."
      );
      return;
    }
    const docRef = doc(firestore, "Users", user.email);
    const document = await getDoc(docRef);
    if (document.exists()) {
      const documentData = document.data();
      const user_settings = documentData.user_settings;
      if (user_settings) {
        const documentClone = documentData.user_settings;
        documentClone[key] = value;
        const { dark_mode, task_order } = documentClone;
        const userSettings = new UserSettings(dark_mode, task_order);
        await setDoc(
          docRef,
          {
            ...documentData,
            user_settings: userSettings.createFirestorePayload(),
          },
          { merge: true }
        );
        return dispatch(setUserSettings(userSettings));
      } else {
        console.warn("No user settings found. Create settings now.");
        return await readUserSettings(user, { [key]: value })(dispatch);
      }
    } else {
      throw new Error("No user document found! Unable to sync user settings.");
    }
  };

const sessionReducer: redux.Reducer = (
  state: Session,
  action: redux.AnyAction
) => {
  switch (action.type) {
    // file deepcode ignore DuplicateCaseBody: <Not needed in this situation>
    case SET_USER:
      const newSession = new Session(action.user, state.userSettings);
      return newSession;
    case UPDATE_USER:
      const updatedSession = new Session(action.user, state.userSettings);
      return updatedSession;
    case REMOVE_USER:
      return new Session();
    case SET_USER_SETTINGS:
      return new Session(state.user, action.settings);
    case UPDATE_USER_SETTINGS:
      return new Session(state.user, action.settings);
    default:
      return state ?? new Session();
  }
};

export default sessionReducer;
