import * as React from "react";
import { TaskDrawerControl } from "../../../../Context/Task/TaskDrawer";
import { Stars } from "@mui/icons-material";
import { CardItem } from "@components/Typography/CardItemText";
import { ApiTask } from "../../../../Types/task";

export default function InventoryRow({ curTask }: { curTask: ApiTask | null }) {
  const [, toggleDrawer] = React.useContext(TaskDrawerControl)
  if (curTask === null) return null
  const isCompatableTask = curTask.type === 'Drop' || curTask.type === 'Pickup'
  if (!isCompatableTask) {
    return null
  }
  if (!curTask.inventory_id) {
    return null
  }

  return (
    <>
      <div className="Task-Card-Item-Container">
        <div className="Task-Card-Item-Row">
          <div className="Task-Card-Item-Row-Split">
            <CardItem header="Inventory Id" text={curTask.inventory_id} />
          </div>
        </div>
      </div>
    </>
  );
}
