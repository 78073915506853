import { doc, setDoc, collection, getDoc } from "@firebase/firestore";
import { updateProfile } from "firebase/auth";
import { auth, firestore } from "@config/Firebase/app";
import isMemeberOfGroup from "@helpers/isMemberOfGroup";
import { formatPhoneNumber } from "../../Components/Inputs/AppFormInputPhone";
import { SalesforceRequestAuthParams } from "@request/Salesforce/SalesforceRequestAuthParams";
import { SalesforceRequestParams } from "@request/Salesforce/SalesforceRequestParams";
import { SalesforceRequest } from "@request/Salesforce/SalesforceRequest";

export interface UserConstructor {
  alias: string;
  email: string;
  first_name: string;
  has_profile_photo: boolean;
  id: string;
  is_active: boolean;
  language: string;
  last_name: string;
  locale: string;
  name: string;
  nick_name: string;
  profile_picture_url: string;
  profile_thumbnail_url: string;
  role_id: string;
  role_name: string;
  sf_access_token: string;
  sf_refresh_token: string;
  sf_user_id: string;
  timezone: string;
  title: string;
  user_type: string;
  username: string;
  is_admin: boolean;

  // optional
  department?: string;
  mobile_phone?: string;
  phone?: string;
  sf_issued_at?: string;
  getAccess_Token?: () => string;
  getRefresh_Token?: () => string;
}

export default class User {
  public readonly id: string;

  public readonly is_active: boolean;

  public readonly user_type: string;

  public readonly first_name: string;

  public readonly last_name: string;

  public readonly role_id: string;

  public readonly role_name: string;

  public readonly name: string;

  public readonly title: string;

  public readonly email: string;

  public readonly alias: string;

  public readonly username: string;

  public readonly nick_name: string;

  public readonly timezone: string;

  public readonly has_profile_photo: boolean;

  public profile_picture_url: string;

  public profile_thumbnail_url: string;

  public readonly language: string;

  public readonly locale: string;

  public readonly is_admin: boolean;

  private sf_access_token: string;

  private readonly sf_refresh_token: string;

  private _darkMode: boolean;

  // optional
  public sf_issued_at: string = "";

  public readonly department: string = "";

  public phone: string = "";

  public mobile_phone: string = "";

  public auth_provider: string | null;

  public messaging_id?: string;

  constructor(userData: UserConstructor) {
    //NOTE: User is constructed in several ways, in several places. The type that
    //      comes in should be considered '<any>'. We generally receive a JSON block
    //      from the backend, but it could also come from Firebase, or be treated
    //      as a copy constructor.
    //      It's important to validate anything vital here in the constructor.
    //      Do not assume it's there just because we take an UserConstructor interface!

    this.id = userData.id;
    this.is_active = userData.is_active;
    this.user_type = userData.user_type;
    this.first_name = userData.first_name;
    this.last_name = userData.last_name;
    this.role_id = userData.role_id;
    this.role_name = userData.role_name;
    this.name = userData.name;
    this.title = userData.title;
    this.email = userData.email;
    this.alias = userData.alias;
    this.username = userData.username;
    this.nick_name = userData.nick_name;
    this.timezone = userData.timezone;

    this.has_profile_photo = userData.has_profile_photo
      ? true
      : typeof userData.profile_picture_url === "string" &&
        userData.profile_picture_url !== "";

    this.profile_picture_url = userData.profile_picture_url;
    this.profile_thumbnail_url = userData.profile_thumbnail_url;
    this.language = userData.language;
    this.locale = userData.locale;
    this.is_admin = userData.is_admin;

    this.department =
      userData.department === undefined ? "" : userData.department;

    const tempPhone = formatPhoneNumber(userData.phone);
    const tempMobilePhone = formatPhoneNumber(userData.mobile_phone);

    this.phone = tempPhone === undefined ? "" : tempPhone;
    this.mobile_phone = tempMobilePhone === undefined ? "" : tempMobilePhone;

    // sometimes these getters come on a copy-constructor

    this.sf_access_token =
      userData.getAccess_Token === undefined
        ? userData.sf_access_token
        : userData.getAccess_Token();

    this.sf_refresh_token =
      userData.getRefresh_Token === undefined
        ? userData.sf_refresh_token
        : userData.getRefresh_Token();

    if (userData.sf_issued_at !== undefined) {
      this.sf_issued_at = userData.sf_issued_at;
    }

    this.auth_provider = null;
    this._darkMode = false;
  }

  initializeFireStore = async () => {
    if (!firestore) {
      console.error("firestore missing in user class");
      return Promise.resolve(null);
    }

    const usersRef = collection(firestore, "Users");

    const payload = {
      ...(this.sf_access_token !== "no access token" && {
        sf_access_token: this.sf_access_token,
      }),
      ...(this.sf_refresh_token !== "no refresh token" && {
        sf_refresh_token: this.sf_refresh_token,
      }),
      ...(this.id !== "no id" && {
        sf_user_id: this.id,
      }),
      ...(this.sf_issued_at !== "no issued at" && {
        sf_issued_at: this.sf_issued_at,
      }),
      ...(this.username !== "no username" && {
        sf_username: this.username,
      }),
    };

    if (!auth || !auth.currentUser) {
      console.error(
        "firestore auth is missing on initializeUser; cannot use setDoc()"
      );
      return Promise.resolve(null);
    }

    await setDoc(doc(usersRef, this.email), payload);

    // write to messaging group
    // if (this.role_name !== undefined && messaging_id !== undefined) {
    //   const messageGroupRef = collection(firestore, "Groups");
    //   const messageGroupPayload = {
    //     [this.email]: messaging_id,
    //   };
    //   await setDoc(doc(messageGroupRef, this.role_name), messageGroupPayload);
    // }

    return Promise.resolve(payload);
  };

  public refreshAccess_Token = async (newToken: string, issuedAt: string) => {
    this.sf_access_token = newToken;
    this.sf_issued_at = issuedAt;
  };

  public setProvider = (provider: string) => {
    this.auth_provider = provider;
    return provider;
  };

  public getProvider = () => this.auth_provider;

  public getAccess_Token = () => this.sf_access_token;

  public getRefresh_Token = () => this.sf_refresh_token;

  public isMemberOfGroup = (group: any) => {
    return isMemeberOfGroup(this.id, group.user_ids);
  };

  public setMobilePhone = (phone: string) => {
    this.mobile_phone = phone;
    return this.mobile_phone;
  };

  public setPhone = (phone: string) => {
    this.phone = phone;
    return this.phone;
  };

  public updateEmail = (email: string) => {
    throw new Error("Unable to update user email. [ ReadOnly ]");
    // this.username = email;
    // return this.username;
  };

  public buildUserInfo = () => ({
    id: this.id,
    type: this.user_type,
    name: this.name,
    title: this.title,
    email: this.email,
    timezone: this.timezone,
    phone: this.phone,
    mobile_phone: this.mobile_phone,
    has_profile_photo: this.has_profile_photo,
    profile_picture_url: this.profile_picture_url,
    profile_thumbnail_url: this.profile_thumbnail_url,
  });

  // INFO: Note that this function can only be used to update the logged in user. Tokens Required
  public updateUserInSalesforce = async (newData: Partial<User>) => {
    const body = { ...this.generateSalesforceUpdatePayload(), ...newData };
    const salesforceAuthParams = new SalesforceRequestAuthParams(
      this.getAccess_Token(),
      this.getRefresh_Token(),
      this.email,
      this.sf_issued_at,
      this.username
    );
    const salesforceRequestParams = new SalesforceRequestParams(
      salesforceAuthParams,
      { endpoint: `/api/user/${this.id}`, method: "PUT", body }
    );
    const salesforceRequest = new SalesforceRequest(salesforceRequestParams);

    try {
      const result = await salesforceRequest.attemptSend();
      if (result.callSent === false) return null; //expired session
      if (result.httpResponse && result.httpResponse.ok) {
        const json = result.GetParsedJson();
        if (json.status === 200) {
          return Promise.resolve(Object.assign(this, ...json.user));
        }
      }
      throw new Error("Problem updating user in salesforce.");
    } catch (e) {
      console.log("updateUserInSalesforce:", e);
      return null;
    }
  };

  public generateSalesforceUpdatePayload = () => ({
    alias: this.alias,
    email: this.email,
    first_name: this.first_name,
    has_profile_photo: true,
    id: this.id,
    is_active: this.is_active,
    language: this.language,
    last_name: this.last_name,
    locale: this.locale,
    mobile_phone: this.mobile_phone,
    name: this.name,
    nick_name: this.nick_name,
    phone: this.phone,
    role_id: this.role_id,
    role_name: this.role_name,
    timezone: this.timezone,
    title: this.title,
    user_type: this.user_type,
    username: this.username,
  });

  public syncProfilePhoto = async () => {
    if (auth?.currentUser) {
      await updateProfile(auth?.currentUser, {
        photoURL: this.has_profile_photo
          ? this.profile_picture_url
          : this.profile_thumbnail_url,
      });
    }
  };

  public updateProfilePhoto = async (
    photoUrl: string,
    options?: Partial<{
      profile_picture_url: boolean;
      profile_thumbnail_url: boolean;
    }>
  ) => {
    if (options) {
      if (options.profile_picture_url) {
        this.profile_picture_url = photoUrl;
      }
      if (options.profile_thumbnail_url) {
        this.profile_thumbnail_url = photoUrl;
      }
    } else {
      this.profile_thumbnail_url = photoUrl;
    }
  };

  public setDarkMode = async (isDarkMode: boolean) => {
    if (!auth || !auth.currentUser) {
      console.warn(
        "firestore auth is missing on setDarkMode; cannot use setDoc()"
      );
      return this._darkMode;
    }

    if (firestore) {
      const docRef = doc(firestore, "Users", this.email);
      await getDoc(docRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            setDoc(docRef, { ...snapshot.data(), darkMode: isDarkMode });
          }
        })
        .catch(() => {});
    }
    this._darkMode = isDarkMode;
    return this._darkMode;
  };

  public checkIsManagementRole() {
    if (!this.role_name) {
      console.log("user rolename missing/invalid on check role");
      return false;
    }

    const userIsDirector =
      this.role_name.toLowerCase().indexOf("director") !== -1;
    const userIsManager =
      this.role_name.toLowerCase().indexOf("manager") !== -1;
    const userIsSupervisor =
      this.role_name.toLowerCase().indexOf("supervisor") !== -1;

    return userIsDirector || userIsManager || userIsSupervisor;
  }
}
