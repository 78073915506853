import { MenuItem, Select, Typography } from "@mui/material";
import PagenationProps from "../Interfaces";

export function FilterBySelect(props: Pick<PagenationProps, "filterBy" | "setFilterBy">) {
  return (
    <Select
      className="pagenation-select"
      value={props.filterBy}
      onChange={(event) => {
        const valueInQuestion = event.target.value as "day" | "week"
        if (["day", "week"].includes(valueInQuestion)) {
          props.setFilterBy(valueInQuestion);
          return valueInQuestion;
        }
        return valueInQuestion
      }}
    >
      <MenuItem value="day">
        <Typography>Day</Typography>
      </MenuItem>
      <MenuItem value="week">
        <Typography>Week</Typography>
      </MenuItem>
    </Select>
  )
}