import * as React from 'react'
import { useLocation } from 'react-router-dom'

export const MobileHeader = React.createContext<string | null>(null)

/**
 * @params - React children
 * This is a context provider that will give us a dynamic title for in mobile view.
 * @Returns - String for mobile title
 */

export default function MobileHeaderProvider({ children }: any) {
  const { pathname } = useLocation()
  const [pending, setTransion] = React.useTransition()
  const [header, setHeader] = React.useState<string>("Ohana Portal")
  React.useEffect(() => {
    setTransion(() => {
      if (pathname.includes("my-tasks")) {
        setHeader('My Tasks')
      }
      else if (pathname.includes('/tasks/all')) {
        setHeader("All Tasks")
      }
      else if (pathname.includes('/tasks/DropStrips')) {
        setHeader("Drop/Strips")
      }
      else if (pathname.includes('/tasks/Maintenance%20Inspections')) {
        setHeader("Maintenance Inspections")
      }
      else if (pathname.includes('/tasks/Cleanings')) {
        setHeader("Cleanings")
      }
      else if (pathname.includes('/tasks/GlassLanai')) {
        setHeader("Glass & Lanai")
      }
      else if (pathname.includes('/tasks/Final%20Inspections')) {
        setHeader("Final Inspections")
      }
      else if (pathname.includes('/tasks/Special%20Projects')) {
        setHeader("Special Projects")
      }
      else if (pathname.includes("/tasks")) {
        setHeader("Tasks")
      }
      else if (pathname.includes('/home')) {
        setHeader('Home')
      }
      else if (pathname.includes('/teams')) {
        const posHeader = pathname.split('/teams/')[1]
        if (posHeader) {
          setHeader(decodeURIComponent(posHeader))
        } else {
          setHeader('Teams')
        }
      } else {
        setHeader("Ohana Portal")
      }
    })
  }, [pathname])
  return (
    <MobileHeader.Provider value={pending ? '...' : header}>
      {children}
    </MobileHeader.Provider>
  )
}