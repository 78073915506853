import AppFormInputText from '@components/Inputs/AppFormInputText';
import { Box, Button, Modal, useMediaQuery } from '@mui/material';
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { handleResetPassword } from './handleResetPassword';
import * as Yup from 'yup'
import useYupValidationResolver from '@components/Inputs/Validators';
import ModalBody from '../Shared/ModalBody';
import ModalBackground from '../Shared/ModalBackground';
import ModalHeader from '../Shared/ModalHeader';
import Copyright from '@config/Copyright';
import "./changePassword.css"
import useProfile from '@hooks/Selectors/useProfile';
import useAppDispatch from '@hooks/Dispatch/useAppDispatch';
import { logOut } from '@store/Reducers/Session'
import { acceptPlusCharInURLString } from '../../../Helpers/acceptPlusCharInEmailString';
import { SnackBarData } from '@context/Snackbar';

interface ChangePasswordForm {
  Reset_Password: string;
  Reset_Confirm_Password: string
}

interface ParamsFromRedirect {
  mode: string | null;
  oobCode: string | null;
  apiKey: string | null;
  lang: string | null;
  email: string | null
}

const ChangePasswordValidator = Yup.object().shape({
  Reset_Password: Yup.string()
    .min(6, "Too Short!")
    .required("Please enter a password!"),
  Reset_Confirm_Password: Yup.string().min(6)
    .min(6, "Too Short!")
    .required("Please enter a password that matches above!")
    .oneOf([Yup.ref("Reset_Password")], "Passwords must match!"),
})

export default function ChangePassword() {
  const [searchParams] = useSearchParams()
  const [, setSnackData] = React.useContext(SnackBarData)
  const navigate = useNavigate()
  const user = useProfile()
  const dispatch = useAppDispatch()
  const resolver = useYupValidationResolver(ChangePasswordValidator)

  const {
    control,
    setValue,
    handleSubmit,
    reset,
  } = useForm<ChangePasswordForm>({
    resolver,
    mode: "onChange",
    reValidateMode: 'onChange'
  })
  const [paramsFromRedirect, setParamsFromRedirect] = React.useState<Partial<ParamsFromRedirect> | null>(null)
  const [isValidParams, setIsValidParams] = React.useState<boolean>(false)
  const matches = useMediaQuery("(min-width:600px)");


  React.useEffect(() => {
    if (isValidParams === true) return
    // watch for mode, oobCode, apiKey, lang, email
    const paramsObjectIfValid: Partial<ParamsFromRedirect> = {}
    if (searchParams.has("mode")) {
      const mode = searchParams.get("mode")!
      if (mode === "") {
        return
      }
      paramsObjectIfValid.mode = mode
    }
    if (searchParams.has("oobCode")) {
      const oobCode = searchParams.get("oobCode")!
      if (oobCode === "") {
        return
      }
      paramsObjectIfValid.oobCode = oobCode
    }
    if (searchParams.has("email")) {
      const email = searchParams.get("email")!

      if (email === "") return

      paramsObjectIfValid.email = email
    }
    // if (searchParams.has("apiKey")) {
    //   paramsObjectIfValid.apiKey = searchParams.get('apiKey')
    // }
    // if (searchParams.has("lang")) {
    //   paramsObjectIfValid.lang = searchParams.get('lang')
    // }
    setParamsFromRedirect(paramsObjectIfValid)
    setIsValidParams(true)
  }, [searchParams, isValidParams])

  const onSubmit = handleSubmit(async (formData) => {
    if (user !== null) {
      await dispatch(logOut())
    }
    if (paramsFromRedirect !== null && isValidParams) {
      try {
        switch (paramsFromRedirect.mode) {
          case 'resetPassword':
            // Display reset password handler and UI.
            await handleResetPassword(paramsFromRedirect.oobCode as string, formData.Reset_Password, ProcessPasswordReset);
            reset()
            break;
          case 'recoverEmail':
            // Display email recovery handler and UI.
            // handleRecoverEmail(auth, actionCode, lang);
            throw new Error('Recovery Email not implemented [Firebase Auth Methods]')
          case 'verifyEmail':
            // Display email verification handler and UI.
            // handleVerifyEmail(auth, actionCode, continueUrl, lang);
            throw new Error('Verfify Email not implemented [Firebase Auth Methods]')
          default:
            // Error: invalid mode.
            throw new Error('Invalid mode [Firebase Auth Methods]')
        }
      } catch (error) {
        console.log("HandleResetPassword", error)
      }
    }
  })

  function ProcessPasswordReset(success: boolean, message: string, email: string) {
    let path = new URLSearchParams()
    if (!success) {
      if (message !== "") {
        path.set('error', encodeURIComponent(message))
      }
      if (email !== "") {
        path.set('email', acceptPlusCharInURLString(encodeURIComponent(email)))
      } else if (paramsFromRedirect !== null && paramsFromRedirect.email !== undefined && paramsFromRedirect.email !== null) {
        path.set('email', acceptPlusCharInURLString(encodeURIComponent(paramsFromRedirect.email)))
      }
    } else {
      setSnackData({ message: "Password Updated!", severity: "success", autoHideDuration: 10000 })
    }
    navigate("/login?" + path.toString())
  }

  return (
    <Modal
      open={true}
      aria-labelledby="auth_modal"
      aria-describedby="modal used to authenticate user"
    >
      <ModalBackground>
        <ModalBody
          sx={{
            width: matches ? "50%" : "80%",
            minWidth: 250,
            height: "auto",
            maxWidth: "460px",
          }}
        >
          <ModalHeader title="Change Password" />
          <Box
            width="80%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <form onSubmit={onSubmit} className="Change-Password-Form">
              <AppFormInputText
                name="Reset_Password"
                type='password'
                control={control}
                label='New Password'
                required
                setValue={setValue}
                // disabled={??}
                variant='outlined'
              // readonly
              />
              <AppFormInputText
                name="Reset_Confirm_Password"
                type='password'
                control={control}
                label='Confirm New Password'
                required
                setValue={setValue}
                // disabled={??}
                variant='outlined'
              // readonly
              />
              <div className="change-password-button">
                <Button variant="contained" color="secondary" type="submit">Continue</Button>
              </div>
            </form>
          </Box>
          <Copyright />
        </ModalBody>
      </ModalBackground>
    </Modal>
  )
}

