import * as React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import AppFormInputProps from "./AppFormInputProps";

export default function AppFormInputText({
  name,
  control,
  label,
  required,
  helperText,
  type = "text",
  disabled = false,
  variant = "standard",
  readOnly = false,
  disableUnderline,
  sx,
  disablePadding = false,
  autoFocus = false,
  id,
  callbackOnInput = () => { }
}: AppFormInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextField
            helperText={(() => {
              if (error) {
                return error.message;
              }
              if (helperText) {
                return helperText;
              }
              return null;
            })()}
            type={type}
            error={!!error}
            onChange={onChange}
            onInput={(e) => {
              if (!e) {
                // this could throw
                console.warn('onInput callback hit with no event!');
                return;
              }
              if (typeof e.target !== 'object') {
                // in theory this is possible; it's a React SyntheticEvent (around a DOM event)
                console.warn('onInput callback hit without an event target', e);
                return;
              }
              if (!e.target.hasOwnProperty('value')) {
                console.warn('onInput callback hit without a target value', e.target);
                return;
              }
              // console.log('relaying onInput event from', name, ' w/', (e.target as any).value);
              callbackOnInput((e.target as any).value);
            }}
            value={type === 'text' ? value || "" : value}
            fullWidth
            id={id}
            label={label}
            variant={variant}
            disabled={disabled}
            required={required}
            sx={{
              ...(disablePadding && {
                ".MuiOutlinedInput-input": { padding: 1 },
              }),
              ...sx,
            }}
            InputProps={{
              readOnly: readOnly && disabled,
              fullWidth: true,
              autoFocus: autoFocus,
              ...(variant === "standard" && { disableUnderline }),
            }}
          />
        );
      }}
    />
  );
}
