import { taskSubTypeValidationErrorText } from "@components/Task/Sub_Components/TaskFormComponents/taskFormValidator";
import { isValid } from "date-fns";
import {
  PriorityOptions,
  taskTypeOptions,
  TaskStatusOptions,
  dropOptions,
  maintenanceOptions,
  cleanOptions,
  inspectionOptions,
  shuttleServiceOptions,
} from "./taskStrings";
import { ApiTask, ApiTaskType } from "../../Types/task";
import { TaskStatus } from "../../Strings/TaskStatus";

export interface EditTaskForm
  extends Omit<
    ApiTask,
    | "related"
    | "assignee"
    | "reporter"
    | "pick_up_location"
    | "drop_off_location"
    | "number_of_adults"
    | "number_of_children"
    | "waiver_verified"
  > {
  related: { label: string; value: string } | null;
  assignee: { label: string; value: string } | null;
  reporter: { label: string; value: string } | null;
  pickup_location?: string;
  dropoff_location?: string;
  number_of_adults?: string;
  number_of_children?: string;
  waiver_verified?: boolean;
}

const emptyUser: ApiUser = {
  department: "",
  is_active: false,
  user_type: "",
  role_id: "",
  role_name: "",
  alias: "",
  username: "",
  nick_name: "",
  timezone: "",
  has_profile_photo: false,
  profile_picture_url: "",
  profile_thumbnail_url: "",
  language: "",
  locale: "",
  first_name: "",
  last_name: "",
  id: "",
  name: "",
};

export class Task implements ApiTask {
  private outdated_task: boolean = false;
  public type: ApiTaskType;
  public readonly id: string;
  public readonly is_timed: boolean;
  public readonly start_time?: string;
  public readonly completed_datetime?: string;
  public status: TaskStatus;
  public sub_type?: string; // Note this is optional since pre 1/17/2023 it was not present on ApiTask
  public description: string;
  public reporter_id: string;
  public readonly assignee: ApiUser;
  public assignee_id: string;
  public readonly assignee_type: string;
  public readonly reporter: ApiUser;
  public priority?: string;
  public readonly related_type: "Listing_Order_Item__c" | string;
  public related_id: string;
  public related_name: string;
  public readonly related?: ApiReservation &
    ApiListing &
    ApiListingOrderItem &
    ApiCase;
  public due_date: string;
  public due_datetime: string;
  public due_time: string;
  public is_closed: boolean;
  public is_high_priority: boolean;
  public is_overdue: boolean;
  public readonly is_claimable: boolean;
  public permission_to_enter: boolean;
  public readonly contact: ApiContact;
  public last_modified_date: string;
  public readonly unit_id: string;
  public readonly name_detailed: string;
  public readonly resort_name: string;
  public readonly bedrooms: number;
  public readonly bathrooms: number;
  public readonly listing_id?: string;
  public readonly listing_status: ApiListingStatus;
  public readonly check_out: string | undefined;
  public readonly next_check_in: string;
  public readonly turnaround: boolean;
  public readonly lessor_name: string;
  public readonly owner_name: string;
  public readonly unit_view: string;
  public readonly reservations: any[];
  public readonly parent_id: string | undefined;
  public owner_id: string | undefined;
  public created_date: string | undefined;
  public name: string | undefined;
  public title: string | undefined;
  public readonly body: string | undefined;
  public inventory_id: string | undefined;
  public readonly additional_housekeepers: string[];
  public pickup_location?: string;
  public dropoff_location?: string;
  public waiver_verified?: boolean;
  public number_of_children?: string;
  public number_of_adults?: string;
  constructor(taskObject: ApiTask) {
    this.type = taskObject.type;
    this.id = taskObject.id;
    this.is_timed = taskObject.is_timed;
    this.start_time = taskObject.start_time;
    this.completed_datetime = taskObject.completed_datetime;
    this.status = taskObject.status;
    this.sub_type = taskObject.sub_type;
    this.description = taskObject.description;
    this.reporter_id = taskObject.reporter_id;
    this.assignee = taskObject.assignee;
    this.assignee_id = taskObject.assignee_id;
    this.assignee_type = taskObject.assignee_type;
    this.reporter = taskObject.reporter || emptyUser;
    this.priority = taskObject.priority;
    this.related_type = taskObject.related_type;
    this.related_id = taskObject.related_id;
    this.related_name = taskObject.related_name;
    this.related = taskObject.related;
    this.due_date = taskObject.due_date;
    this.due_datetime = taskObject.due_datetime;
    this.due_time = taskObject.due_time;
    this.is_closed = taskObject.is_closed;
    this.is_high_priority = taskObject.is_high_priority;
    this.is_overdue = taskObject.is_overdue;
    this.is_claimable = taskObject.is_claimable;
    this.permission_to_enter = taskObject.permission_to_enter;
    this.contact = taskObject.contact;
    this.last_modified_date = taskObject.last_modified_date;
    this.unit_id = taskObject.unit_id;
    this.name_detailed = taskObject.name_detailed;
    this.resort_name = taskObject.resort_name;
    this.bedrooms = taskObject.bedrooms;
    this.bathrooms = taskObject.bathrooms;
    this.listing_id = taskObject.listing_id;
    this.listing_status = taskObject.listing_status;
    this.check_out = taskObject.check_out;
    this.next_check_in = taskObject.next_check_in;
    this.turnaround = taskObject.turnaround;
    this.lessor_name = taskObject.lessor_name;
    this.owner_name = taskObject.owner_name;
    this.unit_view = taskObject.unit_view;
    this.reservations = taskObject.reservations;
    this.parent_id = taskObject.parent_id;
    this.owner_id = taskObject.owner_id;
    this.created_date = taskObject.created_date;
    this.name = taskObject.name;
    this.title = taskObject.title;
    this.body = taskObject.body;
    this.inventory_id = taskObject.inventory_id;
    this.additional_housekeepers = taskObject.additional_housekeepers;
    this.pickup_location = taskObject.pickup_location;
    this.dropoff_location = taskObject.dropoff_location;
    this.waiver_verified = taskObject.waiver_verified;
    this.number_of_children = taskObject.number_of_children;
    this.number_of_adults = taskObject.number_of_adults;
  }

  formDetails() {
    return {
      id: this.id,
      title: this.title,
      permission_to_enter: this.permission_to_enter,
      type: this.type,
      description: this.description,
      due_datetime: this.due_datetime,
      status: this.status,
      priority: this.priority,
      sub_type: this.sub_type,
      related: {
        label: this.related?.name || "",
        value: this.related_id || "",
      },
      assignee: {
        label: this.assignee?.name || "",
        value: this.assignee_id || "",
      },
      reporter: {
        label: this.reporter?.name || "",
        value: this.reporter?.id || "",
      },
      ...(this.type === "Drop" &&
        this.inventory_id && { inventory_id: this.inventory_id }),
      ...(this.type === "Shuttle Service" &&
        this.pickup_location && {
          pickup_location: this.pickup_location,
        }),
      ...(this.type === "Shuttle Service" &&
        this.dropoff_location && {
          dropoff_location: this.dropoff_location,
        }),
      ...(this.type === "Shuttle Service" &&
        this.waiver_verified && {
          waiver_verified: this.waiver_verified,
        }),
      ...(this.type === "Shuttle Service" &&
        this.number_of_adults && {
          number_of_adults: this.number_of_adults
            ? String(this.number_of_adults)
            : "1",
        }),
      ...(this.type === "Shuttle Service" &&
        this.number_of_children && {
          number_of_children: this.number_of_children
            ? String(this.number_of_children)
            : "0",
        }),
    };
  }

  updateTaskWithFormDetails(formDetails: EditTaskForm) {
    if (this.id && this.id !== formDetails.id)
      throw new Error("This set of task details are not valid with this task.");
    this.outdated_task = true;
    if (typeof formDetails.title === "string" && formDetails.title !== "") {
      this.title = formDetails.title;
    }
    if (typeof formDetails.permission_to_enter === "boolean") {
      this.permission_to_enter = formDetails.permission_to_enter;
    }
    if (
      typeof formDetails.type === "string" &&
      taskTypeOptions.includes(formDetails.type)
    ) {
      this.type = formDetails.type;
    }
    if (typeof formDetails.description === "string") {
      this.description = formDetails.description;
    }
    if (typeof formDetails.number_of_adults === "string") {
      this.number_of_adults = formDetails.number_of_adults;
    }
    if (typeof formDetails.number_of_children === "string") {
      this.number_of_children = formDetails.number_of_children;
    }
    if (
      typeof formDetails.status === "string" &&
      TaskStatusOptions.includes(formDetails.status as TaskStatus)
    ) {
      this.status = formDetails.status as TaskStatus;
    }
    if (isValid(new Date(formDetails.due_datetime))) {
      this.due_datetime = new Date(formDetails.due_datetime).toJSON();
    }
    if (
      typeof formDetails.priority === "string" &&
      PriorityOptions.includes(formDetails.priority)
    ) {
      this.priority = formDetails.priority;
    }
    if (
      typeof formDetails.sub_type === "string" &&
      typeof formDetails.type === "string" &&
      taskTypeOptions.includes(formDetails.type)
    ) {
      switch (formDetails.type) {
        case "Drop":
          if (dropOptions.includes(formDetails.sub_type)) {
            this.sub_type = formDetails.sub_type;
            break;
          }
          throw new Error(taskSubTypeValidationErrorText);
        case "Maintenance":
          if (maintenanceOptions.includes(formDetails.sub_type)) {
            this.sub_type = formDetails.sub_type;
            break;
          }
          throw new Error(taskSubTypeValidationErrorText);
        case "Inspection":
          if (inspectionOptions.includes(formDetails.sub_type)) {
            this.sub_type = formDetails.sub_type;
            break;
          }
          throw new Error(taskSubTypeValidationErrorText);
        case "Clean":
          if (cleanOptions.includes(formDetails.sub_type)) {
            this.sub_type = formDetails.sub_type;
            break;
          }
          throw new Error(taskSubTypeValidationErrorText);
        case "Shuttle Service":
          if (shuttleServiceOptions.includes(formDetails.sub_type)) {
            this.sub_type = formDetails.sub_type;
            break;
          }
          throw new Error(taskSubTypeValidationErrorText);
        default:
          break;
      }
    }
    if (
      formDetails?.related &&
      typeof formDetails.related.label === "string" &&
      typeof formDetails.related.value === "string"
    ) {
      this.related_id = formDetails.related.value;
      this.related_name = formDetails.related.label;
    }
    if (
      formDetails.assignee &&
      typeof formDetails.assignee?.label === "string" &&
      typeof formDetails.assignee?.value === "string"
    ) {
      this.assignee_id = formDetails.assignee.value;
      this.assignee.name = formDetails.assignee.label;
    }
    if (
      formDetails.reporter &&
      typeof formDetails.reporter?.label === "string" &&
      typeof formDetails.reporter?.value === "string"
    ) {
      this.reporter_id = formDetails.reporter.value;
      this.reporter.name = formDetails.reporter.label;
    }
    if (typeof formDetails.inventory_id === "string") {
      this.inventory_id = formDetails.inventory_id;
    }
    if (typeof formDetails.dropoff_location === "string") {
      this.dropoff_location = formDetails.dropoff_location;
    }
    if (typeof formDetails.pickup_location === "string") {
      this.pickup_location = formDetails.pickup_location;
    }
    if (formDetails.number_of_adults) {
      this.number_of_adults = formDetails.number_of_adults;
    }
    if (formDetails.number_of_children) {
      this.number_of_children = formDetails.number_of_children;
    }
    if (formDetails.waiver_verified) {
      this.waiver_verified = formDetails.waiver_verified;
    }
  }

  shouldUpdate() {
    return this.outdated_task;
  }

  updatedPayload() {
    if (this.outdated_task === false) {
      throw new Error("There is nothing to update on this tasks.");
    }
    return new SalesforceTaskPayload(
      this.id,
      this.due_datetime,
      this.status,
      this.priority,
      this.type,
      this.permission_to_enter,
      this.sub_type,
      this.title,
      this.description,
      this.assignee_id,
      this.reporter_id,
      this.related_id,
      this.inventory_id,
      this.pickup_location,
      this.dropoff_location,
      this.waiver_verified,
      this.number_of_children,
      this.number_of_adults
    ).payload();
  }
}

export class SalesforceTaskPayload {
  public readonly due_datetime: string;
  constructor(
    public readonly id: string | undefined,
    private readonly due_datetime_string: string,
    public readonly status: string,
    public readonly priority: string | undefined,
    public readonly type: string,
    public readonly permission_to_enter: boolean,
    public readonly sub_type: string | undefined,
    public readonly title: string | undefined,
    public readonly description: string | undefined,
    public readonly assignee_id: string,
    public readonly reporter_id: string,
    public readonly related_id: string,
    public readonly inventory_id: string | undefined,
    public readonly pickup_location?: string,
    public readonly dropoff_location?: string,
    public readonly waiver_verified?: boolean,
    public readonly number_of_children?: string,
    public readonly number_of_adults?: string
  ) {
    this.due_datetime = due_datetime_string;
  }

  payload() {
    if (this.type === "Shuttle Service") {
      const {
        title: unuesed_title,
        due_datetime_string: unused_due_datetime_string,
        inventory_id: unused_inventory_id,
        permission_to_enter: unused_permission_to_enter,
        ...payloadData
      } = this;
      return { ...payloadData, title: "Guest Shuttle Service" };
    }
    if (this.inventory_id) {
      const { due_datetime_string, ...payloadData } = this;
      return payloadData;
    }
    const {
      due_datetime_string: unused_due_datetime_string,
      inventory_id: unused_inventory_id,
      pickup_location: unused_pickup_location,
      dropoff_location: unused_dropoff_location,
      waiver_verified: unused_waiver_verified,
      number_of_adults: unused_number_of_adults,
      number_of_children: unused_number_of_children,
      ...payloadData
    } = this;

    return payloadData;
  }
}

export function InstanciateSalesforceTaskPayloadFromFormFields(json: any) {
  const assignee_id = json.assignee ? json.assignee.value : "";
  const reporter_id = json.reporter ? json.reporter.value : "";
  const related_id = json.related ? json.related.value : "";
  return new SalesforceTaskPayload(
    json.id,
    json.due_datetime,
    json.status,
    json.priority,
    json.type,
    json.permission_to_enter,
    json.sub_type,
    json.title,
    json.description,
    assignee_id,
    reporter_id,
    related_id,
    json.inventory_id,
    json.pickup_location,
    json.dropoff_location,
    json.waiver_verified,
    json.number_of_chidren,
    json.number_of_adults
  ).payload();
}
