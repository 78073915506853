import * as React from "react";
import { Dialog, Button, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { DownloadButton } from "./VideoCard";

//Removed the media card so that it wouldn't apply styles and passed fullscreen to the main dialog

export default function ViewDialog(props: { dialogContent: string; setDialogContent: React.Dispatch<React.SetStateAction<any>>; }) {
  const { dialogContent, setDialogContent } = props;
  const videoRef = React.useRef<HTMLVideoElement>(null)

  React.useEffect(() => {
    if (videoRef.current !== null) {
      if (videoRef.current?.play) {
        videoRef.current.play()
      }
    }
  }, [videoRef])

  const id = React.useId()
  return (
    <Dialog
      open={dialogContent.length > 0}
      onClose={() => {
        setDialogContent("");
      }}
      sx={{
        verticalAlign: "center"
      }}
      fullWidth
      fullScreen
    >
      <Button
        sx={{
          position: "fixed",
          left: 10,
          top: 45,
          backgroundColor: "#efefef77",
          borderRadius: "50px",
          color: (theme) => theme.palette.common.black
        }}
        onClick={() => {
          setDialogContent("")
        }}>
        <Close fontSize="large" />
      </Button>
      <Box
        style={{
          height: '100%',
          width: '100%',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "center"
        }}>
        {dialogContent.startsWith("data:video") && (
          <>
            {(dialogContent.startsWith("data:video/mp4") || dialogContent.startsWith("data:video/quicktime")) ?
              <video
                id={id}
                ref={videoRef}
                autoPlay
                playsInline={false}
                muted
                controls
                preload="auto"
                disablePictureInPicture
                style={{
                  height: "100%",
                  width: '100%'
                }}
              >
                <source src={dialogContent} type="video/mp4" />
              </video> :
              <DownloadButton fileName="DownloadMrr_Attachment" src={dialogContent} />
            }
          </>
        )}
        {dialogContent.startsWith("data:image") && (
          <img
            src={dialogContent}
            alt="dialog_image_preview"
            height={'auto'}
            width={'100%'}
            style={{
              backgroundPosition: "center",
              imageResolution: "from-image",
              imageRendering: "auto",
            }} />
        )}
        {dialogContent.startsWith("data:application/pdf") && (
          <object
            data={dialogContent}
            title="dialog_pdg_preview"
            width={"100%"}
            height={"100%"} />
        )}
        {dialogContent.startsWith("error") && <div>Error</div>}
      </Box>
    </Dialog>
  );
}
