import { format, isValid } from "date-fns";
import { CardItem } from "@components/Typography/CardItemText";
import { ApiTask } from "../../../../../Types/task";

export default function TimeRow({ curTask }: { curTask: ApiTask | null }) {


  if (typeof curTask?.start_time !== "string") return null;
  return (
    <div className="Task-Card-Item-Row">
      {curTask.start_time && (
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Started On" text={
            curTask.start_time && isValid(new Date(curTask.start_time))
              ? format(new Date(curTask.start_time), "M/d/yy, h:mm a")
              : ""} />
        </div>
      )}
      {curTask.completed_datetime && (
        <div className="Task-Card-Item-Row-Split">
          <CardItem header="Completed On" text={
            curTask.completed_datetime && isValid(new Date(curTask.completed_datetime))
              ? format(new Date(curTask.completed_datetime), "M/d/yy, h:mm a")
              : ""
          } />
        </div>
      )}
    </div>
  );
}
