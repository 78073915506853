import { EditTaskForm, InstanciateSalesforceTaskPayloadFromFormFields, Task } from "@classes/Task";
import { inspectionOptions, shuttleServiceOptions, taskFormSubtypeOptions, taskTypeOptions } from "@classes/Task/taskStrings";
import User from "@classes/User";
import { FollowUpTask } from "@components/Task/TaskControls/ActionButtons/TaskFollowUpButton";
import { TaskAttachmentContentPostRequest, TaskAttachmentsDelayedAttachments } from "@context/Attachments";
import { addTaskPermissionFields } from "@context/Task/taskUtils";
import useListingOptions from "@hooks/Selectors/Serializers/useListingOptions";
import useTaskAssignees from "@hooks/Selectors/Serializers/useTaskAssignees";
import useTaskReporters from "@hooks/Selectors/Serializers/useTaskReporters";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";
import useProfile from "@hooks/Selectors/useProfile";
import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, IconButton, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import useSalesforceRequest, { useSalesforceRequestHookParams } from "@request/Salesforce";
import { captureException } from "@sentry/core";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackBarData } from "../../../../Context/Snackbar";
import {
  TaskChangeShouldRefreshView
} from "../../../../Context/Task/TaskDrawer";
import { TaskStatus } from "../../../../Strings/TaskStatus";
import { ApiTask } from "../../../../Types/task";
import processError from "../../../../utils";
import FileManager from "../../../FileManager";
import AttachButton from "../../../FileManager/AttachButton";
import AppFormInputDropdown from "../../../Inputs/AppFormInputDropdown";
import { DueDateInput } from "./DueDateInput";
import { ShuttleServiceForm } from "./ShuttleServiceForm";
import TaskFormDefault from "./TaskFormDefault";
import { TaskFormErrorFallback } from "./TaskFormErrorFallback";
import TaskFormInspection from "./TaskFormInspection";
import { TaskFormLabel, TaskFormTitle } from "./TaskFormLabel";
import "./taskFormStyles.css";

export interface TaskFormProps {
  taskId?: string;
  curTask: FollowUpTask | null;
  setCurTask: (
    taskId: string | null,
    newTask?: ApiTask | undefined
  ) => ApiTask | null;
  open: boolean;
  close: () => void;
  attachmentsMeta: any[];
  setAttachmentsMeta: React.Dispatch<React.SetStateAction<any[]>>;
  refreshAttachments: () => void
}


const todayAt5 = new Date();
todayAt5.setHours(17, 0, 0, 0);

const Default_Task_Type = "Maintenance"
const isReadOnly = (task: ApiTask | null) => typeof task?.related_type === "string" && task.related_type !== "Listing__c"
const createDefaultValues = (task: ApiTask, currentUser: User | null) => {
  const values = {
    id: task.id,
    title: task.title || '',
    permission_to_enter: task?.permission_to_enter === true,
    type: task.type,
    description: task.description || '',
    due_datetime: task.due_datetime || '',
    related: task.related ? { label: task.related.name, value: task.related.id } : null,
    assignee: task.assignee ? { label: task.assignee.name, value: task.assignee.id } : null,
    status: task.status,
    reporter: task.reporter
      ? { label: task.reporter.name, value: task.reporter.id }
      : currentUser !== null
        ? { label: currentUser.name, value: currentUser.id }
        : null,
    priority: task.priority || '',
    sub_type: task.sub_type || '',
    inventory_id: task.inventory_id || '',
    // Shuttle
    ...(task.type === 'Shuttle Service' && {
      pickup_location: task.pickup_location || '',
      dropoff_location: task.dropoff_location || '',
      number_of_adults: task.number_of_adults ? `${task.number_of_adults}` : '1',
      number_of_children: task.number_of_children ? `${task.number_of_children}` : '0',
      waiver_verified: task.waiver_verified
    })
  }
  return values
}
const emptyDefaultValues = (user: User | null) => ({
  id: undefined,
  title: '',
  permission_to_enter: true,
  type: Default_Task_Type,
  description: "",
  due_datetime: todayAt5.toJSON(),
  related: null,
  assignee: null,
  status: TaskStatus.NotStarted,
  reporter: user ? { label: user.name, value: user.id } : null,
  priority: "Normal",
  sub_type: "",
  inventory_id: '',
  // Shuttle
  pickup_location: '',
  dropoff_location: '',
  number_of_adults: '1',
  number_of_children: '0',
  waiver_verified: false
})

export default function TaskForm(props: TaskFormProps) {
  const { curTask, setCurTask, attachmentsMeta, setAttachmentsMeta } = props
  const user = useProfile();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, setSnackData] = React.useContext(SnackBarData);
  const [loading, setLoading] = React.useState<boolean>(false);
  const sentryErrorCache = React.useRef<Error | null>(null);
  const submitRequest = useSalesforceRequest()
  // ------- Async Options -----------
  const persistedObject = usePersistentObject()
  const listings = useListingOptions("id");
  const taskAssignees = useTaskAssignees();
  const taskReporters = useTaskReporters()
  const [, changeShouldRefreshViewInList] = React.useContext(TaskChangeShouldRefreshView)
  // ---------- Attachments ------------------
  const postDelayedAttachments = React.useContext(TaskAttachmentContentPostRequest)
  const [delayedAttachments, setDelayedAttachments] = React.useContext(TaskAttachmentsDelayedAttachments)
  // const fileManagerWorking = React.useContext(FileManagerWorking)
  // const previousTask = React.useRef<FollowUpTask>(curTask)
  //--------------------------- Tracked Form Values ----------------
  const [taskType, setTaskType] = React.useState(
    curTask?.type || Default_Task_Type
  );

  // ------------------ Use Hook Forms ---------------------
  // const resolver = useYupValidator(TaskFormValidator);
  const methods = useForm<EditTaskForm>({
    // resolver,
    mode: "all",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: emptyDefaultValues(user),
  });

  const {
    control,
    setValue,
    getValues,
    formState,
    reset,
    watch,
    handleSubmit,
    resetField
  } = methods

  // Controlling the change of state for Type and setting Sub-Type where needed
  const values = watch()
  const { type } = values
  React.useEffect(() => {
    setTaskType(type)

    switch (type) {
      case "Drop":
        if (curTask !== null && curTask.sub_type) {
          setValue("sub_type", curTask.sub_type);
        }
        //MERGE NOTE: ?? Why doesn't this case do resetField('inventory_id'), like the other three?
        break
      case "Inspection":
        if (curTask !== null && curTask.type === "Inspection") {
          setValue("sub_type", curTask.sub_type);
        } else {
          setValue("sub_type", "Maintenance");
        }
        resetField('inventory_id')
        break
      case "Maintenance":
        resetField('inventory_id')
        // setValue("sub_type", "");
        break
      case "Clean":
        resetField('inventory_id')
        // setValue("sub_type", "");
        break
      case "Shuttle Service":
        resetField('inventory_id')
        if (curTask !== null && curTask.type === "Shuttle Service") {
          setValue("sub_type", curTask.sub_type);
        } else {
          setValue("sub_type", "Guest");
        }
        break
      default:
        break
    }
  }, [type, setValue, curTask])

  React.useEffect(() => {
    if (curTask === null) return
    const defaultValues = createDefaultValues(curTask, user)
    reset(defaultValues)
  }, [curTask, reset])

  //?-------------- This will handle the Header of the form----------
  const titleAction = React.useMemo(() => {
    if (curTask?.id) {
      return "Edit";
    }
    return "Create";
  }, [curTask]);

  const showDefaultForm = taskType !== "Inspection" && taskType !== "Shuttle Service"
  const showInspectionForm = taskType === "Inspection"
  const showShuttleServiceForm = taskType === "Shuttle Service"
  // ------------------------ Save
  const onSave = handleSubmit(async (data: EditTaskForm) => {
    if (user === null) return

    if (!data) return
    setLoading(true); // needed to preform operation at appropriate time
    let resultFormSubmitForPayload

    if (curTask === null) {
      const assignee_id = data.assignee?.value
      const reporter_id = data.reporter?.value
      // const related_id = data.related?.value
      if (data.type === 'Inspection' && !assignee_id) {
        // the inspection form doesn't require a reporter
        setLoading(false)
        return
      }
      if (data.type !== 'Inspection' && (!assignee_id || !reporter_id)) {
        setLoading(false)
        return
      }

      resultFormSubmitForPayload = InstanciateSalesforceTaskPayloadFromFormFields(data)
    } else {
      const clonedTaskToEdit = new Task(curTask)
      clonedTaskToEdit.updateTaskWithFormDetails(data)
      resultFormSubmitForPayload = clonedTaskToEdit.updatedPayload()
    }

    // Instance of EDITING form
    let params: useSalesforceRequestHookParams | null = null
    if (typeof data.id === 'string' && data.id !== "") {
      if (delayedAttachments.length) {
        delayedAttachments.map(async (file) => await postDelayedAttachments(file, data.id))
        setDelayedAttachments([])
      }
      params = {
        method: "PUT",
        body: JSON.stringify(resultFormSubmitForPayload),
        endpoint: `/api/task/${data.id}`
      }
    } else {
      params = {
        method: "POST",
        endpoint: `/api/task`,
        body: JSON.stringify(resultFormSubmitForPayload)
      }
    }
    const submitToSalesforce = submitRequest(params)
    const responseFromSalesforce = await submitToSalesforce.attemptSend()
    if (!responseFromSalesforce.callSent) {
      setSnackData({
        message: "faild to edit task",
        color: "error",
        severity: "error",
      });
      processError(new Error(`Failed to ${titleAction} task`), "Error in task form")
    } else {
      const json = await responseFromSalesforce.GetParsedJson()
      if (json.hasOwnProperty("task")) {
        const task = json.task
        addTaskPermissionFields(task, user, persistedObject)
        setCurTask(task.id)
        setSnackData({
          message: `${titleAction} task successful.`,
          color: "success",
          severity: "success",
        });
        // this will refresh the list
        changeShouldRefreshViewInList(true)
        if (delayedAttachments.length) {
          // this will post any delayedAttachments then clear them completely
          delayedAttachments.map(async (file) => await postDelayedAttachments(file, task.id))
          setDelayedAttachments([])
        }
      }
    }
    props.close();
    setLoading(false);
  })
  const { dirtyFields, errors } = formState
  const disableReadOnly = React.useCallback(() => isReadOnly(curTask), [curTask])
  const assigneeInputEnabled = titleAction !== "Edit" || (curTask !== null && (curTask as any).is_manageable_by_user);
  const disableSave = React.useCallback(() => {
    // Do not disable in these cases
    if (curTask?.follow_up_task) { return false; }
    if (titleAction === 'Edit') { return false; }
    // ---------------------------

    if (!values.assignee || !values.assignee.value) { return true; }
    if (!values.sub_type) { return true; }
    if (showDefaultForm && !values.title) { return true; }
    if (!showInspectionForm && !values.reporter?.value) { return true; }
    if (!showShuttleServiceForm && (!values.related || !values.related.value)) { return true; }
    if (showShuttleServiceForm) {
      if (!values.pickup_location || !values.dropoff_location) {
        return true
      }
    }
    if (Object.keys(errors)?.length > 0) { return true; }
    if (Object.keys(dirtyFields)?.length === 0) { return true; }

    // rules passed, we can offer Save
    return false;
  }, [curTask, errors, dirtyFields, showShuttleServiceForm, showDefaultForm, showInspectionForm, values]);
  const formId = `${titleAction}_task_form`

  return (
    <ErrorBoundary
      FallbackComponent={TaskFormErrorFallback}
      onReset={() => {
        console.log('task form ErrorBoundary reset called');

        if (curTask !== null) {
          // we're in edit mode
          console.log('TaskForm error boundary caught on task: ' + curTask.id);
          // console.log(JSON.stringify(curTask));
        }

        if (sentryErrorCache.current !== null) {
          const sentryEventId = captureException(new Error('Details for error "' + sentryErrorCache.current.message + '"'));
          // console.log('sentryEventId', sentryEventId);
          sentryErrorCache.current = null;
        }

        // close the task form
        props.close();
      }}
      //NOTE: This handler cannot be used to message Sentry, so we just save the error.
      onError={(error: Error, info: { componentStack: string; }) => {
        sentryErrorCache.current = error;
      }}
    >
      <FormProvider {...methods}>
        <form id={formId} className="Task-Form-Default" onSubmit={onSave} >
          <div className="edit_task_title_row">
            <TaskFormTitle title={titleAction + ' Task'} />
            <Grid item position="sticky">
              <Tooltip title="close form">
                <IconButton
                  sx={{
                    color: (theme) => theme.palette.mode === 'light' ? "rgb(64,64,64)" : "#efefef",
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.close();
                  }}
                >
                  <Close fontSize="large" />
                </IconButton>
              </Tooltip>
            </Grid>
          </div>
          <div id="edit_task_type">
            <TaskFormLabel label="Type" />
            <AppFormInputDropdown
              whenChanged={(e: SelectChangeEvent<any>) => { setTaskType(e.target.value) }}
              control={control}
              name="type"
              options={taskTypeOptions}
              sx={{ maxHeight: "41px" }}
            />
          </div>
          {showDefaultForm && (
            <TaskFormDefault
              taskReporters={taskReporters}
              taskAssignees={taskAssignees}
              listings={listings}
              mode={titleAction}
              relatedToReadOnly={
                disableReadOnly()
              }
              subTypes={taskFormSubtypeOptions(taskType)}
              setLoading={setLoading}
              control={control}
              setValue={setValue}
              getValues={getValues}
              formState={formState}
              taskType={taskType}
              currentUser={user}
              assigneeInputEnabled={assigneeInputEnabled}
            />
          )}
          {showInspectionForm && (
            <TaskFormInspection
              taskReporters={taskReporters}
              taskAssignees={taskAssignees}
              listings={listings}
              mode={titleAction}
              relatedToReadOnly={
                disableReadOnly()
              }
              subTypes={inspectionOptions}
              setLoading={setLoading}
              control={control}
              setValue={setValue}
              getValues={getValues}
              formState={formState}
              taskType={taskType}
              currentUser={user}
              assigneeInputEnabled={assigneeInputEnabled}
            />
          )}
          {showShuttleServiceForm && (
            <ShuttleServiceForm
              taskReporters={taskReporters}
              taskAssignees={taskAssignees}
              listings={listings}
              mode={titleAction}
              relatedToReadOnly={true}
              subTypes={shuttleServiceOptions}
              setLoading={setLoading}
              control={control}
              setValue={setValue}
              getValues={getValues}
              formState={formState}
              taskType={taskType}
              currentUser={user}
              assigneeInputEnabled={assigneeInputEnabled}
            />
          )}
          {!showShuttleServiceForm && <DueDateInput control={control} />}
          <Grid item>
            <FileManager disableBorder={(attachmentsMeta && attachmentsMeta.length === 0) || !attachmentsMeta} setAttachmentsMeta={titleAction === 'Edit' ? setAttachmentsMeta : () => undefined} attachmentsMeta={titleAction === 'Edit' ? attachmentsMeta || [] : []} delayedAttachments={delayedAttachments} />
          </Grid>
          <Grid
            className="task-form-action-buttons"
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
            mb={1}
          >
            <Grid item>
              <LoadingButton
                loading={loading}
                disabled={disableSave()}
                variant="contained"
                type="submit"
                form={formId}
              >
                <Typography variant="button">Save</Typography>
              </LoadingButton>
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (delayedAttachments.length) {
                    setDelayedAttachments([])
                  }
                  if (pathname.includes("edit")) {
                    navigate(-1);
                  }
                  props.close();
                }}
                sx={{ ml: 2 }}
              >
                <Typography variant="button">Cancel</Typography>
              </Button>
            </Grid>
            <AttachButton taskId={curTask?.id} label="Attach File" />
          </Grid>
        </form>
      </FormProvider>
    </ErrorBoundary>
  );
}

