import * as React from 'react'
import useAppDispatch from "@hooks/Dispatch/useAppDispatch"
import { ApiQueueList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";
import { ConfirmActionMessage } from "../Helpers"
import DefaultConfirmModal from './DefaultConfimModal'

//! FINISHED DONT TOUCH
/**
 * 
 * @param props handleConfrim !!! May refactor this
 * @param open: boolean
 * @param setOpen: React set state from open param
 * 
 * @returns A confirm modal with the text ""Move task to next check-out?"" and a select option to reassign task to any queue
 */
export default function MoveTaskModal(props: { handleConfirm: any, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
  const dispatch = useAppDispatch()
  const persistedObject = usePersistentObject()
  const [selectLabel, selectDefaultValue] = ["Assign to Team:", "doNotReassign"]

  React.useEffect(() => {
    if (!persistedObject.checkQueues(ApiQueueList.allQueues)) {
      dispatch(updatePersistentObjects(PersistentObject.queue, ApiQueueList.allQueues))
    }
  }, [dispatch, persistedObject])

  const allQueues = React.useMemo(() => {
    if (!persistedObject.checkQueues(ApiQueueList.allQueues)) return []
    return persistedObject.getQueue(ApiQueueList.allQueues)
  }, [persistedObject])
  return (
    <DefaultConfirmModal
      actionLabel={ConfirmActionMessage("moveTask")}
      open={props.open}
      setOpen={props.setOpen}
      selectLabel={selectLabel}
      selectDefaultValue={selectDefaultValue}
      selectOptions={allQueues ? allQueues.map((queue: any) => ({ label: queue.name, value: queue.id })) : [{ label: "", value: "" }]}
      onConfirmed={props.handleConfirm}
    />
  )
}
