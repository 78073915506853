import * as React from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { AppBarToggle } from "../../Context/Sidebars/SideBar";
import { ToggleProfileSidebarContext } from "../../Context/Sidebars/ProfileSidebar";
import useProfile from "@hooks/Selectors/useProfile";
import Avatar from "@mui/material/Avatar";
import { environment, logos } from "../../Config";
import useWindowDimensions from "@hooks/Window/useWindowDimensions";
import { Tooltip, Typography } from "@mui/material";
import { MobileHeader } from "../../Context/MobileHeader";
import { TaskDrawerControl } from "@context/Task/TaskDrawer";
import "./styles.css"
import { DeblurRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import User from "@classes/User";
import { setUser } from "@store/Reducers/Session";


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
}));


export default function Topbar() {
  const user = useProfile();
  const openProfileSidebar = React.useContext(ToggleProfileSidebarContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_taskDrawerOpen, toggleTaskDrawer] = React.useContext(TaskDrawerControl)
  const [open, setOpen] = React.useContext(AppBarToggle);
  const mobileHeader = React.useContext(MobileHeader)
  const dispatch = useDispatch();

  const windowDimension = useWindowDimensions();


  const handleClickAppbarToggle = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  return (
    <AppBar
      className="App-Bar"
      open={true}
    >
      <ul
        className="App-Bar-Inner-Container"
      >
        <li
          key="appSidebarToggle"
          className="App-Sidebar-Toggle disable-margin-and-padding"
        >
          <IconButton
            className="App-Sidebar-Toggle-Btn disable-margin-and-padding"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              openProfileSidebar(false)
              toggleTaskDrawer(false)
              handleClickAppbarToggle()
            }}
            sx={{
              color: (theme) => theme.palette.common.white,
            }}
          >
            {open === true ? (
              <MenuOpenIcon fontSize="large" />
            ) : (
              <MenuIcon fontSize="large" />
            )}
          </IconButton>
        </li>



        {/* TEMP: THIS IS PURE DEBUG HACK for testing w/ bad tokens */}
        {environment.mrrEnv === "development" && <li
          key="DEV-corruptToken"
        // className="App-Sidebar-Toggle disable-margin-and-padding"
        >
          <IconButton
            className="App-Sidebar-Toggle-Btn disable-margin-and-padding"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            color="inherit"
            aria-label="dev token button"
            onClick={() => {
              console.log('DEV CORRUPT TOKENS', user)

              // intentionally write bad tokens to the user model

              const userClone: any = {};

              for (const k in user) {
                if (typeof (user as any)[k] === 'function') {
                  continue;
                }

                userClone[k] = (user as any)[k];
              }

              userClone.sf_access_token += '.invalid';
              userClone.sf_refresh_token += '.invalid';

              const newUser = new User(userClone);

              dispatch(setUser(newUser));

              // if needed, try this also/instead
              //   newUser.refreshAccess_Token(
              //     `${token_type} ${access_token}`,
              //     issued_at
              //   );
            }}
            sx={{
              color: (theme) => theme.palette.common.white,
            }}
          >
            <DeblurRounded fontSize="large" />
          </IconButton>
        </li>}

        <li
          key="MRR_Logo_topAppBar"
          className="MRR_Logo_topAppBar centered"
        >
          {windowDimension.width > 600 &&
            <img
              className="MRR_Logo_topAppBar-img centered"
              src={logos.MRR_Logo_White_Full}
              alt="Maui Resort Rentals Logo in white"
            />}
          {windowDimension.width <= 600 &&
            <Typography variant="h5" whiteSpace="nowrap" className="MRR_Logo_topAppBar-text centered">
              {mobileHeader}
            </Typography>}
        </li>
        <li
          key="profileSideBarToggle"
          className="Profile-SideBar-Toggle centered"
        >
          {user !== null ? (
            <Tooltip title="Toggle Profile Sidebar">
              <Avatar
                alt={user.first_name.charAt(0)}
                src={user.profile_thumbnail_url}
                onClick={() => {
                  setOpen(false)
                  toggleTaskDrawer(false)
                  openProfileSidebar();
                }}
                className="App-Bar-Avatar"
              />
            </Tooltip>
          ) : (
            <Avatar
              alt='No User'
              className="App-Bar-Avatar"
            />
          )}
        </li>
      </ul>
    </AppBar >
  );
}
