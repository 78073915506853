import * as React from "react";
import { IconButton, Link, TextFieldProps, Typography } from "@mui/material";
import { UserInfoProps } from "./index";
import { Edit, Save } from "@mui/icons-material";
import AppFormInputPhone from "@components/Inputs/AppFormInputPhone";

const defaultInputProps: TextFieldProps = {
  margin: "dense",
  variant: "outlined",
  size: "small",
  inputProps: { style: { fontWeight: "bold" } }
}

export default function UserInfo(props: UserInfoProps) {
  const {
    // name,
    // title,
    email,
    // has_profile_photo,
    // id,
    role,
    // mobile_phone,
    // phone,
    // profile_picture_url,
    profile_thumbnail_url,
    // timezone,
    // type
    control,
    editField,
    editableFields,
    onSubmit,
    readonly,
    formState: { isSubmitting }
  } = props;
  return (
    <div className="User-Info-Card">
      <div className="Edit-Container-Button">
        {readonly === false && !editableFields && (
          <IconButton
            className="Edit-Button"
            disabled={editableFields}
            type="button"
            title="Edit"
            color="secondary"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              editField()
            }}>
            <Edit />
          </IconButton>
        )}
        {readonly === false && editableFields && (
          <IconButton
            className="Edit-Button"
            title="Save"
            color="primary"
            form={`${role}-Editable-User-Fields`}
            disabled={isSubmitting}
            type="submit">
            <Save />
          </IconButton>

        )}
      </div>
      <div className="User-Profile-Card User-Info-Grid-Item center">
        <img src={profile_thumbnail_url} alt="Profile" onError={(e) => {
          e.currentTarget.onerror = null
          e.currentTarget.src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
          return
        }}></img>
      </div>
      {/* This will add back the split name and title row */}
      {/* <div className="User-Info-Grid">
        <div className="User-Info-Grid-Item col">
          <Typography variant="caption">Name</Typography>
          <Typography fontSize="large" fontWeight="bold">{name || "-"}</Typography>
        </div>
        <div className="User-Info-Grid-Item col">
          <Typography variant="caption">Title</Typography>
          <Typography fontSize="large" fontWeight="bold">{title || "-"}</Typography>
        </div>
      </div> */}
      <div className="User-Info-Grid">
        <div className="User-Info-Grid-Item col">
          <Typography variant="caption">Email</Typography>
          <Link fontSize="large" fontWeight="bold" component='a' href={`mailto:${email}`}>{email || "-"}</Link>
        </div>
      </div>
      <form onSubmit={onSubmit} className="User-Info-Grid" id={`${role}-Editable-User-Fields`}>
        <div className="User-Info-Grid-Item col">
          <AppFormInputPhone inputProps={defaultInputProps} readonly={!editableFields} control={control} name={`${role}_phone`} label="Phone" />
        </div>
        <div className="User-Info-Grid-Item col">
          <AppFormInputPhone inputProps={defaultInputProps} readonly={!editableFields} control={control} name={`${role}_mobile_phone`} label="Mobile Phone" />
        </div>
      </form >
    </div>
  );
}
