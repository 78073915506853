import React from "react";
import { useSearchParams } from "react-router-dom";
import { sortOptions } from "./Items/SortTasksMenu";
import { TaskSortKey } from "../sortTasks";
export const sortParamKey = "sortBy"
export const localStorage_sortParamKey = "preferredSort"
/**
 * 
 * @param preferredSort One of the sort options
 * @description Adds the sort key to the search params
 * @description <?sortParamKey=value of input>
 * @returns Nothing
 */
export function useSelectedSort(preferredSort: TaskSortKey) {
  const [searchParams, setSearchParams] = useSearchParams();
  React.useEffect(() => {
    // update our preferred setting so it can persist (only while on task pages)
    window.localStorage.setItem(localStorage_sortParamKey, preferredSort);

    const urlSortByParam = searchParams.get(sortParamKey);
    if (urlSortByParam !== null && preferredSort === urlSortByParam) {
      // this sort param is already set in the url, which drives everything
      return;
    }

    searchParams.set(sortParamKey, preferredSort);
    setSearchParams(searchParams);
  });
  return null;
}
