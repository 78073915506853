import * as React from 'react'

import IconButton from '@mui/material/IconButton';
import CardMedia from '@mui/material/CardMedia';
import { Download } from '@mui/icons-material';

const _VIDEO = "data:video"

/**
 * 
 * @param props PDFCardProps
 * @returns JSX.Element for image "data:video"
 */
export default function VideoCard(props: FileData) {
  const id = React.useId()
  const ref = React.useRef<HTMLVideoElement>(null)
  const [openFullScreen, setOpenFullScreen] = React.useState(false)

  const { src, meta, handleClick } = props


  React.useEffect(() => {
    if (ref.current !== null && openFullScreen === true) {
      if (ref.current?.requestFullscreen) {
        ref.current.requestFullscreen()
      } else {
        handleClick()
      }
    }

    setOpenFullScreen(false)
  }, [handleClick, openFullScreen, ref])

  if (!src.startsWith(_VIDEO)) {
    console.error(`Not Video: ${src.slice(0, 100)}...`)
    return null
  }
  return (
    <>
      {isMobile() && meta.content_type !== "video/quicktime" ? (
        <DownloadButton src={src} fileName={meta.name} />
      )
        :
        <CardMedia
          ref={ref}
          id={id}
          onClick={() => {
            setOpenFullScreen(true)
          }}
          sx={{
            padding: 0,
            margin: 0,
            minHeight: '75px',
            minWidth: '100px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: (theme) => theme.palette.background.paper,
            objectPosition: '50% 50%'
          }}
          autoPlay
          muted
          preload="metadata"
          component="video"
          height='75px'
          width='100px'
        >
          <source src={src} type={meta.content_type === "video/quicktime" ? "video/mp4" : meta.content_type} />
          <DownloadButton src={src} fileName={meta.name} />
        </CardMedia>
      }
    </>
  )
}
// ak = api
// route <source src="https://api-dev.mauiresortrentals.com/services/apexrest/api/attachment/<attachment_id>?ak=<api_key>" type="<content_type>">

export function DownloadButton(props: { src: string, fileName: string }) {
  return (
    <div
      style={{ width: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "center", padding: 1 }}
    >
      <IconButton
        LinkComponent="a"
        href={props.src}
        download={props.fileName}
        sx={{ fontSize: "10px" }}
      >
        <Download fontSize='small' sx={{ p: 0, m: 0 }} /> Download Video
      </IconButton>
    </div>
  )
}

function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    return true
  } else {
    // false for not mobile device
    return false
  }
}


//Problem: Videos displaying there corisponding thumbnail on mobile
//Apple mobile ios can only display previews for there special quicktime format ".mov"
//Other browsers in desktop dont care and apple desktop also doesn't care.
//I need an android but to be safe will catch all mobile

//Fix: inMobile 
// Then we want to only display the video element if the contentType is 'quicktime'
// All other browsers we can include mp4 format aswell
