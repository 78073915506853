import React from "react";
import useProfile from "@hooks/Selectors/useProfile";
import { SalesforceRequest } from "./SalesforceRequest";
import { SalesforceRequestParams } from "./SalesforceRequestParams";
import { SalesforceRequestAuthParams } from "./SalesforceRequestAuthParams";

// These are the values needed to use the hook, i.e. begin an http request (endpoint
// is the only required param, the rest will get defaults before the call goes out).
export interface useSalesforceRequestHookParams {
  endpoint: string;
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: Record<string, string> | undefined;
  contentType?: string;
  body?: any;
  requiresAuth?: boolean;
  hostPath?: string;
}

// Every* call to Salesforce should come through this hook (* wherever possible)
export default function useSalesforceRequest() {
  const user = useProfile();

  return React.useCallback(
    (params: useSalesforceRequestHookParams) => {
      if (user === null) {
        // there is no user session, so we will only proceed with pre-auth calls
        if (params.requiresAuth === false) {
          // build the http request params without auth params
          // This will happen during the sign-up process
          console.log("%cNo Auth useSalesforceRequest:", "color:#af7aff", params)
          const nonSessionSFRequestParams = new SalesforceRequestParams(null, params);
          return new SalesforceRequest(nonSessionSFRequestParams);
        }

        // this user's session has ended, so this call is not allowed
        return new SalesforceRequest(null);
      };

      const sfRequestAuthParams = new SalesforceRequestAuthParams(
        user.getAccess_Token(),
        user.getRefresh_Token(),
        user.email,
        user.sf_issued_at,
        user.username);

      // this object joins the session user with the http request params
      const sfRequestParams = new SalesforceRequestParams(
        sfRequestAuthParams,
        params
      );

      // We have have the info needed to make a request, but the request
      // hasn't been sent. That can be done by the caller with this.
      return new SalesforceRequest(sfRequestParams);
    },
    [user]
  );
}
