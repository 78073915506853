import * as React from "react";
import { Controller } from "react-hook-form";
import { FormLabel, TextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";

const StyledTextareaAutosize = styled(
  TextareaAutosize,
  {}
)(({ theme }) => ({
  minWidth: "100%",
  maxWidth: "100%",
  fontFamily: "Heebo, Roboto, sans-serif",
  // fontWeight: 400,
  // lineHeight: "1.4375em",
  fontSize: "1em",
  padding: "10px 14px",
  borderRadius: "4px",
  cursor: "text",
  letterSpacing: "0.00938em",
  color: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
  borderColor:
    theme.palette.mode === "light"
      ? theme.palette.primary.dark
      : theme.palette.primary.light,
  marginBottom: 0,
  ":focus-visible": {
    outline: `${theme.palette.primary.dark} auto 1px`,
    outlineColor: theme.palette.primary.dark,
    outlineStyle: `auto`,
    outlineWidth: `1px`,
  },
}));

export default function AppFormInputTextareaAutosize({
  control,
  label,
  name,
}: {
  control: any;
  label: string;
  name: string;
}) {
  return (
    <Controller
      control={control}
      name={name ? name : label}
      render={({
        field: { onChange, value },
        // fieldState: { error },
      }) => (
        <>
          <FormLabel sx={{ fontWeight: 800, color: (theme) => theme.palette.mode === 'light' ? "rgb(64,64,64)" : "#efefef" }}>
            {label}
          </FormLabel>
          <StyledTextareaAutosize
            value={value}
            onChange={onChange}
            style={{ backgroundColor: "inherit" }}
            minRows={3}
            maxRows={4}
          />
        </>
      )}
    />
  );
}
