import { environment } from "@config/index";
import { acceptPlusCharInURLString } from "@helpers/acceptPlusCharInEmailString";
import { formatDuration, intervalToDuration } from "date-fns";
import { SalesforceRequestParams } from "./SalesforceRequestParams";
import {
  SFAccessTokenRefresher,
  sfReauthConfirmationTag,
} from "./SFAccessTokenRefresher";

const loggingVerbosity = environment.mrrEnv === 'production' ? 1 : 0; // 2: all, 1: some, 0: off

const CONSOLE_COLOR = "color:#fb6";

const MAX_TOKEN_AGE = 2 * 60 * 60 * 1000; // 2hrs

export class SalesforceRequestResult {
  private _parsedJson: any = null;

  constructor(
    public readonly callSent: boolean,
    public readonly reasonNotSent: string,
    public readonly httpResponse: Response | null
  ) {
    if (this.callSent) {
      if (this.httpResponse === null) {
        throw new Error(
          "invalid salesforce result; if call was sent, response is required"
        );
      }
    }

    if (!this.callSent) {
      if (this.reasonNotSent === "") {
        throw new Error(
          "invalid salesforce result; if was not sent, a reason is required"
        );
      }

      console.warn("SF call not sent: " + this.reasonNotSent);
      return;
    }
  }

  async ProcessBlob() {
    if (this.httpResponse !== null && this.httpResponse.ok) {
      const content = await this.httpResponse.blob();
      const reader = new FileReader();
      return await new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(content);
      });
    }
  }

  async AttachJson() {
    if (this.httpResponse === null) {
      throw new Error("attaching JSON to a null salesforce response");
    }
    //TODO: We might want a dedicated try/catch for this line, since the JSON could be corrupt.
    this._parsedJson = await this.httpResponse.json();
  }

  CheckJsonAttached() {
    if (this.httpResponse === null) {
      throw new Error("checking for JSON on a null salesforce response");
    }

    return this._parsedJson !== null;
  }

  GetParsedJson() {
    if (this.httpResponse === null) {
      throw new Error("getting JSON from a null salesforce response");
    }

    return this._parsedJson;
  }
}

const lsKeyHoldForReauth = "Hold-For-Reauth"; // considered true if string "yes", otherwise considered false(y)
const lsKeyReauthTimestamp = "Reauth-Timestamp";

export function checkLSReauthHold() {
  if (window.localStorage.getItem(lsKeyHoldForReauth) !== "yes") {
    writeToLSReauthHold(false);
    return false;
  }

  const timestamp = window.localStorage.getItem(lsKeyReauthTimestamp);

  if (timestamp === null) {
    // local storage is in a bad state; clear and let drop through
    writeToLSReauthHold(false);
    return false;
  }

  if (Date.now() - 60 * 60 * 1000 > Number(timestamp)) {
    console.log("reauth localstorage flag expired; user did not finish reauth");
    writeToLSReauthHold(false);
    return false;
  }

  // step aside for reauth; the user will be automatically logged back in afterward
  console.log("dropping out of auth state change due to rejected SF tokens");
  return true;
}

export function writeToLSReauthHold(state: boolean) {
  if (state) {
    window.localStorage.setItem(lsKeyHoldForReauth, "yes");
    window.localStorage.setItem(lsKeyReauthTimestamp, String(Date.now()));
    return;
  }

  window.localStorage.removeItem(lsKeyHoldForReauth);
  window.localStorage.removeItem(lsKeyReauthTimestamp);
}

export class SalesforceRequest {
  private static _WriteDroppedSessionResult(
    requestID: number,
    internalCode: number
  ) {
    return new SalesforceRequestResult(
      false,
      "Session ended for reauth (code " + internalCode + ":" + requestID + ")",
      null
    );
  }

  public static s_sessionEndedForReauth = false; // this is killswitch that blocks SF calls once our tokens are invalid
  private static s_requestID = 0;

  private _refreshAttempted = false;
  public readonly blockedByExpiredSession: boolean = false;
  public readonly requestID!: number;

  constructor(private readonly _params: SalesforceRequestParams | null) {
    this.requestID = SalesforceRequest.s_requestID++;

    if (_params === null) {
      this.blockedByExpiredSession = true;
      return;
    }
  }

  private async _ReauthorizeInvalidTokens() {
    //!IMPORTANT
    //NOTE: Remember that there can and will be concurrent calls, sometimes many (e.g. getting 10+ images
    //      attached to a task). We only want one of those calls to process a _failed_ token refresh. It's
    //      ok if more than one refreshes, because SF will just respond with the updated token over and over.
    //      However, when refresh fails, we need to cancel everything, and nav to our re-authorization form.
    //      Multiple threads fighting over that sequence proved to be very unstable.
    //      This SalesforceRequest.s_sessionEndedForReauth killswitch is set as soon as we know our tokens
    //      are bad. At that point, the entire session should be considered over.

    SalesforceRequest.s_sessionEndedForReauth = true;

    if (this._params === null || !this._params.checkUsesAccessToken()) {
      throw new Error("found invalid tokens on a blocked or non-auth call");
    }

    await new Promise(async (resolve) => {
      //TODO: We would prefer to use Redux dispatch here, but it creates a circular dependency.
      //      The only solution we can think of right now involves passing extra params
      //      into every single SF call. Not the end of the world, but not ideal. It's much
      //      cleaner to just wipe local storage in this case.
      //
      // await Store.dispatch( setUser(null) );

      //NOTE: We can't clear all here, because we need to keep the Firebase entry!
      //			Since the session is effectively over, we can wipe our keys (until we add
      //			something we'd like to persist, e.g. login username pre-fill or cached images).
      window.localStorage.removeItem("persist:root");

      //NOTE: See onAuthStateChange() for notes on lsKeyHoldForReauth.
      writeToLSReauthHold(true);

      setTimeout(() => {
        resolve(null);
      }, 1000);
    });

    window.location.href =
      window.location.origin +
      "/reconnect?login_hint=" +
      acceptPlusCharInURLString(
        encodeURIComponent(this._params.getSalesforceUsername())
      );

    return Promise.resolve(null);
  }

  public isValid() {
    if (this._params === null || this.blockedByExpiredSession) {
      return false;
    }

    return this._params.isValid();
  }

  async attemptSend(): Promise<SalesforceRequestResult> {
    loggingVerbosity > 1 &&
      console.log(
        "%cAttempting to send a Salesforce request" +
          " >> " +
          this._params?.path +
          "; body: " +
          this._params?.getBody(),
        CONSOLE_COLOR
      );

    // check the killswitch before sending any call
    if (SalesforceRequest.s_sessionEndedForReauth) {
      return Promise.resolve(
        SalesforceRequest._WriteDroppedSessionResult(this.requestID, 0)
      );
    }

    if (!this.isValid()) {
      console.log("%cSalesforce request not valid", CONSOLE_COLOR);

      if (this.blockedByExpiredSession) {
        //NOTE: Our guideline is that session-expired is _not_ explicitly reported by each component.
        //      If the session has expired, i.e. the global user is null, the app should already be
        //      routing the user to '/login'. If the app is _not_ rerouting (which should be very-
        //      rare or impossible) the component should remain in a no-data mode. The user can then
        //      navigate or reset manually.
        //
        //      In this case, the request was built without a user session. Again, this should be
        //      very rare.
        //      Rather than reject(), we respond with resolve(), including a call-not-sent result.
        //      The calling code should always check that state immediately! If the result says
        //      call-not-sent, the component should halt and wait for the redirect.
        //
        //      Of course there may be special cases. Please treat this as a rule of thumb.

        const sfRequestResult = new SalesforceRequestResult(
          false,
          "User session has expired",
          null
        );

        return Promise.resolve(sfRequestResult);
      }

      const sfRequestResult = new SalesforceRequestResult(
        false,
        "Invalid request parameters",
        null
      );

      return Promise.reject(sfRequestResult);
    }

    if (this._params === null) {
      const sfRequestResult = new SalesforceRequestResult(
        false,
        "Invalid request; parameters not available",
        null
      );

      return Promise.reject(sfRequestResult);
    }

    const params = this._params;

    if (loggingVerbosity > 1) {
      console.log("%cSalesforce request inner params:", CONSOLE_COLOR, params);

      console.log(
        "%cSalesforce request uses access token:",
        CONSOLE_COLOR,
        params.checkUsesAccessToken()
      );
    }

    //NOTE: If this call is auth-based, we check the token's issuedDate to determine if access has expired.
    //      This prevents making a request that is expected to fail.

    if (params.checkUsesAccessToken()) {
      const tokenIssuedDate = new Date(Number(params.issuedAt));

      loggingVerbosity > 1 &&
        console.log(
          "%cSalesforce request token issue date:",
          CONSOLE_COLOR,
          tokenIssuedDate
        );

      const tokenTooOld =
        Date.now() - tokenIssuedDate.getTime() > MAX_TOKEN_AGE;

      if (tokenTooOld) {
        loggingVerbosity > 0 &&
          console.log(
            "Access token is too old (" +
              ((Date.now() - tokenIssuedDate.getTime()) / 60 / 1000).toFixed(
                1
              ) +
              " min), attempting refresh prior to call"
          );

        const preemptiveTokenRefresher = new SFAccessTokenRefresher(params);

        if (preemptiveTokenRefresher.isValid()) {
          loggingVerbosity > 1 && console.log("Preemptive token refresh");

          this._refreshAttempted = true;

          const preemptiveRefreshResponse =
            await preemptiveTokenRefresher.start();

          // check the killswitch after every await
          if (SalesforceRequest.s_sessionEndedForReauth) {
            return Promise.resolve(
              SalesforceRequest._WriteDroppedSessionResult(this.requestID, 1)
            );
          }

          // check this before anything else; if token refresh fails, we send the user back through Oauth
          if (preemptiveRefreshResponse.reauth === sfReauthConfirmationTag) {
            // Tokens failed! This session is over, and the user will have to reauthorize.
            await this._ReauthorizeInvalidTokens();

            const sfRequestResult = new SalesforceRequestResult(
              false,
              "Preemptive refresh failed for request ID " + this.requestID,
              null
            );

            return Promise.resolve(sfRequestResult);
          }

          if (!preemptiveRefreshResponse) {
            throw new Error(
              "missing/invalid response from preemptive token refresh"
            );
          }
          loggingVerbosity > 1 &&
            console.log("Preemptive token refresher responded");
          const { access_token, issued_at, token_type } =
            preemptiveRefreshResponse;

          //TODO: Now that 'params' is a class, we should update these together via params.UpdateToken(token, time)
          params.accessToken = `${token_type} ${access_token}`;
          params.issuedAt = issued_at;
          params.headers.set("Authorization", `${token_type} ${access_token}`);
        } else {
          throw new Error("Preemptive access token refresher is invalid!");
        }
      } else {
        const stillValidTokenIssuedDate = new Date(Number(params.issuedAt));

        // token is still valid
        let duration = intervalToDuration({
          start: stillValidTokenIssuedDate,
          end: new Date(Date.now() - MAX_TOKEN_AGE),
        });

        loggingVerbosity > 0 &&
          console.log(
            "Access token will refresh in",
            formatDuration(duration, { delimiter: ", " })
          );
      }
    }
    // else call does not need access token, i.e. does not require auth

    try {
      loggingVerbosity > 0 &&
        console.log(
          "%csending Salesforce request",
          CONSOLE_COLOR,
          params.getMethod(),
          params.path
        );
      const initialRequestResponse = await fetch(params.path, {
        method: params.getMethod(),
        headers: params.headers,
        ...(params.getMethod() !== "GET" && { body: params.getBody() }),
      });

      // check the killswitch after every await
      if (SalesforceRequest.s_sessionEndedForReauth) {
        return Promise.resolve(
          SalesforceRequest._WriteDroppedSessionResult(this.requestID, 2)
        );
      }

      if (initialRequestResponse.ok) {
        const sfRequestResult = new SalesforceRequestResult(
          true,
          "",
          initialRequestResponse
        );

        if (params.getContentType() === "application/json") {
          await sfRequestResult.AttachJson();

          // check the killswitch after every await
          if (SalesforceRequest.s_sessionEndedForReauth) {
            return Promise.resolve(
              SalesforceRequest._WriteDroppedSessionResult(this.requestID, 3)
            );
          }
        }

        return Promise.resolve(sfRequestResult);
      }

      if (initialRequestResponse.status === 401) {
        console.log("401 error with request; ID: " + this.requestID);

        if (!params.checkUsesAccessToken()) {
          throw new Error(
            "401 received by SF call that does not use access tokens"
          );
        }

        // limit each call to one refresh attempt
        if (this._refreshAttempted) {
          //NOTE: This throw is not set in stone. It suggests that we hit a 401
          //      _after_ a successful preemptive refresh, within the blink of an
          //      eye. That's extremely unlikely. But! Since this is confusing, heavily
          //      async code, there could certainly be a case we're missing at
          //      the moment.
          throw new Error("401 redundant attempt to refresh on rejected token");
        }

        this._refreshAttempted = true;

        // Backup refresh time. This is that case that some how a refresh token was issued within the same day but expired
        // This case would be strange as the tokens should have been refreshed

        const rejectedTokenRefresher = new SFAccessTokenRefresher(params);

        if (rejectedTokenRefresher.isValid()) {
          loggingVerbosity > 0 &&
            console.log("Attempting rejected-token refresh");

          const rejectionRefreshResponse = await rejectedTokenRefresher.start();

          // check the killswitch after every await
          if (SalesforceRequest.s_sessionEndedForReauth) {
            return Promise.resolve(
              SalesforceRequest._WriteDroppedSessionResult(this.requestID, 4)
            );
          }

          // check this before anything else; if token refresh fails, we send the user back through Oauth
          if (rejectionRefreshResponse.reauth === sfReauthConfirmationTag) {
            // Tokens failed! This session is over, and the user will have to reauthorize.
            await this._ReauthorizeInvalidTokens();

            const sfRequestResult = new SalesforceRequestResult(
              false,
              "Refresh failed for call ID " + this.requestID,
              null
            );

            return Promise.resolve(sfRequestResult);
          }

          if (!rejectionRefreshResponse) {
            throw new Error(
              "missing/invalid response from rejected-token refresh"
            );
          }

          loggingVerbosity > 0 &&
            console.log("Updating original request's auth params");

          const { access_token, issued_at, token_type } =
            rejectionRefreshResponse;
          params.accessToken = `${token_type} ${access_token}`;
          params.issuedAt = issued_at;
          params.headers.set("Authorization", `${token_type} ${access_token}`);

          loggingVerbosity > 0 &&
            console.log("Repeating original request with new token");

          const followUpResponse = await fetch(params.path, {
            method: params.getMethod(),
            headers: params.headers,
            ...(params.getMethod() !== "GET" && { body: params.getBody() }),
          });

          // check the killswitch after every await
          if (SalesforceRequest.s_sessionEndedForReauth) {
            return Promise.resolve(
              SalesforceRequest._WriteDroppedSessionResult(this.requestID, 5)
            );
          }

          if (followUpResponse.ok) {
            const sfRequestResult = new SalesforceRequestResult(
              true,
              "",
              followUpResponse
            );

            if (params.getContentType() === "application/json") {
              await sfRequestResult.AttachJson();

              // check the killswitch after every await
              if (SalesforceRequest.s_sessionEndedForReauth) {
                return Promise.resolve(
                  SalesforceRequest._WriteDroppedSessionResult(
                    this.requestID,
                    6
                  )
                );
              }
            }

            return Promise.resolve(sfRequestResult);
          }

          //NOTE: Technically the initial call _was_ sent, but it came back 401 (SF access token expired). Then the
          //      attempt to refresh that token failed.
          //      We return a result with 'call not sent', which is generally interpreted as 'Firebase session expired'.
          //      In this case, that's basically true. The session is doomed, because this user has lost oauth2.
          const sfRequestResult = new SalesforceRequestResult(
            false,
            "failed call on follow-up to token refresh (_refreshAttempted " +
              this._refreshAttempted +
              ")",
            followUpResponse
          );

          return Promise.reject(sfRequestResult);
        }

        // if this is the (rare) invalid-tokens scenario, don't error out to sentry
        if (checkLSReauthHold()) {
          return Promise.resolve(
            SalesforceRequest._WriteDroppedSessionResult(this.requestID, 7)
          );
        }

        console.warn(
          "unhandled follow up to 401 response; " +
            "valid: " +
            rejectedTokenRefresher.isValid() +
            ", _refreshAttempted: " +
            this._refreshAttempted
        );
      }

      // the original call was not 'ok'; if it was 401, the refresh was not valid

      console.error(
        "unexpected salesforce call response",
        initialRequestResponse.status,
        initialRequestResponse.statusText
      );

      throw new Error("unexpected salesforce call response");
    } catch (e) {
      console.error("request to salesforce caught", e);
      if (this._params === null) {
        console.log("null call");
      } else {
        console.log("params: " + this._params.writeDevDetailsReport());
      }
      const errorText = (e as any).message;
      throw new Error("failed to perform request to salesforce: " + errorText);
    }
  }
}
