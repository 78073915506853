import * as React from "react";
import Modal from "@mui/material/Modal";
import Copyright from "@config/Copyright";
import ModalBody from "../../Shared/ModalBody";
import ModalHeader from "../../Shared/ModalHeader";
import ModalBackground from "../../Shared/ModalBackground";
import SalesforceAuthModalBody from "./SalesforceAuthModalBody";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

interface SalesforceAuthModalProps {
  open: boolean;
  reconnect: boolean;
}

const redirectTimeMS = 10.4 * 1000; // slightly over ten, so the countdown starts less abruptly

export default function SalesforceAuthModal(props: SalesforceAuthModalProps) {
  const matches = useMediaQuery("(min-width:600px)");
  const arrivalTime = React.useRef(Date.now());
  const redirected = React.useRef(false);
  const timerThread = React.useRef<null | NodeJS.Timer>(null);
  const [timeNow, setTimeNow] = React.useState(Date.now());
  const [triggerRedirect, setTriggerRedirect] = React.useState(false);
  const urlProcessed = React.useRef(false);
  const [errorMessage, setErrorMessage] = React.useState<null | string>(null);
  const [salesforceLoginHint, setSalesforceLoginHint] = React.useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();


  if (props.reconnect) {
    // in reconnect mode, we need the user's email to pass along to Oauth
    if (!urlProcessed.current) {
      urlProcessed.current = true; // only try this once

      if (!searchParams.has('login_hint')) {
        setErrorMessage('Login Hint is missing.')
      }
      else {
        const usernameFromURL = searchParams.get('login_hint')
        if (usernameFromURL === null) {
          setErrorMessage('Unable to get username for salesforce.')
        }
        else {
          setSalesforceLoginHint(decodeURIComponent(usernameFromURL))
        }
      }
    }

    if (timerThread.current !== null) {
      // cleanup and re-create every render
      clearInterval(timerThread.current);
      timerThread.current = null;
    }

    if (timeNow - arrivalTime.current < redirectTimeMS) {
      // we're still counting down
      timerThread.current = setInterval(() => {
        setTimeNow(Date.now());
      },
        100);
    }
  }

  const KillAutoRedirect = () => {
    redirected.current = true;
    if (timerThread.current !== null) {
      // kill the countdown timer
      clearInterval(timerThread.current);
      timerThread.current = null;
      arrivalTime.current = 0; // to prevent any new timers on the way out
    }
  };

  const tickdownDuration = timeNow - arrivalTime.current;
  const tickdownSeconds = Math.max(0, Math.round((redirectTimeMS - tickdownDuration) / 1000));

  if (tickdownSeconds <= 0 && !redirected.current) {
    redirected.current = true;
    setTriggerRedirect(true);
    return null;
  }

  return (
    <div className="modal_container_div">
      <Modal
        open={props.open}
        aria-labelledby="auth_modal"
        aria-describedby="modal used to authenticate user"
      >
        <ModalBackground color="primary">
          <ModalBody
            sx={{
              width: matches ? "50%" : "80%",
              minWidth: 250,
              maxWidth: 400,
              minHeight: 300,
            }}
          >
            {errorMessage !== null
              ?
              <>
                <Typography variant={"body1"} >
                  Error: {errorMessage}<br /><br /><br /><br />
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    navigate("/login")
                  }}
                >
                  Return to Login
                </Button>
                <br /><br />
              </>
              :
              <>
                {props.reconnect
                  ?
                  <>
                    <ModalHeader title="Reconnect" />
                    <Typography variant={"body1"} >
                      We need to reconnect you to Salesforce.<br />
                      You will be redirected in {tickdownSeconds}...<br /><br />
                    </Typography>
                  </>
                  :
                  <ModalHeader title="Sign Up" />
                }
                <SalesforceAuthModalBody
                  killAutoRedirect={KillAutoRedirect}
                  triggerRedirect={triggerRedirect}
                  reauthorizeMode={props.reconnect}
                  salesforceLoginHint={salesforceLoginHint}
                />
              </>
            }
            <Copyright />
          </ModalBody>
        </ModalBackground>
      </Modal>
    </div>
  );
}
