import * as React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

interface FallbackProps {
  message: string;
  minWidth?: `${string}px`,
  maxWidth?: `${string}px`
}

export default function Fallback(props: FallbackProps) {
  return (
    <Box width="100%" maxWidth={props.maxWidth} minWidth={props.minWidth} display="flex" justifyContent="center" alignItems="center" flexDirection='column'>
      <CircularProgress />
      <Typography>{props.message}</Typography>
    </Box>
  );
}
