import * as React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
} from "lexical";
// import { $createCodeNode } from "@lexical/code";
import { $wrapLeafNodesInElements } from "@lexical/selection";
// import {
//   $createHeadingNode,
//   // $createQuoteNode 
// } from "@lexical/rich-text";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import {
  FormatListBulleted,
  FormatListNumbered,
  // FormatQuote,
  FormatTextdirectionLToR,
  // TextFields,
  // Title,
  // Code,
} from "@mui/icons-material";

// this is the format buttons avalible in the dropdown to the far right of editor toolbar
export default function FormatSelectPlugin({
  toolbarRef,
  setBlockType,
  // setCodeLanguage,
  blockType,
}: {
  toolbarRef: React.Ref<any>;
  setBlockType: any;
  // setCodeLanguage: any;
  blockType: string;
}) {
  const [editor] = useLexicalComposerContext();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("paragraph");

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $wrapLeafNodesInElements(selection, () => $createParagraphNode());
      }
    });
  };

  // const formatLargeHeading = () => {
  //   editor.update(() => {
  //     const selection = $getSelection();

  //     if ($isRangeSelection(selection)) {
  //       $wrapLeafNodesInElements(selection, () => $createHeadingNode("h1"));
  //     }
  //   });
  // };

  // const formatSmallHeading = () => {
  //   editor.update(() => {
  //     const selection = $getSelection();

  //     if ($isRangeSelection(selection)) {
  //       $wrapLeafNodesInElements(selection, () => $createHeadingNode("h2"));
  //     }
  //   });
  // };
  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  // const formatQuote = () => {
  //   editor.update(() => {
  //     const selection = $getSelection();

  //     if ($isRangeSelection(selection)) {
  //       $wrapLeafNodesInElements(selection, () => $createQuoteNode());
  //     }
  //   });
  // };

  // const formatCode = () => {
  //   editor.update(() => {
  //     const selection = $getSelection();

  //     if ($isRangeSelection(selection)) {
  //       $wrapLeafNodesInElements(selection, () => $createCodeNode());
  //     }
  // });
  // };

  const handleClose = (event: React.SyntheticEvent) => {
    setOpen(false);
  };
  const handleOpen = (event: React.SyntheticEvent) => {
    setOpen(true);
  };
  const handleSelection = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setValue(value);
    switch (value) {
      case "paragraph":
        setBlockType("paragraph");
        formatParagraph();
        return blockType;
      // case "large":
      //   setBlockType("large heading");
      //   formatLargeHeading();
      //   return blockType;
      // case "small":
      //   setBlockType("small heading");
      //   formatSmallHeading();
      //   return blockType;
      case "bullet":
        setBlockType("bullet list");
        formatBulletList();
        return blockType;
      case "number":
        setBlockType("numbered list");
        formatNumberedList();
        return blockType;
      // case "quote":
      //   setBlockType("quote");
      //   formatQuote();
      //   return blockType;
      // case "code":
      //   setBlockType("code");
      //   formatCode();
      //   return blockType;
      default:
        // console.log(blockType);
        return blockType;
    }
  };

  return (
    <FormControl ref={toolbarRef} size="small">
      <Select
        open={open}
        value={value}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleSelection}
        size="small"
        variant="standard"
        disableUnderline
        IconComponent={() => null}
        SelectDisplayProps={{
          style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        sx={{
          width: "1rem",
          height: "1.22rem",
          overflow: "clip",
          m: ".25rem",
          mb: 0,
          color: (theme) => theme.palette.primary.light,
        }}
      >
        <MenuItem value="paragraph">
          <Tooltip title="Paragraph">
            <FormatTextdirectionLToR fontSize="small" />
          </Tooltip>
        </MenuItem>
        {/* <MenuItem value="large">
          <Tooltip title="Large Heading">
            <TextFields fontSize="small" />
          </Tooltip>
        </MenuItem>
        <MenuItem value="small">
          <Tooltip title="Small Heading">
            <Title fontSize="small" />
          </Tooltip>
        </MenuItem> */}
        <MenuItem value="bullet">
          <Tooltip title="Bullet List">
            <FormatListBulleted fontSize="small" />
          </Tooltip>
        </MenuItem>
        <MenuItem value="number">
          <Tooltip title="Numbered List">
            <FormatListNumbered fontSize="small" />
          </Tooltip>
        </MenuItem>
        {/* <MenuItem value="quote">
          <Tooltip title="Quote">
            <FormatQuote fontSize="small" />
          </Tooltip>
        </MenuItem> */}
        {/* <MenuItem value="code">
          <Tooltip title="Code Block">
            <Code fontSize="small" />
          </Tooltip>
        </MenuItem> */}
      </Select>
    </FormControl>
  );
}
