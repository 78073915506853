import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { FormControlLabel, Checkbox, Select, FormControl, MenuItem, InputLabel } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  minHeight: "200px"
};

interface ConfirmModalProps {
  actionLabel: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmed: any;
  onToggleChange?: any;
  toggleLabel?: string;
  toggleValue?: boolean;
  selectOptions?: { label: string, value: string }[];
  selectLabel?: string;
  selectDefaultValue?: string
  body?: Record<string, any>
}

type GroupToReassignToo = string

export default function ConfirmModal(props: ConfirmModalProps) {
  const { open, setOpen, onConfirmed, actionLabel, toggleLabel, onToggleChange, toggleValue, selectOptions, selectLabel, selectDefaultValue, body } = props;
  const [selectValue, setSelectValue] = React.useState<GroupToReassignToo>(selectDefaultValue || "")


  const handleClose = () => {
    setOpen(false);
    if (typeof onToggleChange === 'function') {
      onToggleChange('reset')
    }
  };

  return (
    <div>
      <Modal
        disableAutoFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm action modal"
        aria-describedby="are you sure you would like to do this action"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" sx={{ color: (theme) => theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black, textAlign: "center", maxWidth: '280px', wordWrap: "break-word" }}>
            {actionLabel}
          </Typography>
          {body &&
            <Box>
              {Object.keys(body).map((key) => {
                const value = body[key]
                if (!value) return null
                return <Typography variant="body2" key={key}>{`${key}: ${value}`}</Typography>
              })}
            </Box>
          }

          {toggleLabel && (
            <>
              <FormControlLabel onChange={onToggleChange} control={<Checkbox value={toggleValue} />} label={toggleLabel} />
            </>
          )}
          {Array.isArray(selectOptions) &&
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id={`${selectLabel}_Input-Label`}>{selectLabel}</InputLabel>
              <Select
                label={selectLabel}
                labelId={`${selectLabel}_Input-Label`}
                id={`${selectLabel}_Input`}
                value={selectValue}
                onChange={(dataFromSelect) => {
                  const groupToAssignMovedTaskToo = dataFromSelect.target.value
                  setSelectValue(groupToAssignMovedTaskToo)
                }}
              >
                <MenuItem key={`${selectLabel}_Select-placeholder`} value={"doNotReassign"}>Do not re-assign</MenuItem>
                {selectOptions.map((option) => {
                  return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          }
          <div style={{ display: "flex", marginTop: 14 }}>
            <Button
              variant="outlined"
              sx={{ mr: 1 }}
              onClick={() => {
                setOpen(false)
                if (typeof onToggleChange === 'function') {
                  onToggleChange('reset')
                }
              }
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (selectValue === "doNotReassign") {
                  onConfirmed()
                } else {
                  onConfirmed({ groupToReassignToo: selectValue })
                }
                setOpen(false);
                if (typeof onToggleChange === 'function') {
                  onToggleChange('reset')
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
    </div >
  );
}
