import isYesterday from "date-fns/isYesterday";
import isTomorrow from "date-fns/isTomorrow";
import isToday from "date-fns/isToday";
import format from "date-fns/format";
import { parseISO } from "date-fns";
/**
 * @param Date | String
 *
 * @return returns a human readable format of a date for displays
 */
export default function createDateLabel(curDate: Date | string) {
  if (typeof curDate === "string") {
    curDate = parseISO(curDate);
  }
  return isYesterday(curDate)
    ? "Yesterday"
    : isToday(curDate)
    ? "Today"
    : isTomorrow(curDate)
    ? "Tomorrow"
    : format(curDate, "eeee"); // full day "Tuesday"
}
