import * as React from "react";
import ReactPhoneInput from "react-phone-input-material-ui";
import { Link, TextField, TextFieldProps, Typography } from "@mui/material";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from "react-hook-form";

interface AppFormInputPhoneProps {
  name: string;
  control: any;
  label: string;
  readonly?: boolean
  inputProps?: TextFieldProps
}



function AppFormInputPhone(props: AppFormInputPhoneProps) {
  const { name, control, label, readonly, inputProps } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={function ({
        field,
        formState,
        fieldState
      }: {
        field: ControllerRenderProps<FieldValues, string>;
        fieldState: ControllerFieldState;
        formState: UseFormStateReturn<FieldValues>;
      }): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        if (readonly) return (
          <>
            <Typography variant="caption">{label}</Typography>
            <Link fontSize="large" fontWeight="bold" component="a" href={`tel:${field.value}`}>
              {field.value ? formatPhoneNumber(field.value) : "-"}
            </Link>
          </>
        )
        return (
          <>
            <ReactPhoneInput
              onBlur={field.onBlur}
              onChange={(data) => {
                if (formatPhoneNumber(data)) {
                  field.onChange(formatPhoneNumber(data))
                  return
                }
                field.onChange(data)
                return
              }}
              autoFormat // on/off phone formatting
              disabled={formState.isSubmitting}
              value={field.value}
              inputProps={{ label, ...inputProps }}
              component={TextField}
              isValid={!!!fieldState.error}
              defaultErrorMessage={fieldState.error?.message}
              onlyCountries={["us"]}
            />
            {fieldState.error && (
              <Typography variant="body2" color="error">{fieldState.error.message}</Typography>
            )}
          </>
        );
      }}
    />
  );
}

export function formatPhoneNumber(phoneNumberString?: string) {
  if (!phoneNumberString) return undefined
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return undefined;
}


export default AppFormInputPhone;
