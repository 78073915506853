import * as React from 'react'
import Drawer, { DrawerProps } from "@mui/material/Drawer"

interface StyledDrawerProps extends DrawerProps {

}

export default function StyledDrawer(props: StyledDrawerProps) {

  return (
    <Drawer
      {...props}
      anchor="left"
    >
      {props.children}
    </Drawer>
  )
}