import * as React from "react";
import User from "@classes/User";
import useProfile from "@hooks/Selectors/useProfile";

export const ModalContext = React.createContext({});
export const OpenModal = React.createContext({});
export const CloseModal = React.createContext({});

export default function LoginModalProvider({ children }: { children: any }) {
  const [open, setOpen] = React.useState<boolean>(true);
  const user = useProfile();
  const openModal = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeModal = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  React.useEffect(() => {
    if (user instanceof User) { // i.e. user !== null
      closeModal();
    } else {
      openModal();
    }
  }, [closeModal, openModal, user]);
  return (
    <ModalContext.Provider value={open}>
      <OpenModal.Provider value={openModal}>
        <CloseModal.Provider value={closeModal}>{children}</CloseModal.Provider>
      </OpenModal.Provider>
    </ModalContext.Provider>
  );
}
