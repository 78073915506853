import { ConfirmActionMessage } from '../Helpers'
import DefaultConfirmModal from './DefaultConfimModal'


//! FINISHED DONT TOUCH
/**
 * 
 * @param curAction : 'resetTask' | 'startTask' | 'pauseTask' | 'deferTask' | 'claimTask'
 * @returns Will return a confirm modal to prevent accidently doing actions on tasks
 */
export default function ConfirmModal(props: { curAction: string, handleConfirm: any, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
  return (
    <DefaultConfirmModal
      open={props.open}
      setOpen={props.setOpen}
      onConfirmed={props.handleConfirm}
      actionLabel={ConfirmActionMessage(props.curAction)}
    />
  )
}