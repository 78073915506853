import { useMediaQuery } from "@mui/material"

const DesktopWidth = 1250
const TabletWidth = 850
// const MobileWidth = 428

export enum uiModes {
  DesktopWidth,
  MobileWidth,
  TabletWidth
}

export function useWindowWidth() {
  const desktopWidth = useMediaQuery(`(min-width: ${DesktopWidth}px)`)
  const tabletWidth = useMediaQuery(`(min-width: ${TabletWidth}px)`)

  if (desktopWidth) {
    // console.log("DESKTOP")
    return uiModes.DesktopWidth
  }

  if (tabletWidth) {
    // console.log("TABLET")
    return uiModes.TabletWidth
  }

  // console.log("MOBILE")
  return uiModes.MobileWidth

}