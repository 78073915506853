import * as React from "react";
import createDateLabel from "@helpers/dateLabel";
import { Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import TaskSkeleton from "./Skeleton";
import useDocumentTitle from "@hooks/Window/useDocTitle";

import NewTaskActionButton from "../TaskControls/ActionButtons/NewTaskActionButton"
import TaskCompactCard from "./TaskCompactCard"
import TaskGroup from "./TaskGroup"
import NoTaskMask from "./NoTaskMask"
import processError from "../../../utils";
import { environment } from "@config/index";
import { TaskChangeShouldRefreshView } from "@context/Task/TaskDrawer";
import useSalesforceRequest from "@request/Salesforce";
import TaskDrawer from "./TaskDrawer";
import { ApiTask, TaskGroupKey } from "../../../Types/task";
import { isEndpointsAllowedToRequest } from "@context/Task";
// import { defaultSortTaskFormValues, FullScreenSortMenu, SortTaskFormFieldValues } from "./SearchPagenation/Items/SortTasksMenu";
// import { FormProvider, useForm } from "react-hook-form";

const VerboseLogging = true

interface TaskListProps {
  taskGroupKey: TaskGroupKey
}
// Currently this component is being used to render unassigned and overdue tasks
export default function TaskList(props: TaskListProps) {
  // const [openSort, setOpenSort] = React.useState(false)
  // const sortContainerRef = React.useRef<HTMLDivElement>(null)
  // const sortFormData = useForm<SortTaskFormFieldValues>({ defaultValues: defaultSortTaskFormValues })
  const [taskState, setTaskState] = React.useState<APP_STATE>('unset')
  const [tasksFromResponse, setTasksFromResponse] = React.useState<ApiTask[]>([])
  const [hiddenGroup, setShowGroup] = React.useState<ApiTask["type"][]>([]);
  const [groupedTasks, setGroupedTasks] = React.useState<Record<string, ApiTask[]>>({});
  const [taskFriendlyErrorMessageState, setTaskFriendlyErrorMessageState] = React.useState("")
  const responsePendingKey = React.useRef<string>("")
  const processingResponse = React.useRef<boolean>(false)
  const { taskGroupKey } = props
  const [shouldRefreshTaskList, setShouldRefreshTaskList] = React.useContext(TaskChangeShouldRefreshView)
  const taskListRequest = useSalesforceRequest()
  React.useEffect(() => {
    if (shouldRefreshTaskList === true) {
      setShouldRefreshTaskList(false)
      responsePendingKey.current = ""
    }
  })

  // Here we are setting the mobile header
  let titleText = taskGroupKey.slice(0, -5).concat(" Tasks");
  if (titleText.length === 0) {
    titleText = 'Tasks';
  } else {
    // capitalize first letter
    titleText = titleText[0].toUpperCase() + titleText.substring(1);
  }
  useDocumentTitle(titleText);

  // this variable is setting a key to index into a task
  // if our taskGroupKey from props is unassignedTasks or overdueTasks then sort by due_date
  // if our taskGroupKey from props is "Engineering", "Housekeeping", or "Guest Experience" then sort by "Queue"
  // if none of those match then sort by type
  const taskKey = ['unassingedTasks', 'overdueTasks'].includes(taskGroupKey)
    ? 'due_date' :
    checkActionIsTypeQueue(taskGroupKey)
      ? "Queue" :
      "type";



  const handleHideGroup = React.useCallback((key: ApiTask["type"]) => {
    const isHidden = hiddenGroup.includes(key);
    if (isHidden) {
      setShowGroup((prev) => {
        return prev
          .slice(0, prev.indexOf(key))
          .concat(prev.slice(prev.indexOf(key) + 1));
      });
    } else {
      setShowGroup((prev) => [...prev, key]);
    }
    return !isHidden;
  }, [hiddenGroup]);

  // Here we are fetching tasks
  //----------- start duped from task search -------------
  React.useEffect(() => {
    VerboseLogging && console.log("getting tasks")
    // if the past taskGroupKey is equal to the new taskGroupKey from props.
    //? It seems instead of creating a new component react tries to recycle the old one.
    // Create a string ref that is of the last fetched taskGroupKey
    // compare that to the current taskGroupKey to see if we can see if we should ignore the superceded call.
    if (taskGroupKey && responsePendingKey.current === taskGroupKey) {
      VerboseLogging && console.log("repeat request. Exiting get tasks.")
      return
    }

    // if (oldTaskGroupKey.current !== taskGroupKey && responsePending.current === false) {
    responsePendingKey.current = taskGroupKey;
    setTaskState('loading')
    //! currently I am handling curQueue with an undefined value. Try and make this defined to better handle in getTaskAction
    let endpoint = checkActionIsTypeQueue(taskGroupKey) ? '/api/task?action=queueTasks' : `/api/task?action=${taskGroupKey}`;
    VerboseLogging && console.log("current endPoint", endpoint)
    const getTasks = taskListRequest({ endpoint })
    getTasks.attemptSend().then((tasksResult) => {
      if (!tasksResult.callSent) { return null; } // session expired

      const json = tasksResult.GetParsedJson();
      if (json.hasOwnProperty('tasks')) {
        setTasksFromResponse(json.tasks)
        processingResponse.current = true
        setTaskState('success')
        return
      }
      throw new Error("Failed to get tasks")
    }).catch((e: any) => {
      const taskFriendlyErrorMessage = processError(e, `Failed to get ${taskGroupKey}`)
      setTaskFriendlyErrorMessageState(taskFriendlyErrorMessage)
      setTasksFromResponse([])
      setTaskState('error')
    })
  }, [taskGroupKey, taskListRequest])

  //----------- end duped from task search -------------

  //This useEffect is stepping through the fetched taskList and building our groupedTasks object
  React.useEffect(() => {
    // here we are looping through all task being sure not to loop task if it is not an array or if the array is empty
    // if processing hasen't already begun
    if (processingResponse.current === true) {
      // start processing
      // If our fetched response is successfull and returned an array of tasks. "Not empty"
      if (Array.isArray(tasksFromResponse) && tasksFromResponse.length > 0) {
        if (taskKey !== 'Queue') {
          let taskSortedByTaskTypeGrouping: Record<string, ApiTask[]> = {}
          tasksFromResponse.forEach((task) => {
            const groupId = task[taskKey]
            if (groupId) {
              taskSortedByTaskTypeGrouping =
                taskSortedByTaskTypeGrouping[groupId]
                  ? { ...taskSortedByTaskTypeGrouping, [groupId]: [...taskSortedByTaskTypeGrouping[groupId], task] }
                  : { ...taskSortedByTaskTypeGrouping, [groupId]: [task] }

            }
          })
          setGroupedTasks(taskSortedByTaskTypeGrouping)
        } else {
          let taskSortedByDueDateGrouping: Record<string, ApiTask[]> = {}
          tasksFromResponse.forEach((task) => {
            const groupId =
              createDateLabel(new Date(task.due_datetime)) +
              ", " +
              format(new Date(task.due_datetime), "MMMM do");
            if (groupId) {
              taskSortedByDueDateGrouping = taskSortedByDueDateGrouping[groupId] ? { ...taskSortedByDueDateGrouping, [groupId]: [...taskSortedByDueDateGrouping[groupId], task] } :
                { ...taskSortedByDueDateGrouping, [groupId]: [task] }
            }
          });
          setGroupedTasks(taskSortedByDueDateGrouping)
        }
      }
      processingResponse.current = false

    }
  }, [taskKey, tasksFromResponse])
  if (!taskGroupKey.length) return null;


  return (
    <>
      <TaskDrawer />
      {/* <Stack direction="row" mr={"10px"} alignItems="center" justifyContent="space-between"> */}
      <Typography
        variant="h5"
        fontWeight={800}
        sx={{ textTransform: "capitalize", color: (theme) => theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black }}
      >
        {taskGroupKey.slice(0, -5)} Tasks
      </Typography>
      {/* <div ref={sortContainerRef}>
          <FormProvider {...sortFormData}>
            <FullScreenSortMenu openSort={openSort} setOpenSort={setOpenSort} anchorRef={sortContainerRef} />
          </FormProvider>
        </div> */}
      {/* </Stack> */}
      <Stack spacing={0} width="100%">
        {processingResponse.current === false && taskState === 'success' &&
          <>
            {Object.values(groupedTasks).length === 0 && <NoTaskMask />}
            {Object.values(groupedTasks).length > 0
              && Object.keys(groupedTasks).map((groupTaskTitle) => {
                return (
                  <div key={groupTaskTitle}>
                    <TaskGroup
                      taskKey={taskKey}
                      size={groupedTasks[groupTaskTitle].length}
                      toggleGroup={() => {
                        handleHideGroup(groupTaskTitle);
                      }}
                      hidden={hiddenGroup.includes(groupTaskTitle)}
                      title={
                        //! There was a bug here. testing to see if its fixed
                        createGroupingLabel(taskKey, groupTaskTitle)
                      }
                      sx={{ marginBottom: 0 }}
                    />
                    {!hiddenGroup.includes(groupTaskTitle) && // here we compare it to see if this property is in the hidden array
                      groupedTasks[groupTaskTitle].map((task) => (
                        <TaskCompactCard
                          task={task}
                          key={`${task.id}_compact_card`}
                        />
                      ))}
                  </div>
                );
              })}
          </>
        }
        {processingResponse.current === true && <TaskSkeleton />}
        {taskState === 'loading' && <TaskSkeleton />}
        {taskState === 'error' && <h1>{taskFriendlyErrorMessageState}</h1>}
      </Stack>
      <NewTaskActionButton />
    </>
  );
}

function createGroupingLabel(taskKey: string, groupTitle: string) {
  // the problem is that this dateString is updating as fast as its being called throughout page navigation
  if (["Maintenance", "Drop", "Inspection", "Clean"].includes(groupTitle)) {
    // This check will help us handle the groupTitle taking a while to reload to the next value
    return groupTitle
  }
  try {
    const resultFromFormingDateGrouping = taskKey === "due_date"
      ? createDateLabel(new Date(`${groupTitle}T10:00:00.000Z`)) +
      ", " +
      format(new Date(`${groupTitle}T10:00:00.000Z`), "MMMM do")
      : groupTitle
    return resultFromFormingDateGrouping
  } catch (e: any) {
    if (environment.env === "development") {
      throw new Error(e)
    }
    return groupTitle
  }
}

/**
 *
 * @param action string
 * @returns boolean
 */
export function checkActionIsTypeQueue(action: string) {
  return isEndpointsAllowedToRequest(action)
}