import * as React from "react";
import { StyledBoxContainer } from "@containers/StyledBoxContainer";
import ListingDetail from "@components/Task/Sub_Components/TaskCard/RelatedRow/ListingDetail";
import { Button, ButtonGroup, Chip, Typography } from "@mui/material";
// import FileManager from "@components/FileManager";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { editorConfig } from "@components/Messages/plugins/config";
import MessagesTextEditor from "@components/Messages/MessageComponent";
// import { FileManagerWorking, useGetTaskAttachments } from "@context/Attachments";
import { AppTabs } from "../Tabs/AppTabs";
import { sortTasksByDueDateForListing } from "./ListingTaskSortHelpers";
import { FilterList as SortIcon } from "@mui/icons-material";
import TaskCompactCard from "@components/Task/Sub_Components/TaskCompactCard";
import TaskFormModal from "@components/Task/Modals/TaskFormModal";
import TaskDrawer from "@components/Task/Sub_Components/TaskDrawer";
import { TaskDrawerSubject } from "@context/Task/TaskDrawer";
import { SortDirectionKey } from "../Task/Sub_Components/sortTasks";

type actionType = "Open" | "Overdue" | "Closed"
const actions: actionType[] = ['Open', 'Closed', 'Overdue']

const createListingTasksEndpoint = (id: string) => {
  const Endpoints = {}
  actions.forEach((action) => {
    Object.assign(Endpoints, { [action]: `/api/task?action=listing${action}Tasks&listing_id=${id}` })
  })
  return Endpoints
}


export function ListingPageContent(props: { listing: ApiListing; onRefresh: () => void; }) {
  const [newTaskModal, setNewTaskModal] = React.useState(false);
  const refreshTaskList = React.useRef(false)
  // const { fileManagerWorking } = React.useContext(FileManagerWorking);
  const [sortTasksDirection, setSortTasksDirection] = React.useState<SortDirectionKey>("asc");
  // const loadedAttachments = React.useRef(false);
  // const [attachmentsMeta, setAttachmentsMeta] = React.useState<any[]>([]);
  // const { getTaskAttachments, getTaskAttachmentsContent } = useGetTaskAttachments();
  // React.useEffect(() => {
  //   if (fileManagerWorking === false) {
  //     loadedAttachments.current = false;
  //   }
  // }, [fileManagerWorking]);

  // React.useEffect(() => {
  //   if (props.listing === null) return
  //   if (loadedAttachments.current === true) return

  //   loadedAttachments.current = true;
  //   //? request to get attachments meta
  //   getTaskAttachments(props.listing.id).then((meta) => {
  //     if (Array.isArray(meta) && meta.length) {
  //       setAttachmentsMeta(meta);
  //       //? request to get attachments content from the meta array gathered in the step before.
  //       meta.forEach(value => getTaskAttachmentsContent(value));
  //     }
  //   });

  // }, [props, getTaskAttachments, getTaskAttachmentsContent]);

  // This set of variables and functions will refresh the tabs list on an edit from the task drawer.
  const [curTaskInDrawer] = React.useContext(TaskDrawerSubject)
  const taskInDrawer = React.useRef(curTaskInDrawer)
  React.useEffect(() => {
    if (curTaskInDrawer !== null) {
      if (taskInDrawer.current !== curTaskInDrawer) {
        refreshTaskList.current = true
      }
    }
  }, [curTaskInDrawer])


  // Final check on listings
  if (props.listing === null) {
    // loadedAttachments.current = false;
    return null;
  }

  return (
    <div className="Listing-Page_Container">
      <TaskDrawer />
      <div className="Listing-Page_Header">
        <StyledBoxContainer
          elevation={2}
          title={`${props.listing.unit_id}・${props.listing.name_detailed}`}
          chipComponent={<Chip
            className="Listing-Page-Status_Chip"
            size="small"
            label={props.listing.listing_status}
            color={props.listing.listing_status === "Occupied"
              ? "primary"
              : props.listing.listing_status === "Vaccant"
                ? "secondary"
                : undefined} />}>
          <div className="Listing-Page_Header-Content">
            <ListingDetail listing={props.listing} noTitle />
          </div>
        </StyledBoxContainer>
      </div>
      <div className="Listing-Task_Container">
        <div className="Listing-Page-Section">
          <TaskFormModal
            refreshAttachments={() => { return null }}
            taskId={undefined}
            attachmentsMeta={[]}
            setAttachmentsMeta={() => undefined}
            open={newTaskModal}
            close={() => setNewTaskModal(false)}
            curTask={null}
            setCurTask={(__a, __b) => {
              refreshTaskList.current = true
              return null;
            }}
          />
          <StyledBoxContainer
            title="Tasks"
            chipComponent={<ButtonGroup>
              <Button
                type="button"
                variant="contained"
                disableElevation
                disableFocusRipple
                disableTouchRipple
                disableRipple
                onClick={() => {
                  setNewTaskModal(true);
                }}>
                New Task
              </Button>
              <Button
                type="button"
                disableFocusRipple
                disableTouchRipple
                disableRipple
                onClick={() => {
                  if (sortTasksDirection === "asc") {
                    setSortTasksDirection("desc");
                    return;
                  }
                  setSortTasksDirection("asc");
                  return;
                }}>
                <div className="Filter_By_Duedate_Time-Inner-Button">
                  {sortTasksDirection !== 'asc' ? <Typography color="secondary" fontSize="small" mr={'5px'} fontWeight="bold">Oldest</Typography> : <Typography color="primary" fontSize="small" mr={'5px'} fontWeight="bold">Newest</Typography>}
                  {sortTasksDirection !== 'asc' ? <SortIcon color="secondary" /> : <SortIcon color="primary" />}
                </div>
              </Button>
            </ButtonGroup>}>
            <div className="Listing-Page_Tabs">
              <AppTabs
                refreshData={refreshTaskList}
                tabEndpoints={createListingTasksEndpoint(props.listing.id)}
                componentPropKey="task"
                component={TaskCompactCard}
                sortFunction={sortTasksByDueDateForListing}
                sortDirection={sortTasksDirection}
                pagination
                maxPerPage={5}
              />
            </div>
          </StyledBoxContainer>
        </div>
        <div className="Listing-Page-Section">
          <StyledBoxContainer disableScrollToo title="Messages" className='Task-Page-Title-Border'>
            <div className="Task-Messages-Container">
              {/* <FileManager setAttachmentsMeta={setAttachmentsMeta} attachmentsMeta={attachmentsMeta} delayedAttachments={[]} /> */}
              <LexicalComposer initialConfig={editorConfig}>
                <MessagesTextEditor
                  disableAttach
                  curTaskOrListing={props.listing} />
              </LexicalComposer>
            </div>
          </StyledBoxContainer>
        </div>
      </div>
    </div>
  );
}
