import { FileManagerWorking, useGetTaskAttachments } from "@context/Attachments";
import { HourglassBottom } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import {
  TaskDrawerControl,
  TaskDrawerLoading,
  TaskDrawerSubject
} from "../../../Context/Task/TaskDrawer";
import TaskFormModal from "../Modals/TaskFormModal";
import TaskCard from "./TaskCard";

//https://api-dev.mauiresortrentals.com/services/apexrest/api/task/00T8C0000033bknUAA
export default function TaskDrawer() {
  const [taskFormOpen, setTaskFormOpen] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [taskDrawerOpen, toggleDrawer] = React.useContext(TaskDrawerControl);
  const { fileManagerWorking } = React.useContext(FileManagerWorking)
  const [curTask, setCurTask] = React.useContext(TaskDrawerSubject);
  const [taskDrawerLoading] = React.useContext(TaskDrawerLoading)
  const [fetchedAttachmentsMeta, setFetchedAttachmentsMeta] = React.useState<any[]>([])
  const [loadingAttachments, setLoadingAttachments] = React.useState<APP_STATE>("unset")
  const { getTaskAttachments } = useGetTaskAttachments()

  React.useEffect(() => {
    if (fileManagerWorking === false) {
      setLoadingAttachments('unset')
      return
    }
    setLoadingAttachments('loading')
  }, [fileManagerWorking])

  React.useEffect(() => {
    if (curTask === null) return
    if (loadingAttachments !== 'unset') return
    setLoadingAttachments('loading')
    getTaskAttachments(curTask.id).then((meta) => {
      setFetchedAttachmentsMeta(meta)
      setLoadingAttachments("success")
    }).catch(() => {
      setLoadingAttachments("error")
    })
  }, [loadingAttachments, curTask, getTaskAttachments])

  //DO NOT TOUCH --- this will refresh the attachments state when the curTask changes
  React.useEffect(() => {
    setLoadingAttachments('unset')
  }, [setCurTask])


  return (
    <>
      <TaskFormModal
        refreshAttachments={() => { setLoadingAttachments('unset') }}
        setAttachmentsMeta={setFetchedAttachmentsMeta}
        attachmentsMeta={fetchedAttachmentsMeta}
        curTask={curTask}
        setCurTask={setCurTask}
        close={() => { setTaskFormOpen(false) }}
        taskId={curTask?.id}
        open={taskFormOpen} />
      <Drawer
        BackdropComponent={Backdrop}
        anchor={"right"}
        open={taskDrawerOpen}
        transitionDuration={{ exit: 200, enter: 600 }}
        onClose={() => {
          toggleDrawer(false);
          setCurTask(null);
          return null
        }}
        onKeyDown={(event) => {
          if (event.key === "ESC") {
            toggleDrawer(false);
            setCurTask(null);
            return null
          }
        }}
      >
        {taskDrawerLoading && <Grid
          container
          sx={{ width: '100dvw', maxWidth: 428, height: '100%' }}
          display="flex" justifyContent='center' alignItems='center'>
          <Grid item>
            <HourglassBottom fontSize="medium" className="spin-me" shapeRendering="circle" color="disabled" />
          </Grid>
          <Grid item ml={1}>
            <Typography fontSize='medium' color="disabled">Loading task...</Typography>
          </Grid>
        </Grid>}
        {!taskDrawerLoading && <TaskCard openForm={taskFormOpen} setOpenForm={setTaskFormOpen} curTask={curTask} setCurTask={setCurTask} />}
      </Drawer>
    </>
  );
}
