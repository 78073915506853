import * as React from 'react';
import { uiModes, useWindowWidth } from "@config/Ui";
import { TaskDrawerControl } from "@context/Task/TaskDrawer";
import { Autorenew } from "@mui/icons-material";
import { Chip, Typography } from "@mui/material";
import { format, isValid } from "date-fns";
import { AppTypographyBody, CardItem } from "../../../../Typography/CardItemText";


interface ListingDetailsI {
  listing: ApiListing
  hideGapRepeat?: boolean
  fullTitle?: boolean
  noTitle?: boolean
  // minBreakPoint: number
  forceVerticalStyle?: boolean
}

export default function ListingDetail(props: ListingDetailsI) {
  const screenWidthMode = useWindowWidth()
  const [, toggleDrawer] = React.useContext(TaskDrawerControl);
  if (!props.listing) return null;
  const drawVerticalMode = props.forceVerticalStyle || screenWidthMode === uiModes.MobileWidth;
  const hideGapRepeat = props.hideGapRepeat !== undefined ? props.hideGapRepeat : false
  return (
    <div className="Task-Card-Item-Container">
      <div className="Task-Card-Item-Row No-Margin">
        {/* noTitle will not render a title row */}
        {/* {props.noTitle === true && <>No title</>} */}
        {props.fullTitle === true &&
          <div className="Task-Card-Item-Row Split-Flex No-Margin">
            <Typography variant="h6" className="BoxContent_Title">{props.listing.unit_id}・{props.listing.name_detailed}</Typography>
            <Chip
              className="BoxContent_Title_Chip"
              size="small"
              label={props.listing.listing_status}
              color={
                props.listing.listing_status === "Occupied"
                  ? "primary"
                  : props.listing.listing_status === "Vaccant"
                    ? "secondary"
                    : undefined
              }
            />

          </div>
        }
        {/* {props.fullTitle && props.noTitle && <>Odd</>} */}
        {/* {props.fullTitle !== true && <>Short</>} */}
        {/* {props.noTitle !== true && <>Has title</>} */}
        {props.fullTitle !== true && props.noTitle !== true &&
          <div className="Task-Card-Item-Row No-Margin">
            <span>
              <CardItem header="Listing" text={props.listing.name_detailed} linkProps={{ to: `/listing/${props.listing.listing_id || props.listing.id}`, onClick: () => toggleDrawer(false) }} />
            </span>
          </div>
        }
      </div>
      <div className="Task-Card-Item-Row">
        <AppTypographyBody text={createListingDescription(props.listing)} />
      </div>
      {
        !hideGapRepeat &&
        <>
          {drawVerticalMode &&
            <div className={"Task-Card-Item-Row"}>
              {createManagerLessorRow(props.listing).map((detail) => {
                if (detail.value === undefined) return null // this will hide any empty items
                return (
                  <div className={"Task-Card-Item-Row-Split"} key={`Task-Extended-${detail.label}`}>
                    <CardItem header={detail.label} text={detail.value} />
                  </div>
                )
              })}
            </div>
          }
          <div className="Task-Card-Item-Row">
            <div className="Task-Card-Item-Row-Split">
              <CardItem header="Check Out" text={props.listing.check_out && isValid(new Date(props.listing.check_out)) ? format(new Date(props.listing.check_out), "MMM dd'・'h:mm a") : "-"} />
            </div>

            <div className="Task-Card-Item-Row-Split">
              <CardItem
                header="Next Check In"
                text={isValid(new Date(props.listing.next_check_in)) ? format(new Date(props.listing.next_check_in), "MMM dd'・'h:mm a") : "-"}
                textIcon={props.listing.turnaround ? Autorenew : undefined}
              />
            </div>

            {!drawVerticalMode && createManagerLessorRow(props.listing).map((detail) => {
              return (
                <div className="Task-Card-Item-Row-Split" key={detail.label}>
                  <CardItem header={detail.label} text={detail.value} />
                </div>)
            })}

          </div>
        </>
      }
    </div >
  );
}

function createBedroomsLabel(numBeds: number | undefined) {
  switch (numBeds) {
    case undefined:
      return "- bedrooms";
    case 0:
      return "Studio";
    case 1:
      return "1 bedroom";
    default:
      return `${numBeds} bedrooms`;
  }
}
function createBathroomsLabel(numBaths: number | undefined) {
  if (numBaths === undefined) {
    return " - baths";
  }
  if (numBaths > 1) {
    return `${numBaths} baths`;
  }
  return `${numBaths} bath`;
}

function createManagerLessorRow(listing: ApiListing) {
  return [
    { label: "Manager", value: listing.owner_name },
    { label: "Lessor", value: listing.lessor_name },
  ]
}

function createListingDescription(listing: ApiListing) {
  let label = createBedroomsLabel(listing.bedrooms) +
    "・" +
    createBathroomsLabel(listing.bathrooms)
  if (typeof listing.unit_view === 'string' && listing.unit_view !== "") {
    label = label + "・" +
      listing.unit_view
  }
  return label
}
