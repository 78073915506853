import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import orginization from "./config.json";

export default function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href={orginization.website}>
        {`${orginization.name}`}
      </Link>
      {` ${new Date().getFullYear()}`}
    </Typography>
  );
}
