import { styled } from "@mui/system";
import Box from "@mui/material/Box";

const ModalBackground = styled(
  Box,
  {}
)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
}));

export default ModalBackground;
