import * as React from "react";
import AppFormInputDropdown from "../../../Inputs/AppFormInputDropdown";
import { TaskFormProps as InnerTaskFormProps, roleEngineer } from "./TaskFormDefault";
import { TaskFormLabel } from "./TaskFormLabel";
import { TaskStatusOptions, PriorityOptions, TaskShuttleNumPersonsOptions, ShuttleLocationOption } from "@classes/Task/taskStrings";
import AppFormInputAutoComplete from "@components/Inputs/AppFormInputAutoComplete";
import AppFormInputTextareaAutosize from "@components/Inputs/AppFormInputTextareaAutosize";
import { Grid, Stack } from "@mui/material";
import { DueDateInput } from "./DueDateInput";
import AppFormInputRadio from "@components/Inputs/AppFormInputRadio";

export function ShuttleServiceForm(props: InnerTaskFormProps) {
  const {
    listings, relatedToReadOnly, assigneeInputEnabled, control, currentUser, mode, subTypes, taskAssignees, taskReporters, getValues
  } = props;

  const isEngineerEdit = React.useMemo(() => currentUser !== null && currentUser.title === roleEngineer && mode === 'Edit', [mode, currentUser]);
  const showReadOnlyRelated = React.useMemo(() => relatedToReadOnly && listings && mode === 'Edit', [mode, listings, relatedToReadOnly]);
  //-------------- This will handle the Header of the form----------
  return (
    <div id="task-shuttle-form">
      {subTypes && (
        <Stack direction='row' spacing={2}>
          <div style={{ flexGrow: 1 }}>
            <TaskFormLabel label="Sub Type" />
            <AppFormInputDropdown
              control={control}
              name="sub_type"
              options={subTypes}
              sx={{ maxHeight: "41px" }} />
          </div>
          <div>
            <TaskFormLabel label="Waiver" />
            <AppFormInputRadio
              control={control}
              name="waiver_verified"
              width='100%'
              height={41}
              sx={{ display: 'flex' }}
            />
          </div>
        </Stack>
      )}
      <div id="edit_task_assigned_to">
        <TaskFormLabel label="Assignee" />
        <AppFormInputAutoComplete
          grouped
          required
          type="text"
          control={props.control}
          name="assignee"
          readOnly={isEngineerEdit || !assigneeInputEnabled}
          options={taskAssignees || [{ label: "Loading...", value: undefined }]} />
      </div>
      <div>
        <TaskFormLabel label="Pick Up Location" />
        <AppFormInputDropdown
          control={control}
          name="pickup_location"
          options={['', ...ShuttleLocationOption]}
          sx={{ maxHeight: "41px" }} />
      </div>
      <div>
        <TaskFormLabel label="Drop Off Location" />
        <AppFormInputDropdown
          control={control}
          name="dropoff_location"
          options={['', ...ShuttleLocationOption]}
          sx={{ maxHeight: "41px" }} />
      </div>
      <DueDateInput control={control} label='Time' />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            <TaskFormLabel label="Adults" />
            <AppFormInputDropdown
              control={control}
              name="number_of_adults"
              options={TaskShuttleNumPersonsOptions}
              sx={{ maxHeight: "41px" }} />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <TaskFormLabel label="Children" />
            <AppFormInputDropdown
              control={control}
              name="number_of_children"
              options={TaskShuttleNumPersonsOptions}
              sx={{ maxHeight: "41px" }} />
          </div>
        </Grid>
      </Grid>
      <div id="task_description">
        <AppFormInputTextareaAutosize
          control={props.control}
          label="Description"
          name="description" />
      </div>
      {showReadOnlyRelated && getValues('related') !== null &&
        <div className="task_related_to">
          <TaskFormLabel label="Related To" />
          <AppFormInputAutoComplete
            readOnly={relatedToReadOnly}
            control={control}
            name="related"
            options={listings || []} />
        </div>}
      {TaskStatusOptions && (
        <div id="edit_task_status">
          <TaskFormLabel label="Status" />
          <AppFormInputDropdown
            control={control}
            name="status"
            options={TaskStatusOptions}
            sx={{ maxHeight: "41px" }} />
        </div>
      )}
      <div id="edit_task_reporter">
        <TaskFormLabel label="Reporter" />
        <AppFormInputAutoComplete
          required={true}
          grouped
          control={props.control}
          name="reporter"
          loading={taskReporters === null}
          options={taskReporters || [{ label: "Loading...", value: undefined }]} //[[Autocomplete undef. value]]
        />
      </div>
      <div id="edit_task_priority">
        <TaskFormLabel label="Priority" />
        <AppFormInputDropdown
          control={control}
          name="priority"
          options={PriorityOptions}
          sx={{ maxHeight: "41px" }} />
      </div>
    </div>
  );
}
