import { auth, firestore } from "@config/Firebase/app";
import { doc, getDoc } from "firebase/firestore";

const verboseLogging = true;

export default async function validateAndRetriveUserDoc(
  emailIdentifier: string
) {
  // exit if there is no authorized user to interact with the firestore
  if (auth.currentUser === null) {
    verboseLogging && console.log("Exiting; no user found.");
    return;
  }
  // throw an error if users email is invalid
  if (typeof emailIdentifier !== "string" || emailIdentifier === "") {
    throw new Error("Please provide a valid email identifier.");
  }

  // Create Ref to user in firestore
  const userRef = doc(firestore, "Users", emailIdentifier);
  // Get Ref from firestore
  const userDoc = await getDoc(userRef);
  // If there is no user in the firestore something has gone a major rye. The user wouldn't even be able to make it to this point or there db entry is currupted.
  if (!userDoc.exists) throw new Error("User document does not exist.");
  try {
    // parse firestore data
    const existingUserData = userDoc.data();
    // for users who have not logged in after Jan 31st will not have the message_ids array on their user document yet.
    if (!existingUserData) {
      verboseLogging && console.warn("User does not exist in firestore.");
      return;
    }

    return existingUserData;
  } catch (e) {
    console.error(e);
    return null;
  }
}
