import * as React from "react";
import { useLocation } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import "./styles.css";
import useDocumentTitle from "@hooks/Window/useDocTitle";
import NoUsers from "./NoUsers";
import useAppDispatch from "@hooks/Dispatch/useAppDispatch";
import LoadingCompactCard from "../LoadingCompactCard";
import { ApiUserList, PersistentObject, updatePersistentObjects } from "@store/Reducers/PersistentObject";
import usePersistentObject from "@hooks/Selectors/usePersistentObject";

import TeamsCard from "./TeamsCard"
import { Box } from "@mui/system";

// ? Category should be allQueues
// ? SubCategory should be the name of the group

export default function TeamsList() {
  const { pathname } = useLocation();
  const [loaded, setLoaded] = React.useState(false)
  const dispatch = useAppDispatch()
  useDocumentTitle(pathname.split("/teams/")[1] || "Teams")
  const persistentObject = usePersistentObject()
  const queryString = camelize(decodeURIComponent(pathname.substring("teams/".length + 1))) as ApiUserList

  React.useEffect(() => {
    if (!persistentObject.checkUserList(queryString)) {
      setLoaded(false)
      // this is here to be sure that loaded doesn't equal true until after the dispatch
      Promise.resolve(dispatch(updatePersistentObjects(PersistentObject.user, queryString))).finally(() => { setLoaded(true) })
    }
  }, [dispatch, queryString, persistentObject])
  if (loaded === false) return (
    <div className="Loading-Users-Container">
      <LoadingCompactCard />
      <LoadingCompactCard />
      <LoadingCompactCard />
    </div>
  )
  if (loaded === true) {
    if (!persistentObject.checkUserList(queryString)) {
      return <ThisGroupDoesNotExist />;
    }

    if (persistentObject.getUserList(queryString).length === 0) {
      return <ThisGroupDoesNotHaveAnyMembers />
    }
  }
  return (
    <>
      {loaded === true && persistentObject.checkUserList(queryString) && Array.isArray(persistentObject.getUserList(queryString)) && persistentObject.getUserList(queryString).length > 0 && (
        <Stack spacing={2}>
          {/* Here we know we have done a fetch since our map has a list, but its empty */}
          {persistentObject.getUserList(queryString).length === 0 && <NoUsers />}
          {persistentObject.getUserList(queryString).length !== 0 && persistentObject.getUserList(queryString).sort((a: ApiUser, b: ApiUser) => {
            if (!a.name || !b.name) {
              return 0
            }
            if (a.name > b.name) {
              return 1
            }
            if (a.name < b.name) {
              return -1
            }
            return 0
          }).map((sortedUser: any) => {
            // const user = new User({ ...sortedUser })
            return (
              <TeamsCard user={sortedUser} key={sortedUser.id} />
            );
          })}
        </Stack>
      )}
    </>
  );
}


function ThisGroupDoesNotExist() {
  return (
    <Box width="100%" height="80vh" display='flex' justifyContent='center' alignItems='center'>
      <Typography color="GrayText">This group does not exist :(</Typography>
    </Box>
  )
}

function ThisGroupDoesNotHaveAnyMembers() {
  return (
    <Box width="100%" height="80vh" display='flex' justifyContent='center' alignItems='center'>
      <Typography color="GrayText">This team has no members.</Typography>
    </Box>
  )
}

function camelize(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}