import User from "@classes/User";
import { auth, firestore } from "@config/Firebase/app";
import { salesforce_api } from "@config/index";
import Store from "@store/index";
import { setUser } from "@store/Reducers/Session";
import { doc, updateDoc } from "firebase/firestore";
import { SalesforceRequestParams } from "./SalesforceRequestParams";

//NOTE: We make this very deliberate, as opposed to a simple bool, because it's a major interruption
//      to the user experience. We want minimal risk of tripping it accidentally.
export const sfReauthConfirmationTag = "reauthConfirmed";

export class SFAccessTokenRefresher {
  private readonly _path: string;
  private readonly _headers: Headers;

  private readonly _userEmail: string = "";
  private _refreshToken: string = "";
  private _accessToken: string = "";
  private _issuedAt: string = "";
  private readonly _salesforceUsername: string = "";

  constructor(
    //NOTE: So far we only use the auth-params side of the full SalesforceRequestParams object. We could
    //      restructure so that we only pass in a SalesforceRequestAuthParams, but that's work which
    //      doesn't really solve anything. We made accessors for this reason, and we might want some of
    //      the information from the call-params side of SalesforceRequestParams down the road.
    readonly authParams: SalesforceRequestParams
  ) {
    if (!authParams.isValid()) {
      throw new Error("Building token refresher with invalid request params!");
    }

    //TODO: Rather than copy in each of these, consider just keeping a private ref (this._authParams).
    this._userEmail = authParams.getEmail();
    this._refreshToken = authParams.getRefreshToken();
    this._accessToken = authParams.accessToken;
    this._issuedAt = authParams.issuedAt;
    this._salesforceUsername = authParams.getSalesforceUsername();
    this._path = `${salesforce_api.api_host}/services/oauth2/token?grant_type=refresh_token&client_id=${salesforce_api.client_id}&client_secret=${salesforce_api.secret}&refresh_token=${this._refreshToken}`;
    this._headers = new Headers();
    this._headers.set("Content-Type", "application/json");
    this._headers.set("x-api-key", salesforce_api.x_api_key);
  }

  isValid() {
    let valid = true;
    if (!this._accessToken) {
      console.warn("Invalid access token");
      valid = false;
    }
    if (!this._refreshToken) {
      console.warn("Invalid refresh token");
      valid = false;
    }
    if (!this._userEmail) {
      console.warn("Invalid email");
      valid = false;
    }
    if (!this._salesforceUsername) {
      console.warn("Invalid username");
      valid = false;
    }
    return valid;
  }

  async start() {
    try {
      const refreshResponse = await fetch(this._path, {
        method: "POST",
        headers: this._headers,
      });

      if (refreshResponse.ok) {
        console.log("REFRESHER: response ok");
        const { access_token, issued_at, token_type } =
          await refreshResponse.json();
        console.log("REFRESHER: ", access_token, issued_at, token_type);
        this._accessToken = `${token_type} ${access_token}`;
        this._issuedAt = issued_at;
        if (this._userEmail) {
          if (!auth || auth.currentUser === null) {
            // const errorMessage = "Firebase auth lost prior to token refresh";
            // throw new Error(errorMessage);
            //TODO: Redirect to login.
            //TODO: There may be a larger issue. Investigate attaching Firebase status
            //      to the rules for protected routes.
          }

          const fireStoreDocumentRef = doc(firestore, "Users", this._userEmail);
          await updateDoc(fireStoreDocumentRef, {
            sf_access_token: `${token_type} ${access_token}`,
            sf_issued_at: issued_at,
          });
        }
        const loggedInUser = await Store.getState().session.user;
        if (loggedInUser !== null) {
          const newUser = new User(loggedInUser);
          newUser.refreshAccess_Token(
            `${token_type} ${access_token}`,
            issued_at
          );
          await Store.dispatch(setUser(newUser));
          // spammy
          //console.log("REFRESHER: dispatched user", newUser);
        }
        return { access_token, issued_at, token_type };
      } else {
        const refreshResponseBlob = await refreshResponse.blob();
        if (refreshResponseBlob.type === "application/json") {
          const refreshResponseBody = JSON.parse(
            await refreshResponseBlob.text()
          );
          /*
            //? This article has more documentation on oauth error codes:
            https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_flow_errors.htm&type=5
          */
          if (refreshResponseBody.error === "invalid_grant") {
            console.log(
              "tokens failed to refresh; user must reauth with salesforce"
            );

            // IMPORTANT: This is end of session! The user's tokens are no longer valid.
            //						We send back this special value to inform the caller of this rare (but
            //						entirely valid!) scenario.

            return { reauth: sfReauthConfirmationTag };
          }
        }
      }
      throw new Error(
        "refresh response is not ok: " +
          refreshResponse.ok +
          ", status: " +
          refreshResponse.status +
          ", statusText: " +
          refreshResponse.statusText
      );
    } catch (e: any) {
      console.warn("Failed to update salesforce access token", e);
      return Promise.reject(e);
    }
  }
}
