import * as React from "react";
import { SignUpAuthContext } from "@context/Auth";
import { Navigate } from "react-router-dom";

export default function RedirectWithToken() {
  const loadingAuth = React.useContext(SignUpAuthContext);
  return <>{!loadingAuth && <Navigate to="/signup" />}</>;
}

//http://localhost:3000/oauth2/signup?display=popup&code=aPrxPg5a_X29KrNLtsNvC_RR2Vd5Tasjh1f1KR9ohISzsUE.pDHEVki9_YK3sh_M8A7MfRUcKQ%3D%3D&state=%2Fsignup
