import * as React from "react";
import Grid from "@mui/material/Grid";
import "./appFriendlyErrorMessage.css"

interface AppFriendlyErrorMessageProps {
  appFriendlyErrorMessageState: string;
}

export function AppFriendlyErrorMessage(props: AppFriendlyErrorMessageProps) {
  return (
    <Grid item container sx={{
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      bgcolor: "#8d2c2c",
      color: "white",
      borderRadius: "4px",
      alignSelf: "center",
      left: "10%",
      top: "20%",
      width: '80%',
      textAlign: 'center',
      fontSize: '0.8em',
      padding: '4px'
    }}>
      <div className="AppFriendlyErrorMessage">{props.appFriendlyErrorMessageState}</div>
    </Grid>
  );
}
