/**
 * @params files to be converted to base 64 encoded strings from e.target.files "attachments input component"
 * @return Promise<DataUrls | undefined>[]
 */
export default async function createFileAsDataUrlObjects(
  filesToBeConvertedByFileReader: FileList
) {
  const filesAsDataUrlsPromiseArray: any[] = [];

  for (let file of filesToBeConvertedByFileReader) {
    const reader = new FileReader();
    filesAsDataUrlsPromiseArray.push({
      meta: file,
      value: await new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(file);
      }),
    });
  }

  return Promise.resolve(filesAsDataUrlsPromiseArray);
}

export async function createFileAsDataUrlObject(file: File | null) {
  if (file === null) return {};
  const reader = new FileReader();
  const fileAsDataUrlObject = {
    meta: file,
    value: await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    }),
  };

  return Promise.resolve(fileAsDataUrlObject);
}
