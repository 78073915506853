import User from "@classes/User";
import { UserSettings, defaultTaskOrder } from "./UserSettings";

const defaultUserSettings = new UserSettings(false, defaultTaskOrder);

export class Session {
  constructor(
    public user: User | null = null,
    public userSettings: UserSettings = defaultUserSettings,
    public readonly sessionStart: number = Date.now()
  ) {}

  endSession() {
    this.user = null;
    this.userSettings = defaultUserSettings;
  }
}
