import * as React from "react";
import { Button } from "@mui/material";
import { FallbackProps } from "react-error-boundary";



export function TaskFormErrorFallback(props: FallbackProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1.2em', margin: '20px 0' }}>
      <div style={{ paddingBottom: '15px' }}>
        There is a problem with this task.
      </div>
      <Button
        variant='contained'
        style={{ width: 'fit-content' }}
        onClick={() => {
          props.resetErrorBoundary();
        }}
      >
        Back
      </Button>
    </div>);
}
;
