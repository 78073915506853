import * as React from "react";
import { FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import AppFormInputProps from "./AppFormInputProps";

export default function AppFormInputAutoComplete({
  name,
  control,
  label,
  required,
  helperText,
  variant,
  options,
  disabled = false,
  whenChanged,
  grouped = false,
  readOnly,
  loading,
  freeSolo
}: AppFormInputProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required === true }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl
            required={required === true}
            disabled={disabled === true}
            fullWidth
          >
            <Autocomplete
              loading={loading}
              readOnly={readOnly}
              noOptionsText="No Options"
              freeSolo={freeSolo} // Set freeSolo to true so the textbox can contain any arbitrary value.
              disabled={disabled === true}
              onChange={(event, item) => {
                //! needed to make controled component
                if (readOnly) return;
                if (!item) return;
                if (disabled) return;
                onChange(item);
                if (whenChanged) {
                  whenChanged(event, item);
                }
              }}
              key={label}
              clearIcon={false}
              inputValue={
                typeof value === "string"
                  ? value
                  : typeof value?.label === 'string'
                    ? value.label // used to separate the value and text input strings
                    : ''}
              fullWidth
              groupBy={grouped ? (option) => option?.group : undefined}
              options={options || []} //! required
              value={value} //! needed for controlled components
              getOptionLabel={(item) => {
                if (typeof item === "string") return item;
                return item?.label ? item.label : item?.value ? item.value : "";
              }}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value.value;
              }}
              renderInput={(
                params //! required
              ) => {
                return (
                  <TextField
                    {...params}
                    disabled={readOnly}
                    label={label || ""}
                    variant={variant || "outlined"}
                    error={!!error}
                    helperText={helperText}
                    value={value?.value} //[[Autocomplete undef. value]]
                    //KEEP: value={value?.value || "VALUE UNDEFINED"}
                    onChange={(e) => {
                      return name && ["status", "type"].includes(name)
                        ? () => null
                        : onChange(e);
                    }}
                    required={required === true}
                    fullWidth
                    sx={{
                      ".MuiAutocomplete-input": { padding: "0 !important" },
                    }}
                  />
                );
              }}
            />
          </FormControl>
        );
      }}
    />
  );
}
