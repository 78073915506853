import * as React from "react";
import {
  AlertProps,
  Snackbar,
  SnackbarProps,
  Alert as MuiAlert,
  Typography,
  Divider,
  Slide,
  SlideProps,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

const removeUnderscores = (stringWithUnderscores: string) =>
  stringWithUnderscores.split("_").join("");

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return (
    <MuiAlert
      action={false}
      elevation={props?.elevation || 6}
      ref={ref}
      variant={props?.variant || "filled"}
      {...props}
      severity={props?.severity || "success"}
    />
  );
});

export interface AppSnackBarProps extends SnackbarProps {
  variant?: "standard" | "filled" | "outlined" | undefined;
  elevation?: number;
  severity?: "success" | "error" | "warning" | "info";
  onClose?: any
}

export default function AppSnackBar(props: AppSnackBarProps) {
  let {
    message,
    title,
    color,
    transitionDuration,
    anchorOrigin,
    autoHideDuration,
    children,
    variant,
    elevation,
    severity,
    onClose
  } = props;

  if (typeof message === "string" && message.includes("_")) {
    message = removeUnderscores(message);
  }

  if (message instanceof Error) {
    message = message.message;
  }

  autoHideDuration ??= 5000;
  // transitionDuration ??= { appear: 2, enter: 2, exit: 2 };
  title ??= message + "::snackbar";
  color ??= "primary";
  severity ??= "info";
  anchorOrigin ??= { vertical: "top", horizontal: "right" };


  return (
    <Snackbar
      {...props}
      title={title}
      color={color}
      transitionDuration={transitionDuration}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      TransitionComponent={SlideTransition}
      ClickAwayListenerProps={{
        "onClickAway": onClose
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClose()
      }}
      sx={{ mt: 2 }}
    >
      <Alert
        severity={severity}
        variant={variant}
        elevation={elevation}
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {children
          ? children
          : <Typography
            fontSize={16}
            maxWidth={{ xs: 240, sm: 400, md: 600, lg: 800 }}
          >
            {message}
          </Typography>
        }
      </Alert>
    </Snackbar>
  );
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}
