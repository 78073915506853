import * as React from "react";
import Typography from '@mui/material/Typography';
import { FileManagerWorking, PreparedAttachmentType, useRemoveTaskAttachments } from "@context/Attachments";
import { LinearProgress } from "@mui/material";
import ViewDialog from "./ViewDialog"
import MultiMediaCard from "./MultiMediaCard";

const VerboseLogging = false

export interface FileManagerProps {
  attachmentsMeta: any[];
  setAttachmentsMeta: React.Dispatch<React.SetStateAction<any[]>>;
  delayedAttachments: PreparedAttachmentType[];
  disableBorder?: boolean;
}

export default function FileManager(props: FileManagerProps) {
  const { attachmentsMeta, delayedAttachments, setAttachmentsMeta } = props
  const removeAttachmentsMeta = useRemoveTaskAttachments()
  const { fileManagerWorking } = React.useContext(FileManagerWorking)
  const [dialogContent, setDialogContent] = React.useState("");

  if (VerboseLogging) {
    console.group("%c----ATTACHMENTS----", "color:green")
    console.log(attachmentsMeta)
    console.groupEnd()
  }

  const onDelete = (singleAttachmentMetaData: any) => { removeAttachmentsMeta(singleAttachmentMetaData, setAttachmentsMeta) }

  return (
    <>
      {fileManagerWorking && props.disableBorder !== true && <LinearProgress className="Multi-Media-Card-Row" />}
      <div className={`Multi_Media-Card-Outter-Container ${(!fileManagerWorking && props.disableBorder !== true) && "Multi_Media-Card-Outter-Container-Border"}`}>
        <ViewDialog dialogContent={dialogContent} setDialogContent={setDialogContent} />
        {attachmentsMeta.length > 0 && (
          <Typography variant="caption">{`Attachments (${attachmentsMeta.length})`}</Typography>
        )}
        <div className="Multi_Media-Card-Container">
          {attachmentsMeta.length > 0 && attachmentsMeta.map((singleAttachmentMetaData, i) => {
            return (
              <MultiMediaCard
                delete={() => onDelete(singleAttachmentMetaData)}
                meta={singleAttachmentMetaData.hasOwnProperty('meta') ? singleAttachmentMetaData.meta : singleAttachmentMetaData}
                key={singleAttachmentMetaData.hasOwnProperty('value') ? `${singleAttachmentMetaData.value}_${i}` : singleAttachmentMetaData.id}
                value={singleAttachmentMetaData?.value}
                openPreview={(src: string) => { setDialogContent(src) }}
              />
            )
          })}
          {delayedAttachments.length > 0 && delayedAttachments.map((pendingAttachment: any, i: number) => {
            return (
              <MultiMediaCard
                delayed={true}
                meta={pendingAttachment.hasOwnProperty('meta') ? pendingAttachment.meta : pendingAttachment}
                key={pendingAttachment.hasOwnProperty('value') ? `${pendingAttachment.value}_${i}` : pendingAttachment.id}
                value={pendingAttachment?.value}
                openPreview={(src: string) => { setDialogContent(src) }}
              />
            )
          })}
        </div>
      </div>
    </>
  );
}

