import * as React from "react";
import "./message.css";

import { RichTextPlugin as LexicalRichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin as LexicalLinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin as LexicalListPlugin } from "@lexical/react/LexicalListPlugin";
import { ClearEditorPlugin as LexicalClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { MarkdownShortcutPlugin as LexicalMarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from '@lexical/markdown'
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

import { Box, Typography } from "@mui/material";

interface AppRichTextEditorProps {
  placeholder?: string;
  taskId: string;
  disabled?: boolean;
  srollRef?: any;
}

export default function AppRichTextEditor(props: AppRichTextEditorProps) {
  return (
    <Box className="editor-outerContainer">
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner-container">
          <LexicalRichTextPlugin
            contentEditable={<LexicalContentEditable className="editor-input" />}
            placeholder={
              <div className="editor-placeholder-container">
                <div className="editor-placeholder">
                  <Typography
                    className="editor-placeholder-text"
                    fontSize="14px"
                  >
                    {props.placeholder}
                  </Typography>
                </div>
              </div>
            }
          />
          <LexicalMarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <LexicalListPlugin />
          <LexicalLinkPlugin />
          <LexicalClearEditorPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={2} />
        </div>
      </div>
    </Box>
  );
}
