import React, { createContext, useState } from "react";
import AppTheme from "./theme";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import useProfile from "@hooks/Selectors/useProfile";

type ThemeToggleType = (paramMode?: "light" | "dark" | undefined, options?: {
  sync: boolean;
}) => "light" | "dark"

type FontSizeToggleType = (paramSize?: "regular" | "large" | undefined) => "regular" | "large"

export const Theme = createContext(createTheme());
export const ThemeToggle = createContext<ThemeToggleType>(() => "light");
export const FontSizeToggle = createContext<FontSizeToggleType>(() => "regular");
export const ThemeSize = createContext<"regular" | "large">("regular");

const useToggleTheme = (
  themeMode: "light" | "dark" = "light",
  fontSize: "regular" | "large" = "regular"
) => {
  const user = useProfile()
  const [mode, setMode] = useState<"light" | "dark">(themeMode);
  const [size, setSize] = useState<"regular" | "large">(fontSize);
  const setTheme = (paramMode?: "light" | "dark", options = { sync: true }) => {
    if (!paramMode) {
      setMode((prev) => {
        if (options.sync) {
          user !== null && user.setDarkMode(prev === "light" ? true : false)
        }
        if (prev === "light") return "dark";
        return "light";
      });
      return mode;
    }
    if (options.sync) {
      user !== null && user.setDarkMode(paramMode === 'dark')
    }
    setMode(paramMode);
    return paramMode;
  };
  const setFontSize = (paramSize?: "regular" | "large") => {
    if (!paramSize) {
      setSize((prev) => {
        if (prev === "regular") return "large";
        return "regular";
      });
      return size;
    }
    setSize(paramSize);
    return paramSize;
  };
  return { mode, setTheme, size, setFontSize };
};

export default function AppThemeProvider({ children }: { children: any }) {
  const { mode, setTheme, size, setFontSize } = useToggleTheme();
  const appTheme = React.useMemo(() => {
    return AppTheme({ mode, fontSize: size });
  }, [mode, size]);
  return (
    <Theme.Provider value={appTheme}>
      <ThemeToggle.Provider value={setTheme}>
        <FontSizeToggle.Provider value={setFontSize}>
          <ThemeSize.Provider value={size}>
            <CssBaseline />
            <ThemeProvider theme={appTheme}>{children}</ThemeProvider>
          </ThemeSize.Provider>
        </FontSizeToggle.Provider>
      </ThemeToggle.Provider>
    </Theme.Provider>
  );
}
